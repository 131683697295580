import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import getStoriesByCollection from "../../../utils";
import AuthorWithTime from "../../molecules/author-with-time";
import { Link } from "@quintype/components";
import { CardImage } from "../../atoms/card-image";
import { CollectionName } from "../../atoms/collection-name";
import { Headline } from "../../atoms/headline";
import NlSenaWrapper from "../../pages/nl-sena-wrapper";

import "./seven-stories-with-campaign.m.css";

export const SevenStoriesWithCampaign = ({ collection }) => {
  const collections = collection.items.filter(collection => collection.type === "collection");
  const stories = getStoriesByCollection(collection).filter(item => item.url.slice(8,13) !== "hindi");
  if (stories.length < 1) {
    return null;
  }

  const campaignId = get(collection, ["associated-metadata", "campaign_id"], "");

  const getStoryCard = (start=0,slice) => {
    return stories && stories.slice(start, slice).map((story, index) => {
      const section = get(story, ["sections", "0"], {});
      const getLabel = get(story, ["metadata", "story-attributes", "storyattribute", 0], "");
      return (
        <div key={index} styleName="story-wrapper">
          <CardImage story={story} />
          {getLabel && <div styleName="ribbon">{getLabel}</div>}
          <div styleName="text-wrapper">
            <Link href={section["section-url"]}>
              <div styleName="section">{section["display-Name"] || section.name}</div>
            </Link>
            <Headline story={story} externalLink={true} />
            <div styleName="author-details">
              <AuthorWithTime story={story} />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
   <> <div className="full-width-with-padding" styleName="wrapper">
      <div styleName={campaignId ? "container" : ""} className="container">
        <div>
          {<CollectionName collection={collection} />}
          {campaignId ? (
            <div styleName="story-container2">{getStoryCard(0,4)}</div>
          ) : (
            <div styleName="story-container">{getStoryCard(0,6)}</div>
          )}
        </div>
        <div>
          {campaignId && collections.length > 0 && (
            <CollectionName collection={collections[0]} className="desktop-only" />
          )}
          {campaignId && (
            <div styleName="sena-wrapper">
              <NlSenaWrapper showSingleCampaign={true} campaignId={campaignId} />
            </div>
          )}
        </div>
      </div>
      
    </div>
    <div>
    {campaignId?<div styleName="story-container-bottom">{getStoryCard(4,7)}</div> :<div styleName="story-container-bottom">{getStoryCard(6,9)}</div> }
     </div> </>
  );
};

SevenStoriesWithCampaign.propTypes = {
  collection: PT.object
};
