import Axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import "./JournoTileGroup.m.css";

const MAX_JOURNALISTS_TO_SHOW = 2;

export function ExtraCount(count) {
  const toShowCount = count.count - MAX_JOURNALISTS_TO_SHOW;
  //console.log("count extra :", count);
  return (
    <div styleName="extra-tile-div">
      <p>+{toShowCount}</p>
    </div>
  );
}
ExtraCount.propType = {
  count: PropTypes.any
};

export function ViewAllModal(props) {
  const [allJournalists, setAllJournalists] = useState([]);

  useEffect(() => {
    Axios.get(
      "https://server.newslaundry.com/journalismSpectrum/journalists"
    ).then(response => {
      setAllJournalists(response.data.journalists);
    });
  }, []);
  
  // allJournalists
  // .filter(
  //   a =>
  //     true === props.journalists.some(b => a.id === b.journalistId)
  // )
  // .map((journo, i) => {
  //   console.log(journo);
  // })
  return (
    <>
      <div styleName="view-all-modal-div">
        <div styleName="modal-contents">
          <div styleName="modal-content-title-row">
            {/* <p styleName="modal-content-title">View All</p> */}
            <button
              styleName="close-btn"
              onClick={() => {
                props.modalOpen();
              }}
            >
              <FaTimes />
            </button>
          </div>
          <div styleName="modal-contents-wrapper">
            {allJournalists
              .filter(
                a =>
                  true === props.journalists.some(b => a.id === b.journalistId)
              )
              .map((journo, i) => (
                <div styleName="content-row" key={i}>
                  <img
                    src={journo.imageURL}
                    alt={journo.name}
                    styleName="content-image"
                    loading="eager"
                  />
                  <div styleName="center-flex">
                    <p styleName="content-title">{journo.name}</p>

                    <h4 styleName="card">{journo.numberOfVotes} votes</h4>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div styleName="view-all-modal-outline"></div>
    </>
  );
}

ViewAllModal.protoType = {
  journalists: PropTypes.any,
  modalOpen: PropTypes.func
};

export default function JournoTileGroup({
  journalists,
  borderXJournalists = [],
  borderYJournalists = [],
  borderPointJournalists = [],
  cellWeightX,
  cellWeightY
}) {
  // console.log({journalists});
  // console.log({borderXJournalists});
  // console.log({borderYJournalists});
  // console.log({borderPointJournalists});
  // console.log({cellWeightX});
  // console.log({cellWeightY});
  // console.log(borderXJournalists.length, "x len")
  // console.log(borderYJournalists.length, "y len")
  // console.log(borderPointJournalists.length, "point len")
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modelJournalists, setModelJornalists] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState({
    width: 200,
    height: 200
  });
  const [journalistData, setJournalistData] = useState([]);
  const photoCount = journalists.length;

  useEffect(() => {
    setWindowDimensions(() => {
      const { innerWidth: width, innerHeight: height } = window;
      //console.log(width, height);
      return {
        width,
        height
      };
    });
    function handleResize() {
      setWindowDimensions(() => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height
        };
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    Axios.get(
      "https://server.newslaundry.com/journalismSpectrum/journalists"
    ).then(response => {
      setJournalistData(response.data.journalists);
    });
  }, []);

  function modalOpen() {
    setIsModalOpen(!isModalOpen);
  }

  switch (photoCount) {
    case 1:
      return (
        <>
          <div styleName="circle-tile">
            <div styleName="circle-tile-wrapper single">
              {journalists.map((journo, j) => {
                return journalistData.map(ini => {
                  return (
                    ini.id === journo.journalistId && (
                      <>
                        <img
                          src={ini.imageURL}
                          alt={ini.name}
                          styleName="circle-tile-wrapper-img"
                        />
                        <p styleName="tooltiptext">
                          {ini.name}
                          <br />
                          <br />
                          {ini.numberOfVotes} votes{" "}
                        </p>
                      </>
                    )
                  );
                });
              })}
            </div>
            <div styleName="x-border">
              {borderXJournalists.length == 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderXJournalists[0].journalistId &&
                      ((cellWeightX + 1 ==
                        borderXJournalists[0].avgCellWeightX &&
                        cellWeightY == borderXJournalists[0].avgCellWeightY) ||
                        (cellWeightX + 1 == borderXJournalists[0].cellWeightX &&
                          cellWeightY ==
                            borderXJournalists[0].cellWeightY)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img single-x"
                          />
                          <p styleName="x-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderXJournalists.length > 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderXJournalists[0].journalistId &&
                      ((cellWeightX + 1 ==
                        borderXJournalists[0].avgCellWeightX &&
                        cellWeightY == borderXJournalists[0].avgCellWeightY) ||
                        (cellWeightX + 1 == borderXJournalists[0].cellWeightX &&
                          cellWeightY ==
                            borderXJournalists[0].cellWeightY)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img"
                          />
                          <p styleName="x-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderXJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="x-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderXJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderXJournalists.length - 1} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
            <div styleName="y-border">
              {borderYJournalists.length == 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderYJournalists[0].journalistId &&
                      ((cellWeightY + 1 ==
                        borderYJournalists[0].avgCellWeightY &&
                        cellWeightX == borderYJournalists[0].avgCellWeightX) ||
                        (cellWeightY + 1 == borderYJournalists[0].cellWeightY &&
                          cellWeightX ==
                            borderYJournalists[0].cellWeightX)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img single-y"
                          />
                          <p styleName="y-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderYJournalists.length > 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderYJournalists[0].journalistId &&
                      ((cellWeightY + 1 ==
                        borderYJournalists[0].avgCellWeightY &&
                        cellWeightX == borderYJournalists[0].avgCellWeightX) ||
                        (cellWeightY + 1 == borderYJournalists[0].cellWeightY &&
                          cellWeightX ==
                            borderYJournalists[0].cellWeightX)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img"
                          />
                          <p styleName="y-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderYJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="y-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderYJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderYJournalists.length - 1} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
            <div styleName="point-border">
              {borderPointJournalists.length > 0 &&
                borderPointJournalists.length == 1 && (
                  <>
                    {" "}
                    {journalistData.map(ini => {
                      return (
                        ini.id === borderPointJournalists[0].journalistId &&
                        ((cellWeightY + 1 ==
                          borderPointJournalists[0].avgCellWeightY &&
                          cellWeightX + 1 ==
                            borderPointJournalists[0].avgCellWeightX) ||
                          (cellWeightY + 1 ==
                            borderPointJournalists[0].cellWeightY &&
                            cellWeightX + 1 ==
                              borderPointJournalists[0].cellWeightX)) && (
                          <>
                            <img
                              src={ini.imageURL}
                              alt={ini.name}
                              styleName="circle-tile-wrapper-img"
                            />
                            <p styleName="point-tooltiptext">
                              {ini.name}
                              <br />
                              <br />
                              {ini.numberOfVotes} votes{" "}
                            </p>
                          </>
                        )
                      );
                    })}
                  </>
                )}
              {borderPointJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="point-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderPointJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderPointJournalists.length} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
          </div>
          <>
            {isModalOpen && (
              <ViewAllModal
                journalists={modelJournalists}
                modalOpen={modalOpen}
              />
            )}
          </>
        </>
      );
    case 2:
      return (
        <>
          <div styleName="circle-tile">
            <div styleName="circle-tile-wrapper double">
              {journalistData.map(ini => {
                return journalists.map((journo, j) => {
                  return (
                    ini.id === journo.journalistId &&
                    j < 3 && (
                      <>
                        <img
                          src={ini.imageURL}
                          alt={ini.name}
                          styleName="circle-tile-wrapper-img"
                        />
                        <p styleName="tooltiptext">
                          {ini.name}
                          <br />
                          <br />
                          {ini.numberOfVotes} votes{" "}
                        </p>
                      </>
                    )
                  );
                });
              })}
            </div>
            <div styleName="x-border">
              {borderXJournalists.length == 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderXJournalists[0].journalistId &&
                      ((cellWeightX + 1 ==
                        borderXJournalists[0].avgCellWeightX &&
                        cellWeightY == borderXJournalists[0].avgCellWeightY) ||
                        (cellWeightX + 1 == borderXJournalists[0].cellWeightX &&
                          cellWeightY ==
                            borderXJournalists[0].cellWeightY)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img single-x"
                          />
                          <p styleName="x-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderXJournalists.length > 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderXJournalists[0].journalistId &&
                      ((cellWeightX + 1 ==
                        borderXJournalists[0].avgCellWeightX &&
                        cellWeightY == borderXJournalists[0].avgCellWeightY) ||
                        (cellWeightX + 1 == borderXJournalists[0].cellWeightX &&
                          cellWeightY ==
                            borderXJournalists[0].cellWeightY)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img"
                          />
                          <p styleName="x-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderXJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="x-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderXJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderXJournalists.length - 1} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
            <div styleName="y-border">
              {borderYJournalists.length == 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderYJournalists[0].journalistId &&
                      ((cellWeightY + 1 ==
                        borderYJournalists[0].avgCellWeightY &&
                        cellWeightX == borderYJournalists[0].avgCellWeightX) ||
                        (cellWeightY + 1 == borderYJournalists[0].cellWeightY &&
                          cellWeightX ==
                            borderYJournalists[0].cellWeightX)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img single-y "
                          />
                          <p styleName="y-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderYJournalists.length > 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderYJournalists[0].journalistId &&
                      ((cellWeightY + 1 ==
                        borderYJournalists[0].avgCellWeightY &&
                        cellWeightX == borderYJournalists[0].avgCellWeightX) ||
                        (cellWeightY + 1 == borderYJournalists[0].cellWeightY &&
                          cellWeightX ==
                            borderYJournalists[0].cellWeightX)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img"
                          />
                          <p styleName="y-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderYJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="y-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderYJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderYJournalists.length - 1} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
            <div styleName="point-border">
              {borderPointJournalists.length > 0 &&
                borderPointJournalists.length == 1 && (
                  <>
                    {" "}
                    {journalistData.map(ini => {
                      return (
                        ini.id === borderPointJournalists[0].journalistId &&
                        ((cellWeightY + 1 ==
                          borderPointJournalists[0].avgCellWeightY &&
                          cellWeightX + 1 ==
                            borderPointJournalists[0].avgCellWeightX) ||
                          (cellWeightY + 1 ==
                            borderPointJournalists[0].cellWeightY &&
                            cellWeightX + 1 ==
                              borderPointJournalists[0].cellWeightX)) && (
                          <>
                            <img
                              src={ini.imageURL}
                              alt={ini.name}
                              styleName="circle-tile-wrapper-img"
                            />
                            <p styleName="point-tooltiptext">
                              {ini.name}
                              <br />
                              <br />
                              {ini.numberOfVotes} votes{" "}
                            </p>
                          </>
                        )
                      );
                    })}
                  </>
                )}
              {borderPointJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="point-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderPointJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderPointJournalists.length} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
          </div>
          <>
            {isModalOpen && (
              <ViewAllModal
                journalists={modelJournalists}
                modalOpen={modalOpen}
              />
            )}
          </>
        </>
      );
    case 3:
      return (
        <>
          <div styleName="circle-tile">
            <div styleName="circle-tile-wrapper triangle">
              {journalists.map((journo, j) => {
                return journalistData.map(ini => {
                  return (
                    ini.id === journo.journalistId && (
                      <>
                        <img
                          src={ini.imageURL}
                          alt={ini.name}
                          styleName="circle-tile-wrapper-img"
                        />
                        <p styleName="tooltiptext">
                          {ini.name}
                          <br />
                          <br />
                          {ini.numberOfVotes} votes{" "}
                        </p>
                      </>
                    )
                  );
                });
              })}
            </div>
            <div styleName="x-border">
              {borderXJournalists.length == 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderXJournalists[0].journalistId &&
                      ((cellWeightX + 1 ==
                        borderXJournalists[0].avgCellWeightX &&
                        cellWeightY == borderXJournalists[0].avgCellWeightY) ||
                        (cellWeightX + 1 == borderXJournalists[0].cellWeightX &&
                          cellWeightY ==
                            borderXJournalists[0].cellWeightY)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img single-x "
                          />
                          <p styleName="x-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderXJournalists.length > 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderXJournalists[0].journalistId &&
                      ((cellWeightX + 1 ==
                        borderXJournalists[0].avgCellWeightX &&
                        cellWeightY == borderXJournalists[0].avgCellWeightY) ||
                        (cellWeightX + 1 == borderXJournalists[0].cellWeightX &&
                          cellWeightY ==
                            borderXJournalists[0].cellWeightY)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img"
                          />
                          <p styleName="x-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderXJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="x-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderXJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderXJournalists.length - 1} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
            <div styleName="y-border">
              {borderYJournalists.length == 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderYJournalists[0].journalistId &&
                      ((cellWeightY + 1 ==
                        borderYJournalists[0].avgCellWeightY &&
                        cellWeightX == borderYJournalists[0].avgCellWeightX) ||
                        (cellWeightY + 1 == borderYJournalists[0].cellWeightY &&
                          cellWeightX ==
                            borderYJournalists[0].cellWeightX)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img single-y "
                          />
                          <p styleName="y-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderYJournalists.length > 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderYJournalists[0].journalistId &&
                      ((cellWeightY + 1 ==
                        borderYJournalists[0].avgCellWeightY &&
                        cellWeightX == borderYJournalists[0].avgCellWeightX) ||
                        (cellWeightY + 1 == borderYJournalists[0].cellWeightY &&
                          cellWeightX ==
                            borderYJournalists[0].cellWeightX)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img"
                          />
                          <p styleName="y-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderYJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="y-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderYJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderYJournalists.length - 1} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
            <div styleName="point-border">
              {borderPointJournalists.length > 0 &&
                borderPointJournalists.length == 1 && (
                  <>
                    {" "}
                    {journalistData.map(ini => {
                      return (
                        ini.id === borderPointJournalists[0].journalistId &&
                        ((cellWeightY + 1 ==
                          borderPointJournalists[0].avgCellWeightY &&
                          cellWeightX + 1 ==
                            borderPointJournalists[0].avgCellWeightX) ||
                          (cellWeightY + 1 ==
                            borderPointJournalists[0].cellWeightY &&
                            cellWeightX + 1 ==
                              borderPointJournalists[0].cellWeightX)) && (
                          <>
                            <img
                              src={ini.imageURL}
                              alt={ini.name}
                              styleName="circle-tile-wrapper-img"
                            />
                            <p styleName="point-tooltiptext">
                              {ini.name}
                              <br />
                              <br />
                              {ini.numberOfVotes} votes{" "}
                            </p>
                          </>
                        )
                      );
                    })}
                  </>
                )}
              {borderPointJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="point-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderPointJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderPointJournalists.length} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
          </div>
          <>
            {isModalOpen && (
              <ViewAllModal
                journalists={modelJournalists}
                modalOpen={modalOpen}
              />
            )}
          </>
        </>
      );
    default:
      return (
        <>
          <div styleName="circle-tile">
            <div styleName="circle-tile-wrapper square">
              {journalists.map((journo, j) => {
                return journalistData.map(ini => {
                  return (
                    ini.id === journo.journalistId &&
                    j < 3 && (
                      <>
                        <img
                          src={ini.imageURL}
                          alt={ini.name}
                          styleName="circle-tile-wrapper-img"
                        />
                        <p styleName="tooltiptext">
                          {ini.name}
                          <br />
                          <br />
                          {ini.numberOfVotes} votes{" "}
                        </p>
                      </>
                    )
                  );
                });
              })}
              {journalists.length > 0 && (
                <div
                  styleName="extra-photos"
                  onClick={() => {
                    setModelJornalists(journalists);
                    modalOpen();
                  }}
                >
                  {/* +{photoCount - 3} */}
                  <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                </div>
              )}
            </div>
            <div styleName="x-border">
              {borderXJournalists.length == 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderXJournalists[0].journalistId &&
                      ((cellWeightX + 1 ==
                        borderXJournalists[0].avgCellWeightX &&
                        cellWeightY == borderXJournalists[0].avgCellWeightY) ||
                        (cellWeightX + 1 == borderXJournalists[0].cellWeightX &&
                          cellWeightY ==
                            borderXJournalists[0].cellWeightY)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img single-x "
                          />
                          <p styleName="x-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderXJournalists.length > 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderXJournalists[0].journalistId &&
                      ((cellWeightX + 1 ==
                        borderXJournalists[0].avgCellWeightX &&
                        cellWeightY == borderXJournalists[0].avgCellWeightY) ||
                        (cellWeightX + 1 == borderXJournalists[0].cellWeightX &&
                          cellWeightY ==
                            borderXJournalists[0].cellWeightY)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img"
                          />
                          <p styleName="x-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderXJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="x-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderXJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderXJournalists.length - 1} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
            <div styleName="y-border">
              {borderYJournalists.length == 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderYJournalists[0].journalistId &&
                      ((cellWeightY + 1 ==
                        borderYJournalists[0].avgCellWeightY &&
                        cellWeightX == borderYJournalists[0].avgCellWeightX) ||
                        (cellWeightY + 1 == borderYJournalists[0].cellWeightY &&
                          cellWeightX ==
                            borderYJournalists[0].cellWeightX)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img single-y "
                          />
                          <p styleName="y-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderYJournalists.length > 1 && (
                <>
                  {" "}
                  {journalistData.map(ini => {
                    return (
                      ini.id === borderYJournalists[0].journalistId &&
                      ((cellWeightY + 1 ==
                        borderYJournalists[0].avgCellWeightY &&
                        cellWeightX == borderYJournalists[0].avgCellWeightX) ||
                        (cellWeightY + 1 == borderYJournalists[0].cellWeightY &&
                          cellWeightX ==
                            borderYJournalists[0].cellWeightX)) && (
                        <>
                          <img
                            src={ini.imageURL}
                            alt={ini.name}
                            styleName="circle-tile-wrapper-img"
                          />
                          <p styleName="y-tooltiptext">
                            {ini.name}
                            <br />
                            <br />
                            {ini.numberOfVotes} votes{" "}
                          </p>
                        </>
                      )
                    );
                  })}
                </>
              )}
              {borderYJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="y-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderYJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderYJournalists.length - 1} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
            <div styleName="point-border">
              {borderPointJournalists.length > 0 &&
                borderPointJournalists.length == 1 && (
                  <>
                    {" "}
                    {journalistData.map(ini => {
                      return (
                        ini.id === borderPointJournalists[0].journalistId &&
                        ((cellWeightY + 1 ==
                          borderPointJournalists[0].avgCellWeightY &&
                          cellWeightX + 1 ==
                            borderPointJournalists[0].avgCellWeightX) ||
                          (cellWeightY + 1 ==
                            borderPointJournalists[0].cellWeightY &&
                            cellWeightX + 1 ==
                              borderPointJournalists[0].cellWeightX)) && (
                          <>
                            <img
                              src={ini.imageURL}
                              alt={ini.name}
                              styleName="circle-tile-wrapper-img"
                            />
                            <p styleName="point-tooltiptext">
                              {ini.name}
                              <br />
                              <br />
                              {ini.numberOfVotes} votes{" "}
                            </p>
                          </>
                        )
                      );
                    })}
                  </>
                )}
              {borderPointJournalists.length > 1 && (
                <>
                  {" "}
                  {
                    <div
                      styleName="point-extra-photos"
                      onClick={() => {
                        setModelJornalists(borderPointJournalists);
                        modalOpen();
                      }}
                    >
                      {/* +{borderPointJournalists.length} */}
                      <img src="https://images.assettype.com/newslaundry/2023-09/bde5d309-5100-4c6f-9a54-3669e71422b6/Untitled_design__8___1_.png" styleName="group-tag" alt="more jourailsits" />
                
                    </div>
                  }
                </>
              )}
            </div>
          </div>
          {isModalOpen && (
            <ViewAllModal
              journalists={modelJournalists}
              modalOpen={modalOpen}
            />
          )}
        </>
      );
  }
}

JournoTileGroup.propType = {
  journalists: PropTypes.any
};
