import { Link } from "@quintype/components";
import React, { useState } from "react";
import { HiExternalLink } from "react-icons/hi";
import Select from "react-select";
import "./resultsDayLive.m.css";

const plans = [
  {
    price: 501,
    razorpayLink: "https://razorpay.com/payment-button/pl_OBYKz76WvjXGC9/view"
  },
  {
    price: 2500,
    razorpayLink: "https://razorpay.com/payment-button/pl_OBYM8JxgEDDsGx/view"
  },
  {
    price: 11000,
    razorpayLink: "https://razorpay.com/payment-button/pl_OBYNFDMNTzOtOu/view"
  },
  {
    price: 20000,
    razorpayLink: "https://razorpay.com/payment-button/pl_OBYO0Z2cRFAtLz/view"
  },
  {
    price: 30000,
    razorpayLink: "https://razorpay.com/payment-button/pl_OBYOm8fXKeAjDf/view"
  },
  {
    price: 50000,
    razorpayLink: "https://razorpay.com/payment-button/pl_OBYPZ1FTZXPuS8/view"
  }
];

function resultsDayLive() {
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);

  function handleSubscriptionClick(e, subscriptionGroup) {
    e.preventDefault();
    e.stopPropagation();

    window.open(selectedPlan.razorpayLink, "_blank");
  }

  function handlePlanChange(e) {
    // console.log({ handlePlanChange: e });

    setSelectedPlan(plans.find(p => p.price === e.value));
  }

  const options = plans.map(plan => {
    return {
      value: plan.price,
      label: `₹ ${plan.price}`,
      link: plan.razorpayLink
    };
  });

  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : null,
        color: isDisabled
          ? null
          : isSelected
          ? "#c00"
          : isFocused
          ? "#000"
          : null
      };
    }
  };

  // hello
  return (
    <div>
      <main styleName="hero-section">
        <div styleName="hero-section-div">
          <div styleName="hero-section-img">
            <img
              src="https://images.assettype.com/newslaundry/2024-05/91152312-55a6-49ce-b10c-26139cdf3b22/ele"
              alt="Combined live desktop"
              styleName="hero-desktop-image"
            />
            <img
              src="https://images.assettype.com/newslaundry/2024-05/719b81ca-a5b0-4c4b-b6a3-282a1794b7b4/ele"
              alt="Combined live mobile"
              styleName="hero-mobile-image"
            />
          </div>
        </div>
      </main>

      <div className="full-width-with-padding" styleName="details-wrapper">
        <div styleName="sena-details">
          <div styleName="description">
            <h2>#ElectionsWithIndependentMedia</h2>
            <p styleName="border-bottom"></p>
            <p>
              Seven phases. Two months. 543 seats. Over 96 crore voters.
              <br />
              <br />
              And five independent news outlets and other journalists to help
              you make sense of all the madness when results are announced on
              June 4.
              <br />
              <br />
              From 8 am onwards, join Newslaundry, The News Minute, The Wire,
              The Caravan and Scroll to pick through all the results as they’re
              announced, in real time. Get the sharpest insights from reporters
              on the ground and independent journalists.
              <br />
              <br />
              No ads, no shouting matches, no over-the-top graphics competing
              for space. Just views and news, explained for you.
              <br />
              <br />
              We’ll make it worth your while. Contribute now.
              <br />
              <br />
              <i>
                Your contribution will be used to fund the production, technical
                and research costs of the videos. The funds will be split
                equitably among the five news organisations to power independent
                journalism.
              </i>
            </p>
            <br />
            <br />
          </div>
          <div styleName="cta-wrapper">
            <h2>Choose a contribution amount</h2>
            <div styleName="list-wrapper">
              <Select
                options={options}
                onChange={e => handlePlanChange(e)}
                defaultValue={options[0]}
                className="basic-single"
                classNamePrefix="select"
                styles={colourStyles}
                isSearchable={false}
              />
            </div>
            <br />

            <div styleName="btn-wrapper">
              <button styleName="btn" onClick={e => handleSubscriptionClick(e)}>
                Contribute Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div styleName="story-links-wrapper">
        <h1>Elections coverage:</h1>
        <p styleName="border-bottom"></p>

        <div styleName="story-box-wrapper">
          <div styleName="story-box">
            <div styleName="box-logo">
              <img
                src="https://images.assettype.com/newslaundry/2020-10/fe2dc714-ad63-43b8-a43f-999fd1f9c7bc/qiMQgIos_400x400.png"
                alt="NL logo"
                styleName="logo"
              />
            </div>
            <div styleName="box-desc">
              <div styleName="btn-wrapper" style={{ marginTop: "0px" }}>
                <Link styleName="external-link" href="/2024-electionfund">
                  <span>Newslaundry</span>
                  <HiExternalLink
                    style={{ width: "16px", height: "16px" }}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div styleName="story-box">
            <div styleName="box-logo">
              <img
                src="https://images.assettype.com/newslaundry/2023-11/a7ef30d4-de3e-48b3-8170-f07628fd4141/logo_round_high_res.jpg"
                alt="TNM logo"
                styleName="logo-tnm"
              />
            </div>
            <div styleName="box-desc">
              <div styleName="btn-wrapper" style={{ marginTop: "0px" }}>
                <Link
                  styleName="external-link"
                  href="https://www.thenewsminute.com"
                >
                  <span>The News Minute</span>
                  <HiExternalLink
                    style={{ width: "16px", height: "16px" }}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div styleName="story-box">
            <div styleName="box-logo">
              <img
                src="https://images.assettype.com/newslaundry/2023-11/52efce52-5792-44ba-9c28-807ed18e94f1/Caravan_logo_black.png"
                alt="Caravan logo"
                styleName="logo-tnm"
              />
            </div>
            <div styleName="box-desc">
              <div styleName="btn-wrapper" style={{ marginTop: "0px" }}>
                <Link
                  styleName="external-link"
                  href="https://caravanmagazine.in"
                >
                  <span>The Caravan</span>
                  <HiExternalLink
                    style={{ width: "16px", height: "16px" }}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div styleName="story-box">
            <div styleName="box-logo">
              <img
                src="https://images.assettype.com/newslaundry/2023-11/629b50f5-4cd1-4999-b521-40fdd3d73541/scroll_black.png"
                alt="Scroll logo"
                styleName="logo-tnm"
              />
            </div>
            <div styleName="box-desc">
              <div styleName="btn-wrapper" style={{ marginTop: "0px" }}>
                <Link styleName="external-link" href="https://scroll.in">
                  <span>Scroll</span>
                  <HiExternalLink
                    style={{ width: "16px", height: "16px" }}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>

          <div styleName="story-box">
            <div styleName="box-logo">
              <img
                src="https://images.assettype.com/newslaundry/2023-11/02aabea0-d31f-4936-af93-fec7f9d562dc/The_Wire_Logo_Carmelian_Rect_1500.png"
                alt="The Wire logo"
                styleName="logo-tnm"
              />
            </div>
            <div styleName="box-desc">
              <div styleName="btn-wrapper" style={{ marginTop: "0px" }}>
                <Link styleName="external-link" href="https://thewire.in/">
                  <span>The Wire</span>
                  <HiExternalLink
                    style={{ width: "16px", height: "16px" }}
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="full-width-with-padding" styleName="benefits-wrapper">
        <h1>Reasons why you should contribute</h1>
        <div styleName="benefits-container">
          <div styleName="benefits-box">
            <div styleName="icon">
              <div style={{ marginTop: "20px" }}>
                <BsNewspaper />
              </div>
            </div>
            <div styleName="heading">
              <h2>Power ground reports</h2>
            </div>
            <div styleName="text">
              <p>
                Reportage is how news consumers stay informed. Your
                contributions support reporters and power access to the facts on
                the ground.
              </p>
            </div>
          </div>
          <div styleName="benefits-box">
            <div styleName="icon">
              <div style={{ marginTop: "20px" }}>
                <BiPhoneCall />
              </div>
            </div>
            <div styleName="heading">
              <h2>Interact with our editorial teams</h2>
            </div>
            <div styleName="text">
              <p>
                Join Zoom calls with our reporters, producers and editors –
                before and after election coverage – for a closer look at our
                work.
              </p>
            </div>
          </div>
          <div styleName="benefits-box">
            <div styleName="icon">
              <div style={{ marginTop: "20px" }}>
                <BsGiftFill />
              </div>
            </div>
            <div styleName="heading">
              <h2>Free merch</h2>
            </div>
            <div styleName="text">
              <p>
                Get exclusive merchandise as a token of our appreciation,
                including our all-new <i>Stronger Together: NL-TNM</i> merch.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export { resultsDayLive };
