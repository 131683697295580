import React, { useEffect, useState } from "react";
import VoteCard from "./voteStep2card";
import "../../index.m.css";
import PropTypes from "prop-types";
import Axios from "axios";
import { useSelector } from "react-redux";
import { get } from "lodash";

const btnFix = {
  position: "relative",
};

export default function VoteStepa(props) {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [calIndex, setCalIndex] = useState(-1);

  
  const [currentJournoIndex, setCurrentJournoIndex] = useState(
    props.selected.map((id, i) => ({
      cellWeightX: 1,
      cellWeightY: 1,
      journoId: id,
    }))
  );
  useEffect(()=>{
    window.localStorage.removeItem("step");
   return ()=>{ window.localStorage.removeItem("selectog")}
  },[])

  const member = useSelector((state) => get(state, ["member"], null));
  const fetchSubscription = useSelector((state) =>
    get(state, ["fetchSubscription"], null)
  );

  const isActive = fetchSubscription && fetchSubscription.isActive;

  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!member) {
      setIsSubmitting(false);
    }

    if (typeof window !== "undefined" && member) {
      try {
        const submission = "submit data via POST request";

        currentJournoIndex.forEach((currentJournoI) => {
          const arr = {
            journalistId: currentJournoI.journoId,
            userEmail: member.email,
            userId: member.id,
            cellWeightX: currentJournoI.cellWeightX,
            cellWeightY: currentJournoI.cellWeightY,
          };

          Axios.post(
            "https://server.newslaundry.com/journalismSpectrum/submissions/",
            arr
          )
            .then((response) => {
              props.setSubmitErr(false);
            })
            .catch((error) => {
              props.setSubmitErr(true);
            });
        });

        // Navigate to confirm page
        props.onClick(1);
        setIsSubmitting(false);
        return submission;
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <div styleName="section">
        <span style={{ display: "none" }}>{isSubmitting}</span>
        <p style={{ color: "#bebebe", textAlign: "left" }}><b>Move the slider to mark where you think the journalist falls on the biascope.</b></p><br/> 
        {props.allres.map((pini) => {
          if (props.selected.includes(pini.id)) {
            return (
              <VoteCard
                key={pini.id}
                calculate={(val) => {
                  setCalIndex(val);
                }}
                cellY={(Yval) => {
                  const updatedJournoIndex = currentJournoIndex.map((j) => {
                    return j.journoId == pini.id
                      ? { ...j, cellWeightY: Yval }
                      : j;
                  });
                  setCurrentJournoIndex(updatedJournoIndex);
                }}
                cellX={(Xval) => {
                  const updatedJournoIndex = currentJournoIndex.map((j) => {
                    return j.journoId == pini.id
                      ? { ...j, cellWeightX: Xval }
                      : j;
                  });
                  setCurrentJournoIndex(updatedJournoIndex);
                }}
                res={pini}
              />
            );
          }
        })}
      </div>

      <div style={{ display: "flex", justifyContent: "right" }}>
        <button onClick={handleSubmit} styleName="ctav" style={btnFix}>
          Submit
        </button>
      </div>
    </>
  );
}
VoteStepa.propTypes = {
  selected: PropTypes.any,
  allres: PropTypes.any,
  onClick: PropTypes.any,
};


