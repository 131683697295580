import React from "react";
import PropTypes from "prop-types";
import getStoriesByCollection from "../../../utils";
import { FourRowStoryCard } from "../../molecules/four-row-story-card";
import HorizontalStoryCard from "../../molecules/horizontal-story-card";
import { ThreeRowStoryCard } from "../../molecules/three-row-story-card";
import { CollectionName } from "../../atoms/collection-name";

import "./three-col-three-collection.m.css";

export const ThreeColThreeCollectionStories = ({ collection = {}, config = {} }) => {
  if (collection.items.length <= 0) {
    return null;
  }
  
  const collections = collection.items.filter(collection => collection.type === "collection");
  const firstCollectionStories = getStoriesByCollection(collections[0]) || [];
  const secondCollectionStories = getStoriesByCollection(collections[1]) || [];
  const thirdCollectionStories = getStoriesByCollection(collections[2]) || [];

  return (
    <div className="full-width-with-padding" styleName="wrapper">
      <div styleName="grid-container" className="container">
        {firstCollectionStories.length > 0 && (
          <div styleName="col">
            <FourRowStoryCard stories={firstCollectionStories} collection={collections[0]} />
          </div>
        )}
        {secondCollectionStories.length > 0 && (
          <div styleName="col">
            <CollectionName collection={collections[1]} />
            {secondCollectionStories.slice(0, 5).map(story => (
              <div styleName="story-wrapper" key={story.id}>
                <HorizontalStoryCard story={story} descriptionPadding={false} removeLastChildMargin={true} />
              </div>
            ))}
          </div>
        )}
        {thirdCollectionStories.length > 0 && (
          <div styleName="col">
            <ThreeRowStoryCard stories={thirdCollectionStories} collection={collections[2]} config={config} />
          </div>
        )}
      </div>
    </div>
  );
};


ThreeColThreeCollectionStories.propTypes = {
  collection: PropTypes.object,
  config: PropTypes.object
};
