import Axios from "axios";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import "./newsletter.m.css";

const NewsletterComponent = props => {
  const [emailAddress, setEmailAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [error, setError] = useState(null);
  const [isHindi, setIsHindi] = useState(false);

  useEffect(() => {
    const isHindiCheck = !!(
      global.location && global.location.host.includes("hindi")
    );

    setIsHindi(isHindiCheck);
  }, []);

  const mailChimpNewsletterID = get(
    props,
    ["metadata", "mailChimpNewsletterID"],
    null
  );

  const { member, fetchSubscription } = useSelector(
    ({ member, fetchSubscription }) => ({
      member,
      fetchSubscription
    })
  );

  const pushToDataLayer = (event, title) => {
    const obj = {
      event: event,
      subscription_category: title,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    if (!emailAddress) {
      setError("Please provide an email address");

      setTimeout(() => {
        setError(null);
      }, 3000);

      return;
    }

    if (mailChimpNewsletterID) {
      setIsLoading(true);
      try {
        await Axios.post(
          `/newsletter-api/lists/${mailChimpNewsletterID}/members`,
          {
            email: emailAddress,
            tags: ["subscription-newsletter"]
          }
        );

        setPrompt("Thanks for signing up. See you in your inbox soon.");

        setTimeout(() => {
          setPrompt(null);
          setEmailAddress("");
        }, 3000);

        setIsLoading(false);
      } catch (error) {
        setError(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );

        setTimeout(() => {
          setError(null);
        }, 3000);

        setIsLoading(false);
      }
    } else {
      setError(
        "Something went wrong! Please contact subscription@newslaundry.com to help this issue get resolved"
      );

      setTimeout(() => {
        setError(null);
      }, 3000);
    }
  };

  return (
    <div styleName="main">
      <div styleName="main-grid">
        <div styleName="hero-div">
          <img
            src="https://images.assettype.com/newslaundry/2023-02/e68aecc2-4308-4d66-9802-4b047ec3ed46/newsletter.png"
            alt="Birubal with your newsletter"
          />
        </div>
        <div styleName="description-div">
          {/* <p styleName="highlight-text">NO SPAM!</p> */}
          <h4 styleName="primary-heading">
            {isHindi
              ? "हमारी कहानियों के ईमेल अलर्ट पाने के लिए साइन-अप करें."
              : "Get the best of our ground reports and analysis. Plus, behind the scenes."}
          </h4>
          <p styleName="helper-text">
            {isHindi
              ? "हम आपके इनबॉक्स का सम्मान करते हैं, इसलिए हम आपको हफ्ते में सिर्फ अपनी चुनिंदा कहानियों से जुड़ी ईमेल ही भेजेंगे"
              : "We value your inbox, so no daily emails or spam. Biweekly jottings by our writers and editors, not bots."}
          </p>
          <form
            styleName="subscribe-button-wrapper"
            onSubmit={handleFormSubmit}
          >
            <input
              type="email"
              name="email"
              id="email"
              styleName={`subscribe-input ${isLoading ? "disabled-input" : ""}`}
              disabled={isLoading}
              placeholder={
                isHindi
                  ? "कृपया अपना ईमेल एड्रेस डालें"
                  : "Please enter your email address"
              }
              required={true}
              value={emailAddress}
              onChange={e => setEmailAddress(e.target.value)}
              aria-label="Enter your email here"
            />
            <button
              styleName={`subscribe-button ${isLoading ? "disabled-btn" : ""}`}
              disabled={isLoading}
              onMouseDown={() =>
                pushToDataLayer("nl_banner", "newsletter_subscription")
              }
              aria-label="Click to sign-up to recieve newsletter directly to your inbox"
            >
              {isLoading ? "Please wait..." : isHindi ? "साइन अप" : "Sign up"}
            </button>
          </form>
          {prompt && (
            <p styleName="success-text">
              <FaCheckCircle aria-hidden="true" />
              <span>{prompt}</span>
            </p>
          )}
          {error && (
            <p styleName="error-text">
              <FaTimesCircle aria-hidden="true" />
              <span>{error}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export { NewsletterComponent };
