/* eslint-disable react/prop-types */
import React, { useState, useRef } from "react";
import assetify from "@quintype/framework/assetify";
import Cancel from "../../../../assets/images/nl-images/Cancel.png"; // Add sad NL chars
import { Button } from "../../atoms/button";
import { Modal } from "../../molecules/modal";

import "./cancelprompt.m.css";

export function CancelPrompt({
  handleClose,
  subscription,
  CancelFunc,
  member,
  setSelectedCancelReason
}) {
  const [page, setPage] = useState(1);
  const [err, setErr] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const textRef = useRef(null);

  console.log({ selectedOption });

  console.log(subscription);
  const canceldata = {
    subscriber: { email: member.email, id: subscription.subscriber_id },
    planId: subscription.subscription_plan_id,
    planName: subscription.plan_name,
    planType: subscription.subscription_type,
    reason: "",
    durationType: subscription.duration_unit,
    durationLength: subscription.duration_length,
    gift: subscription.gifter !== null
  };

  const submitFunc = async e => {
    const desc = textRef.current && textRef.current.value;
    if (selectedOption === "") {
      setErr(true);
    } else if (selectedOption === "Other") {
      const reasondesc = "Other: " + desc;
      canceldata.reason = reasondesc;
      setPage(2);
    } else {
      canceldata.reason = selectedOption;
      setPage(2);
    }

    setSelectedCancelReason(canceldata.reason);
  };

  // Radio Button Reasons List
  const reasons = [
    "It’s too expensive. I am cutting down my expenses",
    "Want to take a break from the news",
    "I am no longer consuming enough content from Newslaundry",
    "Not enough subscriber-only benefits",
    "Other"
  ];

  // Cancel Buttons
  const CancelButtons = ({ ContinueFunc }) => {
    return (
      <React.Fragment>
        <div styleName="button">
          <Button
            style={{ padding: "10px", fontSize: "14px" }}
            className="nl-btn"
            onClick={handleClose}
          >
            Keep My subscription
          </Button>
        </div>
        &nbsp;&nbsp;
        <div styleName="button">
          <Button
            style={{ padding: "10px", fontSize: "14px" }}
            className="nl-btn"
            onClick={ContinueFunc}
          >
            Cancel My subscription
          </Button>
        </div>
      </React.Fragment>
    );
  };

  // Radio buttons for reasons
  const Radiobuttons = () => {
    return (
      <div>
        {reasons.map((reason, idx) => {
          return (
            <div key={idx}>
              <label
                style={{
                  width: "100%",
                  fontSize: "0.9rem",
                  textAlign: "right"
                }}
                htmlFor={`${idx}`}
              >
                <input
                  id={`${idx}`}
                  type="radio"
                  value={reason}
                  checked={selectedOption === reason}
                  onChange={() => setSelectedOption(reason)}
                />
                &nbsp; {reason}
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  const ReasonForm = () => {
    return (
      <div styleName="form">
        <div styleName="content">
          Please could you tell us why you want to cancel your subscription?
        </div>

        <div styleName="reason-wrapper">
          <div styleName="reasons">
            <Radiobuttons />
          </div>
          {selectedOption === "Other" && (
            <div style={{ flex: "4", marginTop: "5px" }}>
              <React.Fragment>
                {" "}
                <div style={{ fontSize: "16px" }}>
                  Mention reason of Cancellation (250 words max){" "}
                </div>
                <textarea styleName="reason-box" ref={textRef} required />
              </React.Fragment>
            </div>
          )}
        </div>
      </div>
    );
  };

  // First Modal Page
  const FirstPage = () => {
    return (
      <div styleName="wrapper">
        <div styleName="mini-wrapper">
          <h2 styleName="headline">We&apos;re sad to see you go</h2>
          <img
            style={{ maxHeight: "200px", marginBottom: "20px" }}
            alt="banner"
            src={assetify(Cancel)}
          />
        </div>

        <div styleName="main-content">
          <ReasonForm />
        </div>

        <div style={{ marginTop: "auto" }}>
          {err && <div styleName="err-text"> Please Select an option </div>}
        </div>
      </div>
    );
  };

  // Second Modal Page
  const SecondPage = () => {
    return (
      <div styleName="wrapper">
        <div styleName="mini-wrapper">
          <h2 styleName="headline">
            {" "}
            {member["first-name"] ? member["first-name"] : "User"}, before you
            go ...
          </h2>
          <div>
            <div styleName="large-info">
              {/*
              <div style={{ backgroundColor: '#f6decc', padding: '2rem' }}>
                <heading styleName="header">Reader-supported, Independent, Ad-free</heading>
                <p styleName="infotext">
                  Newslaundry isn't driven by corporate and government
                  interests, we strongly believe in the need for an independent
                  news model, and a free and accountable press. Consequently,
                  we serve the public and not advertisers.
                </p>
              </div>
            */}
              <div styleName="perks">
                <heading styleName="header-perks">
                  Perks you&apos;ll miss out on
                </heading>
                <section styleName="infotext" style={{ marginLeft: "2rem" }}>
                  <ul>
                    <li>
                      All paywall content: podcasts (NL Hafta and Let&apos;s
                      Talk About), interviews, comics, and reports.
                    </li>
                    <li>
                      {" "}
                      Subscriber-only events like NL Chatbox, NL Recess,
                      webinars and The Media Rumble.
                    </li>
                    {/* <li>Newslaundry Discord server</li> */}
                  </ul>
                </section>
              </div>

              <div
                // style={{ backgroundColor: "rgb(255 245 138)", padding: "2rem" }}
              >
                <heading styleName="header">
                  Why you should continue to power our journalism?
                </heading>
                <p styleName="infotext">
                  News media can’t be free and independent if it survives on
                  government and corporate largesse. Advertisements cannot
                  sustain public interest journalism – only you can.
                  <br />
                  Your subscription directly powers our reporters to continue
                  telling stories that matter. Ground reports are far and few in
                  the media ecosystem. We want to change that with our team of
                  reporters. Your cancellation will directly impact our ability
                  to do more ground reports.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal hideCloseIcon>
      {page === 1 ? <FirstPage /> : <SecondPage />}
      <div styleName="button-cancel">
        <CancelButtons ContinueFunc={page === 1 ? submitFunc : CancelFunc} />
      </div>
    </Modal>
  );
}
