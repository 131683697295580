import { AccessType, WithMember } from "@quintype/components";
import { get } from "lodash";
import PropTypes from "prop-types";
import { parseUrl } from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import IntegrationData from "../../../../../config/integrations";
import { StepProgress } from "../../molecules/step-progress";
import { CampaignConfirmation } from "./campaign-checkout/campaign-confirmation";
import { PaymentMethods } from "./campaign-checkout/payment-methods";
import { SubscriptionLevels } from "./campaign-checkout/subscription-levels";
import {
  fetchDataFromLocalStorage,
  generateRedirectURL,
  generateSuccessUrl,
  saveDataToLocalStorage
} from "./utils";
import { Button } from "../../../ui/components/button";

import { CgSpinner } from "react-icons/cg";
import "./campaign-checkout.m.css";

const CampaignCheckout = props => {
  const accessTypeCampaignSubscriptions = useSelector(state =>
    get(state, ["accessTypeCampaignSubscriptions"], [])
  );
  const member = useSelector(state => get(state, ["member"], null));

  const integrationsConfig = IntegrationData();
  const pub = useSelector(state => get(state, ["qt", "config", "publisher"]));
  const email = get(member, ["email"], "");
  const phone = get(member, ["metadata", "phone"], "");
  const isStaging = get(pub, ["publisher", "accessTypeEnvironment"], true);
  const enableAccesstype = get(
    pub,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  const { query } = parseUrl(props.currentPath);

  const [currentStep, setCurrentStep] = useState("payment");

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [paymentOption, setPaymentOption] = useState("upi");
  const [loading, setLoading] = useState(false);
  const [paymentError, setPaymentError] = useState(null);

  const redirectUrl = generateRedirectURL();
  const successUrl = generateSuccessUrl();

  useEffect(() => {
    if (accessTypeCampaignSubscriptions.length) {
      setSelectedPlan(
        accessTypeCampaignSubscriptions.find(item => +query.group === item.id)
      );
    } else if (
      fetchDataFromLocalStorage("selectedPlan") &&
      fetchDataFromLocalStorage("selectedLevel")
    ) {
      const savedPlan = fetchDataFromLocalStorage("selectedPlan");
      const savedLevel = fetchDataFromLocalStorage("selectedLevel");

      setSelectedPlan(JSON.parse(savedPlan));
      setSelectedLevel(JSON.parse(savedLevel));
    }
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      setSelectedLevel(
        selectedPlan.subscription_plans.find(item => item.id === +query.plan)
      );

      saveDataToLocalStorage(JSON.stringify(selectedPlan), "selectedPlan");
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (query.payment_status && Boolean(query.payment_status)) {
      setCurrentStep("confirm");
    }
  }, [props.currentPath]);

  const handlePaymentError = (getErrorMessage, getGiftErrorMessage) => {
    if (getErrorMessage === "user closed the payment") {
      setPaymentError("Payment attempt was cancelled by the user.");
    } else if (
      getErrorMessage === "already has active subscription for the same plan"
    ) {
      setPaymentError(
        `You already have an active recurring subscription. For support in addition to your current subscription, you can check out and join NL Sena or gift a subscription to someone you know`
      );
    } else if (
      getErrorMessage ===
      "already has active subscription for a recurring plan with same asset"
    ) {
      setPaymentError(
        `You already have an active recurring subscription. For support in addition to your current subscription, you can check out and join NL Sena or gift a subscription to someone you know`
      );
    } else if (getGiftErrorMessage === "subscriber cannot be same as gifter") {
      setPaymentError(
        `The email address of the sender and receiver cannot be the same. Please go back and enter a different email address.`
      );
    } else {
      setPaymentError(
        "Oops! Something went wrong. Please contact subscription@newslaundry.com for further assistance"
      );
    }
  };

  const handlePayment = async (
    initAccessType,
    initRazorPayPayment,
    initStripePayment
  ) => {
    saveDataToLocalStorage(JSON.stringify(selectedLevel), "selectedLevel");

    try {
      setLoading(true);
      await initAccessType();

      if (paymentOption === "upi") {
        const options = {
          argType: "options",
          selectedPlan: selectedLevel,
          planType: "campaign",
          couponCode: "",
          successUrl: successUrl.toString(),
          recipientSubscriber: {
            recipient_identity_provider: "email"
          }
        };
        // console.log({options},"razorpay options")
        await initRazorPayPayment(options);

        setLoading(false);
        setCurrentStep("confirm");

        return;
      }

      if (paymentOption === "card") {
        const options = {
          argType: "options",
          selectedPlan: selectedLevel,
          planType: "campaign",
          couponCode: "",
          successUrl: successUrl.toString(),
          cancelUrl: redirectUrl.href.toString(),
          recipientSubscriber: {
            recipient_identity_provider: "email"
          }
        };

        // console.log({options},"Stripe options")
        await initStripePayment(options);

        setLoading(false);
        setCurrentStep("confirm");

        // return;
      }
    } catch (error) {
      const getErrorMessage = get(
        error,
        ["error", "message", "subscriber", 0],
        error.message
      );
      const getGiftErrorMessage = get(
        error,
        ["error", "message", "subscription_gift", 0],
        error.message
      );
      console.error(
        `Payment Gateway error: ${JSON.stringify(error.message, null, 2)}`
      );
      handlePaymentError(getErrorMessage, getGiftErrorMessage);
      setLoading(false);
    }
  };

  if (currentStep !== "confirm" && (!selectedPlan || !selectedLevel)) {
    return (
      <div styleName="error-wrapper">
        <p>You don&apos;t have any plan currently selected.</p>
      </div>
    );
  }

  return (
    <WithMember>
      {({ member, logout, checkForMemberUpdated, isLoading }) => {
        return (
          <div styleName="wrapper" className="full-width">
            <div styleName="steps-wrapper">
              <StepProgress
                {...props}
                activeStep={currentStep}
                setActiveStep={setCurrentStep}
              />
            </div>
            <section styleName="section-wrapper">
              {currentStep !== "confirm" && (
                <h1 styleName="campaign-title">{selectedPlan.name}</h1>
              )}
              {currentStep === "account" ? (
                <main styleName="subsection-wrapper">
                  {/* {isSubscriptionActiveOrPending && (
                    <ActivePlanDiv
                      gifteeEmail={gifteeEmail}
                      setGifteeEmail={setGifteeEmail}
                      toGiftSub={toGiftSub}
                      setToGiftSub={setToGiftSub}
                    />
                  )} */}
                  {/* <div styleName="right-align-div">
                    <button styleName="next-btn" onClick={increaseCurrentStep}>
                      Next
                    </button>
                  </div> */}
                  {/* {paymentError && (
                    <p styleName="bottom-prompt error">{paymentError}</p>
                  )} */}
                </main>
              ) : currentStep === "payment" ? (
                <main styleName="subsection-wrapper">
                  {/* {isSubscriptionActiveOrPending && (
                    <BackStepCounter
                      decreaseCurrentStep={decreaseCurrentStep}
                    />
                  )} */}
                  {selectedLevel && (
                    <SubscriptionLevels
                      selectedPlan={selectedPlan}
                      selectedLevel={selectedLevel}
                      setSelectedLevel={setSelectedLevel}
                    />
                  )}
                  <PaymentMethods
                    paymentOption={paymentOption}
                    setPaymentOption={setPaymentOption}
                  />
                  {selectedLevel && (
                    <>
                      <div styleName="price-div">
                        <div styleName="price-breakup">
                          <p styleName="price-topay">To pay</p>
                          <p styleName="price-amount">
                            &#x20b9;{" "}
                            {(selectedLevel.price_cents / 100).toLocaleString(
                              "en-IN"
                            )}
                          </p>
                        </div>
                        <div>
                          <AccessType
                            enableAccesstype={enableAccesstype}
                            isAccessTypeCampaignEnabled={true}
                            isStaging={false}
                            accessTypeKey={accessTypeKey}
                            accessTypeBkIntegrationId={
                              accessTypeBkIntegrationId
                            }
                            email={email}
                            phone={phone}
                          >
                            {({
                              initAccessType,
                              initRazorPayPayment,
                              initStripePayment
                            }) => {
                              return (
                                <>
                                  {loading ? (
                                    <Button disabled={true}>
                                      <CgSpinner
                                        styleName="loading"
                                        aria-hidden="true"
                                      />
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="success"
                                      disabled={loading}
                                      className="typography-heading-styles-h5"
                                      onClick={() =>
                                        handlePayment(
                                          initAccessType,
                                          initRazorPayPayment,
                                          initStripePayment
                                        )
                                      }
                                    >
                                      Contribute
                                    </Button>
                                    // <button
                                    //   disabled={loading}
                                    //   styleName={`pay-btn ${
                                    //     loading ? "pay-btn-disabled" : ""
                                    //   }`}
                                    //   onClick={() =>
                                    //     handlePayment(
                                    //       initAccessType,
                                    //       initRazorPayPayment,
                                    //       initStripePayment
                                    //     )
                                    //   }
                                    // >
                                    //   Contribute
                                    // </button>
                                  )}
                                </>
                              );
                            }}
                          </AccessType>
                        </div>
                      </div>
                      {paymentError && (
                        <p styleName="bottom-prompt error">{paymentError}</p>
                      )}
                    </>
                  )}
                </main>
              ) : (
                <CampaignConfirmation />
              )}
            </section>
          </div>
        );
      }}
    </WithMember>
  );
};

CampaignCheckout.propTypes = {
  currentPath: PropTypes.string
};

export { CampaignCheckout };
