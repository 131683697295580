import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getHeroImage, getStoryMetadata, getTextToSlug } from "../../../utils";

import "./hero-vertical-story-card.m.css";
import { Link, ResponsiveImage } from "@quintype/components";
import { SectionName } from "../../atoms/section-tag";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import { TimeStamp } from "../../atoms/time-stamp";

const HeroVerticalStoryCard = ({ story }) => {
  const config = useSelector(({ qt }) => qt.config);

  const heroS3Key = getHeroImage(story, config);
  const slug =
    story["story-template"] === "news-elsewhere"
      ? story.metadata["reference-url"] || story.url
      : story.url;

  const authorName = story.authorName;
  const authorSlug = getTextToSlug(authorName);

  return (
    <div styleName="card-wrapper">
      <div styleName="hero-image">
        {story["story-template"] === "news-elsewhere" ? (
          <a
            href={slug}
            target={
              story["story-template"] === "news-elsewhere" ? "_blank" : ""
            }
          >
            <ResponsiveImage
              slug={heroS3Key}
              metadata={getStoryMetadata(story)}
              aspectRatio={[16, 9]}
              imgParams={{ auto: ["format", "compress"] }}
            />
          </a>
        ) : (
          <Link
            href={slug}
            target={
              story["story-template"] === "news-elsewhere" ? "_blank" : ""
            }
          >
            <ResponsiveImage
              slug={heroS3Key}
              metadata={getStoryMetadata(story)}
              aspectRatio={[16, 9]}
              imgParams={{ auto: ["format", "compress"] }}
            />
          </Link>
        )}
      </div>
      <div styleName="content-wrapper">
        <div styleName="section">
          <SectionName story={story} />
        </div>
        <div styleName="headline">
          <Headline story={story} headlineColor={"#fff"} externalLink={true} />
        </div>
        <div styleName="subheadline">
          <Subheadline story={story} subheadlineColor={"#fff"} />
        </div>
        <div styleName="time-social">
          <div styleName="author">
            {story.authors ? (
              story.authors.map((author) => (
                <Link href={`/author/${author.slug}`} key={author.id}>
                  <p styleName="author-name">{author.name}</p>
                </Link>
              ))
            ) : (
              <Link href={`/author/${authorSlug}`}>
                <p styleName="author-name">{authorName}</p>
              </Link>
            )}
            <span>&bull;</span>
            <TimeStamp story={story} timestampColor={"#fff"} />
          </div>
        </div>
      </div>
    </div>
  );
};

HeroVerticalStoryCard.propTypes = {
  story: PropTypes.object,
};

export { HeroVerticalStoryCard };
