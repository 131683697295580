/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { Link, ResponsiveImage } from "@quintype/components";
import PropType from "prop-types";
import React from "react";
import { getStoryMetadata } from "../../../utils";
import NlSenaWrapper from "../../pages/nl-sena-wrapper";

import "./three-sena-collection.m.css";

const sena2024ElectionFundCampaings = [1513, 1497, 1512, 1500, 1510, 1511];

const StoryColumnCard = ({ collection, isFirstItem }) => {
  const stories = collection.items
    .filter((item) => item.type === "story")
    .slice(0, 6);
  // console.log(
  //   collection.items.filter(item => item.type === "story").slice(0, 6)
  // );

  return (
    <div styleName="story-column-wrapper">
      <div styleName="title-wrapper">
        <Link href={`/${collection.slug}`}>
          <h3 styleName="title">{collection.name}</h3>
          <p styleName="border-bottom"></p>
        </Link>
      </div>
      <div styleName={`content-wrapper ${isFirstItem ? "dark-container" : ""}`}>
        {/* <ul style={{ listStyle: "none"}} aria-label="of stories"> */}
        {stories.map((story, i) => (
          <>
            <div
              styleName={`story-card ${i === 0 ? "bt-1 rounded-xxs" : ""}`}
              key={story.id}
            >
              {i === 0 && (
                <Link href={`/${story.story.slug}`} aria-hidden="true">
                  <ResponsiveImage
                    slug={story.story["hero-image-s3-key"]}
                    metadata={getStoryMetadata(story)}
                    aspectRatio={[16, 9]}
                    defaultWidth={480}
                    widths={[250, 480, 640]}
                    sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
                    imgParams={{ auto: ["format", "compress"] }}
                    className="responsive-img"
                    styleName="image"
                  />
                </Link>
              )}
              {/* <li> */}
              <article>
                <Link
                  href={`/${story.story.slug}`}
                  aria-label={`Story Headline: ${story.story.headline}`}
                >
                  <p
                    styleName={`story-headline ${
                      isFirstItem ? "dark-story-headline" : ""
                    }`}
                  >
                    {story.story.headline}
                  </p>
                </Link>
              </article>
              {/* </li> */}
              {i === 0 && (
                <div
                  styleName={`date-author-wrapper ${
                    isFirstItem ? "dark-date-author-wrapper" : ""
                  }`}
                  // aria-hidden="true"
                >
                  <Link href={`/author/${story.story.authors[0].slug}`}>
                    <p
                      styleName="author-name"
                      aria-label={`By: ${story.story.authors[0].name}`}
                    >
                      {story.story.authors[0].name}
                    </p>
                  </Link>
                  <time styleName="published-at" aria-hidden="true">
                    {new Date(story.story["published-at"]).toDateString()}
                  </time>
                </div>
              )}
            </div>
            {i === stories.length - 1 && <div styleName="pt-8"></div>}
            {i !== stories.length - 1 && <div styleName="bottom-rule"></div>}
          </>
        ))}
        {/* </ul> */}
      </div>
    </div>
  );
};

const ThreeSenaCollectionHybrid = ({ associatedMetadata, collection }) => {
  const { first_campaign_id, second_campaign_id, third_campaign_id } =
    associatedMetadata;

  let collections = [];

  if (collection.items.length > 0) {
    collections = collection.items.filter((item) => item.type === "collection");
  }
  // console.log({collection});

  let num = 0;
  let derivedComponentClassName = "";
  let derivedSenaClassName = "";

  if (first_campaign_id) num++;
  if (second_campaign_id) num++;
  if (third_campaign_id) num++;

  derivedComponentClassName = `flex${3 - num}`;
  derivedSenaClassName = `flex${num}`;

  return (
    <div styleName="collection-wrapper">
      <div styleName={`component-wrapper ${derivedComponentClassName}`}>
        {collection.name == "3 COLUMN TILE"
          ? collections.map((item, i) => (
              <StoryColumnCard
                key={item.id}
                collection={item}
                isFirstItem={i === 0}
              />
            ))
          : collections
              .slice(0, -1)
              .map((item, i) => (
                <StoryColumnCard
                  key={item.id}
                  collection={item}
                  isFirstItem={i === 0}
                />
          ))}
      </div>

      <div styleName={`sena-component-wrapper ${derivedSenaClassName}`}>
        {first_campaign_id && (
          <div styleName="sena-wrapper">
            <div styleName="title-wrapper">
              {/* <h3 styleName="title">एनएल-टीएनएम इलेक्शन फंड</h3> */}
              {sena2024ElectionFundCampaings.includes(first_campaign_id) ? (
                <Link href="/2024-electionfund">
                  <h3 styleName="title">Election Fund</h3>
                </Link>
              ) : (
                <Link href="/sena">
                  <h3 styleName="title">Sena</h3>
                </Link>
              )}
              <p styleName="border-bottom"></p>
            </div>
            <NlSenaWrapper
              showSingleCampaign={true}
              campaignId={first_campaign_id}
            />
          </div>
        )}
        {second_campaign_id ? (
          <div styleName="sena-wrapper">
            <div styleName="title-wrapper">
              {sena2024ElectionFundCampaings.includes(second_campaign_id) ? (
                <Link href="/2024-electionfund">
                  <h3 styleName="title">Election Fund</h3>
                </Link>
              ) : (
                <Link href="/sena">
                  <h3 styleName="title">Sena</h3>
                </Link>
              )}
              <p styleName="border-bottom"></p>
            </div>
            <NlSenaWrapper
              showSingleCampaign={true}
              campaignId={second_campaign_id}
            />
          </div>
        ) : null}
        {third_campaign_id && (
          <div styleName="sena-wrapper">
            <div styleName="title-wrapper">
              {sena2024ElectionFundCampaings.includes(third_campaign_id) ? (
                <Link href="/2024-electionfund">
                  <h3 styleName="title">Election Fund</h3>
                </Link>
              ) : (
                <Link href="/sena">
                  <h3 styleName="title">Sena</h3>
                </Link>
              )}
              <p styleName="border-bottom"></p>
            </div>
            <NlSenaWrapper
              showSingleCampaign={true}
              campaignId={third_campaign_id}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { ThreeSenaCollectionHybrid };

ThreeSenaCollectionHybrid.propType = {
  associatedMetadata: PropType.object,
  collection: PropType.object,
};
