import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import assetify from "@quintype/framework/assetify";
import groupImage from "../../../../assets/images/newsletter.png";
import { TextField } from "../../atoms/textfield";
import { Button } from "../../atoms/button";
import { subscribeToMailchimp } from "../../pages/newsletter/api";
import { isValidEmail } from "../../../utils";
import "./subscribe-to-newsletters.m.css";
import LoadingIcon from "../../../../assets/images/loader.svg";

const defaultSummary =
  "We deliver weekly newsletters. Customised and curated for you. Check it out.";
const defaulttitle =
  "No spam! Newslaundry newsletters, tailored to your interests.";

export const SubscribeToNewsletters = ({ collection = {} }) => {
  const [showLoader, setShowLoader] = useState();
  const [email, setEmail] = useState();
  const [error, setError] = useState();
  const [subscribeMailStatus, setSubscribeMailStatus] = useState([]);

  const { member, fetchSubscription } = useSelector(state => state);

  const onChange = e => {
    setEmail(e.target.value);
    e.target.value ? setError("") : setError("Please provide Email !!");
  };

  const pushToDataLayer = (event, title) => {
    const obj = {
      event: event,
      subscription_category: title,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  const onSubmitClick = () => {
    if (email) {
      if (!isValidEmail(email)) {
        setError("Invalid Email !!");
      } else {
        setShowLoader(true);
        subscribeToMailchimp({
          email: email,
          listId5: "aa2c780e6c"
        })
          .then(status => {
            pushToDataLayer("nl_banner", "newsletter_subscription");
            setSubscribeMailStatus(status);
            setEmail("");

            setShowLoader(false);
            setTimeout(function() {
              setSubscribeMailStatus([]);
              // setCheckedItems([]);
            }, 6000);
          })
          .catch(err => {
            console.log(err);
          });
      }
    } else {
      setError("Please provide Email !!");
    }
  };

  const domainSlug = global.location && global.location.host.includes("hindi");
  return (
    <div styleName="row-container">
      <div styleName="subscribe-wrapper" className="full-width-with-padding">
        <div styleName="group-img">
          <img
            styleName="newsletter-image"
            src={assetify(groupImage)}
            alt="newlaundry logo"
          />
        </div>
        <div styleName="content-wrapper">
          <div styleName="collection-title">
            {collection.name || defaulttitle}
          </div>
          <div styleName="collection-summary">
            {collection.summary || defaultSummary}
          </div>
          <div styleName="btn-input">
            {domainSlug ? (
              <div styleName="subscribe-view-content ">
                {showLoader === true && (
                  <div styleName="modal">
                    <div styleName="modal-content">
                      <img src={assetify(LoadingIcon)} alt="loading" />
                    </div>
                  </div>
                )}
                <TextField
                  value={email}
                  error={error}
                  onChange={onChange}
                  placeholder="Type your email"
                />
                <button styleName="submit-btn" onClick={onSubmitClick}>
                  साइन अप
                </button>
              </div>
            ) : (
              <div styleName="subscribe-button-wrapper">
                {/* <input
                  type="email"
                  name="email"
                  id="email"
                  styleName="subscribe-input"
                  placeholder="Please enter your email address"
                />
                <button
                  styleName="subscribe-button"
                  onMouseDown={() =>
                    pushToDataLayer("nl_banner", "newsletter_subscription")
                  }
                >
                  Sign up
                </button> */}
                <div styleName="subscribe-button">
                  <Button
                    href={"/newsletter-signup?ref=home"}
                    onMouseDown={() =>
                      pushToDataLayer("nl_banner", "newsletter_subscription")
                    }
                  >
                    <span>Sign Up</span>
                  </Button>
                </div>
              </div>
            )}
          </div>
          {subscribeMailStatus && subscribeMailStatus.length > 0 && (
            <div styleName="modal">
              <div styleName="modal-content show-message">
                Thanks for signing up. See you in your inbox soon.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SubscribeToNewsletters.propTypes = {
  collection: PropTypes.object
};

// const mailchimpListIds = [
//   {
//     name: "NL Events",
//     listId: "3cf030021e"
//   },
//   {
//     name: "Mic Drop",
//     listId: "82e2052fcc"
//   },
//   {
//     name: "Stop Press",
//     listId: "a2ab56c30d"
//   },
//   {
//     name: "न्यूज़लॉन्ड्री डाक",
//     listId: "aa2c780e6c"
//   },
//   {
//     name: "NL Digest",
//     listId: "c6b749d171"
//   },
//   {
//     name: "So Sketchy",
//     listId: "b7fd7fb036"
//   }
// ];
