/* eslint-disable max-len */
import React, { useState } from "react";
import "./fellowship.m.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import assetify from "@quintype/framework/assetify";
import quoteIcon from "../../../../assets/images/quotes.png";
import timeline from "../../../../assets/images/timeline.png";
import timelinesoon from "../../../../assets/images/timeline-soon.png";
import idplogo from "../../../../assets/images/idp-logo.png";
import Atul from "../../../../assets/images/advisors/atul.jpg";
import Snigh from "../../../../assets/images/advisors/snigh.jpg";
import Jay from "../../../../assets/images/advisors/jay.jpg";
import rupesh from "../../../../assets/images/advisors/rupesh.jpeg";
import kedar from "../../../../assets/images/advisors/kedar.jpeg";
import vinay from "../../../../assets/images/advisors/vinay.jpeg";

export function Fellowship() {
  const fellowData = [
    {
      q: "What does the program constitute of?",
      a: (
        <p>
          The fellowship comprises a four-week programme to familiarize the
          fellows with datasets on India Data Portal, attend exclusive
          masterclasses and get research support from the Indian School of
          Business. The fellows will attend training workshops and interact with
          experts. At the end of the programme, the fellows will work on their
          stories by leveraging India Data Portal’s datasets.{" "}
        </p>
      )
    },
    {
      q: "Am I eligible to apply?",
      a: (
        <p>
          Journalists from all over the country -- across languages, outlets and
          mediums, are eligible to apply.
        </p>
      )
    },
    {
      q: "Is the program funded?",
      a: (
        <p>
          The fellows will be given a stipend of Rs. 12000 INR once the story is
          live. Based on how they perform, the fellows move to phases 2 and 3,
          which include workshops on long-form writing, in-depth masterclasses,
          and a one-week research trip to the Indian School of Business’s campus
          in Mohali.
        </p>
      )
    }
  ];

  const advisors = [
    { img: assetify(Jay), name: "Jayashree Arunachalam", lang: "English" },

    {
      img: assetify(Atul),
      name: "Atul Chaurasia",
      lang: "Hindi"
    },
    {
      img: assetify(Snigh),
      name: "Snighdhendu Bhattacharya",
      lang: "Bengali"
    },
    {
      img: assetify(vinay),
      name: "Vinay Kumar",
      lang: "Telugu"
    },
    {
      img: assetify(rupesh),
      name: "Rupesh Kalantri",
      lang: "Marathi"
    },
    {
      img: assetify(kedar),
      name: "Kedar Mishra",
      lang: "Oriya"
    }
  ];

  const pastfellows = [
    {
      name: "Toshi Mandol",
      lang: "Hindi",
      link:
        "https://hindi.newslaundry.com/2021/06/20/coronavirus-lockdown-labour-modi-government-uttar-pradesh-bihar",
      icon: "https://www.newslaundry.com/favicon.ico"
    },
    {
      name: "Vishal Shukla",
      lang: "Hindi",
      link:
        "https://hindi.newslaundry.com/2021/06/16/thousands-of-years-old-bonda-tribe-doing-wonders-with-climate-friendly-agricultural-technology",
      icon: "https://www.newslaundry.com/favicon.ico"
    },
    {
      name: "Varsha Singh",
      lang: "Hindi",
      link:
        "https://hindi.newslaundry.com/2021/06/24/life-of-mountain-people-in-trouble-in-uttarakhand",
      icon: "https://www.newslaundry.com/favicon.ico"
    },
    {
      name: "Harihar Panda",
      lang: "Oriya",
      link:
        "https://odishatime.com/not-the-recipients-but-three-oil-companies-acquired-huge-benefit-by-pradhan-mantri-ujjwala-yojana/",
      icon:
        "https://odishatime.com/wp-content/uploads/2020/05/cropped-ODISHA-TIME-LOGO_-32x32.jpg"
    },
    {
      name: "Durgamadhab Mishra",
      lang: "Oriya",
      link:
        "https://odishatime.com/kalia-scheme-by-odisha-govt-has-proved-as-an-instrument-for-the-empowerment-of-farm-and-farmers/",
      icon:
        "https://odishatime.com/wp-content/uploads/2020/05/cropped-ODISHA-TIME-LOGO_-32x32.jpg"
    },
    {
      name: "Aparajito Bandopadhyay",
      lang: "Bengali",
      link:
        "http://bangla.ganashakti.co.in/Home/PopUp/?url=/admin/uploade/image_details/2021-07-08/202107072340034.jpg&category=0&date=2021-07-08&button=",
      icon: "http://bangla.ganashakti.co.in/images/logo.png"
    },

    {
      name: "Aniket Brahmadev",
      lang: "Marathi",
      link:
        "https://visheshmarathi.com/msp-farmer-aniketjadhav-indiadataportal-newslaundry/",
      icon:
        "https://visheshmarathi.com/wp-content/uploads/2020/07/cropped-vm-logo.png"
    },

    {
      name: "Rajit Sengupta",
      lang: "English",
      link:
        "https://www.newslaundry.com/2021/07/16/why-modis-promise-to-double-farmer-incomes-by-2023-will-go-unfulfilled",
      icon: "https://www.newslaundry.com/favicon.ico"
    },

    {
      name: "Donita Jose",
      lang: "English",
      link: "https://www.newslaundry.com/author/donita-jose",
      icon: "https://www.newslaundry.com/favicon.ico"
    },

    {
      name: "Poojil Tiwari",
      lang: "English",
      link:
        "https://www.newslaundry.com/2021/07/22/creeping-disaster-over-half-of-indias-soil-is-degraded",
      icon: "https://www.newslaundry.com/favicon.ico"
    },

    {
      name: "Etikala Bhavani",
      lang: "Telugu",
      link: "http://telugadda.com/?p=4912"
    },

    {
      name: "Amar Sadashiv Shaila",
      lang: "Marathi",
      link: ""
    }
  ];

  const reviews = [
    {
      name: "Etikala Bhavani, Telugu Fellow",
      img:
        "https://thumbor-stg.assettype.com/newslaundry/2021-08/e2b9875a-15bc-4f2f-bc1d-eabf1ab3a528/PHOTO_EtikalaBhavani.jpg",
      review:
        "I am glad to be one of the few first batch members to use the portal as it makes it easier and  faster to work with data. I thank ISB,Newslaundry very much for the opportunity."
    },

    {
      name: "Varsha Singh, Hindi Fellow",
      img:
        "https://thumbor-stg.assettype.com/newslaundry/2021-08/7ae0bdc9-854b-451c-b326-0640416753b0/Varsha_Singh.jpg",
      review:
        "Newslaundry and India Data Portal Fellowship explained the importance of using data in news stories. India Data Portal is a great place for journalists. With important data like agriculture, economic status at one place it makes the work of journalists easy."
    },

    {
      name: "Toshi Mandola, Hindi Fellow",
      img:
        "https://thumbor-stg.assettype.com/newslaundry/2021-08/1b1e457a-7e05-47b9-a3ae-e7677d3da871/Toshi_Mandola.jpeg",
      review:
        "My story has been published with Newslaundry as a part of the Newslaundry X India data portal fellowship. It was a great experience working with IDP and Newslaundry."
    }
  ];

  const seasons = [
    {
      name: "Fall",
      timeline: <img styleName="timeline-image" src={assetify(timeline)} />
    },

    {
      name: "Spring",
      timeline: <img styleName="timeline-image" src={assetify(timelinesoon)} />
    },

    {
      name: "Summer",
      timeline: <img styleName="timeline-image" src={assetify(timelinesoon)} />
    },

    {
      name: "Winter",
      timeline: <img styleName="timeline-image" src={assetify(timelinesoon)} />
    }
  ];

  const [season, setseason] = useState(seasons[0]);

  return (
    <React.Fragment>
      <div styleName="top-wrapper">
        <div styleName="fellowship-top">
          <div styleName="fellowship-top-left">
            <h1 styleName="fellowship-top-left-heading">
              Newslaundry - IDP fellowship
            </h1>
            <p styleName="fellowship-top-left-content">
              A quarterly fellowship that finds and trains journalists in data
              journalism. It aims to enable journalists to use data and data
              visualization as effective storytelling devices.
            </p>
            <a
              styleName="nl-btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://newslaundry.zohorecruit.in/jobs/Careers/42180000001126147/Data-Journalism-Fellowship-by-Newslaundry-and-India-Data-Portal?source=CareerSite"
            >
              Apply now
            </a>
          </div>

          <iframe
            styleName="iframeYT-mobile"
            width="350"
            height="200"
            src="https://www.youtube-nocookie.com/embed/ktUCtF9U2mo"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <iframe
            styleName="iframeYT"
            width="464"
            height="261"
            src="https://www.youtube-nocookie.com/embed/ktUCtF9U2mo"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      {/* second block */}

      <div styleName="fellowship-about">
        {/* <iframe styleName="iframeYT" width="560" height="315" src="https://www.youtube-nocookie.com/embed/7fWjjp5D7Iw?controls=0&amp;start=15" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe styleName="iframeYT-mobile" width="300" height="190" src="https://www.youtube-nocookie.com/embed/7fWjjp5D7Iw?controls=0&amp;start=15" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

        <img
          styleName="about-illustration"
          src="https://thumbor-stg.assettype.com/newslaundry/2021-08/e3298fb8-1ab3-45d6-bf86-e9c58f8ecb09/fellowship_about.png"
        />
        <div styleName="fellowship-about-left">
          <h1 styleName="fellowship-about-right-heading">
            About the fellowship
          </h1>
          <div>
            <Accordion allowZeroExpanded>
              {fellowData.map(item => (
                <AccordionItem key={item.q}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.q}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{item.a}</AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>

      <div styleName="top-wrapper">
        <div styleName="fellowship-idp">
          <div styleName="fellowship-top-left">
            <h1 styleName="fellowship-top-left-heading">About IDP</h1>
            <p styleName="fellowship-top-left-content">
              An open-access portal incubated at ISB for journalists to access,
              interact with, and visualize information relating to data. It
              includes datasets on agriculture, financial inclusion and rural
              development.
              <br />
              It is available in six different Indian languages to enable
              vernacular journalists and help them with convenient access to
              data and visualizations they can use for stories.
            </p>
            <a
              styleName="nl-btn"
              target="_blank"
              rel="noopener noreferrer"
              href="https://indiadataportal.com/"
            >
              Learn more
            </a>
          </div>
          <div styleName="fellowship-idp-right">
            <img src={assetify(idplogo)} alt="image" />
          </div>
        </div>
      </div>

      <div styleName="schedule-wrapper">
        <div styleName="fellowship-schedule">
          <div styleName="fellowship-screening">
            <h1 styleName="fellowship-advisor-heading">Fellowship timelines</h1>
            <div styleName="tl-tabs">
              {seasons.map((s, idx) => {
                return (
                  <div
                    key={idx}
                    styleName={
                      s.name === season.name ? "tl-tab-selected" : "tl-tab"
                    }
                    onClick={() => {
                      setseason(s);
                    }}
                  >
                    {s.name}
                  </div>
                );
              })}
            </div>

            {/* <p styleName="fellowship-about-right-content">
              {season.name === "Spring" ?(<><span styleName="screening-content">  The fellowship application will go live a month before the programme begins. </span>
         <span styleName="screening-content">
                Preference will be given to the journalists from vernacular media outlets from Tier 2 and Tier 3 cities. It will ensure equal gender representation and include fellows from across the country to ensure geographic diversity.


              </span><span styleName="screening-content">
                The applicants will also have to pitch a story based on a dataset(s) on the IDP platform. Post-fellowship, the fellows will do a story each from their regions based on their initial pitch.
              </span></>) :(<><span styleName="screening-content">  The fellowship application for {season.name} cohort will go live a month before the programme begins.</span> <span styleName="screening-content">The dates and scrreening will be declared soon </span><span styleName="screening-content">Stay tuned</span></>)}
            </p> */}
          </div>
          <div styleName="fellowship-timeline">{season.timeline}</div>
        </div>
      </div>

      <div styleName="top-wrapper">
        <div styleName="fellowship-idp">
          <div styleName="fellowship-top-left">
            <h1 styleName="fellowship-top-left-heading">SELECTION CRITERIA</h1>
            <p>
              <ul style={{ listStyle: "circle" }}>
                <li>
                  As part of the application, the applicant will pitch a story
                  based on an IDP dataset which, if selected, they will work on
                  during the course of the fellowship.{" "}
                </li>
                <li>
                  The applications will be assessed by a jury of Newslaundry
                  editors and IDP team members.
                </li>
                <li>
                  The jury will shortlist 16 fellows – four each writing in
                  English and Hindi, and two each in Marathi, Telugu, Oriya, and
                  Bengali – based on story pitches and work experiences.
                </li>
                <li>
                  Journalists from vernacular media outlets from Tier 2 and Tier
                  3 cities will be preferred.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <div styleName="advisor-wrapper">
        <div styleName="fellowship-advisors">
          <h1 styleName="fellowship-screening-heading">
            Our Fellowship Advisors
          </h1>
          <div styleName="fellowship-cards">
            {advisors.map((advisor, i) => {
              return (
                <div key={i} styleName="profile-card">
                  <img
                    src={
                      advisor.img
                        ? advisor.img
                        : "https://images.unsplash.com/photo-1557773092-edb2806042d2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=634&q=80"
                    }
                    alt="image1"
                    styleName="profile-icon"
                  />
                  <div styleName="profile-name">{advisor.name}</div>
                  <div styleName="profile-position">{advisor.lang}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div styleName="past-wrapper">
        <div styleName="fellowship-past">
          <h1 styleName="fellowship-screening-heading">
            Explore Past Editions
          </h1>
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Summer 2021</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div styleName="fellow-cards">
                  <table>
                    <tr styleName="fellow-card-head">
                      <tr styleName="fellow-name">
                        <h3>Name</h3>
                      </tr>
                      <tr styleName="fellow-lang">
                        <h3>Language</h3>
                      </tr>
                      <tr styleName="fellow-article">
                        <h3>Article</h3>
                      </tr>
                    </tr>
                    {pastfellows.map((fellow, idx) => {
                      return (
                        <tr key={idx} styleName="fellow-card">
                          <td styleName="fellow-name">{fellow.name}</td>
                          <td styleName="fellow-lang">{fellow.lang}</td>
                          <td styleName="fellow-article">
                            {fellow.link !== "" ? (
                              <a
                                styleName="fellow-lang"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={fellow.link}
                              >
                                Read article{" "}
                              </a>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
      <h1 styleName="fellowship-testimonial-heading">
        What do our past fellow say?
      </h1>
      <div styleName="testimonials">
        {reviews.map((review, idx) => {
          return (
            <div key={idx} styleName="testimonial">
              <div styleName="photo-desc-wrapper">
                <img styleName="photo" src={review.img} />
                <div styleName="content">
                  <img
                    styleName="quote-icon"
                    src={assetify(quoteIcon)}
                    alt="quote"
                  />
                  <div styleName="description-wrapper">
                    <span styleName="description">{review.review}</span>
                    <h3 styleName="name">{review.name}</h3>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
