/* eslint-disable max-len */
import React from "react";
import "./faq.m.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

export function FaqPage() {
  return (
    <div styleName="container">
      <h1>FREQUENTLY ASKED QUESTIONS</h1>
      <div styleName="faq-header">
        <div>
          <img
            // src="https://images.assettype.com/newslaundry/2021-05/cd1b46a7-831e-47ff-8146-11a62d95ebaf/error_404.png"
            src="https://images.assettype.com/newslaundry/2022-04/47f14dc9-a1be-48ad-aef5-5ba0b530fd8a/FAQ_page_copy.png"
            alt="faq_image"
          />
        </div>
        <div styleName="sub">
          <h3 style={{ marginBottom: "10px" }}>
            <u>Select any category to anchor</u>
          </h3>
          {faqData.map((item) => (
            <a key={item.id} href={`#` + item.id}>
              {item.sectionName}
            </a>
          ))}
        </div>
      </div>
      {faqData.map((sec) => (
        <div key={sec.sectionName} id={`${sec.id}`} styleName="sections">
          <h3>{sec.sectionName}</h3>
          <Accordion allowZeroExpanded>
            {sec.children.map((item) => (
              <AccordionItem key={item.q}>
                <AccordionItemHeading>
                  <AccordionItemButton>{item.q}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>{item.a}</AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      ))}
    </div>
  );
}

export const faqData = [
  {
    sectionName: "Subscription basics",
    id: "subs",
    children: [
      {
        q: "I’m facing issues with my subscription. Who should I get in touch with?",
        a: [
          <p key="1">
            For all issues related to subscription, payment or tech, write to{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>
            . Our team will get back to you as soon as possible.
          </p>,
        ],
      },
      {
        q: "What’s the ten-year window I see for recurring subscriptions? ",
        a: [
          <p key="1">
            If you’re paying for a recurring subscription via Razorpay (one of
            the payment gateways we use), you will see the following message: To
            begin your subscription, a payment of ₹xxx will be charged now.
            Newslaundry will then charge ₹xxx every year until (payment date ten
            years from now)
            <br/><br/>
            <i>The mentioned date (10 years from the payment date) shows how long the auto-renewal will continue if you do not cancel your subscription. </i>
            <br/><br/>
            <i>You can cancel your subscription at any time from the user profile page on the app or website before the next renewal date to stop future charges. </i>
          </p>,
        ],
      },
      {
        q: "How do I renew my subscription?",
        a: [
          <p key="1">
            Once your subscription nears expiration, head to{" "}
            <a href="/subscription">subscription</a> and purchase a subscription
            of your choice. You can also click on the Renew button on the top
            right of the website.
          </p>,
        ],
      },
      {
        q: "How do I sign up for a recurring (auto-pay) subscription?",
        a: [
          <p key="1">
            Step 1: Go to the subscription page, select the plan of your choice,
            hit the ‘subscribe’ button.
          </p>,
          <p key="2">Step 2: Select the first payment method.</p>,
          <p key="3">
            Please note that while all credit cards support recurring payments,
            only a few select Indian debit cards support recurring payments:
            ICICI Bank India, Citibank India, Canara Bank India and, Kotak
            Mahindra Bank. If you have a credit card, we suggest you use a
            credit card instead of a debit card. In case you choose the first
            payment option and use a debit card that doesn’t support a recurring
            payment, your subscription is treated as a one time subscription.
          </p>,
          <p key="4">
            We accept all major international credit and debit cards. If you’re
            based overseas and want to have a recurring subscription in USD, you
            can use PayPal too.
          </p>,
        ],
      },
      {
        q: "How can I make a donation to Newslaundry?",
        a: [
          <p key="1">
            Newslaundry is a private limited company, not a non-profit or a
            Section 25 company. Contributions to us are in the form of
            subscriptions, not donations. One can subscribe/Contribute to{" "}
            <a href="/sena">NL Sena</a> /{" "}
            <a href="/student-subscription">Sponsor a student</a> subscription.
          </p>,
        ],
      },
      {
        q: "What is behind the paywall? What are the subscription benefits that I get?",
        a: [
          <p key="1">
            As of now, all NL Interviews, podcasts (NL Hafta,Let&apos;s Talk
            About), special ground reports, and comics are behind the paywall.
            You will also get access to NL Chatbox, NL Recess, The Media Rumble,
            and a chance to participate in our podcast NL vs NL.
          </p>,
          <p key="2">
            Apart from all this, you will also get a sneak peek into our new
            podcasts and video shows. You can access all paywall content here.
          </p>,
        ],
      },
      {
        q: "What are the different ways in which I can contribute to Newslaundry?",
        a: [
          <p key="1">
            You can <a href="/subscription">subscribe</a> to any of our plans:
            Disruptor or Gamechanger. You also can contribute to our{" "}
            <a href="/sena">NL Sena</a> projects, our{" "}
            <a href="/nl-legal-fund">Legal Fund</a>, or sponsor a{" "}
            <a href="/student-subscription">student subscription</a>.
          </p>,
        ],
      },
      {
        q: "Which subscription should I buy?",
        a: [
          <p key="1">
            We have two levels of subscription plans: Disruptor and Game
            Changer.
          </p>,
          <p key="2">
            Disruptor: Starting at Rs 300 per month, you will get access to all
            paywall content (NL Hafta, Let&apos;s Talk About, NL Interviews,
            comics) and NL Chatbox, our monthly interaction with subscribers,
            and NL Recess, a digital get-together of subscribers and a guest.
            You will also have access to the private subscribers&apos; group on
            Facebook, our Discord Server, and all events, including The Media
            Rumble.
          </p>,
          <p key="3">
            Game Changer: Starting at Rs 1,000 per month, you will get all
            Disruptor benefits plus early registration access to all events,
            including The Media Rumble. You will also have access to NL Baithak,
            our quarterly video interaction with subscribers, and a private RSS
            feed URL for podcasts that are behind the paywall. You will also get
            a Pay To Keep News Free tote bag and flask if you get an annual
            Gamechanger subscription.
          </p>,
        ],
      },
      {
        q: "What is NL Sena and how do I contribute to it?",
        a: [
          <p key="1">
            While subscriptions support everything that we do, NL Sena
            contributions are to power long-form ground reports that take
            considerable time and are ignored by the mainstream media.
          </p>,
          <p key="2">
            You can contribute to the current NL Sena Project from the{" "}
            <a href="/sena">NL Sena page</a> or from the Homepage itself.
          </p>,
        ],
      },
      {
        q: "I want to contribute to a NL Sena project. The membership amount is Rs 1,000 onwards. Is there a way I can contribute with a smaller amount?",
        a: [
          <p key="1">
            The idea behind NL Sena has always been to keep it separate from our
            regular subscriptions. While the latter powers all our operations,
            Sena membership is to power specific, comprehensive stories that the
            mainstream media won’t take up. If you want to contribute with a
            smaller amount, you can always support us with a{" "}
            <a href="/subscription">subscription</a> or by sponsoring a{" "}
            <a href="/student-subscription">student subscription</a>.
          </p>,
        ],
      },
      {
        q: "I have an active subscription, will contributing to NL Sena extend my subscription?",
        a: [
          <p key="1">
            Yes, the subscription that you get out of a NL Sena contribution
            kicks in after your current one-time subscription expires. For
            recurring subscriptions, you can email us to gift your NL Sena
            subscription to a student.
          </p>,
        ],
      },
      {
        q: "How can I sponsor Newslaundry student subscriptions?",
        a: [
          <p key="1">
            Head <a href="/student-subscription">here</a>, choose the number of
            students you want to sponsor, and make the payment.
          </p>,
        ],
      },
      {
        q: "I am a student. How can I get a student subscription?",
        a: [
          <p key="1">
            Click on the “Claim a free student subscription” option{" "}
            <a href="/student-subscription">here</a> and fill the registration
            form. We will map your subscription and email you.
          </p>,
        ],
      },
      {
        q: "I have renewed my subscription but my account details aren’t updated with new subscription end date.",
        a: [
          <p key="1">
            You must have renewed your subscription before the current
            subscription expired. As soon as the current subscription expires,
            the new subscription kicks in and reflects under my profile &gt;
            Active subscription. Although, the renewed one would reflect under
            ‘Plan History’.
          </p>,
        ],
      },
      {
        q: "Can I make a one-time payment?",
        a: [
          <p key="1">
            We do accept one-time contributions. You can select a plan of your
            choice from <a href="/subscription">here</a>, and select the 2nd
            payment method - Netbanking, UPI, Wallets or RuPay Card on the
            payment checkout page.
          </p>,
        ],
      },
      {
        q: "I have a recurring subscription. How can I change my card?",
        a: [
          <p key="1">
            We do not provide an option to change card on the website. You will
            have to cancel your subscription and buy a new one.
          </p>,
        ],
      },
      {
        q: "Why can I not contribute a custom amount to Newslaundry?",
        a: [
          <p key="1">
            There are specific deliverables that have been set for every level
            of subscription. In case of custom amount, it will be difficult for
            us to assign deliverables.
          </p>,
        ],
      },
      {
        q: "I was charged Rs.5 while trying to renew my subscription. Why?",
        a: [
          <p key="1">
            As per RBI guidelines, one has to set up a mandate to initiate a
            recurring payment via UPI. If you already have an active
            subscription, your subscription will kick in after your current
            subscription expires. The subscription payment will be processed as
            soon as your new subscription starts. In the meantime, the Rs. 5
            charge is used to set up a mandate. Upon successful set up of
            mandate, Rs. 5 charge is refunded automatically within 12 hours.
          </p>,
        ],
      },
      {
        q: "How do I contribute via PayPal for NL Sena/NL Legal Fund?",
        a: [
          <p key="1">
            After selecting the contribution amount, choose the second option,
            “Netbanking, UPI, Wallets or Paypal” on the payment checkout page.
          </p>,
        ],
      },
    ],
  },
  {
    sectionName:
      "Login, paywall access, subscription issues and subscriber benefits",
    id: "login",
    children: [
      {
        q: "How do I sign up/login? How do I access paywall content?",
        a: [
          <p key="1">
            Click on the user icon at the top right of the homepage. To access
            paywall content, log in using the email or social login (Facebook,
            Google) you used while subscribing. You can find all paywall content{" "}
            <a href="/subscriber-only">here</a>.
          </p>,
        ],
      },
      {
        q: "Where can I look for my subscription status and details?",
        a: [
          <p key="1">
            Once logged in, click My Profile under the user icon at the top
            right of the homepage. You will see all your subscription details
            such as <a href="/account-details">Plan History</a> and the
            expiration date of your current subscription.
          </p>,
        ],
      },
      {
        q: "How do I extend my one-time subscription?",
        a: [
          <p key="1">
            If you have an active one-time subscription that you want to extend,
            you can simply purchase a new subscription plan. It will kick in as
            soon as your current subscription gets over, and start reflecting in
            ‘My Profile’.
          </p>,
        ],
      },
      {
        q: "I forgot my password or don't remember the social login I used. How do I log in?",
        a: [
          <p key="1">
            Click on the user icon at the top right of the homepage and then
            Forgot Password in the login pop up. Enter the email address you
            registered with or the one linked to the social login you used while
            subscribing. An OTP will be sent to your email. Enter it on the same
            screen and change your password.
          </p>,
          <p key="2">
            If you don’t receive the OTP, email us at{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>
            . Do check once in your promotions and spam folders.
          </p>,
        ],
      },
      {
        q: "How do I cancel my subscription?",
        a: [
          <p key="1">
            Go to My Profile. Make sure you’re logged in using the same email or
            social login linked to your subscription. Click on Cancel.
          </p>,
        ],
      },
      {
        q: "I don’t want to share my phone number to make the subscription purchase. How can I get a subscription without sharing my contact number?",
        a: [
          <p key="1">
            Most Indian banks mandate mobile numbers for domestic payment
            gateways. So, if you’re making the payment via Razorpay, there’s no
            way to subscribe without entering your contact number. This phone
            number is not saved or accessed by Newslaundry. It’s saved as
            encrypted data with Razorpay, our payment gateway.
          </p>,
          <p key="2">
            However, if you’re using a payment method that’s powered by Stripe
            or PayPal, you can make the payment without entering your mobile
            number.
          </p>,
        ],
      },
      {
        q: "How do I reset my password?",
        a: [
          <p key="1">
            Step 1: Click on the user icon at the top right of the website. You
            will get this pop-up.
          </p>,
          <p key="2">
            <img
              src="https://images.assettype.com/newslaundry/2020-12/0acfdab6-3eb0-4354-b8dd-ce92e7f9f466/Forgot_password.png"
              alt="img"
            />
          </p>,
          <p key="3">Step 2: Enter your email address.</p>,
          <p key="4">
            Step 3: Check your email for the OTP, add it along with your
            password.
          </p>,
        ],
      },
      {
        q: "I am not receiving the OTP. What should I do?",
        a: [
          <p key="1">
            Do check your spam and promotions folders once. If you still can’t
            find it, write to us at{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>
            .{" "}
          </p>,
        ],
      },
      {
        q: "I am a subscriber but I am hitting the paywall pop-up while accessing subscriber-only content. What should I do?",
        a: [
          <p key="1">
            Make sure you are logged in with the same email address to which
            your subscription is mapped. If it’s the same address, clear your
            cache once and then try to access the subscriber-only content.
          </p>,
          <p key="2">
            If the issue persists, send us a screen recording of the same at{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>
            .
          </p>,
        ],
      },
      {
        q: "I am a subscriber. How do I watch interviews that are behind the paywall on YouTube?",
        a: [
          <p key="1">
            You can access paywall interviews on our website only.{" "}
          </p>,
        ],
      },
      {
        q: "How do I get access to NL’s subscriber-only Facebook subscribers group?",
        a: [
          <p key="1">
            You can send a request on the Facebook subscribers group{" "}
            <a href="https://www.facebook.com/groups/825754627612142">here</a>.
            Do add the email linked to your subscription on the request form
            there.
          </p>,
        ],
      },
      {
        q: "I want to upgrade my plan to Game Changer. How can I do that?",
        a: [
          <p key="1">
            The only way to upgrade to a GameChanger subscription is to cancel
            the current subscription (if it’s recurring) and get another
            GameChanger plan. It will kick in as soon as the previous
            subscription gets expired.
          </p>,
          <p key="2">
            If it’s one-time: The Game Changer will kick in after the current
            one expires.{" "}
          </p>,
          <p key="3">
            Write to us at{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>{" "}
            and we will provide you with private RSS Feed URLs once you
            subscribe to Game Changer plan.
          </p>,
        ],
      },
    ],
  },
  {
    sectionName: "NL-TNM Joint Subscription",
    id: "joint_subscription",
    children: [
      {
        q: "I am an existing subscriber. How can I upgrade to the NL-TNM joint subscription?",
        a: [
          <p key="1">
            Right now, the NL-TNM joint subscription can be availed by those who
            don’t have an active subscription. If you are an existing
            subscriber, please wait for a few weeks, we are working to upgrade
            your subscription.
          </p>,
          <p key="2">
            Alternatively, you can cancel your current subscription and buy a
            joint subscription. Please note, your joint subscription will only
            kick in once your current subscription ends.
          </p>,
        ],
      },
      {
        q: "How can I cancel my NL-TNM joint subscription",
        a: [
          <p key="1">
            You can cancel the NL-TNM joint subscription through the website you
            purchased it from. For instance, If you bought it from the
            Newslaundry website, simply click on the user profile, then click on
            the subscription tab, and choose “cancel your subscription”. If you
            have made a purchase through The News Minute website, please send an
            email to{" "}
            <a href="mailto: members@thenewsminute.com">
              {" "}
              members@thenewsminute.com
            </a>
          </p>,
        ],
      },
      {
        q: "I purchased the NL-TNM joint subscription from Newslaundry website. How do I access it on The News Minute?",
        a: [
          <p key="1">
            To access the NL-TNM joint subscription on The News Minute website,
            you need to sign up on the TNM website with the email address you
            used to buy the joint subscription on the Newslaundry website.
          </p>,
          <p key="2">
            You can also choose to log in with your gmail or Google Workspace
            account.
          </p>,
        ],
      },
      {
        q: "I have an existing subscription. If I buy the NL-TNM joint subscription, when will it start?",
        a: [
          <p key="1">
            As an existing one-time subscriber, if you get the NL-TNM joint
            subscription, it will start instantly. If you have a recurring
            subscription, it will start after your current subscription expires.
          </p>,
        ],
      },
      {
        q: "How do I find out about subscriber meet-ups organised by The News Minute and Newslaundry?",
        a: [
          <p key="1">
            All our subscribers are informed three weeks prior to the meet-up.
            We have WhatsApp groups for subscribers in different regions and
            cities. If you are a subscriber and want to be added to these
            groups, please write to us at You can also reach out to{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>{" "}
            with your city or region.
          </p>,
        ],
      },
    ],
  },
  {
    sectionName: "Payment related issues",
    id: "payment",
    children: [
      {
        q: "What are the different payment methods?",
        a: [
          <p key="1">
            Our payment gateway works with all major international debit and
            credit cards (Mastercard, Visa, American Express), Netbanking, and
            UPI options (Google Pay and BHIM). It also supports wallet options
            like FreeCharge, PayZapp, PhonePe, Airtel Money, MobiKwik, JioMoney,
            and Amazon Pay.
          </p>,
        ],
      },
      {
        q: "I live outside India. How can I pay using a non-Indian card? Can I pay in USD to save the bank currency conversion fee?",
        a: [
          <p key="key-1">
            Yes, our payments are powered by multiple gateways including Stripe
            and PayPal which work with all major international cards:
            Mastercard, Visa, American Express. You can contribute via Paypal to
            pay in USD.
          </p>,
        ],
      },
      {
        q: "Can I pay via Google Pay directly? What's the UPI id or phone number?",
        a: [
          <p key="1">
            We do have UPI-based options. To make the payment via Google Pay,
            Phone Pay, Bhim or any other UPI-based app, first choose the
            Netbanking, UPI, Wallets or RuPay Card payment option, click on
            UPI/QR, and then enter your UPI ID or pay by scanning the QR code.
          </p>,
        ],
      },
      {
        q: "I have an active one-month recurring subscription plan, I want to upgrade it and switch to a six-month recurring subscription plan.",
        a: [
          <p key="1">
            One can’t have simultaneous recurring subscriptions or upgrade from
            one recurring plan to another. You will need to cancel your current
            recurring subscription and get a new recurring subscription of your
            choice.
          </p>,
        ],
      },
      {
        q: "I am not able to pay via PayPal when sponsoring a student subscription. What should I do?",
        a: [
          <p key="1">
            You can reach out to us at{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>
            . We will send you the PayPal payment link.
          </p>,
        ],
      },
      {
        q: "I have gifted a subscription to a friend but he/she hasn’t received the confirmation email. How will I know if his/her subscription is active?",
        a: [
          <p key="1">
            We still haven’t rolled out the feature where the friend receiving
            the gift gets an email about it. For now, your friend can log in
            using the email address to which the subscription is mapped, head to
            My Profile under the Profile icon, and see the active plan.
          </p>,
          <p key="2">
            You can also reach out to{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>{" "}
            and confirm with us.
          </p>,
        ],
      },
      {
        q: "I want to pay via Paytm. How can I?",
        a: [
          <p key="1">
            We don&apos;t have a separate payment option for Paytm. However, you
            can pay via Paytm using your UPI ID. Just choose the Netbanking,
            UPI, Wallets or RuPay Card on the payment checkout page and click on
            UPI.{" "}
          </p>,
        ],
      },
    ],
  },
  {
    sectionName: "Podcast player and access",
    id: "podcast",
    children: [
      {
        q: "How do I access the podcast player?",
        a: [
          <p key="1">
            Click on the floating radio button at the bottom right. Choose the
            show and the episode you want to play.
          </p>,
        ],
      },
      {
        q: "I am a Newslaundry subscriber. How can I access paywalled podcasts on other podcast apps like Apple Podcasts?",
        a: [
          <p key="1">
            If you have a Gamechanger subscription, you can find the private RSS
            feed URL for all the behind paywall podcasts under your My Profile
            page and paste in your podcast player. This feature is for
            Gamechanger subscribers only and works with apps like Apple
            Podcasts, Pocket Casts and Castro.
          </p>,
          // <p key="2">
          //   <img
          //     src="https://s2.gifyu.com/images/Animated-GIF-source-18f7cfd339e720b97.gif"
          //     alt="gif"
          //   />
          // </p>,
        ],
      },
      {
        q: "How do I add the private RSS feeds for NL Hafta and Let’s Talk About to my Apple Podcast app?",
        a: [
          <p key="1">
            Click on Get Feed Link below the show icon. It will copy the link to
            your clipboard. Launch Apple Podcasts on your phone, tap on Library
            from the menu items at the bottom, click on Edit at the top right,
            and then Choose Add a Show by URL. Paste the copied link and hit
            Subscribe.
          </p>,
        ],
      },
      // {
      //   q:
      //     "How do I add the private RSS feeds for NL Hafta and Let’s Talk About to my Google Podcast app?",
      //   a: [
      //     <p key="1">
      //       Click on Get Feed Link below the show icon. Launch Google Podcasts
      //       on your phone, tap on Activity from the menu icons at the bottom,
      //       and choose Subscriptions from the menu at the top. Click on the
      //       kebab icon (⋮) at the top right, click on Add by RSS feed, paste the
      //       copied link, and hit Subscribe.
      //     </p>,
      //     <p key="2">
      //       <img
      //         src="https://s2.gifyu.com/images/Animated-GIF-source-2.gif"
      //         alt="gif"
      //       />
      //     </p>,
      //   ],
      // },
      {
        q: "How do I add the private RSS feeds for NL Hafta and Let’s Talk About to my Castro app?",
        a: [
          <p key="1">
            Click on Get Feed Link below the show icon. Launch Castro on your
            phone, tap on the Search or Discover icon from the menu icons at the
            bottom, paste the copied link, select the show, and hit Subscribe.
          </p>,
        ],
      },
      {
        q: "How do I add the private RSS feeds for NL Hafta and Let’s Talk About to my Pocket Casts app?",
        a: [
          <p key="1">
            Click on Get Feed Link below the show icon. Launch Pocket Casts on
            your phone, tap on Discover, paste the copied link, and tap on the
            Search icon. Select the show and hit Subscribe.
          </p>,
          <p key="2">
            <img
              src="https://s8.gifyu.com/images/Animated-GIF-source-3.gif"
              alt="gif"
            />
          </p>,
        ],
      },
      {
        q: "How do I add the private RSS feeds for NL Hafta and Let’s Talk About to my Podcast Addict app?",
        a: [
          <p key="1">
            Click on Get Feed Link below the show icon. Launch Podcast Addict on
            your phone, tap the “+” icon at the top right, click on the RSS Feed
            icon, paste the copied link, and hit Add.
          </p>,
          <p key="2">
            <img
              src="https://s8.gifyu.com/images/Animated-GIF-source-4.gif"
              alt="gif"
            />
          </p>,
        ],
      },
      {
        q: "I want to listen to the full NL Hafta episode on Spotify.",
        a: [
          <p key="1">
            Not all apps support private RSS feed authentication. Our private
            RSS feeds work with Apple Podcasts, Pocket Casts, Castro, Podcast
            Addict and Podcast Go.
          </p>,
        ],
      },
      {
        q: "I had a Disruptor subscription but upgraded to Gamechanger to access the RSS feature. Will my Gamechanger subscription kick in only when my current subscription expires?",
        a: [
          <p key="1">
            Email us at{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>{" "}
            and we will send you a unique RSS feed link.
          </p>,
        ],
      },
      {
        q: "The podcast player stops playing when my phone’s screen is locked or when I switch back from apps such Google Maps. Can I download the episode and play in another player?",
        a: [
          <p key="1">
            While we have included as many features as we could in our podcast
            player, a website based podcast player still has limitations in
            mobile devices. Some browsers and devices do not let functions
            within a browser sync with phone devices controls. That&apos;s why
            we are working on iOS and Android apps. For now, if you&apos;re a
            Game Changer subscriber, you can access the paywall podcasts by
            using the RSS Feed URL on apps like: Apple Podcasts, Google
            Podcasts, Pocket Casts, Castro, Podcast Addict and Podcast Go.
          </p>,
          <p key="2">
            If you are a Disruptor subscriber, you can download the episode from
            our player and play it natively in your podcast or audio apps such
            as VLC, Podcast Addict, Pocket Casts, Wynk Music, and Google Play
            Music.
          </p>,
        ],
      },
    ],
  },
  {
    sectionName: "Website features",
    id: "website",
    children: [
      {
        q: "How do I gift a Newslaundry subscription to a friend?",
        a: [
          <p key="1">
            On the <a href="/subscription">subscription page</a>, hit the “Gift
            a subscription” option. Select the plan and enter the email address
            of the person you want to gift a subscription to, and pay.
          </p>,
        ],
      },
      {
        q: "I have to login every time before posting a comment under a story, even after I am logged in to the site. Why?",
        a: [
          <p key="1">
            We are integrating our login with the comment widget. It will be up
            soon.
          </p>,
        ],
      },
      {
        q: "Does NL have a Discord server?",
        a: [
          <p key="1">
            Yes, We do! Go to your <a href="/account-details">Profile page</a>{" "}
            and click on NL Discord Server.
          </p>,
        ],
      },
      {
        q: "I want to introduce my friend to Newslaundry and share paywall content with them before they can subscribe. How can I do that?",
        a: [
          <p key="1">
            Subscribers can gift upto three paywall/subscribers-only pieces to
            non-subscribers in a month. Click on the Gift Story button on the
            paywall story to gift it.
          </p>,
        ],
      },
      {
        q: "Do you have an app?",
        a: [
          <p key="1">
            Yes, we do. Click{" "}
            <a href="https://www.newslaundry.com/download-app">here</a> to
            download the app.
          </p>,
        ],
      },
      {
        q: "The payment went through but my subscription isn’t active. I used UPI as a payment method (Google Pay, BHIM, PhonePe).",
        a: [
          <p key="1">
            Subscription payments via UPI often don&apos;t get captured. Write
            to us at{" "}
            <a href="mailto:subscription@newslaundry.com">
              subscription@newslaundry.com
            </a>
            . We will check your payment status and help you out.
          </p>,
        ],
      },
    ],
  },
  {
    sectionName: "Events and webinars",
    id: "events",
    children: [
      {
        q: "How do I get my free NL event passes and access to NL Recess?",
        a: [
          <p key="1">
            We send a unique RSVP code to each of our subscribers at least two
            weeks before the event. The subscriber can enter this code on the
            event page to RSVP and get their free pass. We send a Zoom link a
            day before the NL Recess.
          </p>,
        ],
      },
      {
        q: "What is NL Chatbox? How can I attend it?",
        a: [
          <p key="1">
            NL Chatbox is our subscribers-only interaction with our team where
            you can review and critique our work, and ask questions that we
            respond to in real-time. You can find all previous Chatbox sessions{" "}
            <a href="/nl-chatbox">here</a>.
          </p>,
        ],
      },
      {
        q: "What is NL Recess? How can I attend it? Will the sessions be available later?",
        a: [
          <p key="1">
            NL Recess is a grown-up version of the “break time” we got in
            school. It’s a subscribers-only digital event where we invite a
            guest and get our subscribers to ask questions throughout the
            free-wheeling and interactive session.
          </p>,
          <p key="2">
            If you are an active subscriber, we will send you a registration
            form in advance and then a Zoom link to attend the session.
          </p>,
          <p key="3">
            The full conversation will not be uploaded anywhere as the Chatham
            House rules apply to NL Recess. Only a trailer of 3-4 minutes will
            be uploaded. If there&apos;s an interview in any of the sessions, we
            will put that out on the website as an NL Interview.
          </p>,
        ],
      },
      {
        q: "NL Chatbox, NL Baithak and NL Recess. Are they all the same?",
        a: [
          <p key="1">
            NL Chatbox is a monthly interaction where the entire NL team answers
            questions and feedback from subscribers in real-time.
          </p>,
          <p key="2">
            NL Baithak is a quarterly event where our editors and team leads
            share what’s in store and take feedback from subscribers
          </p>,
          <p key="3">
            NL Recess is a grown-up version of the “break time” we got in
            school. It’s a subscribers-only digital event where we invite a
            guest and get our subscribers to ask questions throughout the
            free-wheeling and interactive session.
          </p>,
        ],
      },
    ],
  },
  {
    sectionName: "Merchandise",
    id: "merch",
    children: [
      {
        q: "I ordered NL merchandise but haven’t received it yet.",
        a: [
          <p key="1">
            We take 1-2 weeks for delivery. In case you need details or want to
            check on the status, write to{" "}
            <a href="mailto:store@newslaundry.com">store@newslaundry.com</a>.
          </p>,
        ],
      },
      {
        q: "I contributed to the NL Sena/Legal Fund. I haven't received the merch yet.",
        a: [
          <p key="1">
            We take 1-2 weeks for delivery. In case you need details or want to
            check on the status, write to{" "}
            <a href="mailto:store@newslaundry.com">store@newslaundry.com</a>.
          </p>,
        ],
      },
      {
        q: "Do you deliver outside India?",
        a: [
          <p key="1">
            No, we deliver in India only. You can share an Indian address of a
            friend or family members who lives in India.
          </p>,
        ],
      },
    ],
  },
  // {
  //   sectionName: "Discord",
  //   id: "discord",
  //   children: [
  //     {
  //       q:
  //         "How do I get access to the rest of the subscriber-only channels on this server?",
  //       a: [
  //         <p key="1">Subscribe at https://www.newslaundry.com/subscription</p>,
  //         <p key="2">
  //           Go to user dashboard at https://www.newslaundry.com/account-details
  //         </p>,
  //         <p key="3"> Click on the Discord server tab.</p>,
  //         <p key="4">
  //           Read the instructions, add your Discord id, and hit submit.
  //         </p>,
  //         <p key="5">
  //           Note: Your username is not your Discord id. It&apos;s a unique
  //           18-digit number. Head to #find-your-id channel on Discord and type
  //           the command myid
  //         </p>,
  //       ],
  //     },
  //     {
  //       q: "Which channels here are exclusively for Newslaundry subscribers?",
  //       a: [
  //         <p key="1">
  //           Well, lots. Barring rules, memes, and an updates bot on our videos,
  //           all channels are accessible to Newslaundry subscribers only. These
  //           include discussion boards (#media, #politics, #books,
  //           #recommendations, #sports) to engage with fellow subscribers, and
  //           channels to share feedback (#haftaletters), subscription issues, and
  //           updates on our events. Gamechanger subscribers also get access to a
  //           discussion board where they can engage and speak with the
  //           Newslaundry team.
  //         </p>,
  //       ],
  //     },
  //     {
  //       q:
  //         "What will happen to my server membership if my Newslaundry subscription expires?",
  //       a: [
  //         <p key="1">
  //           Upon expiry of your subscription, you will lose access to the
  //           subscriber-only channels in this server. On renewing it, you will
  //           automatically gain access to these channels.
  //         </p>,
  //       ],
  //     },
  //     {
  //       q:
  //         "I live outside India. How do I get access to the #overseas-subscribers channel?",
  //       a: [
  //         <p key="1">
  //           Once you have submitted your user id on our website dashboard, you
  //           can post your handle in #subscription-issue channel or email us at{" "}
  //           <a href="mailto:subscription@newslaundry.com">
  //             subscription@newslaundry.com
  //           </a>
  //           . We will add you.{" "}
  //         </p>,
  //       ],
  //     },
  //   ],
  // },
];
