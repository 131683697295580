/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useSelector } from "react-redux";
import { parseUrl } from "query-string";
import { get } from "lodash";
import "./stuednt-sub-explained.m.css";
import { Link } from "@quintype/components";

export function StudentSubscriptionExplainedPage() {
  const { member, qt } = useSelector(state => state);

  const authHost = qt.config.publisher.publisher.auth_host;

  const handleBtnClick = async () => {
    if (!member) {
      const params = parseUrl(qt.currentPath);

      const callbackUrl = get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      );
      const redirectUrl = get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      );
      window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
    } else {
      window.open(
        `https://app.studentidentify.com/?oauth=true&clientId=499e1f1098d0533f49fe8c767db46e2e448e58f7&response_type=code&redirect_uri=https://www.newslaundry.com/student-subscription&scope=status,basic_info,mobile&state=${member.id}`
      );
    }
  };

  return (
    <div>
      <div>
        <main styleName="hero-section">
          <div>
            <div styleName="hero-section-content">
              <h1 styleName="title">Student Subscription</h1>
              <p styleName="border-bottom"></p>
              <p styleName="description">
                As a subscription-based news organisation, we firmly believe
                that readers need to pay to keep news free from any advertiser
                influence. We were the first movers and our subscribers
                disrupted the news ecosystem with their contributions, paving
                the way for other news organisations to try out this model.
              </p>
              <p styleName="description">
                Besides supporting our journalism, subscribers also get access
                to access to paywall content — stories that not only report on
                issues that matter but also track and analyse the media’s
                coverage of it.
              </p>
            </div>
            <div styleName="hero-section-img">
              <img
                src="https://images.assettype.com/newslaundry/2022-03/7b8c9113-5364-48aa-a12b-7581be1310d7/student_subscription_main.png"
                alt="Student Subscription"
              />
            </div>
          </div>
        </main>
        <div styleName="sub-section">
          <div>
            <h3 styleName="feature-headline">
              Help us power student subscription
            </h3>
            <p styleName="feature-text">
              Your contribution can directly help students get a better
              understanding of the media, and access information that can help
              them make informed voters and citizens.
            </p>
          </div>
          <div styleName="cta-btn-row">
            <button styleName="pay-btn" onClick={handleBtnClick}>
              Yes, I&apos;m a student
            </button>
            <Link styleName="pay-btn" href="/sponsor-student-subscription">
              Sponsor student subscription
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
