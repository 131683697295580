import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { TextField } from "../../../atoms/textfield";
import axios from "axios";
// import {
//   fetchDataFromLocalStorage,
//   saveDataToLocalStorage
// } from "../../subscription-checkout/utils";
// import {
//   getDigitsExceptLastN,
//   getLastNDigits,
//   isMobileValid
// } from "../../../../utils";

import "./edit-profile.m.css";

// import countryCodeAndEmojisData from "../countryCodes.json";

export const EditProfile = () => {
  const member = useSelector(state => get(state, ["member"], null));
  const usernameInputRef = useRef(null);
  // const oldMobileNumber = get(member, ["phone-number"], null);

  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: ""
  });
  // const [countryName, setCountryName] = useState(null);

  useEffect(() => {
    if (usernameInputRef.current) usernameInputRef.current.focus();
  }, [usernameInputRef]);

  // useEffect(() => {
  //   const cName = fetchDataFromLocalStorage("phoneCountryName");

  //   if (cName) {
  //     setCountryName(cName);
  //   } else if (
  //     member &&
  //     member["phone-number"] &&
  //     member["phone-number"].length > 10
  //   ) {
  //     const setCode = getDigitsExceptLastN(member["phone-number"], 10);

  //     const countryData = countryCodeAndEmojisData.find(
  //       c => c.countryPhoneCode === `+${setCode}`
  //     );

  //     if (countryData) {
  //       setCountryName(countryData.countryName);

  //       saveDataToLocalStorage(countryData.countryName, "phoneCountryName");
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (member) {
      setUser({
        first_name: member["first-name"],
        last_name: member["last-name"],
        email: member.email,
        mobile: member["phone-number"]
      });
    } else {
      setUser({
        first_name: "",
        last_name: "",
        email: "",
        mobile: ""
      });
    }
  }, [member]);

  const setData = e => {
    const userObj = { ...user };
    const fieldName = e.target.id;
    userObj[fieldName] = e.target.value;
    setUser(userObj);
  };

  async function updateDetails() {
    try {
      if (!user.first_name) {
        setErrorMsg("First name required");

        setTimeout(() => {
          setErrorMsg("");
        }, 3000);

        return null;
      }

      // const isMobValid = isMobileValid(user.mobile);

      // if (!isMobValid) {
      //   setErrorMsg("Please enter a valid mobile number with country code.");

      //   setTimeout(() => {
      //     setErrorMsg("");
      //   }, 3000);

      //   return null;
      // }

      const userObj = {
        "first-name": user.first_name,
        "last-name": user.last_name,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        "phone-number": user.mobile,
        id: member.id
      };

      await axios.post("/api/auth/v1/users/profile", userObj);

      // await axios.put("/whatsapp-api/updateUserWhatsapp", {
      //   newMobileNumber:
      //     user.mobile.length > 10
      //       ? getLastNDigits(user.mobile, 10)
      //       : Number(user.mobile),
      //   oldMobileNumber:
      //     oldMobileNumber.length > 10
      //       ? getLastNDigits(oldMobileNumber, 10)
      //       : Number(oldMobileNumber),
      //   countryCode:
      //     user.mobile.length > 10
      //       ? getDigitsExceptLastN(user.mobile, 10)
      //       : null,
      //   emailAddress: member.email,
      //   name: `${member["first-name"]} ${member["last-name"]}`,
      //   phoneCountryName: countryName
      // });

      // if (user.mobile.length > 10) {
      //   const cCode = getDigitsExceptLastN(user.mobile, 10);

      //   const countryData = countryCodeAndEmojisData.find(
      //     c => c.countryPhoneCode === `+${cCode}`
      //   );

      //   if (countryData) {
      //     // Had to do this because AT's shitty profile update API does not support metadata updation
      //     // and neither has any country field in users table
      //     saveDataToLocalStorage(countryData.countryName, "phoneCountryName");
      //   }
      // }

      setSuccessMsg("Profile updated");
    } catch (error) {
      setErrorMsg("Something went wrong. Please try again later");
    }
  }
  return (
    <div styleName="profile-details">
      <form>
        <h2 styleName="heading">Account Details</h2>
        <div styleName="profile-wrapper">
          <label htmlFor="first_name" styleName="form-labels">
            <span>Your first name</span>
            <TextField
              type="text"
              value={user.first_name}
              placeholder="Your first name"
              id="first_name"
              name="first_name"
              onChange={setData}
              ref={usernameInputRef}
            />
          </label>
          <label htmlFor="last_name" styleName="form-labels">
            <span>Your last name</span>
            <TextField
              type="text"
              value={user.last_name}
              placeholder="Your last name"
              id="last_name"
              name="last_name"
              onChange={setData}
            />
          </label>
          <label htmlFor="email" styleName="form-labels">
            <span>Your email address</span>
            <TextField
              type="email"
              value={user.email}
              placeholder="Your email address"
              id="email"
              name="email"
              disabled={true}
            />
          </label>
          {/* <label htmlFor="mobile" styleName="form-labels">
            <span>
              Your whatsapp number preceded by the country code. Ex.
              919810741333
            </span>
            <TextField
              type="tel"
              value={user.mobile}
              pattern="^\d{13}$"
              maxLength="13"
              placeholder="Your whatsapp number with country code"
              id="mobile"
              name="mobile"
              onChange={setData}
            />
          </label> */}
        </div>
        {successMsg && (
          <p aria-live="polite" styleName="success-msg">
            {successMsg}
          </p>
        )}
        {errorMsg && (
          <p aria-live="polite" styleName="error">
            {errorMsg}
          </p>
        )}
        <div styleName="actions">
          <button
            styleName="button updated"
            type="submit"
            onClick={() => updateDetails()}
          >
            UPDATE
          </button>
        </div>
      </form>
    </div>
  );
};
