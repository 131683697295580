import React, { useState } from "react";
import PT from "prop-types";
import get from "lodash/get";
import { connect, useSelector } from "react-redux";
import { Button } from "../../atoms/button";
import { parseUrl } from "query-string";
import { InputField } from "../../atoms/input-field";
import { verifyEmailOTP, verifyEmail } from "../../helper/api";
import { IS_OPEN_LOGIN_FORM } from "../../helper/actions";
import "./forms.m.css";

function OTPBase({ id, member, checkForMemberUpdated, manageLoginForm }) {
  const [otp, setOTP] = useState("");
  const [error, setError] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [otpId, setOPTId] = useState(id);
  const sketchesHost = useSelector(state =>
    get(state, ["qt", "currentHostUrl"], "https://newslaundry.com")
  );
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  const params = parseUrl(currentPath);
  // const redirectUrl =
  //   get(params, ["query", "redirect-url"], sketchesHost) +
  //   "?signup_success=true";
  const getRedirectUrl = get(params, ["query", "redirect-url"], sketchesHost);
  const redirectUrl = new URL(getRedirectUrl);
  redirectUrl.searchParams.append("signup_success", "true");

  const otpHandler = async e => {
    e.preventDefault();
    e.stopPropagation();
    verifyEmailOTP(otp, otpId)
      .then(() => {
        checkForMemberUpdated().then(res => {
          manageLoginForm(false);
          console.log("successfully login");
          window.location.href = redirectUrl.href;
        });
      })
      .catch(error => {
        setError(true);
        console.warn("error", error);
      });
  };

  const setData = e => {
    setOTP(e.target.value);
  };

  const resendOTP = () => {
    verifyEmail(member.email)
      .then(res => {
        setSuccessMsg("OTP Sent to your registered email");
        setOPTId(res["email-token"]);
      })
      .catch(error => setError(error));
  };
  return (
    <React.Fragment>
      <p styleName="otp-text">
        A One Time Password code was sent via email to{" "}
        <span>{member.email}</span>
      </p>
      <form styleName="nl-form" onSubmit={otpHandler}>
        <InputField
          name="Enter OTP"
          id="otp"
          type="text"
          required
          onChange={setData}
        />
        {error && <p styleName="error">Invalid OTP</p>}
        {successMsg && <p styleName="success-msg">{successMsg}</p>}
        <div styleName="actions">
          <Button onClick={otpHandler} className="nl-btn-large">
            Verify OTP
          </Button>
          <p styleName="resend-otp" onClick={resendOTP}>
            Resend OTP
          </p>
        </div>
      </form>
    </React.Fragment>
  );
}

OTPBase.propTypes = {
  onSubmit: PT.func,
  member: PT.object,
  id: PT.string,
  checkForMemberUpdated: PT.func,
  manageLoginForm: PT.func,
  isLoginOpen: PT.bool
};

function mapStateToProps(state) {
  return {
    isLoginOpen: get(state, ["isLoginOpen"], false)
  };
}

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function(payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  }
});

const OTP = connect(mapStateToProps, mapDispatchToProps)(OTPBase);
export { OTP };
