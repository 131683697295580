import React from "react";
import { Link, ResponsiveImage, collectionToStories } from "@quintype/components";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
import { object } from "prop-types";

import Arrow from "../../../../assets/images/chevron-right-solid.svg";
import { CollectionName } from "../../atoms/collection-name";
import Carousel from "./carousel";
import { getStoryHeading, getHeroImage, getStoryMetadata } from "../../../utils";
import Authors from "../../atoms/authors";

import "./comics.m.css";

export const ComicsRow = ({ collection, config }) => {
  const stories = collectionToStories(collection);
  if (stories.length < 1) {
    return null;
  }
  const children = stories.map((story, index) => {
    const headline = getStoryHeading(story);
    const heroS3Key = getHeroImage(story, config);
    const slug =
      story["story-template"] === "news-elsewhere" ? get(story, ["metadata", "reference-url"], story.url) : story.url;
    return (
      <div styleName="story-card" key={index}>
        {story["story-template"] === "news-elsewhere" ? (
          <a href={`${slug}`} target="_blank" rel="noopener noreferrer">
            <figure styleName="image-wrapper">
              <ResponsiveImage
                slug={heroS3Key}
                metadata={getStoryMetadata(story)}
                aspectRatio={[179, 239]}
                styleName="image"
                defaultWidth={259}
                widths={[250, 375]}
                alt={story["hero-image-caption"] && story["hero-image-caption"]}
              />
            </figure>
            <h3 styleName="headline">{headline} </h3>
          </a>
        ) : (
          <Link href={`${slug}`}>
            <figure styleName="image-wrapper">
              <ResponsiveImage
                slug={heroS3Key}
                metadata={getStoryMetadata(story)}
                aspectRatio={[179, 239]}
                styleName="image"
                defaultWidth={259}
                widths={[250, 375]}
                alt={story["hero-image-caption"] && story["hero-image-caption"]}
              />
            </figure>
            <h3 styleName="headline">{headline} </h3>
          </Link>
        )}
        <Authors story={story} authorColor={"#fff"} />
      </div>
    );
  });

  return (
    <div className="full-width-with-padding" styleName="wrapper">
      <div className="full-width-with-padding" styleName="inner-wrapper">
        <div className="container">
          <CollectionName collection={collection} color="#fff" />
          <div styleName="content-wrapper">
            <Carousel
              carouselName={`carousel-${collection.slug}`}
              options={{
                type: `${stories.length <= 4 ? "slider" : "carousel"}`,
                perView: 5,
                gap: 62,
                breakpoints: {
                  580: { perView: 1, gap: 32, peek: { before: 0, after: 80 } },
                  992: { perView: 3, peek: { before: 0, after: 80 } }
                }
              }}
              totalItems={stories.length}
              showArrows={true}
              renderCenterLeftControls={({ previousSlide, currentSlide }) => {
                return (
                  currentSlide !== 0 && (
                    <button onClick={previousSlide} className="slider-btn">
                      <img src={`${assetify(Arrow)}`} alt="left" styleName="slider-arrow arrow-left" />
                    </button>
                  )
                );
              }}
              renderCenterRightControls={({ nextSlide }) =>
                stories.length > 4 && (
                  <button onClick={nextSlide} className="slider-btn">
                    <img src={`${assetify(Arrow)}`} alt="right" styleName="slider-arrow" />
                  </button>
                )
              }
            >
              {children}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

ComicsRow.storyLimit = 16;

ComicsRow.propTypes = {
  collection: object,
  config: object
};
