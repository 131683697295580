/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import TimeAgo from "react-timeago";
import getStoriesByCollection, {
  dateFormatterWithoutTime
} from "../../../utils";
import {
  LoadMoreCollectionStories,
  ResponsiveImage
} from "@quintype/components";
import { LoadMoreButton } from "../../atoms/load-more-button";
import { Headline } from "../../atoms/headline";
import { CardImage } from "../../atoms/card-image";
// import { TimeStamp } from "../../atoms/time-stamp";
// import NlSenaWrapper from "../../pages/nl-sena-wrapper";
// import { CollectionName } from "../../atoms/collection-name";

import "./podcast-collection.m.css";
import HorizontalStoryCardList from "../../molecules/horizontal-story-card-list";
import VerticleStoryCardList from "../../molecules/verticle-story-card-list";

const PodcastCollectionStory = ({ story }) => {
  const getLabel = get(
    story,
    ["metadata", "story-attributes", "storyattribute", 0],
    ""
  );
  return (
    <div styleName="story-wrapper">
      <div className="story-image">
        <CardImage story={story} />
        {getLabel && <div styleName="ribbon">{getLabel}</div>}
      </div>
      <div styleName="story-content">
        {/* <div styleName="time-stamp">
          <TimeStamp story={story} />
        </div> */}
        <div styleName="headline">
          <Headline story={story} externalLink={true} />
        </div>
        <TimeAgo
          styleName="time-ago"
          date={story["last-published-at"]}
          formatter={dateFormatterWithoutTime}
        />
      </div>
    </div>
  );
};

PodcastCollectionStory.propTypes = {
  story: PropTypes.object
};

const PodcastCollectionBase = props => {
  const collection = get(props, ["data", "collection"], {});
  const coverImgUrl = get(
    collection,
    ["metadata", "cover-image", "cover-image-s3-key"],
    ""
  );
  const coverImgMetadata = get(
    collection,
    ["metadata", "cover-image", "cover-image-metadata"],
    {}
  );
  const podcastStory = getStoriesByCollection(collection);

  const totalCount = get(
    props,
    ["data", "collectionstories", "collection", "total-count"],
    ""
  );

  const subPodcastTemplate = ({ onLoadMore, stories }) => {
    return (
      <div styleName="story-wrapper">
        <div className="desktop-only">
          <HorizontalStoryCardList
            stories={stories}
            hideAuthorWithTime={false}
            hideAuthor={true}
          />
        </div>
        <div className="mobile-only">
          <VerticleStoryCardList stories={stories} hideAuthor={true} />
        </div>
        <div style={{ textAlign: "center" }}>
          {totalCount !== stories.length && (
            <LoadMoreButton onClick={onLoadMore} />
          )}
        </div>
      </div>
    );
  };

  if (podcastStory.length < 1) {
    return <div styleName="not-found">Stories not found...</div>;
  }
  console.log(props)
  return (
    <div>
      <div className="full-width-with-padding" styleName="wrapper">
        <div styleName="content">
          <h1 styleName="heading">{collection.name}</h1>
          <div styleName="border"></div>
          {coverImgUrl && (
            <div styleName="image display-mobile">
              <figure styleName="image-wrapper">
                <ResponsiveImage
                  slug={coverImgUrl}
                  metadata={coverImgMetadata}
                  aspectRatio={[268, 268]}
                  defaultWidth={259}
                  widths={[250, 375]}
                  alt={collection.name}
                  className="resp-image"
                />
              </figure>
            </div>
          )}
          <p styleName="description">{collection.summary}</p>
        </div>
        {coverImgUrl && (
          <div styleName="image  display-desktop">
            <figure styleName="image-wrapper">
              <ResponsiveImage
                slug={coverImgUrl}
                metadata={coverImgMetadata}
                aspectRatio={[268, 268]}
                defaultWidth={259}
                widths={[250, 375]}
                alt={collection.name}
                className="resp-image"
              />
            </figure>
          </div>
        )}
      </div>
      <div styleName="story">
        <LoadMoreCollectionStories
          template={subPodcastTemplate}
          data={{ stories: podcastStory }}
          collectionSlug={get(props, ["data", "collection", "slug"], {})}
          numStoriesToLoad={10}
          params={{ "item-type": "story" }}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    currentPath: get(state, ["qt", "currentPath"], "/")
  };
}

export const PodcastCollection = connect(mapStateToProps)(
  PodcastCollectionBase
);
