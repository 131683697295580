/* eslint-disable max-len */

/* not used now */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect, useSelector } from "react-redux";
import { AccessType } from "@quintype/components";

import { TextField } from "../../atoms/textfield";
import { Button } from "../../atoms/button";
import { SUBSCRIPTION_PAGE_URL } from "../../../../isomorphic/constants";
import Loader from "../../atoms/loader";
import {
  IS_OPEN_LOGIN_FORM,
  USER_SUBSCRIPTION,
  PAYMENT_SUCCESS
} from "../../helper/actions";
import {
  subscriptionGifterEmail,
  subscriptionsGifteeEmail,
  logWebsiteSource
} from "../../helper/api";
import IntegrationData from "../../../../../config/integrations.js";
import { isValidEmail } from "../../../utils";
import "./payment-checkout-page.m.css";
import { parseUrl } from "query-string";

// Plan legend
// STAGING
// 1793 - 3 year GC Onetime
// 1381 - GC Annual OneTime
// 1859 - GC One year onetime USD
// PROD
// 1797 - 3 year GC onetime INR
// 1796 - 3 year GC onetime USD
// 750 - GC one year onetime USD
// 327 - GC one year onetime INR
// 330 - Disruptor one year onetime INR
// 742 - Disruptor one year onetime USD
// 4520 - Disruptor yearly Joint onetime INR
// 4523 - Gamechanger yearly joint onetime INR
// 4524 - Disruptor yearly Joint onetime USD
// 4527 - Gamechanger yearly joint onetime USD

const toWhitelistPlans = [
  1793,
  1381,
  1859,
  1797,
  1796,
  750,
  327,
  330,
  742,
  4520,
  4523,
  4524,
  4527
];
// const toWhitelistPlans = [ 4523, 4520, 4527, 4524 ];

const PaymentCheckoutLayout = ({
  initAccessType,
  validateCoupon,
  initRazorPayPayment,
  initStripePayment,
  initPaypalPayment,
  selectedPlan,
  planType,
  redirectURL,
  config,
  navigateTo,
  member,
  manageLoginForm,
  paymentCheckout,
  subscriptionPlans,
  subscriptionGroups,
  getSubscriptionForUser,
  userSubscriptionDetails,
  userSubscription
  // paymentSuccess
}) => {
  const [email, setEmail] = useState();
  const [isLoading, setLoading] = useState(false);
  const [paymentOption, setPaymentOption] = useState({
    razorpay: "",
    paypal: "",
    stripe: true
  });
  const [error, setError] = useState("");
  const [coupon, setCoupon] = useState();
  const [isCouponLoading, setCouponLoading] = useState(false);
  const [isValidCoupon, setisValidCoupon] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [discountDetails, setDiscountDetails] = useState({
    value: 0,
    type: null
  });
  const [useSelectedPlan, setUseSelectedPlan] = useState(selectedPlan);
  const [discountedPrice, setDiscountedPrice] = useState(
    selectedPlan.price_cents
  );
  // const [attemptBody] = useState({
  //   subscriber: { id: "", email: member.email, name: member.name },
  //   planId: selectedPlan.id,
  //   groupName: selectedPlan.subscription_group_id.toString(),
  //   durationType: selectedPlan.duration_unit,
  //   gift: paymentCheckout === "gift-subscription",
  //   currency: selectedPlan.price_currency,
  //   planName: selectedPlan.title,
  //   amount: selectedPlan.price_cents,
  //   durationLength: selectedPlan.duration_length,
  //   planType: planType,
  //   gifter: {},
  //   paymentGateway: ""
  // });

  const { qt } = useSelector(state => state);

  const domainSlug = get(config, ["domainSlug"], null);
  const subDomainUrl = get(config, ["publisher", "subdomain_url"], "");
  // const { ga } = global;

  const onChange = e => {
    setCoupon(e.target.value.toUpperCase());
  };

  useEffect(() => {
    if (isValidCoupon) {
      selectEquivalentOneTimePlan();
    }
  }, [isValidCoupon, paymentOption]);

  // Handle Coupon Submit click
  const onSubmitClick = async (coupon, validateCoupon) => {
    setCouponLoading(true);

    const oneTimePlanID = +selectedPlan.description.split(",")[0];

    try {
      const response = await validateCoupon(oneTimePlanID, coupon);

      if (!response.valid) {
        setisValidCoupon(false);
        setCouponLoading(false);
        setCouponError(
          "Oops! The discount code is either incorrect or not active anymore."
        );
        return;
      }

      if (!toWhitelistPlans.includes(oneTimePlanID)) {
        setisValidCoupon(false);
        setCouponLoading(false);
        setCouponError(
          // "Oops! This discount code is not valid for the subscription plan you have chosen. Try a different plan OR yearly plans"
          "Oops! This discount code is not valid for the subscription plan you have chosen. Try annual plans."
        );
        return;
      }

      setisValidCoupon(true);
      setCouponError("");
      await setDiscountDetails({
        value: response.discount_details && response.discount_details.value,
        type:
          response.discount_details && response.discount_details.discount_type
      });

      const newPrice = get(
        response,
        ["discount_details", "discounted_price_cents"],
        useSelectedPlan.price_cents
      );

      setDiscountedPrice(newPrice);
      setCouponLoading(false);
    } catch (error) {
      console.error("Coupon error: " + error);
    }
  };

  const handleCouponRemoval = () => {
    setCouponLoading(true);
    setisValidCoupon(false);
    setCouponError("");
    setCoupon("");
    setDiscountDetails({ value: 0, type: null });
    setDiscountedPrice(selectedPlan.price_cents);

    setUseSelectedPlan(selectedPlan);
    setCouponLoading(false);
  };

  async function handlePaymentSuccess(res, successUrl) {
    // ga &&
    //   ga("send", {
    //     hitType: "pageview",
    //     page: "/subscription-success"
    //   });
    try {
      await logWebsiteSource(successUrl, res && res.subscription.id.toString());

      if (paymentCheckout === "gift-subscription") {
        const gifteeEmail = email;
        const gifterEmail = get(member, ["email"], "");

        if (gifterEmail && isValidEmail(gifteeEmail)) {
          subscriptionGifterEmail({
            to: gifterEmail,
            template: `d-0993c18160a445ec996261436ef29d72`
          });
          subscriptionsGifteeEmail({
            to: gifteeEmail,
            template: `d-7ce7dbd76d9940d2a636e949777627cd`
          });
        }
      }

      await setLoading(false);
      await setSubscribedUser(res);
      await navigateTo(successUrl);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    !global.AccessType && initAccessType();
    if (paymentCheckout === "gift-subscription") {
      selectEquivalentOneTimePlan();
    }
  }, []);

  const onChangeEmail = e => {
    setEmail(e.target.value);
  };

  const handleChange = event => {
    const value = event.target.value;
    setError("");
    if (value === "razorpay") {
      setPaymentOption({ razorpay: event.target.value });
      selectEquivalentOneTimePlan();
    } else if (value === "paypal") {
      setPaymentOption({ paypal: event.target.value });

      if (isValidCoupon) {
        selectEquivalentOneTimePlan();
      } else {
        paymentCheckout === "gift-subscription"
          ? selectEquivalentOneTimePlan()
          : selectEquivalentRecurringPlan();
      }
    } else if (value === "stripe") {
      setPaymentOption({ stripe: event.target.value });

      if (isValidCoupon) {
        selectEquivalentOneTimePlan();
      } else {
        paymentCheckout === "gift-subscription"
          ? selectEquivalentOneTimePlan()
          : selectEquivalentRecurringPlan();
      }
    }
  };

  const setSubscribedUser = res => {
    const subscriptionData = (res && res.subscription) || [];
    const getUserSubscription = [...userSubscription];

    getUserSubscription.unshift(subscriptionData);
    const hasSub = getUserSubscription
      .filter(subscription => subscription.active === true)
      .filter(item => get(item, ["assets", 0, "type"], "") === "site");
    if (hasSub.length > 0) {
      userSubscriptionDetails({
        userHasSubscription: true,
        userSubscription: getUserSubscription
      });
    } else {
      userSubscriptionDetails({
        userHasSubscription: false,
        userSubscription: getUserSubscription
      });
    }
  };

  const handlePaymentError = (getErrorMessage, getGiftErrorMessage) => {
    if (getErrorMessage === "user closed the payment") {
      setError("Payment attempt was cancelled by the user.");
    } else if (
      getErrorMessage === "already has active subscription for the same plan"
    ) {
      setError(
        `You already have an active recurring subscription. For support in addition to your current subscription, you can check out and join NL Sena or gift a subscription to someone you know`
      );
    } else if (
      getErrorMessage ===
      "already has active subscription for a recurring plan with same asset"
    ) {
      setError(
        `You already have an active recurring subscription. For support in addition to your current subscription, you can check out and join NL Sena or gift a subscription to someone you know`
      );
    } else if (getGiftErrorMessage === "subscriber cannot be same as gifter") {
      setError(
        `The email address of the sender and receiver cannot be the same. Please go back and enter a different email address.`
      );
    } else {
      setError(
        "Oops! Something went wrong. Please contact subscription@newslaundry.com for further assistance"
      );
    }
  };

  const payMent = async (
    initRazorPayPayment,
    initStripePayment,
    initPaypalPayment
  ) => {
    if (!member) {
      manageLoginForm(true);
      return false;
    }
    if (paymentCheckout === "gift-subscription" && !isValidEmail(email)) {
      setError("Invalid Email");
      return false;
    }

    setLoading(true);

    const params = parseUrl(qt.currentPath);
    const authCode = params.query.code;

    const appendSuccessURL =
      `?subscription-status=success` +
      (planType !== "standard" ? "&sena=true" : "") +
      ((useSelectedPlan.duration_unit === "years" &&
        useSelectedPlan.subscription_group_id === 113) ||
      useSelectedPlan.subscription_group_id === 760 ||
      useSelectedPlan.subscription_group_id === 804 ||
      useSelectedPlan.subscription_group_id === 805
        ? "&gamechanger=true"
        : "") +
      ((useSelectedPlan.duration_unit === "years" &&
        useSelectedPlan.subscription_group_id === 114) ||
      useSelectedPlan.subscription_group_id === 759
        ? "&disruptor=true"
        : "") +
      (useSelectedPlan.id === 2033 || useSelectedPlan.id === 2034
        ? `&student-sub=true&code=${authCode}`
        : "");

    const host = domainSlug
      ? subDomainUrl
      : get(config, ["publisher", "frontend_url"], "");

    if (paymentOption.razorpay) {
      const selectedPlanObj = Object.assign({}, useSelectedPlan);
      const successUrl = redirectURL + appendSuccessURL;
      selectedPlanObj.price_cents = discountedPrice;

      const options = {
        argType: "options",
        selectedPlan: selectedPlanObj,
        planType: planType,
        couponCode: isValidCoupon ? coupon : "",
        recipientSubscriber: {
          recipient_identity_provider: "email",
          recipient_identity: email
        }
      };

      initRazorPayPayment(options)
        .then(async res => {
          // TODO need to store this invoice responce in store for my account
          await handlePaymentSuccess(res, successUrl);
        })
        .catch(error => {
          const getErrorMessage = get(
            error,
            ["error", "message", "subscriber", 0],
            ""
          );
          const getGiftErrorMessage = get(
            error,
            ["error", "message", "subscription_gift", 0],
            error.message
          );
          console.error(`Razorpay error: ${error}`);

          handlePaymentError(getErrorMessage, getGiftErrorMessage);
          setLoading(false);
        });
      return true;
    }

    if (paymentOption.stripe) {
      let plan;

      if (isValidCoupon) {
        if (planType === "standard") {
          const splitDescription =
            (selectedPlan.description && selectedPlan.description.split(",")) ||
            "";
          const getSelectedPlan = subscriptionPlans.filter(
            plan => plan.id === +splitDescription[0]
          );
          plan = getSelectedPlan[0];
        }
      } else {
        plan = useSelectedPlan;
      }

      const selectedPlanObj = Object.assign({}, plan);
      const successUrl = host + redirectURL + appendSuccessURL;
      const cancelUrl = host + "/payment-checkout";
      selectedPlanObj.price_cents = discountedPrice;

      const options = {
        argType: "options",
        selectedPlan: selectedPlanObj,
        planType: planType,
        couponCode: isValidCoupon ? coupon : "",
        successUrl,
        cancelUrl,
        recipientSubscriber: {
          recipient_identity_provider: "email",
          recipient_identity: email
        }
      };

      initStripePayment(options)
        .then(async res => {
          await handlePaymentSuccess(res, successUrl);
        })
        .catch(error => {
          const getErrorMessage = get(
            error,
            ["error", "message", "subscriber", 0],
            ""
          );
          const getGiftErrorMessage = get(
            error,
            ["error", "message", "subscription_gift", 0],
            error.message
          );
          console.error(`Stripe error: ${error}`);

          handlePaymentError(getErrorMessage, getGiftErrorMessage);
          setLoading(false);
        });
      return true;
    }

    if (paymentOption.paypal) {
      const splitDescription =
        (useSelectedPlan.description &&
          useSelectedPlan.description.split(",")) ||
        "";
      if (!splitDescription[1]) {
        console.error("USD plan id missing in recurring plan");
        return;
      }

      let usdPlan = {};

      usdPlan = subscriptionPlans.find(
        plan => plan.id === +splitDescription[1]
      );
      if (!usdPlan) {
        console.error(
          `Equivalent USD plan with id ${splitDescription[1]} not found`
        );
        return;
      }

      const returnUrl = host + redirectURL + appendSuccessURL;
      const cancelUrl = host + "/payment-checkout";

      if (isValidCoupon) {
        await validateCoupon(usdPlan.id, coupon)
          .then(res => {
            if (res.valid) {
              usdPlan.price_cents = res.discount_details.discounted_price_cents;
            }
          })
          .catch(err => console.error(`Paypal coupon error: ${err}`));
      }

      const selectedPlanObj = Object.assign({}, usdPlan);

      const options = {
        argType: "options",
        selectedPlan: selectedPlanObj,
        planType: planType,
        couponCode: isValidCoupon ? coupon : "",
        recipientSubscriber: {
          recipient_identity_provider: "email",
          recipient_identity: email
        },
        returnUrl,
        cancelUrl
      };

      initPaypalPayment(options)
        .then(async res => {
          await handlePaymentSuccess(res, returnUrl);
        })
        .catch(error => {
          const getErrorMessage = get(
            error,
            ["error", "message", "subscriber", 0],
            ""
          );
          const getGiftErrorMessage = get(
            error,
            ["error", "message", "subscription_gift", 0],
            error.message
          );
          console.error(`Paypal error: ${error}`);

          handlePaymentError(getErrorMessage, getGiftErrorMessage);
          setLoading(false);
        });
      return true;
    }
    setLoading(false);
    setError("Please select one payment options");
    return false;
  };

  const selectEquivalentOneTimePlan = () => {
    if (planType === "standard") {
      const splitDescription =
        (selectedPlan.description && selectedPlan.description.split(",")) || "";
      const getSelectedPlan = subscriptionPlans.filter(
        plan => plan.id === +splitDescription[0]
      );
      setUseSelectedPlan(getSelectedPlan[0]);
    }

    return useSelectedPlan;
  };

  const selectEquivalentRecurringPlan = () => {
    if (planType === "standard") {
      setUseSelectedPlan(selectedPlan);
    }
  };

  return (
    <div styleName="outer-wrapper">
      <div styleName="inner-wrapper with-shadow">
        <h1 styleName="headline">Confirm details</h1>
        {useSelectedPlan.title && (
          <div styleName="name-wrapper">
            <span styleName="text">
              {planType === "standard" ? "Subscription Type:" : "Project Name:"}{" "}
            </span>
            <span styleName="text plan-name">{useSelectedPlan.title}</span>
          </div>
        )}
        <div styleName="coupon-wrapper">
          <h3 styleName="secondary-heading">Discount Code</h3>
          <div styleName="input-wrapper">
            <input
              type="text"
              name="discount-code"
              id="discount-code"
              styleName="discount-input"
              value={coupon}
              onChange={onChange}
              placeholder="Enter Coupon Code"
            />
            {isValidCoupon ? (
              <button
                styleName="apply-coupon"
                disabled={isCouponLoading || !coupon}
                onClick={() => handleCouponRemoval()}
              >
                {isCouponLoading ? <Loader color="#ec2227" /> : "REMOVE"}
              </button>
            ) : (
              <button
                styleName="apply-coupon"
                disabled={isCouponLoading || !coupon}
                onClick={() => onSubmitClick(coupon, validateCoupon)}
              >
                {isCouponLoading ? <Loader color="#ec2227" /> : "APPLY"}
              </button>
            )}
          </div>
          {couponError && <div styleName="error">{couponError}</div>}
          {isValidCoupon && (
            <div styleName="success-msg">Your Coupon has been applied.</div>
          )}
        </div>
        {useSelectedPlan.price_cents && (
          <div styleName="name-wrapper">
            <span styleName="text">
              {" "}
              {planType === "standard" ? "Subtotal:" : "Contribution:"}{" "}
            </span>
            <span styleName="text plan-name">
              ₹ {useSelectedPlan.price_cents / 100}{" "}
              {/* {planType === "standard" && checkboxSelect
              ? "every " + useSelectedPlan.duration_length + " " + useSelectedPlan.duration_unit
              : null} */}
            </span>
          </div>
        )}
        {discountedPrice !== useSelectedPlan.price_cents && (
          <div styleName="name-wrapper">
            <span styleName="text">Discount: </span>
            <span styleName="text plan-name">
              {discountDetails.value} {discountDetails.type}
            </span>
          </div>
        )}
        {discountedPrice !== useSelectedPlan.price_cents && (
          <div styleName="name-wrapper">
            <span styleName="text">Discount Value: </span>
            <span styleName="text plan-name">
              ₹ {(useSelectedPlan.price_cents - discountedPrice) / 100}
            </span>
          </div>
        )}
        {planType === "standard" && paymentCheckout === "gift-subscription" && (
          <div styleName="email-wrapper">
            <TextField
              type="email"
              value={email}
              onChange={onChangeEmail}
              placeholder="Email Address Of Giftee"
              required
            />
          </div>
        )}
        <div styleName="text plan-name price-wrapper">
          <span>To pay</span>
          <span styleName="price">&#x20B9; {discountedPrice / 100}</span>
        </div>
        <h3
          style={{
            fontWeight: "bold",
            margin: "2rem 0",
            fontFamily: "var(--primary-font)"
          }}
        >
          Payment Method
        </h3>
        <div styleName="payment-selection">
          {useSelectedPlan.supported_payment_providers
            .sort()
            .reverse()
            .map(item => {
              if (item === "razorpay") {
                return (
                  <div styleName="payment-wrapper" key={item}>
                    <div styleName="radio text">
                      <input
                        checked={paymentOption[item]}
                        name="payment"
                        onChange={handleChange}
                        id={item}
                        type="radio"
                        value={item}
                      />
                      <label htmlFor={item} styleName="radio-label">
                        <div styleName="label-inner-wrapper">
                          <div styleName="label-text">
                            Netbanking, UPI, Wallets or Paypal
                          </div>
                          <div styleName="label-img-wrapper">
                            <img
                              alt="google pay"
                              styleName="radio-label-img"
                              src="https://images.assettype.com/newslaundry/2020-10/1b086995-83ca-4388-b0ad-88383e02c2da/googlepay.png"
                            />
                            <img
                              alt="paytm"
                              style={{
                                height: "1.4rem",
                                marginBottom: "0.4em"
                              }}
                              styleName="radio-label-img"
                              src="https://thumbor-stg.assettype.com/newslaundry/2021-07/21aac1e9-d2f2-41f5-b6c6-22700fe8a59e/paytmlogo.png"
                            />
                            <img
                              alt="phone pe"
                              styleName="radio-label-img"
                              src="https://images.assettype.com/newslaundry/2020-10/f104aa32-abb5-48d3-84ea-34b7cfcbf20f/phonepe.png"
                            />
                            <img
                              alt="paypal"
                              style={{
                                height: "2.5rem",
                                marginBottom: "-0.4em"
                              }}
                              styleName="radio-label-img"
                              src="https://img.icons8.com/color/96/000000/paypal.png"
                            />
                            <img
                              alt="RuPay"
                              style={{ height: "2.4rem" }}
                              styleName="radio-label-img"
                              src="https://images.assettype.com/newslaundry/2020-10/2e01405e-03c2-4ce4-818c-99461a20c0e0/Rupay_Logo.png"
                            />
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                );
              } else if (item === "paypal" && planType === "standard") {
                return (
                  <div styleName="payment-wrapper" key={item}>
                    <div styleName="radio text">
                      <input
                        checked={paymentOption[item]}
                        name="payment"
                        onChange={handleChange}
                        id={item}
                        type="radio"
                        value={item}
                      />
                      <label htmlFor={item} styleName="radio-label">
                        <div styleName="label-inner-wrapper">
                          <div styleName="label-text">PayPal</div>
                          <div styleName="label-img-wrapper">
                            <img
                              style={{ height: "1.5rem" }}
                              alt="paypal"
                              styleName="radio-label-img"
                              src="https://images.assettype.com/newslaundry/2020-10/8c48530f-1c8a-4729-94aa-d8a0312cbec6/paypal.png"
                            />
                          </div>
                        </div>
                      </label>
                    </div>
                    {paymentOption[item] && (
                      <div styleName="payment-note">
                        <small>
                          Only for subscribers based outside India. You will be
                          charged in USD. You can turn off the auto-renewal from
                          the subscriber dashboard anytime.
                        </small>
                      </div>
                    )}
                  </div>
                );
              } else if (item === "stripe") {
                return (
                  <div styleName="payment-wrapper" key={item}>
                    <div styleName="radio text">
                      <input
                        checked={paymentOption[item]}
                        name="payment"
                        onChange={handleChange}
                        id={item}
                        type="radio"
                        value={item}
                      />
                      <label htmlFor={item} styleName="radio-label">
                        <div styleName="label-inner-wrapper">
                          <div styleName="label-text">
                            Credit or Debit Card{" "}
                          </div>
                          <div styleName="label-img-wrapper">
                            <img
                              alt="master card"
                              styleName="radio-label-img"
                              src="https://images.assettype.com/newslaundry/2020-10/252dd4b3-d916-4407-ae66-b76f70d78df1/mastercard.png"
                            />
                            <img
                              styleName="radio-label-img"
                              alt="visa card"
                              src="https://images.assettype.com/newslaundry/2020-10/b62f6c9f-ba78-480d-909f-206414bb57b0/visacard.png"
                            />
                            <img
                              alt="maestro card"
                              styleName="radio-label-img"
                              src="https://images.assettype.com/newslaundry/2020-10/8bb15b8a-02d1-4654-98b0-a5dda9b4af47/maestrp.png"
                            />
                            <img
                              alt="americanexpress card"
                              styleName="radio-label-img"
                              src="https://images.assettype.com/newslaundry/2020-10/955142d7-7686-4268-97f1-4c5329c2b6ad/americanexpresscard.png"
                            />
                          </div>
                        </div>
                      </label>
                    </div>
                    {paymentOption[item] && planType === "standard" ? (
                      <div styleName="payment-note">
                        <small>
                          Your subscription will be renewed automatically, if
                          your bank supports it. You can turn off the
                          auto-renewal from the subscriber dashboard anytime.
                        </small>
                      </div>
                    ) : null}
                  </div>
                );
              }
            })}
        </div>
        {error && <div styleName="error">{error}</div>}
        <div
          onClick={() =>
            payMent(initRazorPayPayment, initStripePayment, initPaypalPayment)
          }
          styleName="button"
        >
          <Button className="nl-btn-large" disabled={isLoading}>
            {isLoading ? <Loader /> : "SUBSCRIBE"}
          </Button>
        </div>
      </div>
    </div>
  );
};

PaymentCheckoutLayout.propTypes = {
  config: PropTypes.object,
  selectedPlan: PropTypes.object,
  navigateTo: PropTypes.func,
  manageLoginForm: PropTypes.func,
  member: PropTypes.object,
  initAccessType: PropTypes.func,
  initRazorPayPayment: PropTypes.func,
  initStripePayment: PropTypes.func,
  initPaypalPayment: PropTypes.func,
  validateCoupon: PropTypes.func,
  paymentCheckout: PropTypes.string,
  redirectURL: PropTypes.string,
  planType: PropTypes.string,
  getSubscriptionForUser: PropTypes.func,
  userSubscriptionDetails: PropTypes.func,
  subscriptionGroups: PropTypes.array,
  subscriptionPlans: PropTypes.array,
  userSubscription: PropTypes.array
};

const PaymentCheckoutPageBase = ({
  selectedPlan,
  planType,
  redirectURL,
  config,
  navigateTo,
  member,
  manageLoginForm,
  paymentCheckout,
  subscriptionPlans,
  userSubscriptionDetails,
  userSubscription,
  subscriptionGroups
}) => {
  // Logic for retaining data in Payment
  // if(typeof window !== 'undefined'){
  //   const storage = window.sessionStorage
  //   if( Object.keys(selectedPlan).length>1)
  // {storage.setItem('selectedPlan',JSON.stringify(selectedPlan)) }
  // else{
  // selectedPlan = JSON.parse(storage.getItem('selectedPlan'))
  // }}

  if (Object.keys(selectedPlan).length < 1) {
    return (
      <div styleName="select-paln">
        <h1>Please select the option...</h1>
        <div styleName="go-back">
          <Button socialButton href={SUBSCRIPTION_PAGE_URL}>
            SUBSCRIBE
          </Button>
        </div>
      </div>
    );
  }

  const integrationsConfig = IntegrationData();
  const email = get(member, ["email"], "");
  const phone = get(member, ["metadata", "phone"], "");
  const isStaging = get(
    config,
    ["publisher", "publisher", "accessTypeEnvironment"],
    true
  );
  const enableAccesstype = get(
    config,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;
  return (
    <div className="full-width-with-padding" styleName="wrapper">
      <AccessType
        enableAccesstype={enableAccesstype}
        isAccessTypeCampaignEnabled={true}
        isStaging={false}
        accessTypeKey={accessTypeKey}
        email={email}
        phone={phone}
        accessTypeBkIntegrationId={accessTypeBkIntegrationId}
      >
        {({
          initAccessType,
          validateCoupon,
          initRazorPayPayment,
          initStripePayment,
          initPaypalPayment,
          getSubscriptionForUser
        }) => {
          return (
            <PaymentCheckoutLayout
              initAccessType={initAccessType}
              validateCoupon={validateCoupon}
              initRazorPayPayment={initRazorPayPayment}
              initStripePayment={initStripePayment}
              initPaypalPayment={initPaypalPayment}
              selectedPlan={selectedPlan}
              planType={planType}
              redirectURL={redirectURL}
              config={config}
              navigateTo={navigateTo}
              member={member}
              manageLoginForm={manageLoginForm}
              paymentCheckout={paymentCheckout}
              subscriptionPlans={subscriptionPlans}
              subscriptionGroups={subscriptionGroups}
              getSubscriptionForUser={getSubscriptionForUser}
              userSubscriptionDetails={userSubscriptionDetails}
              userSubscription={userSubscription}
            />
          );
        }}
      </AccessType>
    </div>
  );
};

PaymentCheckoutPageBase.propTypes = {
  config: PropTypes.object,
  selectedPlan: PropTypes.object,
  navigateTo: PropTypes.func,
  manageLoginForm: PropTypes.func,
  member: PropTypes.object,
  paymentCheckout: PropTypes.string,
  redirectURL: PropTypes.string,
  planType: PropTypes.string,
  userSubscription: PropTypes.object,
  userSubscriptionDetails: PropTypes.func,
  subscriptionPlans: PropTypes.array,
  subscriptionGroups: PropTypes.array
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function(payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url),
  userSubscriptionDetails: function(payload) {
    dispatch({
      type: USER_SUBSCRIPTION,
      PAYMENT_SUCCESS,
      payload: payload
    });
  },
  paymentSuccess: function(payload) {
    dispatch({
      type: PAYMENT_SUCCESS,
      payload
    });
  }
});

const mapStateToProps = state => {
  return {
    selectedPlan: get(state, ["selectedPlan", "plan"], {}),
    planType: get(state, ["selectedPlan", "planType"], ""),
    subscriptionPlans: get(state, ["selectedPlan", "subscriptionPlans"], []),
    subscriptionGroups: get(state, ["subscriptions"], []),
    redirectURL: get(state, ["selectedPlan", "redirectUrl"], ""),
    config: get(state, ["qt", "config"], {}),
    member: get(state, ["member"], null),
    paymentCheckout: get(state, ["paymentCheckout"], ""),
    userSubscription: get(state, ["isSubscribedUser", "userSubscription"], [])
  };
};

export const PaymentCheckoutPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCheckoutPageBase);
