/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { parseUrl } from "query-string";
import PT from "prop-types";
import { Link } from "@quintype/components";
import get from "lodash/get";
import {
  IS_OPEN_LOGIN_FORM,
  SELECTED_PLAN,
  PAYMENT_CHECKOUT_PAGE
} from "../../helper/actions";

import "./nl-fund-project-card.m.css";

let NLFundProjectCard = class NLFundProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscriptions: {}
    };
    this.handleSubscriptionClick = this.handleSubscriptionClick.bind(this);
  }

  handleSubscriptionClick(e, subscriptionGroup) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.props.member) {
      const params = parseUrl(this.props.currentPath);
      const callbackUrl = get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      );
      const redirectUrl = get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      );
      window.location = `${this.props.authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${this.props.authHost}/user-login`;
      return false;
    }

    const firstPlan = get(subscriptionGroup, ["subscription_plans", 0]);
    const selectedSubscriptionPlan = get(
      this.state,
      ["selectedSubscriptions", subscriptionGroup.id],
      firstPlan
    );
    this.props.selectedPlan(selectedSubscriptionPlan, this.props.redirectUrl);
    // this.props.navigateTo(`/payment-checkout`);
    this.props.navigateTo(
      `/campaign/checkout?plan=${selectedSubscriptionPlan.id}&group=${selectedSubscriptionPlan.subscription_group_id}&legal=true`
    );
    return true;
  }

  handlePlanChange(e, subscriptionGroupId) {
    const currentPlan = JSON.parse(e.value);
    const currentState = Object.assign({}, this.state.selectedSubscriptions, {
      [subscriptionGroupId]: currentPlan
    });
    this.setState({ selectedSubscriptions: currentState });
  }

  render() {
    const {
      showBottom = true,
      subscriptions
      // showSingleCampaign = false
    } = this.props;
    if (!subscriptions) {
      return null;
    }
    const currency = get(subscriptions, ["currency"], "");
    const currencySymbol = currency === "INR" ? "₹" : "$";
    const targetAmount = get(subscriptions, ["target_amount"], 0) / 100;
    const completedAmount = get(subscriptions, ["collected_amount"], 0);
    const barWidth = showBottom ? (completedAmount / targetAmount) * 100 : 100;
    const plans = get(subscriptions, ["subscription_plans"], []);
    const options = plans.map((plan, index) => {
      const currency = plan.price_currency;
      const currencySymbol = currency === "INR" ? "₹" : "$";
      const label =
        plan.title + " - " + currencySymbol + plan.price_cents / 100;
      return { value: `${JSON.stringify(plan)}`, label: label };
    });
    const colourStyles = {
      option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "#fff"
            : isFocused
            ? "#fff"
            : null,
          color: isDisabled
            ? null
            : isSelected
            ? "#c00"
            : isFocused
            ? "#000"
            : null
        };
      }
    };

    const { imageUrl, summary, storySlug } = subscriptions.description;

    const subscriptionGroupId = get(subscriptions, ["id"]);
    return (
      <div styleName="card-container">
        <div className="figure">
          {imageUrl ? (
            storySlug ? (
              <Link href={`/${storySlug}`}>
                <img src={`${imageUrl}`} alt={subscriptions.name} />
              </Link>
            ) : (
              <img src={`${imageUrl}`} alt={subscriptions.name} />
            )
          ) : null}
        </div>
        <div styleName="text-wrapper">
          {storySlug ? (
            <Link href={`/${storySlug}`}>
              <h2 styleName="title">{get(subscriptions, ["name"], "")}</h2>
            </Link>
          ) : (
            <h2 styleName="title">{get(subscriptions, ["name"], "")}</h2>
          )}

          <div
            dangerouslySetInnerHTML={{ __html: summary }}
            styleName="description show-scroll"
            style={{ marginBottom: !showBottom ? "50px" : "" }}
          ></div>
          <div styleName="progress-wrapper">
            <div
              styleName="progress-bar"
              style={{
                width: barWidth + "%",
                backgroundColor: !showBottom ? "#1ad193" : ""
              }}
            ></div>
          </div>
          {showBottom && (
            <>
              <div styleName="amount-container">
                <div styleName="amount-wrapper">
                  <div styleName="amount">
                    {currencySymbol + completedAmount}
                  </div>
                  <div styleName="goal">Raised so far</div>
                </div>
                <div styleName="amount-wrapper">
                  <div
                    styleName="amount select"
                    style={{ textAlign: "right", width: "100%" }}
                  >
                    {currencySymbol + targetAmount}
                  </div>
                  <div styleName="goal">Projected Spend</div>
                </div>
              </div>
              {options && options.length > 0 && (
                <div styleName="list-wrapper">
                  <Select
                    options={options}
                    onChange={e =>
                      this.handlePlanChange(e, subscriptionGroupId)
                    }
                    defaultValue={options[0]}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={colourStyles}
                    isSearchable={false}
                    inputProps={{"aria-label":"select the appropriate level"}}
                  />
                </div>
              )}
              <div styleName="btn-wrapper">
                <button
                  styleName="btn"
                  onClick={e => this.handleSubscriptionClick(e, subscriptions)}
                >
                  Support
                </button>
              </div>
            </>
          )}
          {!showBottom && (
            <div styleName="complete-wrapper">
              <div styleName="goal">100% Funded</div>
              <div styleName="amount">{currencySymbol + targetAmount}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
};

NLFundProjectCard.propTypes = {
  showBottom: PT.bool,
  subscriptions: PT.object,
  member: PT.object,
  manageLoginForm: PT.func,
  selectedPlan: PT.func,
  navigateTo: PT.func,
  showSingleCampaign: PT.bool
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function(payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url),
  selectedPlan: function(plan, redirectUrl) {
    dispatch({
      type: SELECTED_PLAN,
      plan: plan,
      planType: "campaign",
      redirectUrl: redirectUrl
    });
  },
  paymentCheckout: function(payload) {
    dispatch({
      type: PAYMENT_CHECKOUT_PAGE,
      payload: payload
    });
  }
});

const mapStateToProps = state => {
  return {
    config: get(state, ["qt", "config"], {}),
    member: get(state, ["member"], null),
    currentPath: get(state, ["qt", "currentPath"], ""),
    authHost: get(
      state,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    )
  };
};

NLFundProjectCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(NLFundProjectCard);

export { NLFundProjectCard };
