import React from "react";

import "./download-app-banner.m.css";

export const DownloadAppBanner = () => {
  return (
    <div styleName="main">
      <div styleName="main-grid">
        <div styleName="description-div">
          <h4 styleName="primary-heading">Download the Newslaundry App now!</h4>
          <p styleName="helper-text">
            Native podcast player, in-app subscriptions, gift subscription,
            ad-free, personalised notifications, bookmark, dark mode ... we got
            it all. What are you waiting for? Click on the links below to
            download.
          </p>
          <div styleName="download-cta-div">
            <a
              href="https://apps.apple.com/in/app/newslaundry/id1609227019?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://images.assettype.com/newslaundry/2022-05/13b3de80-f642-4a15-be70-874855d17ba4/512x512.png"
                alt="app store button"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.newslaundry.android.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://images.assettype.com/newslaundry/2022-05/c4d901fe-bf33-4d3a-b720-58c2182a8e9c/512x512.png"
                alt="google play store button"
              />
            </a>
          </div>
        </div>
        <div>
          <img
            src="https://images.assettype.com/newslaundry/2022-06/7b3254cc-62ff-4135-9ea6-5c43169e87b1/contact_us_page.png"
            alt="announcement img"
          />
        </div>
      </div>
    </div>
  );
};
