import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { AccessType } from "@quintype/components";
import IntegrationData from "../../../../../config/integrations.js";
import { NLLegalFundComponent } from "./nl-legal-fund-component";
import { campaignDescriptionParser } from "../../../utils";

let NLLegalFundPage = props => {
  const integrationsConfig = IntegrationData();
  const email = get(props.config, ["publisher", "publisher", "email"], "");
  const phone = get(props.config, ["publisher", "publisher", "phone"], "");
  const isStaging = get(
    props.config,
    ["publisher", "publisher", "accessTypeEnvironment"],
    true
  );
  const enableAccesstype = get(
    props.config,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;

  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  let campaignSubscriptions = get(props, ["campaignSubscriptions"], []);

  campaignSubscriptions = campaignSubscriptions
    .map(campaignDescriptionParser)
    .filter(camp => camp.description.type === "legal_fund");

  return (
    <AccessType
      enableAccesstype={enableAccesstype}
      isStaging={false}
      accessTypeKey={accessTypeKey}
      email={email}
      phone={phone}
      isAccessTypeCampaignEnabled={true}
      accessTypeBkIntegrationId={accessTypeBkIntegrationId}
    >
      {({
        initAccessType,
        initRazorPayPayment,
        initStripePayment,
        initPayPalPayment
      }) => {
        return (
          <NLLegalFundComponent
            initRazorPayPayment={initRazorPayPayment}
            initStripePayment={initStripePayment}
            initPayPalPayment={initPayPalPayment}
            initAccessType={initAccessType}
            {...props}
            campaignSubscriptions={campaignSubscriptions}
          />
        );
      }}
    </AccessType>
  );
};

const mapStateToProps = state => {
  return {
    config: get(state, ["qt", "config"], {}),
    subscriptions: get(state, ["subscriptions"], null),
    campaignSubscriptions: get(state, ["accessTypeCampaignSubscriptions"], []),
    member: get(state, ["member"], null)
  };
};

NLLegalFundPage.propTypes = {
  config: PropTypes.object
};

NLLegalFundPage = connect(mapStateToProps, {})(NLLegalFundPage);

export { NLLegalFundPage };
