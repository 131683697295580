/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Client from "shopify-buy";
import Products from "./Products";
import Cart from "./Cart";
import "./merch-page.m.css";
import { useUserSubscription } from "../../helper/hooks";
// import { podcastRssLink } from "../../helper/api";
import { FaShoppingCart } from "react-icons/fa";
import { SubscriberOnlyNudge } from "./subscriber-only-nudge";
import { useSelector } from "react-redux";

export function MerchPage() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [checkout, setCheckout] = useState({ lineItems: [] });
  const [products, setProducts] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [windowWidth, setWindowWidth] = useState(false);

  const { fetchSubscription, member } = useSelector(state => state);

  const userSubscription = useUserSubscription();

  const client = Client.buildClient({
    domain: "newslaundry-merchandise-store.myshopify.com",
    storefrontAccessToken: "c801e87cd24f4e034204c5849a9cb91b"
  });

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    client.checkout
      .create()
      .then(res => {
        setCheckout(res);
      })
      .catch(err => {
        console.log(err);
      });

    client.product
      .fetchAll()
      .then(res => {
        setProducts(res);
        // console.log("products:",res)
        // res.forEach(product => {
        //   const id = product.id;
        //   const title = product.title;
        //   const price = product.variants[0].price.amount;
        //   console.log(`Product ID: ${id}`);
        //   console.log(`Product Title: ${title}`);
        //   console.log(`Product Price: ${price}`);
        // });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const pushToDataLayer = (productName, productPrice) => {
    const obj = {
      event: "product_addToCart",
      product_name: productName,
      price: productPrice,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  const addVariantToCart = (variantId, quantity) => {
    let addedProduct = null;
    let addedVariant = null;

    products.forEach(product => {
      if (product.variants.find(variant => variant.id === variantId)) {
        addedProduct = product;

        addedVariant = product.variants.find(
          variant => variant.id === variantId
        );
      }
    });

    pushToDataLayer(addedProduct.title, addedVariant.price);

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }];
    const checkoutId = checkout.id;

    return client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then(res => {
        setCheckout(res);
        if (parseInt(windowWidth) > 768) {
          setIsCartOpen(true);
        }
      });
  };

  const updateQuantityInCart = (lineItemId, quantity) => {
    const checkoutId = checkout.id;
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) }
    ];

    return client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then(res => {
        setCheckout(res);
      });
  };

  const removeLineItemInCart = lineItemId => {
    const checkoutId = checkout.id;

    return client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then(res => {
        setCheckout(res);
      });
  };

  const handleCartClose = () => {
    setIsCartOpen(false);
  };

  const showDialogFunc = () => {
    handleCartClose();
    setShowDialog(true);
  };

  const calcItems = lineItems =>
    lineItems.map(i => i.quantity).reduce((a, b) => a + b);

  return (
    <div>
      <div styleName="header-grid">
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <div styleName="container">
            <h2 styleName="headline">Newslaundry Store</h2>
            <div styleName="border" />
          </div>
          <p styleName="description" style={{ marginBottom: "0.5rem" }}>
            It&apos;s time to tell the world you pay to keep news free. Flaunt
            your love for independent media and inspire others.
          </p>
          <p styleName="description">
            {/* 
            For subscribers only. If you&apos;re not one, subscribe here{" "}
            <a style={{ color: "#ec2227" }} href="/subscription">
              first.
            </a>{" "}
            */}
            Deliveries in India only, and take 2-3 weeks. Write to{" "}
            <a href="mailto:store@newslaundry.com">store@newslaundry.com </a>
            for questions, feedback and delivery status.
          </p>
        </div>
        <img
          styleName=""
          alt="artwork"
          src="https://images.assettype.com/newslaundry/2024-01/7ddbf3c9-dd6a-4c15-b436-4503090b104d/nl_store_main.png"
          // src="https://images.assettype.com/newslaundry/2022-04/42b1186e-52d1-4b39-aede-2c368116c175/nl_store_main.png"
          // src="https://images.assettype.com/newslaundry/2020-11/cc00f47a-aee6-4c6d-b702-07215995a988/merch.jpg"
        />
      </div>
      <header>
        {!isCartOpen && (
          <div styleName="app__view-cart-wrapper">
            <div styleName="app__view-cart" onClick={() => setIsCartOpen(true)}>
              {checkout.lineItems.length ? calcItems(checkout.lineItems) : 0}
              <FaShoppingCart color="white" />
            </div>
          </div>
        )}
      </header>
      <Products
        products={products}
        client={client}
        addVariantToCart={addVariantToCart}
      />
      <Cart
        checkout={checkout}
        isCartOpen={isCartOpen}
        handleCartClose={handleCartClose}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
        subscription={userSubscription}
        dialog={showDialogFunc}
      />
      {showDialog ? (
        <SubscriberOnlyNudge handleClose={() => setShowDialog(false)} />
      ) : null}
    </div>
  );
}
