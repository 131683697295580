/* eslint-disable max-len */
import React from "react";
import "./christmas-offer.m.css";

const ChristmasOfferPage = () => {
  const handleBuyNow = planId => {
    window.open(`https://razorpay.com/payment-button/${planId}/view`, "_blank");
  };

  return (
    <div styleName="wrapper">
      <h1 styleName="title">Christmas with Newslaundry</h1>
      <br />
      <div styleName="top-wrapper">
        <div styleName="img-wrap">
          <img
            alt="banner"
            src="https://images.assettype.com/newslaundry/2022-01/aaa72797-14d9-4b87-8186-1edf269915af/christmas_campaign_no_date.jpg"
          />
        </div>
        <div styleName="text-wrap">
          <p styleName="description">
            This festive season, be the Santa for free press. With our Christmas
            offer, get your favourite NL stickers, Karare Chane mugs, comics and
            a lot more on discount. And besides each customised NL gift hamper,
            there is a free giftable subscription too, for your friends and
            family.
            <br />
            <br />
            Get our merchandise for yourself and say you’re a proud member of a
            community that keeps independent media alive. Or give these to
            friends and family, and spread the word about free, accurate and
            independent news.
            <br />
          </p>
        </div>
      </div>
      <br />
      <div styleName="hamper__container">
        <div styleName="hamper__card">
          <h2 styleName="hamper__title">Hamper One</h2>
          <img
            alt="christmas hamper"
            src="https://images.assettype.com/newslaundry/2023-12/aabef6f7-3bb2-4443-82c2-c75701ed3875/HAMPER_1__POST_.jpg"
          />
          <div styleName="hamper__hr"></div>
          <ul>
            <li>
              <span>❄️</span>
              <span>Laptop Stickers <b>worth ₹ 249</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Car Stickers <b>worth ₹ 299</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Karare Chane Mug <b>worth ₹ 349</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Newslaundry Backpack <b>worth ₹ 549</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Karare Chane T-Shirt <b>worth ₹ 699</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Gift 2x One Month Disruptor Subscriptions <b>worth ₹600</b></span>
            </li>
          </ul>
          <h2 styleName="hamper__price">
            <span styleName="rupee_sign">₹</span>2,100
          </h2>
          <button onClick={() => handleBuyNow("pl_NCEPMP4pgcMbui")}>
            Buy Now
          </button>
        </div>
        <div styleName="hamper__card">
          <h2 styleName="hamper__title">Hamper Two</h2>
          <img
            alt="christmas hamper"
            src="https://images.assettype.com/newslaundry/2023-12/eea78a0e-0597-4b70-b070-b0bcc97c79f2/HAMPER_2__POST_.jpg"
          />
          <div styleName="hamper__hr"></div>
          <ul>
            <li>
              <span>❄️</span>
              <span>Laptop Stickers <b>worth ₹249</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Car Stickers <b>worth ₹299</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Fridge Magnets <b>worth ₹399</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Karare Chane Mug <b>worth ₹349</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Mug: Which Naxal are you? <b>worth ₹249</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Amar Bari Tomar Bari Naxalbari Comic Reprint <b>worth ₹799</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Kashmir Ki Kahani Collector's Edition <b>worth ₹1499</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Newslaundry Backpack <b>worth ₹549</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Karare Chane T-Shirt <b>worth ₹699</b></span>
            </li>
            <li>
              <span>❄️</span>
              <span>Gift 5x One Month Disruptor Subscriptions <b>worth ₹1500</b></span>
            </li>
          </ul>
          <h2 styleName="hamper__price">
            <span styleName="rupee_sign">₹</span>5,100
          </h2>
          <button onClick={() => handleBuyNow("pl_NCET8mpU4X4AQk")}>
            Buy Now
          </button>
        </div>
      </div>
      <div>
        <strong>Terms &amp; Conditions</strong>
      </div>

      <ul styleName="notes_wrapper">
        <li>
          This is a limited period offer from December 15th, 2023 to January
          1st, 2024.
        </li>
        <li>Delivery in India only.</li>
        <li>
          If you are based overseas, please share an Indian delivery address (of
          friends/family).
        </li>
        <li>
          Please note, delivery of packages will take at least two to three
          weeks. Thank you for your patience.
        </li>
        <li>
          We will manually map the gift subscriptions. The subscription will be
          mapped within 48 hours.
        </li>
        <li>
          If you have any queries, please drop an email on{" "}
          <a style={{ color: "#ec2227" }} href="mailto:store@newslaundry.com">
            store@newslaundry.com
          </a>
        </li>
      </ul>
      <br />
      {/* {showDialog ? (
        <SubscriberOnlyNudge handleClose={() => setShowDialog(false)} />
      ) : null} */}
    </div>
  );
};

export { ChristmasOfferPage };
