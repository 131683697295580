import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useSelector } from "react-redux";

import "./load-more-button.m.css";
import { Button } from "../../../ui/components/button/Button";

export const LoadMoreButton = ({ onClick }) => {
  const domainSlug = useSelector(state =>
    get(state, ["qt", "domainSlug"], null)
  );
  return (
    <div styleName="load-more-div">
      <Button onClick={onClick}>
        {domainSlug ? "और लोड करें" : "Load More"}
      </Button>
    </div>
    // <button onClick={onClick} styleName="load-more-button">
    //   {domainSlug ? "और लोड करें" : "Load More"}
    // </button>
  );
};

LoadMoreButton.propTypes = {
  onClick: PropTypes.func
};
