import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { Headline } from "../../atoms/headline";
import AuthorWithTime from "../author-with-time";
import { ResponsiveImage } from "@quintype/components";
import { getHeroImage, getStoryMetadata } from "../../../utils";
import "./one-col-four-stories.m.css";

const OnecolFourStories = ({ stories = [], headingColor = "#000", authorColor = "#000" , config = {}}) => {
  const story = stories[0] || {};
  const heroS3Key = getHeroImage(story, config);

  return (
    <div styleName="onecol-four-stories-container">
      <figure className="figure"  aria-hidden="true" >
        <ResponsiveImage
          slug={heroS3Key}
          metadata={getStoryMetadata(story)}
          aspectRatio={[265, 354]}
          defaultWidth={173}
          widths={[173, 265]}
          sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
          imgParams={{ auto: ["format", "compress"] }}
          className="responsive-img"
        />
      </figure>
      {stories.splice(0, 4).map((story, index) => (
        <article styleName={`wrapper ${index === 0 ? "first-wrapper" : ""}`} key={story.id}>
          <Headline story={story} externalLink={true} headlineColor={headingColor} />
          <div aria-hidden="true" >
          {index < 1 && <AuthorWithTime story={story} color={authorColor} />}
          </div>
        </article>
      ))}
    </div>
  );
};

OnecolFourStories.propTypes = {
  stories: PropTypes.array,
  headingColor: PropTypes.string,
  authorColor: PropTypes.string,
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {})
});

export default connect(mapStateToProps)(OnecolFourStories);
