import React from "react";
import PropTypes from "prop-types";

import { FaCcMastercard, FaCcVisa, FaPaypal, FaWallet } from "react-icons/fa";
import { SiGooglepay } from "react-icons/si";
import { RiBankFill } from "react-icons/ri";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";
import RupaySVG from "../../../../../assets/images/nl-images/rupay.svg";
import { useSelector } from "react-redux";
import get from "lodash/get";

import "./payment-methods.m.css";

const PaymentMethods = ({ paymentOption, setPaymentOption }) => {
  const userCountry = useSelector(state =>
    get(state, ["fetchUserCountry"], {
      country_code: "",
      country_code_alias: "",
      country_name: "",
      user_timezone: ""
    })
  );
  const toShowPaymentOptions =
    userCountry.country_code === "IN" ? ["stripe", "upi"] : ["upi", "stripe"];

  // Legend: upi = rzpay, stripe = stripe

  return (
    <div styleName="section-wrapper">
      <h3 styleName="title">Choose your payment method:</h3>
      <div styleName="payment-methods-wrapper">
        {toShowPaymentOptions.includes("stripe") && (
          <div
            styleName={`payment-method ${
              paymentOption === "card" ? "selected" : ""
            }`}
            onClick={() => setPaymentOption("card")}
            aria-label="Click to pay through Credit or Debit Card"
            tabIndex="0"
          >
            <div styleName="checkbox-svg" aria-hidden="true">
              {paymentOption === "card" ? (
                <BiRadioCircleMarked styleName="checkbox-svg" />
              ) : (
                <BiRadioCircle styleName="checkbox-svg" />
              )}
            </div>
            <div styleName="payment-div-block" aria-hidden="true">
              <span styleName="payment-title">Credit or Debit Card</span>
              {/* </div>
          <div styleName="payment-div-block"> */}
              <FaCcVisa />
              <FaCcMastercard />
            </div>
          </div>
        )}
        {toShowPaymentOptions.includes("upi") && (
          <div
            styleName={`payment-method ${
              paymentOption === "upi" ? "selected" : ""
            }`}
            tabIndex="0"
            onClick={() => setPaymentOption("upi")}
            aria-label="Click to pay through UPI, Netbanking, Rupay, Wallets or Paypal"
          >
            <div styleName="checkbox-svg" aria-hidden="true">
              {paymentOption === "card" ? (
                <BiRadioCircle />
              ) : (
                <BiRadioCircleMarked />
              )}
            </div>
            <div styleName="payment-div-block" aria-hidden="true">
              <span styleName="payment-title">
                UPI, Netbanking, Rupay, Wallets or Paypal
              </span>
              {/* </div>
          <div styleName="payment-div-block"> */}
              <SiGooglepay />
              <RiBankFill />
              <img
                src={RupaySVG}
                alt="rupay svg"
                style={{
                  height: "1.5rem",
                  width: "1.5rem",
                  color: "#333"
                }}
              />
              <FaWallet />
              <FaPaypal />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PaymentMethods.propTypes = {
  paymentOption: PropTypes.string,
  setPaymentOption: PropTypes.func
};

export { PaymentMethods };
