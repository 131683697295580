import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
// import { Button } from "../../atoms/button";
import nlSenaLogo from "../../../../assets/images/nl-images/NLSenaPage-image-on-topleft.png";
import { ProjectCard } from "../../molecules/project-card";
import CommonStoryCard from "../../molecules/common-story-card";
import getStoriesByCollection from "../../../utils";
import GiftCard from "../../molecules/gift-card";
import Loader from "../../atoms/loader";
import { Button } from "../../../ui/components/button";

import "./nl-sena.m.css";

const RowWithHeadline = ({ title, showBottom, campSubscriptions }) => {
  return (
    <React.Fragment>
      <div styleName="heading">
        <div>{title}</div>
        <div styleName="line" />
      </div>
      <div styleName="row">
        {campSubscriptions &&
          campSubscriptions.map((subscriptions, index) => {
            if (subscriptions.name === "Test Sena Campaign") {
              return;
            }

            return (
              <ProjectCard
                showBottom={showBottom}
                subscriptions={subscriptions}
                key={index}
                redirectUrl="/sena"
              />
            );
          })}
      </div>
    </React.Fragment>
  );
};

RowWithHeadline.propTypes = {
  title: PT.string,
  showBottom: PT.bool,
  campSubscriptions: PT.array
};

class NLSena extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: {}
    };
  }

  componentDidMount() {
    this.props.initAccessType();
  }

  render() {
    const title = get(this.props, ["data", "collection", "name"], "");
    const summary = get(this.props, ["data", "collection", "summary"], "");
    const img = get(this.props, [
      "data",
      "collection",
      "metadata",
      "cover-image",
      "cover-image-url"
    ]);
    const campSubscriptions = get(this.props, ["campaignSubscriptions"], []);
    const currentProjects = campSubscriptions
      .filter(
        subscriptions =>
          subscriptions.campaign_active === true &&
          subscriptions.collected_amount < subscriptions.target_amount / 100 &&
          subscriptions.name !== "Test Sena Campaign"
      )
      .sort(function (y, x) {
        return x.id - y.id;
      });
    const completedProjects = campSubscriptions
      .filter(
        subscriptions =>
          subscriptions.campaign_active === false ||
          subscriptions.collected_amount >= subscriptions.target_amount / 100
      )
      .sort(function (y, x) {
        return x.id - y.id;
      });
    const gifts = get(this.props, ["config", "giftsBenefits", "gifts"], []);
    // Changed gifts config value
    const collection = get(this.props, ["data", "collection"], {});
    const collections =
      (collection.items &&
        collection.items.filter(
          collection => collection.type === "collection"
        )) ||
      [];
    const stories = getStoriesByCollection(collections[0]) || [];
    const getFirstStory =
      (collection.items &&
        collection.items.filter(collection => collection.type === "story")) ||
      [];
    return (
      <>
        <div styleName="container">
          <img
            src={img || assetify(nlSenaLogo)}
            alt="nlsena-logo"
            styleName="img-wrapper"
          />
          <div styleName="text-wrapper">
            <h1 styleName="title">{title}</h1>
            <div styleName="summary">
              The peaceful army that keeps news unafraid and independent.
            </div>
            <div styleName="description">{summary}</div>
            {getFirstStory.length > 0 && (
              <div styleName="btn-wrapper">
                <Button
                  variant="accent"
                  href={getFirstStory[0].story.url}
                  className="nl-btn-large"
                >
                  Know More
                </Button>
                {/* <Button
                  href={getFirstStory[0].story.url}
                  className="nl-btn-large"
                >
                  Know More
                </Button> */}
              </div>
            )}
          </div>
        </div>
        {campSubscriptions.length > 0 ? (
          <div styleName="proj-container" className="full-width">
            <div className="container" styleName="proj-wrapper">
              {currentProjects.length > 0 && (
                <RowWithHeadline
                  title="Current Projects"
                  campSubscriptions={currentProjects}
                />
              )}
              {completedProjects.length > 0 && (
                <RowWithHeadline
                  title="Completed Projects"
                  showBottom={false}
                  campSubscriptions={completedProjects}
                />
              )}
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "60px 0" }}>
            <Loader color="#ec2227" />
          </div>
        )}
        <div styleName="gift-conatiner">
          <GiftCard firstCard={true} />
          {gifts.length > 0 &&
            gifts.map((gift, index) => {
              return <GiftCard key={index} gift={gift} />;
            })}
        </div>
        {stories.length > 0 && (
          <div styleName="proj-container" className="full-width">
            <div className="container" styleName="proj-wrapper">
              {
                <h2 styleName="summary-text">{`${
                  collections[0].summary
                    ? collections[0].summary
                    : collections[0].name
                }`}</h2>
              }
              <div styleName="subscription-collection">
                {stories.slice(0, 6).map((story, index) => {
                  return (
                    <CommonStoryCard
                      story={story}
                      key={index}
                      showSection={true}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

NLSena.propTypes = {
  initAccessType: PT.func,
  config: PT.object
};

export default NLSena;
