/* eslint-disable react/prop-types */
import React from "react";
import { CollectionName } from "../../atoms/collection-name";
import { FaRegPlayCircle } from "react-icons/fa";

import "./tippani-component.m.css";

export function TippaniComponent({ collection }) {
  const [playlistData, setPlaylistData] = React.useState(null);
  const [nowPlayingUrl, setNowPlayingUrl] = React.useState(null);

  React.useEffect(() => {
    fetch(
      `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=5&playlistId=PLpHbno9djTOSYnVTYUlN3IkSQ1rCfZsI5&key=AIzaSyDhn4laWphWRpS9mIEDmX2BnCzZP8SNdyk`
    )
      .then(response => response.json())
      .then(data => {
        setPlaylistData(data);
        setNowPlayingUrl(data.items[0].snippet.resourceId.videoId);
      });
  }, []);

  return (
    <div className="full-width-with-padding" styleName="inner-wrapper">
      <div className="container">
        <CollectionName collection={collection} color="#fff" />
        <div styleName="flex-grid">
          <div styleName="now-playing">
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${nowPlayingUrl}?rel=0&amp;`}
              styleName="iframeYT"
              allow="autoplay"
              allowFullScreen
            />
          </div>
          <div styleName="video-list">
            {playlistData &&
              playlistData.items.slice(1, 5).map(({ id, snippet = {} }) => {
                const { title, thumbnails = {}, resourceId = {} } = snippet;
                const { medium } = thumbnails;
                return (
                  <div
                    styleName="video-item"
                    onClick={() => setNowPlayingUrl(resourceId.videoId)}
                    key={resourceId.videoId}
                  >
                    <img
                      width="44%"
                      height="10%"
                      src={medium.url}
                      stylename="thumb"
                      alt=""
                    />
                    <FaRegPlayCircle />
                    {/* <a /> */}
                    <span styleName="video-title">{title}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
