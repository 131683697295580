import React from "react";
import PropTypes from "prop-types";
import HorizontalStoryCard from "../horizontal-story-card";

const HorizontalStoryCardList = ({ stories = [], hideAuthor }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "var(--space-base)"
      }}
    >
      {stories.map(story => (
        <HorizontalStoryCard
          story={story}
          key={story.id}
          boxPadding={false}
          increaseFontSize
          hideAuthor={hideAuthor}
        />
      ))}
    </div>
  );
};

HorizontalStoryCardList.propTypes = {
  stories: PropTypes.array,
  hideAuthor: PropTypes.bool
};
export default HorizontalStoryCardList;
