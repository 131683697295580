/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import { Link } from "@quintype/components";
import { TextField } from "../../atoms/textfield";
import { isValidEmail } from "../../../utils";
import { FaWhatsapp, FaRegEnvelope } from "react-icons/fa";
import Axios from "axios";

import "./friends-of-nl.m.css";

const FriendsOfNLPage = ({ data: { collection, podcastCollection } }) => {
  const state = useSelector(state => state);

  const items = collection.items[0].items;
  const podcastItems = podcastCollection.items[0].items;

  const collections = items.filter(item => item.type === "collection");
  const podcastCollections = podcastItems.filter(
    item => item.type === "collection"
  );

  const filteredPodcastCollections = podcastCollections.filter(item =>
    [
      "nl-hafta-podcast",
    //   "the-awful-and-awesome-podcast",
      "nl-charcha-podcast-newslaundry-hindi",
      "lets-talk-about"
    ].includes(item.slug)
  );
  const filteredVideoCollections = collections.filter(item =>
    [
      "tv-newsance-tv",
      "nl-tippani",
      "nl-charcha-podcast-newslaundry-hindi",
      "lets-talk-about"
    ].includes(item.slug)
  );

  const showsData = [];
  filteredPodcastCollections.forEach(item => {
    showsData.push({
      label: item.name,
      value: {
        slug: item.slug,
        type: item.type
      }
    });
  });
  filteredVideoCollections.forEach(item => {
    showsData.push({
      label: item.name,
      value: {
        slug: item.slug,
        type: item.type
      }
    });
  });

  const [isVideoSelectDisabled, setIsVideoSelectDisabled] = useState(true);

  const [playlistOptions, setPlaylistOptions] = useState([]);
  const [playlistValue, setPlaylistValue] = useState({});

  const [videoOptions, setVideoOptions] = useState([]);
  const [videoValue, setVideoValue] = useState({});
  const [uName, setUName] = useState("");
  const [rName, setRName] = useState("");
  const [rEmail, setREmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const [templateOptions] = useState([
    {
      label: "Hey! Watch this video:",
      value: "Hey! Watch this video:"
    },
    {
      label: "Check out this video from Newslaundry:",
      value: "Check out this video from Newslaundry:"
    }
  ]);
  const [templateValue, setTemplatevalue] = useState({
    label: "Click here to watch:",
    value: "Click here to watch:"
  });

  const [error, setError] = useState(null);

  // Set Playlist/Shows on page load
  useEffect(() => {
    setPlaylistOptions(showsData);
  }, []);

  useEffect(() => {
    if (videoOptions.length) {
      setIsVideoSelectDisabled(false);
    } else {
      setIsVideoSelectDisabled(true);
    }
  }, [videoOptions]);

  // Set Videos on Playlist select
  const handlePlaylistChange = e => {
    if (e.value.slug) {
      setPlaylistValue(e);
      setVideoValue({});

      const videoShow = filteredVideoCollections.find(
        item => item.slug === e.value.slug
      );
      const podcastShow = filteredPodcastCollections.find(
        item => item.slug === e.value.slug
      );

      let items;

      if (videoShow) {
        items = videoShow.items.filter(item => item.type === "story");
      } else {
        items = podcastShow.items.filter(item => item.type === "story");
      }

      setVideoOptions(
        items.map(item => ({
          label: item.story.headline,
          value: {
            url: item.story.url,
            slug: item.story.slug,
            image: item.story["hero-image-s3-key"]
          }
        }))
      );
      setVideoValue({});
      setTemplatevalue({
        label: "Click here to watch:",
        value: "Click here to watch:"
      });
    }
  };

  // Check if logged in and redirect users if they aren't
  const checkIfLoggedIn = () => {
    if (!state.member) {
      const callbackUrl = global.location.origin;
      const redirectUrl = global.location.href;
      const authHost = state.qt.config.publisher.publisher.auth_host || null;

      window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
      return false;
    }

    return true;
  };

  const handleWhatsappShareClick = () => {
    const res = checkIfLoggedIn();

    if (res) {
      if (!playlistValue.value) {
        setError("Please select a playlist");
        return;
      }

      if (!videoValue.value) {
        setError("Please select a video");
        return;
      }

      const ref = state.member.email;
      const text = `${templateValue.value} ${
        videoValue.value.url
      }?ref=${ref}_${Date.now()}`;
      window.open(`https://api.whatsapp.com/send?text=${text}`, "_blank");
    }
  };

  const handleMailShareClick = async () => {
    // const res = checkIfLoggedIn();

    // if (res) {
    //   if (!playlistValue.value) {
    //     setError("Please select a playlist");
    //     return;
    //   }

    //   if (!videoValue.value) {
    //     setError("Please select a video");
    //     return;
    //   }

    //   const ref = state.member.email;
    //   const subject = `Watch: ${videoValue.label}`;
    //   const text = `${templateValue.value} ${
    //     videoValue.value.url
    //   }?ref=${ref}_${Date.now()}`;
    //   window.open(`mailto:?subject=${subject}&body=${text}`, "_blank");
    // }
    if(isValidEmail(rEmail)){
        if(!uName){
            setError("Please enter a name");  
            return;
        }
        if(!rName){
            setError("Please enter a recipient's name");  
            return;
        }
        if (!playlistValue.value) {
            setError("Please select a show to share.");
            return;
        }
        let showName="hafta";
        if(playlistValue.value.slug=="lets-talk-about") showName = "lta";
        else if(playlistValue.value.slug=="nl-charcha-podcast-newslaundry-hindi") showName = "charcha";
        else if(playlistValue.value.slug=="tv-newsance-tv") showName = "newsance";
        else if(playlistValue.value.slug=="nl-tippani") showName = "tippani";

        console.log(uName, rName, rEmail, playlistValue.value.slug);
        console.log(uName, rName, rEmail, showName);
        
        try{
             await Axios.post("https://server.newslaundry.com/recommendAShow", {senderName:uName, receiverEmail:rEmail, receiverName:rName, show:showName});
             setError("");
             setSuccess(true);
        }
        catch(err){
            setError("Server error, please try again after sometime.")
        }
        
        
    }
    else{
        setError("Please enter a valid email");
        return;
    }

  };

  return (
    <section styleName="section">
      {/* <main styleName="hero-section">
        <div>
          <div styleName="hero-section-content">
            <h1 styleName="title">Friends of Newslaundry</h1>
            <p styleName="border-bottom"></p>
            <p>
              Be a friend to Newslaundry and support our independent journalism by volunteering, sharing our content, or by gifting Newslaundry subscription to your loved ones.
            </p>
          </div>
          <div styleName="hero-section-img">
            <img
              src="https://fea.assettype.com/newslaundry/assets/podcast-collection-new-fe6ea830bcac40805d7e.png"
              alt="Friends Hero Image"
            />
          </div>
        </div>
      </main> */}
      <main styleName="hero-section">
        <div styleName="hero-section-div">
          <div styleName="hero-section-img">
            <img
              src="https://fea.assettype.com/newslaundry/assets/podcast-collection-new-fe6ea830bcac40805d7e.png"
              alt="Friends Hero Image"
            />
          </div>
         
          <div styleName="hero-section-content">
            <h1 styleName="title">Friends of Newslaundry</h1>
            <p styleName="border-bottom"></p>
            <p styleName="description">
            Be a friend to Newslaundry and support our journalism by volunteering, sharing our content, or by gifting Newslaundry subscription to your loved ones.
            </p>
          </div>
        </div>
      </main>
      <section styleName="sub-section">
        <div styleName="two-col-grid">
          <div styleName="item-col">
            <div styleName="demo-artwork">
              <img
                src="https://images.assettype.com/newslaundry/2022-06/ad6a561d-1c0c-46a0-8397-0d29f861afda/show.png"
                alt="Recommend a Show"
              />
            </div>
            <div styleName="title-wrapper">
              <h2 styleName="sub-heading">Recommend a Show</h2>
              <div styleName="border-bottom"></div>
            </div>
            <div styleName="share-section-content">
            <p>
              {/* Share our videos to receive credits. You can later redeem those
              credits in our store. */}
              <p>Choose a video from a playlist and hit share.</p>
            </p>
            </div>
            {/* <button
              styleName="cta-btn"
              onClick={() => setSelectedOption("showRecommend")}
            >
              Choose
            </button> */}
          </div>
          <div styleName="item-col">
          <div styleName="share-section">
            {/* <div styleName="share-section-content">
              <p>
                Share our videos to receive credits. You can later redeem those
                credits in our store.
              </p>
              <p>Choose a video from a playlist and hit share.</p>
            </div> */}
            <div styleName="share-section-form">
              <h3>Share videos</h3>
              <div>
                <div styleName="form-group">
                  <label htmlFor="playlist" styleName="form-labels"><span>Choose a Video/Podcast Show:</span></label>
                  <Select
                    defaultValue={{ value: "", label: "Choose a show." }}
                    name="playlist"
                    className="basic-single"
                    classNamePrefix="select"
                    options={playlistOptions}
                    onChange={handlePlaylistChange}
                  />
                </div>
                {/* <div styleName="form-group">
                  <label htmlFor="video">Choose a Video/Podcast Episode:</label>
                  <Select
                    defaultValue={{ value: "", label: "Choose a video" }}
                    name="video"
                    isDisabled={isVideoSelectDisabled}
                    isSearchable={true}
                    options={videoOptions}
                    onChange={e => setVideoValue(e)}
                  />
                </div> */}
                {/* <div styleName="form-group">
                  <label htmlFor="template">
                    Choose a template: <span>(Optional)</span>
                  </label>
                  <Select
                    defaultValue={{ value: "", label: "Choose a template" }}
                    name="template"
                    isDisabled={isVideoSelectDisabled}
                    options={templateOptions}
                    onChange={e => setTemplatevalue(e)}
                  />
                </div>
                {playlistValue.value && videoValue.value && (
                  <div styleName="form-group">
                    <label>Preview:</label>
                    <img
                      src={`https://gumlet.assettype.com/${videoValue.value.image}`}
                      alt={videoValue.value.slug}
                    />
                    <p styleName="preview-text">
                      {templateValue.value} {videoValue.value.url}
                    </p>
                  </div>
                )} */}
                <label htmlFor="first_name" styleName="form-labels">
          <span>Your name</span>
          <TextField
            type="text"
            value={uName}
            placeholder="Your name"
            id="first_name"
            name="first_name"
            onChange={(e)=>{
               setUName(e.target.value);
            }}
          />
        </label>
        <label htmlFor="last_name" styleName="form-labels">
          <span>Recipient's name</span>
          <TextField
            type="text"
            value={rName}
            placeholder="Recipient's name"
            id="recipient_name"
            name="recipient_name"
            onChange={(e)=>{
                setRName(e.target.value);
            }}
          />
        </label>
        <label htmlFor="email" styleName="form-labels">
          <span>Recipient's email address</span>
          <TextField
            type="email"
            value={rEmail}
            placeholder="Recipient's email address"
            id="recipient_email"
            name="recipient_email"
            onChange={(e)=>{
                setREmail(e.target.value);
            }}
          />
        </label>
                {error && <p styleName="error">{error}</p>}
                <div styleName="form-group">
                  <label>Share via:</label>
                  {/* <button
                    onClick={handleWhatsappShareClick}
                    styleName="whatsapp-share-btn"
                  >
                    <FaWhatsapp />
                    <span>Whatsapp</span>
                  </button> */}
                  <button
                    onClick={handleMailShareClick}
                    styleName="email-share-btn"
                  >
                    <FaRegEnvelope />
                    <span>E-Mail</span>
                  </button>
                  {success && <p style={{color:"green"}}><b>Email successfully sent!</b></p>}
                </div>
              </div>
            </div>
          </div>
         </div>
        </div>
      </section>
      {/* {selectedOption === "showRecommend" && (
        <div styleName={`sub-section`}>
          <div styleName="share-section">
            <div styleName="share-section-content">
              <p>
                Share our videos to receive credits. You can later redeem those
                credits in our store.
              </p>
              <p>Choose a video from a playlist and hit share.</p>
            </div>
            <div styleName="share-section-form">
              <h3>Share videos</h3>
              <div>
                <div styleName="form-group">
                  <label htmlFor="playlist">Choose a Video/Podcast Show:</label>
                  <Select
                    defaultValue={{ value: "", label: "Choose a show." }}
                    name="playlist"
                    options={playlistOptions}
                    onChange={handlePlaylistChange}
                  />
                </div>
                <div styleName="form-group">
                  <label htmlFor="video">Choose a Video/Podcast Episode:</label>
                  <Select
                    defaultValue={{ value: "", label: "Choose a video" }}
                    name="video"
                    isDisabled={isVideoSelectDisabled}
                    isSearchable={true}
                    options={videoOptions}
                    onChange={e => setVideoValue(e)}
                  />
                </div>
                <div styleName="form-group">
                  <label htmlFor="template">
                    Choose a template: <span>(Optional)</span>
                  </label>
                  <Select
                    defaultValue={{ value: "", label: "Choose a template" }}
                    name="template"
                    isDisabled={isVideoSelectDisabled}
                    options={templateOptions}
                    onChange={e => setTemplatevalue(e)}
                  />
                </div>
                {playlistValue.value && videoValue.value && (
                  <div styleName="form-group">
                    <label>Preview:</label>
                    <img
                      src={`https://gumlet.assettype.com/${videoValue.value.image}`}
                      alt={videoValue.value.slug}
                    />
                    <p styleName="preview-text">
                      {templateValue.value} {videoValue.value.url}
                    </p>
                  </div>
                )}
                {error && <p styleName="error">{error}</p>}
                <div styleName="form-group">
                  <label>Share via:</label>
                  <button
                    onClick={handleWhatsappShareClick}
                    styleName="whatsapp-share-btn"
                  >
                    <FaWhatsapp />
                    <span>Whatsapp</span>
                  </button>
                  <button
                    onClick={handleMailShareClick}
                    styleName="email-share-btn"
                  >
                    <FaRegEnvelope />
                    <span>E-Mail</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedOption === "giftSubscription" && (
        <div styleName="sub-section">
          <div styleName="title-wrapper">
            <h2 styleName="sub-heading">Gift a Subscription</h2>
            <div styleName="border-bottom"></div>
          </div>
          <div styleName="mt-4">
            <p>
              <a styleName="inline-link" href="/subscription">
                Gift
              </a>{" "}
              your loved ones a subscription starting at just &#x20B9;300 per
              month.
            </p>
          </div>
        </div>
      )}
      {selectedOption === "volunteer" && (
        <div styleName="sub-section">
          <div styleName="title-wrapper">
            <h2 styleName="sub-heading">Volunteer</h2>
            <div styleName="border-bottom"></div>
          </div>
          <div styleName="mt-4">
            <p>
              Wish to volunteer? Fill{" "}
              <a styleName="inline-link" href="#">
                this
              </a>{" "}
              Google form and we'll get back to you.
            </p>
          </div>
        </div>
      )} */}
      <div styleName="two-col-grid">
      <div styleName="item-col">
            <div styleName="demo-artwork">
              <img
                src="https://images.assettype.com/newslaundry/2022-06/9093727f-cefa-481c-b678-7142a8557925/giftsub.png"
                alt="Gift subscription image"
              />
            </div>
            <div styleName="title-wrapper">
              <h2 styleName="sub-heading">Gift a subscription</h2>
              <div styleName="border-bottom"></div>
            </div>
            <p>
              Gift your loved ones a subscription starting at just &#x20B9;300
              per month.
            </p>
            <Link
              styleName="cta-btn"
              href="/gift"
            >
              Gift
            </Link>
          </div>
          <div styleName="item-col">
            <div styleName="demo-artwork">
              <img
                src="https://images.assettype.com/newslaundry/2022-06/9a345b28-8fbe-45f0-a5d0-c36e8a806d72/volunteer.png"
                alt="volunteer"
              />
            </div>
            <div styleName="title-wrapper">
              <h2 styleName="sub-heading">Volunteer</h2>
              <div styleName="border-bottom"></div>
            </div>
            <p>
              Wish to volunteer? Fill this Google form and we'll get back to
              you.
            </p>
            <Link
              styleName="cta-btn"
              href="https://docs.google.com/forms/d/1qKxl4j95iFvAcu22i7u4lvHKRPmTWw93GNnEgmOPR6Q"
            >
              Fill the form
            </Link>
          </div>
          </div>
    </section>
  );
};

export { FriendsOfNLPage };

FriendsOfNLPage.propTypes = {
  data: PropTypes.object,
  collection: PropTypes.object
};
