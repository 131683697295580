/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { FaAward, FaXTwitter, FaLink } from "react-icons/fa6";

export function AboutUsPage() {
  return (
    <div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n.feature-image{\nborder-radius:50%;\n}\n.award-item p{\ntext-align:left !important;\n}\n",
        }}
      />
      <div className="full-width-with-padding">
        <p style={{ textAlign: "center" }}>
          <img
            src="https://thumbor-stg.assettype.com/newslaundry/2020-08/40e6b9de-9e4b-4c9e-b88d-05e50de54fc2/About_Us_Header_Illustration.jpg"
            style={{ maxWidth: "700px", width: "100%", margin: "0 auto" }}
          />
        </p>
      </div>
      <div className="about-us-wrapper">
        <h1 className="static-page-title" style={{ textAlign: "center" }}>
          About us
        </h1>
        <div className="about-intro">
          <p className="paragraph">
            Newslaundry is a reader-supported, independent news media company.
            In an industry driven by corporate and government interests, we
            strongly believe in the need for an independent news model, and a
            free and accountable press.
          </p>
          <p className="paragraph">
            Through media critique, reportage, podcasts, documentaries, comics
            and animation, our stories bring you the latest in current affairs
            in innovative and engaging formats.
          </p>
        </div>
        <br />
        <h2 className="static-page-title" style={{ textAlign: "center" }}>
          Our Core Values
        </h2>
        <div className="features-wrapper">
          <div className="feature-item">
            <p>
              <img
                className="feature-image"
                src="https://i.ibb.co/SJzwy2b/no-ads.png"
                alt="ad free"
              />
            </p>
            <p className="feature-headline">Ad-free</p>
            <p className="feature-text">
              Stories that are produced to bring value to you, not clicks for ad
              revenue. Our journalism is not dictated and influenced by
              advertisers, whether corporate or government. At Newslaundry, you
              get to bypass the digital clutter and go straight to the story.
            </p>
          </div>
          <div className="feature-item">
            <p>
              <img
                src="https://thumbor-stg.assettype.com/newslaundry/2020-09/0fbd4f6d-151c-42c7-afef-2117be3c9d1b/focus_on_media.jpg"
                className="feature-image"
                alt="Focus on media"
              />
            </p>
            <p className="feature-headline">Focus on media</p>
            <p className="feature-text">
              The media holds a democracy to account, but what about the media
              itself? We believe news organisations should be accountable,
              including us. Our stories on the media are driven by that belief.{" "}
            </p>
          </div>
          <div className="feature-item">
            <p>
              <img
                src="https://thumbor-stg.assettype.com/newslaundry/2020-09/a8037330-c572-4cf4-8d17-1fcd22cbbbcf/substance.jpg"
                className="feature-image"
                alt="Substance, not breaking"
              />
            </p>
            <p className="feature-headline">Substance, not breaking</p>
            <p className="feature-text">
              Organisations compete to “break” the news. We believe in bringing
              our readers the stories behind the headlines, through
              thoughtfully-researched deep dives and ground reports.
            </p>
          </div>
          <div className="feature-item">
            <p>
              <img
                src="https://thumbor-stg.assettype.com/newslaundry/2020-09/9bcb7ec5-b1c4-4c96-855f-afb880ef06c1/community__1_.jpg"
                className="feature-image"
                alt="Community, not pageviews"
              />
            </p>
            <p className="feature-headline">Community, not pageviews</p>
            <p className="feature-text">
              We value our community of subscribers more than any metric. We
              have learned from their feedback and criticism and owe where we
              are today entirely to them.{" "}
            </p>
          </div>
          <div className="feature-item">
            <p>
              <img
                src="https://thumbor-stg.assettype.com/newslaundry/2020-09/66bb59f3-e40c-4cc1-a0cb-635ef16cfb50/instituitional_view.jpg"
                className="feature-image"
                alt="Independent, not institutional, view"
              />
            </p>
            <p className="feature-headline">
              Independent, not institutional, view
            </p>
            <p className="feature-text">
              We aim to be a “big tent” for opinions and viewpoints, presenting
              a broad spectrum of thoughtful perspectives.
            </p>
          </div>
          <div className="feature-item">
            <p>
              <img
                src="https://thumbor-stg.assettype.com/newslaundry/2020-09/ae59ac08-be5f-4506-bfd9-90dafe1271f2/transparency.jpg"
                className="feature-image"
                alt="Transparency"
              />
            </p>
            <p className="feature-headline">Transparency</p>
            <p className="feature-text">
              Readers deserve transparency from their news sources. Our
              innovations are powered by our community of subscribers, from our
              monthly Chatbox with our subscribers to subscriber meet-ups.{" "}
            </p>
          </div>
          <div className="feature-item">
            <p>
              <img
                src="https://thumbor-stg.assettype.com/newslaundry/2020-09/000dd7a5-16b1-404a-9f81-a8dec56f9f79/privacy.jpg"
                className="feature-image"
                alt="Substance, not breaking"
              />
            </p>
            <p className="feature-headline">Privacy</p>
            <p className="feature-text">
              We take privacy seriously because users of our website deserve to
              know what data is collected and how it’s used. For this, we
              conducted an in-depth privacy audit of our site, and have spelt it
              out, devoid of jargon, in our{" "}
              <a
                href="https://www.newslaundry.com/privacy-payment-and-comment-policy"
                // style={{ color: "red" }}
                aria-label="Click to read privacy policies"
              >
                {" "}
                privacy policy.{" "}
              </a>
            </p>
          </div>
        </div>
        <div>
          <h3 className="static-page-title" style={{ textAlign: "center" }}>
            <a href="/team">Team bio</a>
          </h3>
          <div className="team-main-wrapper">
            <div className="team-wrapper">
              <div className="team">
                {/* <a
                  href="https://www.newslaundry.com/author/abhinandan-sekhri-2"
                  aria-label="CLick to go to Abhinandan author page"
                > */}
                <img
                  className="team-img"
                  src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1d928e43-7b64-4291-871a-bdd8fcee4bce/Abhinandan_Sekhri.png"
                  alt="abhinandan image"
                />
                <h5
                  className="feature-headline"
                  style={{ paddingBottom: "0px" }}
                >
                  Abhinandan Sekhri
                </h5>
                <p className="feature-text">Chief Executive Officer</p>
                <a
                  href="https://twitter.com/abhinandansekhr"
                  aria-label="CLick to go to Abhinandan twitter"
                >
                  <FaXTwitter
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    aria-hidden="true"
                  />
                  {/* <img
                    src="https://images.assettype.com/newslaundry/2020-10/f7c656c1-275a-4864-aa03-b6ca87c7beb4/twitter.jpg"
                    style={{ width: "24px" }}
                    loading="lazy"
                    className="gm-observing gm-observing-cb"
                  /> */}
                </a>
                <a
                  href="https://www.newslaundry.com/author/abhinandan-sekhri-2"
                  aria-label="CLick to go to Abhinandan author page"
                >
                  {/* <img
                    src="https://images.assettype.com/newslaundry/2020-10/9d576420-43cf-47d1-a606-aa0b3a19345f/link.svg"
                    width="18px"
                  /> */}
                  <FaLink
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    aria-hidden="true"
                  />
                </a>
              </div>
              <div className="team">
                <img
                  className="team-img"
                  src="https://thumbor-stg.assettype.com/newslaundry/2020-08/23263a1a-fdcc-46aa-948c-06f66f4e5aeb/Raman.png"
                  alt="Raman image"
                />
                <h5
                  className="feature-headline"
                  style={{ paddingBottom: "0px" }}
                >
                  Raman Kirpal{" "}
                </h5>
                <p className="feature-text">Editor-in-Chief</p>
              </div>
              <div className="team">
                {/* <a
                  href="https://www.newslaundry.com/author/manisha-pande/"
                  aria-label="CLick to go to manisha author page"
                > */}
                <img
                  className="team-img"
                  src="https://thumbor-stg.assettype.com/newslaundry/2020-08/c529419c-bcba-48f8-b259-b2c6349a715b/Manisha.jpeg"
                  alt="Manisha image"
                />
                <h5
                  className="feature-headline"
                  style={{ paddingBottom: "0px" }}
                >
                  Manisha Pande
                </h5>
                <p className="feature-text">Managing Editor</p>
                {/* </a> */}
                <a
                  href="https://twitter.com/MnshaP"
                  aria-label="CLick to go to manisha twitter"
                >
                  <FaXTwitter
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    aria-hidden="true"
                  />
                  {/* <img
                    src="https://images.assettype.com/newslaundry/2020-10/f7c656c1-275a-4864-aa03-b6ca87c7beb4/twitter.jpg"
                    style={{ width: "24px" }}
                    loading="lazy"
                    className="gm-observing gm-observing-cb"
                  /> */}
                </a>
                <a
                  href="https://www.newslaundry.com/author/manisha-pande"
                  aria-label="CLick to go to manisha author page"
                >
                  <FaLink
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    aria-hidden="true"
                  />
                  {/* <img
                    src="https://images.assettype.com/newslaundry/2020-10/9d576420-43cf-47d1-a606-aa0b3a19345f/link.svg"
                    width="18px"
                    loading="lazy"
                    className="gm-observing gm-observing-cb"
                  /> */}
                </a>
              </div>
              <div className="team">
                {/* <a
                  href="https://www.newslaundry.com/author/atul-chaurasia"
                  aria-label="CLick to go to atul author page"
                > */}
                <img
                  className="team-img"
                  src="https://thumbor-stg.assettype.com/newslaundry/2020-08/2ab4018e-811a-4ff7-a7f4-a5361a493aa6/Atul_chaurasia.jpg"
                  alt="atul image"
                />
                <h5
                  className="feature-headline"
                  style={{ paddingBottom: "0px" }}
                >
                  Atul Chaurasia
                </h5>
                <p className="feature-text">Managing Editor</p>
                {/* </a> */}
                <a
                  href="https://twitter.com/BeechBazar"
                  aria-label="CLick to go to atul's twitter"
                >
                  <FaXTwitter
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    aria-hidden="true"
                  />
                  {/* <img
                    src="https://images.assettype.com/newslaundry/2020-10/f7c656c1-275a-4864-aa03-b6ca87c7beb4/twitter.jpg"
                    style={{ width: "24px" }}
                    loading="lazy"
                    className="gm-observing gm-observing-cb"
                  />{" "} */}
                </a>
                <a
                  href="https://www.newslaundry.com/author/atul-chaurasia"
                  aria-label="CLick to go to atul's author page"
                >
                  {/* <img
                    src="https://images.assettype.com/newslaundry/2020-10/9d576420-43cf-47d1-a606-aa0b3a19345f/link.svg"
                    width="18px"
                    loading="lazy"
                    className="gm-observing gm-observing-cb"
                  /> */}
                  <FaLink
                    style={{ width: "1.25rem", height: "1.25rem" }}
                    aria-hidden="true"
                  />
                </a>
              </div>
            </div>
            <p style={{ textAlign: "center" }}>
              <a
                className="btn-link"
                href="/team"
                aria-label="CLick to go to team's page"
              >
                Meet the rest of the team
              </a>
            </p>
          </div>
          <h1 className="static-page-title" style={{ textAlign: "center" }}>
            Awards
          </h1>
          <div className="awards-wrapper">
            <a
              href="https://www.newslaundry.com/2024/05/28/newslaundry-app-wins-best-innovative-digital-product-at-wan-ifra-digital-media-awards-worldwide"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                WAN-IFRA Digital Media Awards Worldwide (2024)
              </p>
              <p className="award-text">
                The Newslaundry app won the 'Best Innovative Digital Product'.
                The jury noted, ..."This is a great case that confirms how
                important it is to prioritise ’product’ strategies and not only
                ’pricing’ strategies when we move to subscription-based models.”
              </p>
            </a>
            <a
              // href="https://www.newslaundry.com/nl-sena/plunder-of-the-aravallis"
              className="award-item"
              aria-label="India Audio Summit and Awards (2024)"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                India Audio Summit and Awards (2024)
              </p>
              <p className="award-text">
                The Newslaundry iOS and Android app won the Best Innovation in
                Technology for its podcast player.
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/nl-sena/plunder-of-the-aravallis"
              className="award-item"
              aria-label="Click to go to read more about the award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                The Ramnath Goenka Excellence in Journalism for Environment,
                Science and Technology Reporting (2022)
              </p>
              <p className="award-text">
                For a four-part series on the plunder of the Aravallis, as
                governments and private players view the mountains not as a
                habitat but as prime real estate.
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2022/01/24/arsenic-in-water-brings-death-and-disease-in-up-bihar"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                The Ramnath Goenka Excellence in Journalism for Hindi broadcast
                (2022)
              </p>
              <p className="award-text">
                For a documentary on arsenic contamination in water in districts
                across Uttar Pradesh and Bihar.
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2024/01/30/wan-ifra-awards-newslaundry-app-bags-gold-as-the-best-innovative-digital-product"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                WAN-IFRA Digital Media Awards South Asia (2024)
              </p>
              <p className="award-text">
                The Newslaundry won the ‘Best Innovative Digital Product’ at the
                Digital Media Awards South Asia. The jury noted, “The stand-out
                entry demonstrates best-in-class, audience-focused product
                development. What is particularly positive about the entry is
                not only the product-market fit but also the business-model
                product fit. It demonstrates the virtuous cycle possible with a
                reader-revenue-led newsroom, where the audience is central to
                business and product development."
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2023/06/06/best-new-digital-product-newslaundry-app-receives-honourable-mention-at-inma-awards"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">INMA Global Media awards (2023)</p>
              <p className="award-text">
                Newslaundry app received an honorable mention in the 'best new
                digital product' category at the INMA Global Media awards,
                recognizing its excellence in innovation and its role in pushing
                boundaries and driving change within the news industry.
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2023/07/28/best-use-of-new-technology-newslaundry-app-wins-digipub-awards-2023"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">Digipub Awards (2023)</p>
              <p className="award-text">
                Newslaundry's Android and iOS app was awarded the AFAQs Digipub
                Awards 2023 in the 'Best Use of Technology' category,
                recognizing its utilization of technology in online publishing
                to enhance user experience and efficiency.
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2022/11/02/newslaundry-wins-two-laadli-awards-for-reportage-on-hathras-yati-narsinghanand"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                The Laadli Media Award for Gender Sensitivity (2022)
              </p>
              <p className="award-text">
                Two Laadli Media awards in the same year. For a ground report on
                the Hathras case and a report on Yati Narsinghanand and his army
                of believers.
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/author/nitin-setthii"
              target="_blank"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                The Asian College of Journalism award for Investigative
                Journalism (2020)
              </p>
              <p className="award-text">
                For a five-part series on the electoral bonds by Nitin Sethi.
                The investigation revealed the haste and irregularities that
                paved the way for bringing in the electoral bonds.
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/2020/11/20/newslaundry-wins-three-laadli-awards-2"
              target="_blank"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                The Laadli Media and Advertising Award for Gender Sensitivity
                (2020)
              </p>
              <p className="award-text">
                Three Laadli Media awards in the same year. These include a
                report on gender representation in newspapers and the story of
                an 18-month-old pellet gun survivor from Jammu and Kashmir’s
                Shopian district.
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/2020/04/07/we-will-die-doing-our-duty-how-ashas-are-battling-coronavirus-without-safety-gear-or-training"
              target="_blank"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                Press Institute of India and International Committee of the Red
                Cross Annual Awards (2020)
              </p>
              <p className="award-text">
                For a report on how ASHA workers are battling Covid without
                safety gear. The Ministry of Health and Family Welfare issued
                orders for an additional incentive of Rs. 2,000 to ASHAs after
                the story was published.
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/2019/01/28/gaya-neelam-patva-honer-killing-rape"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                Breakthrough Media Award for Gender-Sensitive Journalism (2019){" "}
              </p>
              <p className="award-text">
                For a report investigating the murder of a teenage girl in Gaya,
                Bihar.{" "}
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/2018/08/20/sukma-encounter-innocent-tribals-killed-ground-report"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                RedInk Award for Excellence in Indian Journalism- Human Rights
                category (2019){" "}
              </p>
              <p className="award-text">
                For a ground report on fake encounters by security forces in
                Chhattisgarh’s Sukma district. It brought to light how innocent
                Adivasis were killed by the police in an encounter.
              </p>
            </a>
            <a
              className="award-item"
              href="https://asiapodcastawards.com/"
              target="_blank"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">Asia Podcast Awards (2019) </p>
              <p className="award-text">
                Our pop culture podcast, The Awful and Awesome Entertainment
                Wrap was adjudged Asia&apos;s Best Podcast, and Let&apos;s Talk
                About bagged the Best Education Podcast.
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/campus-politik/sexual-harassment-on-campus"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                The Laadli Media Award for Gender Sensitivity (2018){" "}
              </p>
              <p className="award-text">
                For a Campus Politik series on the state of sexual harassment
                redressal mechanisms in colleges.
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/2020/02/08/cauvery-a-basin-on-the-burn"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                RedInk Award for Excellence in Indian Journalism (2018)
              </p>
              <p className="award-text">
                For a ground report on the Cauvery water dispute.{" "}
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/2020/02/08/cauvery-chronicles-ii-ponnis-perish"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                IIMC Alumni Association Award for Best Developmental Reporting
                (2018)
              </p>
              <p className="award-text">
                For a ground report on India’s oldest water dispute.
              </p>
            </a>
            <a
              className="award-item"
              href="https://www.newslaundry.com/2015/08/26/how-politicians-use-psus-as-cash-vending-machines-an-rti-investigation"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                The Ramnath Goenka Excellence in Journalism for Investigative
                Reporting Award (2016){" "}
              </p>
              <p className="award-text">
                For a five-part series on how politicians across party lines use
                their official position to cull monetary favours from PSUs, and
                use this to fund organisations with which they are directly or
                indirectly associated.
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2014/12/05/where-are-the-women"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />

              <p className="award-headline">
                The Laadli Media Award for Gender Sensitivity (2015){" "}
              </p>
              <p className="award-text">
                For a report analysing gender representation on the pages of
                India’s four leading English dailies.
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
