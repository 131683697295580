/* eslint-disable max-len */
import React from "react";
import "./subscription-playlist.m.css";

const IS_HINDI = !!(global.location && global.location.host.includes("hindi"));

export const SubscriptionPlaylist = () => {
  const [playlistData, setPlaylistData] = React.useState(null);
  const [nowPlayingUrl, setNowPlayingUrl] = React.useState(null);

  React.useEffect(() => {
    fetch(
      `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=5&playlistId=PLpHbno9djTOT1t_IG7LX2Gu6XIckYixNc&key=AIzaSyDhn4laWphWRpS9mIEDmX2BnCzZP8SNdyk`
    )
      .then(response => response.json())
      .then(data => {
        setPlaylistData(data);
        setNowPlayingUrl(data.items[0].snippet.resourceId.videoId);
      });
  }, []);

  return (
    <div className="full-width-with-padding" styleName="inner-wrapper">
      {IS_HINDI ? (
        <h2>आप हमारी पत्रकारिता को शक्ति क्यों दें?</h2>
      ) : (
        <h2>Why you should power our journalism?</h2>
      )}
      <br />
      <div className="container">
        <div styleName="flex-grid">
          <div styleName="now-playing">
            <iframe
              width="800"
              height="450"
              src={`https://www.youtube.com/embed/${nowPlayingUrl}?rel=0&amp;`}
              styleName="iframeYT"
              allow="autoplay"
              allowFullScreen
              title="Subscription youtube playlist"
            />
          </div>
          <div styleName="video-list">
            {playlistData &&
              playlistData.items.slice(0, 5).map(({ id, snippet = {} }) => {
                const { title, thumbnails = {}, resourceId = {} } = snippet;
                const { medium } = thumbnails;
                return (
                  <div
                    styleName="video-item"
                    onClick={() => setNowPlayingUrl(resourceId.videoId)}
                    key={resourceId.videoId}
                    style={
                      nowPlayingUrl === resourceId.videoId
                        ? { opacity: "0.5" }
                        : null
                    }
                  >
                    <img
                      width="44%"
                      height="10%"
                      src={medium.url}
                      style={{ borderRadius: "8px" }}
                      stylename="thumb"
                      alt=""
                    />
                    {nowPlayingUrl !== resourceId.videoId && <a />}
                    <span styleName="video-title">{title}</span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
