import React, { useState } from "react";
import PT from "prop-types";
import { Button } from "../../atoms/button";
import { InputField } from "../../atoms/input-field";
import { verifyEmail, resetPasswordByOtp } from "../../helper/api";
import "./forms.m.css";

export function ForgotPassword({ onBackdropClick }) {
  const [email, setEmail] = useState("");
  const [data, setOTPData] = useState({
    otp: "",
    password: "",
    confirmPassword: "",
    id: ""
  });
  const [successMsg, setSuccessMsg] = useState("");
  const [error, setError] = useState(null);
  const [showVerficationScreen, verificationScreenHandler] = useState(false);

  const emailHandler = async e => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const verifiedData = await verifyEmail(email);
      if (verifiedData) {
        // data.id = verifiedData["email-token"];
        setError(null);
        verificationScreenHandler(true);
      } else {
        setError({ message: "error" });
      }
    } catch (e) {
      console.log(JSON.parse(e.message).error.message);
      setError({ message: `${JSON.parse(e.message).error.message}` });
    }
  };

  const setEmailData = e => {
    setEmail(e.target.value);
  };

  const setData = e => {
    const userObj = { ...data };
    const fieldName = e.target.id;
    userObj[fieldName] = e.target.value;
    setOTPData(userObj);
  };

  const changePassword = e => {
    e.preventDefault();
    e.stopPropagation();

    if (data.password !== data.confirmPassword) {
      setError({ message: "Password does not match" });
      return null;
    }
    resetPasswordByOtp(data.otp.trim(), email, data.password)
      .then(() => {
        setSuccessMsg("Your Password has been changed");
        setTimeout(() => {
          onBackdropClick(); // hinding modal after 3 second
        }, 3000);
      })
      .catch(error => {
        console.log("error", error);
        setError({ message: `Invalid OTP or member not found` });
      });
  };
  return (
    <React.Fragment>
      {!showVerficationScreen ? (
        <form styleName="nl-form" onSubmit={emailHandler}>
          <InputField name="Email" id="email" type="email" required onChange={setEmailData} />
          {error && <p styleName="error">{error.message}</p>}
          <div styleName="actions">
            <Button onClick={emailHandler} className="nl-btn-large">
              Submit
            </Button>
          </div>
        </form>
      ) : (
        <form styleName="nl-form" key="otp" onSubmit={changePassword}>
          <p styleName="message">
            Please check your spam and promotions, and if you still don't write to{" "}
            <a href="mailto:subscription@newslaundry">subscription@newslaundry</a>
          </p>
          <InputField name="Enter OTP" id="otp" type="text" value={data.otp} required onChange={setData} />
          <InputField
            name="Enter Password"
            id="password"
            type="password"
            value={data.password}
            required
            onChange={setData}
          />
          <InputField
            name="Confirm Password"
            id="confirmPassword"
            type="password"
            value={data.confirmPassword}
            required
            onChange={setData}
          />
          {successMsg && <p styleName="success-msg">{successMsg}</p>}
          {error && <p styleName="error">{error.message}</p>}
          <div styleName="actions">
            <Button onClick={changePassword} className="nl-btn-large">
              Verify OTP
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
}

ForgotPassword.propTypes = {
  onBackdropClick: PT.func
};
