import React from "react";
import PropTypes from "prop-types";
import Timeago from "react-timeago";
import { dateFormatterWithoutTime } from "../../../utils";
import "./time-stamp.m.css";

export const TimeStamp = ({ story, timestampColor = "#333" }) => {
  const time = story["last-published-at"] || story["first-published-at"];
  // console.log(typeof time, {time} );
  const dateObject = new Date(time);

  function formatDateToEnglishStyle(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: 'numeric',
      // minute: 'numeric',
      // second: 'numeric',
      hour12: true // Use 12-hour clock format
      // timeZoneName: 'short',
    };

    return date.toLocaleString("en-US", options);
  }
  const formattedDate = formatDateToEnglishStyle(dateObject);

  return (
    <div
      styleName="time"
      // style={{ color: timestampColor }}
      aria-label={`Published at: ${formattedDate}`}
    >
      <Timeago
        date={time}
        formatter={dateFormatterWithoutTime}
        aria-hidden="true"
      />
    </div>

    //    <time dateTime={dateObject.toISOString()} styleName="time" style={{ color: timestampColor }}>
    //    <span style={{ display: 'none' }} aria-live="assertive">
    //      <Timeago date={time} formatter={dateFormatterWithoutTime} />
    //    </span>
    //    <span aria-hidden="true">
    //      <Timeago date={time} formatter={dateFormatterWithoutTime} />
    //    </span>
    //  </time>
  );
};

TimeStamp.propTypes = {
  story: PropTypes.object,
  isBottom: PropTypes.bool,
  timestampColor: PropTypes.string
};
