/* eslint-disable react/prop-types */
import React, { useState } from "react";
import get from "lodash/get";
// import assetify from "@quintype/framework/assetify";
import NlSenaWrapper from "../nl-sena-wrapper";
import { VideoModal } from "../../molecules/video-modal";
// import Carousel from "../../collection-templates/comics/carousel";
// import CommonStoryCard from "../../molecules/common-story-card";
import getStoriesByCollection from "../../../utils";
import "./nl-sena-detail.m.css";
import {
  LoadMoreCollectionStories
  // LazyLoadImages,
  // LazyCollection
} from "@quintype/components";
import HorizontalStoryCardList from "../../molecules/horizontal-story-card-list";
import VerticleStoryCardList from "../../molecules/verticle-story-card-list";
import { LoadMoreButton } from "../../atoms/load-more-button";

export const NLSenaDetailPage = ({ props, id }) => {
  const [showModal, setShowModal] = useState(false);
  // const campSubscriptions = get(props, ["campaignSubscriptions"], []);
  const collection = get(props, ["data", "collection"]);
  const stories = getStoriesByCollection(collection);
  const totalCount = get(
    props,
    ["data", "collectionstories", "collection", "total-count"],
    ""
  );

  // console.log(stories)

  const storiesTemplate = ({ onLoadMore, stories }) => {
    return (
      <div>
        <div className="desktop-only">
          <HorizontalStoryCardList stories={stories} />
        </div>
        <div className="mobile-only">
          <VerticleStoryCardList stories={stories} />
        </div>
        {totalCount !== stories.length && (
          <LoadMoreButton onClick={onLoadMore} />
        )}
      </div>
    );
  };

  return (
    <div>
      {id ? (
        <NlSenaWrapper
          showSingleCampaign={true}
          campaignId={id}
          detailPage={true}
        />
      ) : null}
      {showModal && <VideoModal setShowModal={setShowModal} />}

      {stories.length > 0 && (
        <div styleName="container">
          <h2
            styleName="headline"
            className="collection-name"
            // style={{ color: "black" }}
          >
            Stories
          </h2>
          <div
            styleName="border"
            className="collection-border"
            // style={{ borderBottomColor: "#ec2227" }}
          />
        </div>
      )}
      <div styleName="sena-stories">
        <LoadMoreCollectionStories
          template={storiesTemplate}
          data={{ stories: stories }}
          collectionSlug={props.data.collection.slug}
          numStoriesToLoad={2}
          params={{ "item-type": "story" }}
        />
      </div>
    </div>
  );
};
