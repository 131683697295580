/* eslint-disable react/prop-types */
import React from "react";
import Product from "./Product";
import "./merch-page.m.css";

const Products = props => {
  const products = props.products.map(product => {
    if (product.title === "Test Product") {
      return null;
    }

    return (
      <Product
        addVariantToCart={props.addVariantToCart}
        client={props.client}
        key={product.id.toString()}
        product={product}
      />
    );
  });

  return <div styleName="product-wrapper">{products}</div>;
};

export default Products;
