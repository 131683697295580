import React from 'react'
import { Modal } from '../modal'
import { CloseIcon } from '../../podcast-player/icons'
import "./video-modal.m.css"

export const VideoModal = ({videoId, handleClose}) => {

    return(
        <Modal
            onBackdropClick={handleClose}
            styleName = "video-modal"
            hideCloseIcon
        >
            <div>
                <div styleName="btn">
                    <button className="pressable" onClick={handleClose} style={{}}>
                        <CloseIcon fill="white" width={18}></CloseIcon>
                    </button>
                 </div>
            </div>
            
            <iframe styleName="sena-iframe"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&ref=0`}
            />

        </Modal>
    )
}