import React, { useState } from "react";

import "./independence-day.m.css";

const IndependenceDayPage = () => {
  const [hamperOption, setHamperOption] = useState(1);

  const handleBtnClick = () => {
    if (hamperOption === 1) {
      window.open(
        `https://razorpay.com/payment-button/pl_MM1N3UPajREYfV/view`,
        "_blank"
      );
    } else {
      window.open(
        `https://razorpay.com/payment-button/pl_MM1Rb5zklpSgS8/view`,
        "_blank"
      );
    }
  };

  return (
    <section styleName="section" className="full-width">
      <div styleName="container-div">
        <main styleName="hero-section">
          <div>
            <div styleName="hero-section-content-wrapper">
              <div styleName="hero-section-content">
                <div styleName="hero-title-wrapper">
                  <h1 styleName="title">Independence Day with Newslaundry</h1>
                  <p styleName="border-bottom"></p>
                </div>
                <p styleName="description">
                  This August celebrate the spirit of independence with
                  Newslaundry. These celebrations are dear to every Indian for
                  an array of reasons. This year, Newslaundry adds another to
                  the list.
                </p>
              </div>
              <div styleName="hero-section-img">
                <img
                  src="https://images.assettype.com/newslaundry/2022-08/cb953f03-751f-4c4a-91e1-fdbf96cf4666/FB_Banner.jpeg"
                  alt="independence day image"
                />
              </div>
            </div>
          </div>
        </main>
        <div styleName="sub-section">
          <div styleName="sub-section-contents">
            <p style={{ fontWeight: "bold", fontSize: "20px" }}>
              The spirit of independent journalism.
            </p>
            <p>
              Get this merchandise for yourself and say you&apos;re a proud
              member of a community that keeps independent media alive.
            </p>
            <p>
              Or send it to friends and family to help spread the word about
              free, accurate, and independent news.
            </p>
            <p>
              The hamper includes Newslaundry merchandise, such as NL T-shirts*,
              backpacks, exciting NL comics, stickers, and much more.
            </p>
            <p>So, pick a hamper and delight those around you.</p>
          </div>
          <div styleName="sub-section-form">
            <p styleName="option-heading">Choose a hamper</p>
            <div styleName="option-row">
              <div
                styleName={`option left ${
                  hamperOption === 1 ? "selected-option" : "unselected-option"
                }`}
                onClick={() => setHamperOption(1)}
              >
                Hamper #1
              </div>
              <div
                styleName={`option right ${
                  hamperOption === 2 ? "selected-option" : "unselected-option"
                }`}
                onClick={() => setHamperOption(2)}
              >
                Hamper #2
              </div>
            </div>
            <p styleName="option-heading">Your hamper will include:</p>
            {hamperOption === 1 ? (
              <ul>
                <li>NL Cap</li>
                <li>Laptop Stickers</li>
                <li>Karare Chane Mug</li>
                <li>Fridge Magnets</li>
                <li>Naxalbari Comic</li>
                <li>Free 2 x 1-month subscription</li>
              </ul>
            ) : (
              <ul>
                <li>NL Bagpack</li>
                <li>Kashmir ki Kahani comic </li>
                <li>Stay Mad/Karare Chane T-Shirt</li>
                <li>Cap</li>
                <li>Laptop Stickers</li>
                <li>Car Stickers</li>
                <li>Fridge Magnets</li>
                <li>Karare Chane Mug</li>
                <li>"Which Naxal are you?” Mug</li>
                <li>Naxalbari Comic</li>
                <li>Free 5 x 1-month subscriptions </li>
              </ul>
            )}
            <div styleName="cta-row">
              <div styleName="cta-content">
                <div styleName="cta-content-to-pay">To pay</div>
                <div styleName="cta-content-price">
                  &#x20B9; {hamperOption === 1 ? "2,200" : "5,200"}{" "}
                  <span
                    style={{
                      fontSize: "16px",
                      textDecorationLine: "line-through"
                    }}
                  >
                    &#x20B9;
                    {hamperOption === 1 ? "2,996" : "7,190"}
                  </span>
                </div>
              </div>
              <button styleName="cta-pay-btn" onClick={handleBtnClick}>
                Buy Now
              </button>
            </div>
          </div>
        </div>
        <div styleName="items-sub-section">
          <h3 styleName="items-heading">A peek into the hampers</h3>
          <div styleName="item-option-grid">
            <div styleName="item-option">
              {/* <p styleName="item-option-heading">
                Books signed by Newslaundry Team
              </p> */}
              <img
                styleName="h-image"
                loading="lazy"
                src="https://images.assettype.com/newslaundry/2023-08/42d96aeb-029e-4e87-b0fb-7d67bf4d74d7/hamper_1_h.jpg"
                alt=""
                // style={{ aspectRatio: "16/9" }}
              />
              <img
                styleName="v-image"
                loading="lazy"
                src="https://images.assettype.com/newslaundry/2023-08/fdcfabe6-0a45-42a1-9a36-68d933cae587/hamper_1_v.jpg"
                alt=""
                // style={{ aspectRatio: "16/9" }}
              />
            </div>
            <div styleName="item-option">
              {/* <p styleName="item-option-heading">Desk Calendar by Manjul</p> */}
              <img
                styleName="h-image"
                loading="lazy"
                src="https://images.assettype.com/newslaundry/2023-08/45ba551a-474b-40b5-8d04-c995505fed01/hamper_2_hb.jpg"
                alt=""
                // style={{ aspectRatio: "16/9" }}
              />
              <img
                styleName="v-image"
                loading="lazy"
                src="https://images.assettype.com/newslaundry/2023-08/84e3466f-eca0-452e-9cf6-27e1a9735887/hamper_2_vb.jpg"
                alt=""
                // style={{ aspectRatio: "16/9" }}
              />
            </div>
            {/* <div styleName="item-option last">
              <p styleName="item-option-heading">Newslaundry Merchandise</p>
              <div styleName="products-grid">
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/SoapBox-1_10cefa24-99b9-4d54-9352-5844946d8401.png?v=1605338175"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/NaxalMug-Sides_79389f8f-60cb-4459-940a-8ce7b7474cf3.png?v=1606400739"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Bottle-Front_d1dd7f68-53e0-41cf-9199-9489bc3d2168.png?v=1605338424"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/ToteBag-Front_cd715edd-e674-4ab0-9c58-5c0b734b8abd.png?v=1605338846"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Candle-withshadow.png?v=1605339027"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/asd3_578e67fa-162b-4f8d-8104-b021759e6a13.png?v=1628580393"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/shopify-black-mask2.jpg?v=1631534798"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Mug-1.jpg?v=1637326448"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Fridgemagnets.jpg?v=1636627660"
                  alt=""
                />
                <img
                  loading="lazy"
                  src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Shopify-hoodie-red.jpg?v=1642506664"
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>
        <div styleName="terms-sub-section">
          <div styleName="terms-heading">Terms and Conditions:</div>
          <ul styleName="notes-wrapper">
            <li>This offer is valid from August 5 to 20, 2023.</li>
            <li>
              *Our T-shirts range from S-XL, so put your size in the preference
              section in case your box contains one.
            </li>
            <li>
              Please give us a couple of days to map the gift subscription.
            </li>
            <li>
              Deliveries in India only. If you are based overseas, please share
              an Indian delivery address (of friends/family).
            </li>
            <li>
              Please note, delivery of packages will take at least two to three
              weeks. Thank you for your patience.
            </li>
            <li>
              If you have any queries, please drop an email on{" "}
              <a
                style={{ color: "#ec2227" }}
                href="mailto:store@newslaundry.com"
              >
                store@newslaundry.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export { IndependenceDayPage };
