/* eslint-disable max-len */
import React from "react";
import "./cases-component.m.css";

export const cases = [
  {
    headline: "Delhi court dismisses IT complaint against NL",
    description: [
      {
        text: (
          <p key={1} styleName="legal-cases-text">
            The Income Tax department wanted to pursue a criminal investigation
            against Newslaundry and its CEO Abhinandan Sekhri, along with
            Newslaundry’s chartered accountant and merchant bankers.
          </p>
        )
      },
      {
        text: (
          <p key={2} styleName="legal-cases-text">
            On November 29, 2022, a Delhi court{" "}
            <a
              style={{ color: "var(--text-accent-muted)" }}
              href="https://www.livelaw.in/news-updates/no-criminal-conspiracy-no-material-irregularities-in-valuation-report-delhi-court-junks-income-tax-dept-complaint-against-newslaundry-215346"
              target="_blank"
              rel="noopener noreferrer"
            >
              pronounced
            </a>{" "}
            a clear and detailed judgment denying them this pursuit. The court
            called the charges “bereft of merit” and said there was no
            criminality involved in the actions of Newslaundry and its
            directors.
          </p>
        )
      },
      {
        text: (
          <p key={3} styleName="legal-cases-text">
            “It is also not the case of the complainant that there was any wrong
            entry in the books of accounts, balance sheet and income tax
            return…No actus reus is attributable to the accuses no. 1,” said the
            court.
          </p>
        )
      }
    ]
  },
  {
    headline: "TV Today's copyright and defamation suit",
    description: [
      {
        text: (
          <p key={1} styleName="legal-cases-text">
            The TV Today Network, which runs India Today and Aaj Tak among a
            gamut of news channels,{" "}
            <a
              style={{ color: "var(--text-accent-muted)" }}
              href="https://thewire.in/media/india-today-defmation-newslaundry-copyright-violation-rs-2-crore"
              target="_blank"
              rel="noopener noreferrer"
            >
              demanded
            </a>{" "}
            Rs 2 crore in damages from Newslaundry for alleged copyright
            violation, defamation and commercial disparagement.
          </p>
        )
      },
      {
        text: (
          <p key={2} styleName="legal-cases-text">
            They moved the Delhi High Court in October 2021, seeking immediate
            relief by restraining us from “writing, tweeting, or publishing”
            anything defamatory about its channels. They also sought suspension
            and termination of our YouTube channel and an order for us to take
            down all shows in which we used their footage while critiquing the
            TV Today Network’s channels.
          </p>
        )
      },
      {
        text: (
          <p key={3} styleName="legal-cases-text">
            The case went for 11 hearings after which the high court reserved
            the judgement on whether Newslaundry could be stopped from reporting
            on TV Today during the pendency of the suit.
          </p>
        )
      },
      {
        text: (
          <p key={4} styleName="legal-cases-text">
            On July 29, the high court{" "}
            <a
              style={{ color: "var(--text-accent-muted)" }}
              href="https://www.barandbench.com/news/litigation/tv-today-v-newslaundry-right-to-comment-publish-on-social-media-part-of-free-speech-under-article-191a-delhi-high-court"
              target="_blank"
              rel="noopener noreferrer"
            >
              refused
            </a>{" "}
            TV Today Network interim relief. It observed that neither the aspect
            of any balance of convenience nor irreparable loss was met to grant
            interim relief.
          </p>
        )
      }
    ]
  },
  {
    headline: "FIR against Nidhi Suresh",
    description: [
      {
        text: (
          <p key={1} styleName="legal-cases-text">
            On July 4, 2021, the police in Shahjahanpur, Uttar Pradesh,
            registered an FIR against Newslaundry journalist Nidhi Suresh on the
            basis of a complaint by journalist Deep Srivastava. The complaint
            alleged defamation under sections 500 and 501 of Indian Penal Code
            over an unspecified tweet by Nidhi.
          </p>
        )
      },
      {
        text: (
          <p key={2} styleName="legal-cases-text">
            A few days earlier, Nidhi had{" "}
            <a
              style={{ color: "var(--text-accent-muted)" }}
              href="https://www.newslaundry.com/2021/07/01/threatened-us-tried-to-extort-money-muslim-convert-accuses-media-of-misreporting-harassment"
              target="_blank"
              rel="noopener noreferrer"
            >
              reported
            </a>{" "}
            that a woman named Ayesha Alvi was in the Delhi High Court alleging
            “harassment” by the media after she converted to Islam.
            Specifically, Alvi mentioned getting a call from an unknown person
            who threatened to make her conversion public unless she paid up.
            Nidhi wrote that she called the number and the person “identified
            himself as Deep Srivastava, reporter at News18.
          </p>
        )
      },
      {
        text: (
          <p key={3} styleName="legal-cases-text">
            Nidhi tweeted her report when it was published.
          </p>
        )
      },
      {
        text: (
          <p key={4} styleName="legal-cases-text">
            The Supreme Court has ruled that a criminal defamation complaint
            must be filed before a magistrate under Section 200 of the CrPC as
            the offenses under IPC 500 and 501 are non-cognizable. Yet, the
            Uttar Pradesh police registered the FIR without filing a complaint
            before a magistrate.
          </p>
        )
      },
      {
        text: (
          <p key={5} styleName="legal-cases-text">
            We will continue to pursue the matter.
          </p>
        )
      }
    ]
  },
  {
    headline: "Sakal Media’s defamation suit",
    description: [
      {
        text: (
          <p key={1} styleName="legal-cases-text">
            The Bombay High Court on April 21{" "}
            <a
              style={{ color: "var(--text-accent-muted)" }}
              href="https://www.newslaundry.com/2021/04/21/no-further-proceedings-high-court-quashes-sakal-media-groups-fir-against-newslaundry-reporter"
              target="_blank"
              rel="noopener noreferrer"
            >
              quashed
            </a>{" "}
            the FIR filed against Newslaundry reporter Prateek Goyal by the
            Sakal Media Group.
          </p>
        )
      },
      {
        text: (
          <p key={2} styleName="legal-cases-text">
            The court{" "}
            <a
              style={{ color: "var(--text-accent-muted)" }}
              href="https://www.livelaw.in/news-updates/bombay-high-court-quashes-fir-sakal-times-newslaundry-journalist-falsely-applying-trademark-172895"
              target="_blank"
              rel="noopener noreferrer"
            >
              observed
            </a>{" "}
            that “at least prima facie the ingredients of the offence ought to
            be demonstrated...In the absence of ingredients of the offence being
            made out, even on admitting facts, the First Information Report
            could not have been registered” for an offence to be registered
            against NL Reporter Prateek Goyal. The court said, “it would be
            futile to allow further proceedings in such matters”.
          </p>
        )
      },
      {
        text: (
          <p key={3} styleName="legal-cases-text">
            The Sakal Media Group’s defamation suit worth Rs 65 crore against
            Newslaundry is still pending.
          </p>
        )
      }
    ]
  }
];
