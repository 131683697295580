import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Loader from "../../../../atoms/loader";
import "./subscription-template.m.css";
import { parseUrl } from "query-string";
import { connect } from "react-redux";

class TrialSubscriptionTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscriptions: {},
      userSubscriptions: [],
      isAccesstypeLoaded: false,
      selectFirstPlan: {},
      selectedGroup: {},
      activeTab: "tab-1"
    };
    this.handleSubscriptionClick = this.handleSubscriptionClick.bind(this);
  }

  componentDidMount() {
    this.mountedRoute = window.location.href;
    const userSubscription = () => this.getSubscription();
    this.props.initAccessType(userSubscription);
    this.props.selectedPlan({});
  }

  // componentDidUpdate() {
  //   const newRoute = window.location.href;

  //   if (newRoute !== this.mountedRoute) {
  //     const activeTab = newRoute.indexOf("gift-subscription") > -1 ? "tab-2" : "tab-1";

  //     this.manageTab(activeTab);

  //     this.mountedRoute = newRoute;subscriptions
  async getSubscription() {
    const response = await this.props.getSubscriptionForUser();
    this.setState({ userSubscriptions: response.subscriptions, isAccesstypeLoaded: true });
  }

  getDurationBlock() {
    const groups = get(this.props, ["subscriptions"], []);

    return (
      <div styleName="tab">
        <button
          styleName={`${this.state.activeTab === "tab-1" ? "tab-btn-active" : ""} tab-btn-1 `}
          onClick={() => this.manageTab("tab-1", groups[0])}
        >
          {groups[0].name}
        </button>
        <button
          styleName={`${this.state.activeTab === "tab-2" ? "tab-btn-active" : ""} tab-btn-2 `}
          onClick={() => this.manageTab("tab-2", groups[1])}
        >
          {groups[1].name}
        </button>
      </div>
    );
  }

  manageTab(tab, group) {
    this.setState({
      activeTab: tab,
      selectedGroup: group
    });
    // const paymentCheckoutPage = tab === "tab-2" ? "gift-subscription" : "subscription";
    // this.props.paymentCheckout(paymentCheckoutPage);
  }

  addDefaultSrc(ev, imgUrl) {
    ev.target.src = imgUrl;
  }

  handleSubscriptionClick(e, subscriptionGroup, hasSubscription) {
    e.preventDefault();
    e.stopPropagation();

      if (!this.props.member) {
        const params = parseUrl(this.props.currentPath);
        const callbackUrl = get(params, ["query", "callback-url"], global.location && global.location.origin);
        const redirectUrl = get(params, ["query", "redirect-url"], global && global.location && global.location.href);
        window.location = `${this.props.authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${this.props.authHost}/user-login`;
        return false;
        }

    const plans = get(subscriptionGroup, ["subscription_plans"]);
    const selectedSubscriptionPlan = plans[0];
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get("story-url") || "/trial-subscription";
    this.props.selectedPlan(selectedSubscriptionPlan, redirectUrl, subscriptionGroup.subscription_plans);
    this.props.navigateTo(`/payment-checkout`);
    return true;
  }

  getSubscriptionBlocks(subscriptionGroup) {
    const plan = get(subscriptionGroup, ["subscription_plans", "0"], {});
    let description;
    let imageUrl;
    const bottomDesc1="Free for 30 days";
    let bottomDesc2;
    const getDefaultImg = get(this.props.config, ["publisher", "publisher", "defaultSubImg"], "");
    const hasSubscription =
      (this.state.userSubscriptions &&
        this.state.userSubscriptions.filter(userSub => userSub.subscription_group_id === subscriptionGroup.id)) ||
      [];
      switch (this.state.activeTab){
        case "tab-1":
          description = get(subscriptionGroup, ["description"], "").split("\n");
          imageUrl = description[0];
          bottomDesc2="₹ 3000/month";
          break;
        case "tab-2":
          description = get(subscriptionGroup, ["description"], "").split("\n");
          imageUrl = description[0];
          bottomDesc2="₹ 10000/month";
          break;
      }
      
    return (
      <div styleName="group">
        <img
          onError={ev => this.addDefaultSrc(ev, getDefaultImg)}
          styleName="image"
          src={`${imageUrl}`}
          alt={subscriptionGroup.name}
        />
        <div styleName="plan-content">
          {this.getDurationBlock()}

          {description && description.length > 3 && (
            <ul styleName="feature">
              {description.slice(this.state.activeTab=="tab-1"?2:1, description.length).map((feature, index) => {
                return (
                  feature.length > 0 && (
                    <li styleName="feature-list" key={index + 1}>
                      {feature}
                    </li>
                  )
                );
              })}
            </ul>
          )}
          <div styleName="btn-wrapper">
            <button
              data-testid="plans-subscribe-btn"
              styleName="subs-btn"
              onClick={e => this.handleSubscriptionClick(e, subscriptionGroup, hasSubscription)}
            >
              Try it for free
            </button>
          </div>
          <div styleName="bottom-wrapper">
              {bottomDesc1}<br/>
              {bottomDesc2}
          </div>
        </div>
      </div>
    );
    // });
  }

  render() {
    const planPageTitile = get(this.props.config, ["publisher", "publisher", "planPageTitle"], "").split(",");
    const planPageDescription = get(this.props.config, ["publisher", "publisher", "planPageDesc"], "");
    const groups = get(this.props, ["subscriptions"], []);
    // const getStandardUser =
    //   (this.state.userSubscriptions &&
    //     this.state.userSubscriptions.filter(item => {
    //       return item.subscription_type === "standard";
    //     })) ||
    //   [];

    if (this.state.isAccesstypeLoaded === false) {
      return (
        <div styleName="loader">
          <Loader color="#ec2227" />
        </div>
      );
    }

    const selectedGroup = isEmpty(this.state.selectedGroup) ? groups[0] : this.state.selectedGroup;

    return groups && groups.length > 0 ? (
      <div className="full-width-with-padding" styleName="bg-wrapper">
        <div styleName="wrapper">
          {planPageTitile[0] && <h1 styleName="title">{planPageTitile[0]}</h1>}
          {planPageTitile[1] && <h2 styleName="title red">{planPageTitile[1]}</h2>}
          <h3 styleName="description">
            {planPageDescription} If you have any issue regarding subscription, please check out our{" "}
            <a styleName="red" href="/faq">
              FAQ section{" "}
            </a>
          </h3>
          <div styleName="layout">
            {this.getSubscriptionBlocks(selectedGroup)}
          </div>
        </div>
      </div>
    ) : null;
  }
}
const mapStateToProps = state => {
  return {
    currentPath: get(state, ["qt", "currentPath"], ""),
    authHost: get(state, ["qt", "config", "publisher", "publisher", "auth_host"], null)
    
  };
};

TrialSubscriptionTemplate.propTypes = {
  initAccessType: PropTypes.func,
  member: PropTypes.object,
  config: PropTypes.object,
  manageLoginForm: PropTypes.func,
  navigateTo: PropTypes.func,
  selectedPlan: PropTypes.func,
  paymentCheckout: PropTypes.func,
  getSubscriptionForUser: PropTypes.func
};

export default connect(mapStateToProps, null)(TrialSubscriptionTemplate);
