/* eslint-disable react/prop-types */
import React from "react";
import Stepper from "react-stepper-horizontal";
import "./step-progress.m.css";

export const StepProgress = props => {
  return (
    <div styleName="wrapper">
      <Stepper
        steps={[
          // { title: "Account" },
          { title: "Payment" },
          { title: "Confirmation" }
        ]}
        size={24}
        circleFontSize={16}
        titleFontSize={15}
        circleTop={20}
        activeStep={
          props.activeStep === "account"
            ? 0
            : props.activeStep === "payment"
            ? 1
            : 2
        }
        // completeBarColor={"#5096FF"}
        activeColor={"var(--component-background-accent-solid-default)"}
        completeColor={"var(--component-background-neutral-solid-default)"}
        completeBarColor={"var(--component-background-accent-solid-default)"}
        activeTitleColor={"var(--text-neutral-default)"}
        completeTitleColor={"var(--text-neutral-muted)"}
        defaultTitleColor={"var(--text-neutral-default)"}
      />
    </div>
  );
};
