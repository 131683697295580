import React from "react";
import get from "lodash/get";
import GeneralSectionPage from "./general-seciton-page";
import NlSenaWrapper from "./nl-sena-wrapper";
import { PodcastCollection } from "../pages/podcast-collection-page";
import { PodcastPage } from "../pages/podcast-page";
import VideosSectionPage from "./videos-section-page";
import VideosSubSectionPage from "./videos-sub-section-page";
import ComicsSectioPage from "./comics-section-page";
import { NLLegalFundPage } from "./nl-legal-fund-page";
import { NLSenaDetailPage } from "./nl-sena-detail";

const SectionPage = props => {
  const sectionSummary = get(props, ["data", "collection", "summary"], "");
  const sectionUrl = get(props, ["data", "section", "section-url"], "");
  const secitonSlug = get(props, ["data", "collection", "slug"], "");
  let sectionTemplate = get(props, ["data", "collection", "template"], "");

  if (secitonSlug === "sena") {
    sectionTemplate = "sena";
  } else if (secitonSlug === "nl-legal-fund") {
    sectionTemplate = "nl-legal-fund";
  }

  if (sectionSummary && sectionUrl.includes("nl-sena")) {
    sectionTemplate = "nl-sena-subsection";
  }

  switch (sectionTemplate) {
    case "videossection":
      return <VideosSectionPage {...props} />;
    case "videossubsection":
      return <VideosSubSectionPage {...props} />;
    case "sena":
      return <NlSenaWrapper {...props} />;
    case "comicsection":
      return <ComicsSectioPage {...props} />;
    case "podcastsubsection":
      return <PodcastCollection {...props} />;
    case "podcast":
      return <PodcastPage {...props} />;
    case "nl-legal-fund":
      return <NLLegalFundPage {...props} />;
    case "nl-sena-subsection":
      return <NLSenaDetailPage props={props} id={parseInt(sectionSummary)} />;
    default:
      return <GeneralSectionPage {...props} />;
  }
};

export { SectionPage };
