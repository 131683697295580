import React, { useEffect, useState } from "react";

// import axios from "axios";
import { Button, RadioGroup, Separator } from "../../../ui/components";
import { FaTriangleExclamation } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { useSettingsStore } from "../../../context/useSettingsState";

import "./settings.m.css";

const SettingsPage = () => {
  const settingsState = useSettingsStore(
    ({
      theme,
      setTheme,
      colourFilter,
      setColourFilter,
      dyslexiaMode,
      setDyslexiaMode
    }) => ({
      themeState: theme,
      colourFilterState: colourFilter,
      setThemeState: setTheme,
      setColourFilterState: setColourFilter,
      dyslexiaModeState: dyslexiaMode,
      setDyslexiaModeState: setDyslexiaMode
    })
  );

  // console.log({ settingsState });

  const [colourTheme, setColourTheme] = useState(
    () => settingsState.themeState
  );
  const [colourFilter, setColourFilter] = useState(
    () => settingsState.colourFilterState
  );
  const [dyslexiaMode, setDyslexiaMode] = useState(
    () => settingsState.dyslexiaModeState
  );

  const member = useSelector(state => get(state, ["member"], null));

  useEffect(() => {
    console.log("%cRENDERED", "color: #bada55");

    const syncWithSystem = () => {
      switch (colourTheme) {
        case "dark":
          document.documentElement.dataset.theme = "dark";
          break;
        case "light":
          document.documentElement.dataset.theme = "light";
          break;
        default:
          document.documentElement.dataset.theme = "system";
          break;
      }

      switch (colourFilter) {
        case "deuteranopia":
          document.documentElement.dataset.filter = "deuteranopia";
          break;
        case "protanopia":
          document.documentElement.dataset.filter = "protanopia";
          break;
        case "tritanopia":
          document.documentElement.dataset.filter = "tritanopia";
          break;
        default:
          document.documentElement.dataset.filter = "none";
          break;
      }

      switch (dyslexiaMode) {
        case "enabled":
          document.documentElement.dataset.dyslexiaMode = "enabled";
          break;
        case "disabled":
          document.documentElement.dataset.dyslexiaMode = "disabled";
          break;
        default:
          document.documentElement.dataset.dyslexiaMode = "disabled";
          break;
      }
    };

    syncWithSystem();
  }, []);

  // useEffect(() => {
  //   if (member) {
  //     syncWithDatabase();
  //   }
  // }, [member]);

  // NOT TO USE
  // const syncWithSystem = () => {
  //   const darkTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

  //   if (darkTheme) {
  //     settingsState.setThemeState("dark");
  //     document.documentElement.dataset.theme = "dark";
  //   } else {
  //     settingsState.setThemeState("light");
  //     document.documentElement.dataset.theme = "light";
  //   }

  //   settingsState.setColourFilterState("none");
  // };

  // TO USE
  // const syncWithDatabase = async () => {
  //   try {
  //     const { data: fetchedProfileData } = await axios.get(
  //       `https://server.newslaundry.com/preferences/${member.email}`
  //     );

  //     console.log({ fetchedProfileData });

  //     if (fetchedProfileData.colorMode) {
  //       settingsState.setThemeState(fetchedProfileData.colorMode);
  //       document.documentElement.dataset.theme = fetchedProfileData.colorMode;
  //     } else {
  //       await axios.post(`https://server.newslaundry.com/preferences/`, {
  //         email: member.email,
  //         colorMode: settingsState.themeState
  //       });
  //     }

  //     if (fetchedProfileData.disabilityFilter) {
  //       settingsState.setColourFilterState(fetchedProfileData.disabilityFilter);
  //       document.documentElement.dataset.filter =
  //         fetchedProfileData.disabilityFilter;
  //     } else {
  //       await axios.post(`https://server.newslaundry.com/preferences/`, {
  //         email: member.email,
  //         disabilityFilter: settingsState.colourFilterState
  //       });
  //     }
  //   } catch (error) {
  //     if (error && error.response && error.response.status === 404) {
  //       const { data } = await axios.post(
  //         `https://server.newslaundry.com/preferences/`,
  //         {
  //           email: member.email,
  //           colorMode: settingsState.themeState,
  //           disabilityFilter: settingsState.colourFilterState
  //         }
  //       );

  //       console.log({ errorData: data });
  //     }
  //   }
  // };

  const handleColourThemeSubmit = async e => {
    e.preventDefault();

    settingsState.setThemeState(colourTheme);
    document.documentElement.dataset.theme = colourTheme;

    // if (!member) {
    //   settingsState.setThemeState(colourTheme);
    //   document.documentElement.dataset.theme = colourTheme;

    //   return;
    // }

    // try {
    //   settingsState.setThemeState(colourTheme);
    //   document.documentElement.dataset.theme = colourTheme;

    //   const { data: handleColourThemeSubmitData } = await axios.post(
    //     `https://server.newslaundry.com/preferences/`,
    //     {
    //       email: member.email,
    //       colorMode: colourTheme
    //     }
    //   );

    //   console.log({ handleColourThemeSubmitData });
    // } catch (error) {
    //   console.error({ handleColourThemeSubmitError: error });
    // }
  };

  const handleColourFilterSubmit = async e => {
    e.preventDefault();

    settingsState.setColourFilterState(colourFilter);
    document.documentElement.dataset.filter = colourFilter;

    // if (!member) {
    //   settingsState.setColourFilterState(colourFilter);
    //   document.documentElement.dataset.filter = colourFilter;

    //   return;
    // }

    // try {
    //   settingsState.setColourFilterState(colourFilter);
    //   document.documentElement.dataset.filter = colourFilter;

    //   const { data: handleColourFilterSubmitData } = await axios.post(
    //     `https://server.newslaundry.com/preferences/`,
    //     {
    //       email: member.email,
    //       disabilityFilter: colourFilter
    //     }
    //   );

    //   console.log({ handleColourFilterSubmitData });
    // } catch (error) {
    //   console.error({ handleColourFilterSubmitError: error });
    // }
  };

  const handleDyslexiaModeSubmit = async e => {
    e.preventDefault();

    settingsState.setDyslexiaModeState(dyslexiaMode);
    document.documentElement.dataset.dyslexiaMode = dyslexiaMode;

    // if (!member) {
    //   settingsState.setColourFilterState(colourFilter);
    //   document.documentElement.dataset.filter = colourFilter;

    //   return;
    // }

    // try {
    //   settingsState.setColourFilterState(colourFilter);
    //   document.documentElement.dataset.filter = colourFilter;

    //   const { data: handleColourFilterSubmitData } = await axios.post(
    //     `https://server.newslaundry.com/preferences/`,
    //     {
    //       email: member.email,
    //       disabilityFilter: colourFilter
    //     }
    //   );

    //   console.log({ handleColourFilterSubmitData });
    // } catch (error) {
    //   console.error({ handleColourFilterSubmitError: error });
    // }
  };

  return (
    <div styleName="settings-page-wrapper">
      <h2 className="typography-heading-styles-h2">Settings</h2>
      {/* <p
        className="typography-heading-styles-h6"
        styleName="form-section-description"
        style={{ marginTop: "-20px" }}
      >
        We have made a bunch of structural changes to make our website
        accessible. Read more about it{" "}
        <a href="https://www.newslaundry.com/2024/05/01/presenting-newsable-the-newslaundry-website-and-app-are-now-accessible">
          <u>here</u>
        </a>
        . News is a public interest product and we believe everyone should have
        access to it without any barrier. Choose from the settings below to
        alter the website experience according to your needs. For features such
        as read-aloud, podcast transcription, audio search, go directly to the
        story page and search page.
      </p> */}

      {!member ? (
        <div styleName="callout-wrapper">
          <FaTriangleExclamation aria-hidden="true" />
          <p>Please login to sync the settings below to your account</p>
        </div>
      ) : null}

      <div styleName="form-wrapper-div">
        <h2 className="typography-heading-styles-h3">Appearance Settings</h2>
        <form styleName="form-wrapper" onSubmit={handleColourThemeSubmit}>
          <h3 className="typography-heading-styles-h4">Colour theme</h3>
          <p
            className="typography-heading-styles-h6"
            styleName="form-section-description"
          >
            Choose how Newslaundry looks to you. Select a single theme, or sync
            with your system and automatically switch between dark and light
            themes.
          </p>

          <RadioGroup
            defaultValue={colourTheme}
            value={colourTheme}
            onValueChange={setColourTheme}
            aria-label="Colour theme settings"
          >
            <RadioGroup.Item value="system">Sync with system</RadioGroup.Item>
            <RadioGroup.Item value="light">Light</RadioGroup.Item>
            <RadioGroup.Item value="dark">Dark</RadioGroup.Item>
          </RadioGroup>
          <Button type="submit">Save colour theme preference</Button>
        </form>
      </div>
      <Separator />
      <div styleName="form-wrapper-div">
        <h2 className="typography-heading-styles-h3">Accessibility Settings</h2>
        <form styleName="form-wrapper" onSubmit={handleColourFilterSubmit}>
          <h3 className="typography-heading-styles-h4">Colour filters</h3>
          <p
            className="typography-heading-styles-h6"
            styleName="form-section-description"
          >
            Colour filters can be used to differentiate colours by users who are
            colour blind and aid users who have difficulty reading text
          </p>

          <RadioGroup
            defaultValue={colourFilter}
            value={colourFilter}
            onValueChange={setColourFilter}
            aria-label="Colour filter settings"
          >
            <RadioGroup.Item value="none">None</RadioGroup.Item>
            <RadioGroup.Item value="protanopia">
              Protanopia (Red/Green filter)
            </RadioGroup.Item>
            <RadioGroup.Item value="deuteranopia">
              Deuteranopia (Green/Red filter)
            </RadioGroup.Item>
            <RadioGroup.Item value="tritanopia">
              Tritanopia (Blue/Yellow filter)
            </RadioGroup.Item>
          </RadioGroup>
          <Button type="submit">Save colour filter preference</Button>
        </form>
        <form styleName="form-wrapper mt-8" onSubmit={handleDyslexiaModeSubmit}>
          <h3 className="typography-heading-styles-h4">
            Dyslexia friendly mode
          </h3>
          <p
            className="typography-heading-styles-h6"
            styleName="form-section-description"
          >
            Choose to activate Dyslexia friendly fonts on the website
          </p>

          <RadioGroup
            defaultValue={dyslexiaMode}
            value={dyslexiaMode}
            onValueChange={setDyslexiaMode}
            aria-label="Dyslexia mode settings"
          >
            <RadioGroup.Item value="disabled">Disabled</RadioGroup.Item>
            <RadioGroup.Item value="enabled">Enabled</RadioGroup.Item>
          </RadioGroup>
          <Button type="submit">Save Dyslexia mode preference</Button>
        </form>
      </div>
    </div>
  );
};

export { SettingsPage };
