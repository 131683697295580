import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import TimeAgo from "react-timeago";
import { Headline } from "../../atoms/headline";
import Authors from "../../atoms/authors";
import "./verticle-story-card.m.css";
import {
  getHeroImage,
  getStoryMetadata,
  dateFormatterWithoutTime
} from "../../../utils";
import { ResponsiveImage } from "@quintype/components";

const VerticleStoryCard = ({ story = {}, config = {} }) => {
  const heroS3Key = getHeroImage(story, config);
  const time = story["last-published-at"];
  const dateObject = new Date(time);

  function formatDateToEnglishStyle(date) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      // hour: 'numeric',
      // minute: 'numeric',
      // second: 'numeric',
      hour12: true // Use 12-hour clock format
      // timeZoneName: 'short',
    };

    return date.toLocaleString("en-US", options);
  }
  const formattedDate = formatDateToEnglishStyle(dateObject);
  return (
    <React.Fragment>
      <article styleName="story-wrapper">
        <figure className="figure">
          <ResponsiveImage
            slug={heroS3Key}
            metadata={getStoryMetadata(story)}
            aspectRatio={[265, 354]}
            defaultWidth={173}
            widths={[173, 265]}
            sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
            imgParams={{ auto: ["format", "compress"] }}
            className="responsive-img"
            alt="Article image"
          />
        </figure>
        <div styleName="text-wrapper">
          <Headline story={story} externalLink={true} />
          <div styleName="author-details">
            <Authors story={story} />
            <div
              aria-label={formattedDate ? `Published at: ${formattedDate}` : ""}
            >
              <TimeAgo
                formatter={dateFormatterWithoutTime}
                date={story["last-published-at"]}
                live={false}
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

VerticleStoryCard.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {})
});

export default connect(mapStateToProps)(VerticleStoryCard);
