// import { Link } from "@quintype/components";
import { get } from "lodash";
import "./photo-gallery.m.css";
import { parseUrl } from "query-string";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { photoArray } from "./constants";
import { SubscribePopup } from "../../molecules/popup/subscribe-popup";
import {
  saveDataToLocalStorage,
  fetchDataFromLocalStorage,
} from "../subscription-checkout/utils";

const PhotoGallery = () => {
  const member = useSelector((state) => get(state, ["member"], null));
  const fetchSubscription = useSelector((state) =>
    get(state, ["fetchSubscription"], null)
  );
  const { qt } = useSelector((state) => state);
  const authHost = qt.config.publisher.publisher.auth_host;
  const isActive = fetchSubscription && fetchSubscription.isActive;
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (linkUrl) => {
    if (!member) {
      const params = parseUrl(qt.currentPath);
      saveDataToLocalStorage(linkUrl, "redirect");
      const callbackUrl = get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      );
      const redirectUrl = get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      );
      window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
      // return;
    } else {
      if (isActive) {
        window.open(linkUrl, "_blank");
        // window.location = linkUrl;
        // return;
      } else {
        setModalOpen(true);
      }
    }
  };

  useEffect(() => {
    const url = fetchDataFromLocalStorage("redirect");
    // console.log({url, window})

    console.log({ isActive });
    if (url) {
      if (isActive) {
        setModalOpen(false);
        window.localStorage.removeItem("redirect");
        window.location = url;
      } else {
        window.localStorage.removeItem("redirect");
        setModalOpen(true);
      }
    }
  }, [isActive]);

  return (
    <>
      {modalOpen && (
        <SubscribePopup showModal={modalOpen} setShowModal={setModalOpen} />
      )}
      <div styleName="section">
        <div>
          <main styleName="hero-section">
            <div>
              <div styleName="hero-section-content">
                <h1 styleName="title">Our subscriber-only events</h1>
                <p styleName="border-bottom"></p>
                <p styleName="description">
                  We love hanging out with our subscribers! See photos
                  from film screenings, events and meet-ups. Subscribe now to
                  get full access to the gallery.
                </p>
              </div>
              <div styleName="hero-section-img">
                <img
                  src="https://images.assettype.com/newslaundry/2023-11/039fe5c3-f57d-41d4-bdc4-d17064248194/NL_store_1_copy.png"
                  alt="Student Subscription"
                />
              </div>
            </div>
          </main>
        </div>
        <div styleName="gallery-section">
          {photoArray.map((photoObj, i) => {
            return (
              <div
                styleName="photo-card"
                key={i}
                onClick={() => handleClick(photoObj.linkUrl)}
              >
                <img src={photoObj.ImgUrl} styleName="pic" />
                <div styleName="desc">
                  <p>{photoObj.text}</p>
                </div>
              </div>
            );
          })}
          {/* <div styleName="photo-card">
         <Link href={"https://www.themediarumble.com/gallery"}>
          <img
            src="https://images.assettype.com/newslaundry/2023-11/039fe5c3-f57d-41d4-bdc4-d17064248194/NL_store_1_copy.png"
            styleName="pic"
          />
          <div styleName="desc">Irure et magna officia.</div>
          </Link>
        </div> */}
        </div>
      </div>
    </>
  );
};

export { PhotoGallery };
