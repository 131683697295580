import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "@quintype/components";
import Spectrum from "../../components/Grid/Spectrum";
import "../../index.m.css";
import Axios from "axios";
import { FaCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { NotFoundPage } from "../../../not-found";
import { FaSearch } from "react-icons/fa";
import Select from "react-select";

const SpectrumResults = () => {
  const [averages, setAverages] = useState([]);
  const [activeBtn, setActivebtn] = useState("subs");
  const [originalAverages, setOriginalAverages] = useState([]);
  const [journalists, setJournalists] = useState([]);
  const [journalistData, setJournalistData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [totalVotes, setTotalVotes] = useState(null);
  const member = useSelector((state) => get(state, ["member"], null));
  const [UniqueOrgainistions, setUniqueOrgainistions] = useState([]);
  const customNoOptionsMessage = () =>
    "Oops! Journalist not found. Try others and suggest missing journalist on the voting page.";
  const fetchSubscription = useSelector((state) =>
    get(state, ["fetchSubscription"], null)
  );
  const isActive = fetchSubscription && fetchSubscription.isActive;
  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : null,
        color: isDisabled
          ? null
          : isSelected
          ? "#c00"
          : isFocused
          ? "#000"
          : null,
      };
    },
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
  };
  useEffect(() => {
    activeBtn === "team"
      ? Axios.get(
          "https://server.newslaundry.com/journalismSpectrum/averages/team"
        )
          .then((response) => {
            setAverages(response.data.nlTeamAverages);
            setOriginalAverages(response.data.nlTeamAverages);
          })
          .catch((err) => console.log(err))
      : activeBtn === "subs"
      ? Axios.get(
          // "https://server.newslaundry.com/journalismSpectrum/averages/subs"
          "https://server.newslaundry.com/journalismSpectrum/averages"
        )
          .then((response) => {
            // setAverages(response.data.nlSubsAverages);
            setAverages(response.data.allAverages);
            setOriginalAverages(response.data.allAverages);
          })
          .catch((err) => console.log(err))
      : Axios.get(
          `https://server.newslaundry.com/journalismSpectrum/submissions/user/${member.email}`
        ).then((response) => {
          setAverages(response.data.userSpectrumSubmissions);
          setOriginalAverages(response.data.userSpectrumSubmissions);
        });
  }, [activeBtn]);

  useEffect(() => {
    if (member) setActivebtn("yourVotes");
  }, [member]);

  useEffect(() => {
    const fetchJournalists = async () => {
      try {
        // console.log("jas");
        const response = await Axios.get(
          "https://server.newslaundry.com/journalismSpectrum/journalists"
        );
        //console.log({ resp: response.data });
        setJournalists(response.data.journalists);
        const UniqueOrgainistion = filterJournalistsWithSameOrganization(
          response.data.journalists
        );
        setUniqueOrgainistions(UniqueOrgainistion);
        const totalVotes = await Axios.get(
          "https://server.newslaundry.com/journalismSpectrum/submissions"
        );
        //console.log({ resp: response.data });
        setTotalVotes(totalVotes.data.spectrumSubmissions.length);

        const x = {};
        response.data.journalists.forEach((j) => {
          x[j.id] = {
            name: j.name,
            organization: j.organization,
          };
        });
        setJournalistData(x);
      } catch (error) {
        console.error({ fetchJournalistsError: error });
      }
    };

    fetchJournalists();
  }, []);

  function filterJournalistsWithSameOrganization(journalistsArray) {
    const uniqueOrganizations = new Map();
    for (const journalist of journalistsArray) {
      if (!uniqueOrganizations.has(journalist.organization)) {
        uniqueOrganizations.set(journalist.organization, journalist);
      }
    }
    return Array.from(uniqueOrganizations.values());
  }

  const options = journalists
    .map((journalist) => ({
      value: journalist.id,
      label: journalist.name,
      type: "name",
    }))
    .concat(
      UniqueOrgainistions.map((journalist) => ({
        value: journalist.organization,
        label: journalist.organization,
        type: "organisation",
      }))
    );
  options.unshift({ value: "dlknmelkd", label: "All", type: "name" });
  function isObjectEqual(obj1, obj2) {
    return obj1.organization === obj2.organization && obj1.name === obj2.name;
  }
  const filteredOptions = options.filter(
    (currentObj, index, array) =>
      array.findIndex((obj) => isObjectEqual(obj, currentObj)) != index
  );

  const filterAverages = (search) => {
    const filteredAverages = originalAverages.filter((average) => {
      const journalistInfo = journalistData[average.journalistId];

      if (journalistInfo) {
        return (
          journalistInfo.name.toLowerCase().includes(search.toLowerCase()) ||
          (journalistInfo.organization &&
            journalistInfo.organization
              .toLowerCase()
              .includes(search.toLowerCase()))
        );
      } else {
        return false;
      }

      // if (activeBtn === "subs") {
      //   return (
      //     journalistInfo &&
      //     (journalistInfo.name
      //       .toLowerCase()
      //       .includes(searchTerm.toLowerCase()) ||
      //       (journalistInfo.organization &&
      //         journalistInfo.organization
      //           .toLowerCase()
      //           .includes(searchTerm.toLowerCase())))
      //   );
      // } else if (activeBtn === "yourVotes") {
      //   return (
      //     journalistInfo &&
      //     (journalistInfo.name
      //       .toLowerCase()
      //       .includes(searchTerm.toLowerCase()) ||
      //       (journalistInfo.organization &&
      //         journalistInfo.organization
      //           .toLowerCase()
      //           .includes(searchTerm.toLowerCase())))
      //   );
      // }
      // return false;
    });

    if (search.length === 0) {
      setAverages(originalAverages);
    } else {
      setAverages(filteredAverages);
    }
  };

  const handleSelectChange = (selectedOption) => {
    setSearchTerm(selectedOption ? selectedOption.label : "");
    if (selectedOption.label === "All") {
      setAverages(originalAverages);
      filterAverages("");
    } else {
      filterAverages(selectedOption ? selectedOption.label : "");
    }
    const section = document.querySelector("#spectrum-grid");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleInputChange = (e) => {
    //console.log({ e: e.target.value });
    setSearchTerm(e.target.value);
    filterAverages(e.target.value);
  };

  return (
    <>
      {!(member && isActive) ? (
        <NotFoundPage />
      ) : (
        <>
          <br />
          <div styleName="section hideScbar">
            <h3>
              Where does your favorite journalist lie on the Media Biascope?
            </h3>
            <p>
              Chart based on {totalVotes && <b>{`${totalVotes}`}</b>} votes from
              our subscribers. Use the toggle button below to select the desired
              audience.{" "}
            </p>
            <br />
            <p styleName="scroll-helper">
              {" "}
              Slide horizontally to view the full Biascope
            </p>
            <div styleName="tabberWrapper">
              <div styleName="chartTabber">
                <button
                  styleName={activeBtn === "subs" ? "active" : ""}
                  onClick={() => {
                    setActivebtn("subs");
                    filterAverages(searchTerm);
                  }}
                  id="subs"
                >
                  NL Subscribers
                </button>
                <button
                  styleName={activeBtn === "yourVotes" ? "active" : ""}
                  onClick={() => {
                    setActivebtn("yourVotes");
                    filterAverages(searchTerm);
                  }}
                  id="subs"
                >
                  My Votes
                </button>
              </div>
            </div>
            {/* <div styleName="search" style={{ marginTop: "40px" }}>
              <FaSearch styleName="search-icon" />
              <input
                type="text"
                placeholder="Search by journalist or organisation name"
                onChange={handleInputChange}
              />
              <i className="fa fa-search fs-2"></i>
            </div> */}
            <div styleName="list-wrapper">
              <Select
                styleName="custom-select"
                className="basic-single"
                classNamePrefix="select"
                options={options}
                styles={colourStyles}
                value={options.find((option) => option.label === searchTerm)}
                onChange={handleSelectChange}
                noOptionsMessage={customNoOptionsMessage}
                placeholder="Search by journalist name or organistion"
              />
            </div>
            <span id="spectrum-grid"></span>
            <div
              styleName="container"
              style={{ position: "relative", marginLeft: "-5vw" }}
            >
              {!averages.length && (
                <p styleName="error-msg">No votes yet for {`${searchTerm}`}</p>
              )}
              <Spectrum selectedJournalist={null} avgJournalists={averages} />
            </div>
          </div>
          <div styleName="align-center">
            <Link styleName="ctav" href="/voting">
              ⬅ Vote for another journalist
            </Link>
          </div>
          <br />
        </>
      )}
    </>
  );
};

export { SpectrumResults };
