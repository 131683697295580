import React from "react";
import PropTypes from "prop-types";
import { CollectionName } from "../../atoms/collection-name";
import HorizontalStoryCard from "../../molecules/horizontal-story-card";
import OnecolFourStories from "../../molecules/one-col-four-stories";
import getStoriesByCollection from "../../../utils";
import CommonStoryCard from "../../molecules/common-story-card";
import "./twocollections-with-seven-stories.m.css";

const TwocollectionsWithSevenStories = ({ collection = {} }) => {
  const collections = collection.items.filter(collection => collection.type === "collection");

  const firstCollectionStories = getStoriesByCollection(collections[0]) || [];
  const secondCollectionStories = getStoriesByCollection(collections[1]) || [];
  if (collections.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <div styleName="grid-container">
        {collections[0] && <div className="horizontal-cards">
          <div styleName="headline">
            <CollectionName collection={collections[0]} />
          </div>
          <div className="desktop-only">
            {firstCollectionStories.slice(1, 4).map(story => (
              <HorizontalStoryCard story={story} key={story.id} boxPadding={false} descriptionPadding />
            ))}
          </div>
          <div className="mobile-only">
            {firstCollectionStories.slice(1, 4).map(story => (
              <CommonStoryCard story={story} key={story.id} />
            ))}
          </div>
        </div>}
        {collections[1] && <div className="verticle-card">
          <div styleName="headline">
            <CollectionName collection={collections[1]} />
          </div>
          <OnecolFourStories stories={secondCollectionStories} />
        </div>}
      </div>
    </React.Fragment>
  );
};


TwocollectionsWithSevenStories.propTypes = {
  collection: PropTypes.object,
  stories: PropTypes.object
};

export default TwocollectionsWithSevenStories;
