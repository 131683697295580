import React, { useEffect, useState } from "react";
import Spectrum from "./Spectrum";
import Axios from "axios";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import PropTypes from "prop-types";
import "../../index.m.css";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { FaTimes } from "react-icons/fa";

export default function JournalismSpectrumEdit(props) {
  const [journalists, setJournalists] = useState([]);
  const [initialOption, setInitialOption] = useState({});
  const [warntog, setWarntog] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    Axios.get(
      "https://server.newslaundry.com/journalismSpectrum/journalists"
    ).then((response) => {
      setJournalists(response.data.journalists);
      setInitialOption({
        image: response.data.journalists[0].imageURL,
        value: response.data.journalists[0].name,
      });
    });
  }, []);

  useEffect(() => {
    Axios.get(
      "https://server.newslaundry.com/journalismSpectrum/submissions/"
    ).then((response) => {
      //console.log(response.data);
    });
  }, []);

  //const member = "rohit@newslaundry.com";

  const [options, setOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [isSelectClicked, setIsSelectClicked] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    image: "",
    value: "",
  });
  const [currentJournoIndex, setCurrentJournoIndex] = useState({
    cellWeightX: 4,
    cellWeightY: 3,
    index: 15,
  });
  const member = useSelector((state) => get(state, ["member"], null));
  const state = useSelector((state) => state);
  const fetchSubscription = useSelector((state) =>
    get(state, ["fetchSubscription"], null)
  );
  const isActive = fetchSubscription && fetchSubscription.isActive;
  const warn = {
    color: "#ed1b24",
    background: "rgb(255 202 202)",
    padding: "20px",
    borderRadius: "5px",
    display: isSelectClicked
      ? selectedOption.image
        ? "none"
        : "block"
      : "none",
    textAlign: "center",
  };
  useEffect(() => {
    if (journalists.length) {
      setOptions(
        journalists.map((journo) => ({
          value: journo.name,
          label: journo.name,
          image: journo.imageURL,
          journalistId: journo.id,
        }))
      );
    }
  }, [journalists]);
  const resetCurrentJournoIndex = () => {
    setCurrentJournoIndex({
      cellWeightX: 4,
      cellWeightY: 3,
      index: 0,
    });
  };
  const clearCurrentJournalistSelection = () => {
    setSelectedOption({ image: "", value: "" });
  };
  // Handle journalist vote submission
  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (!member) {
      // If the user has not logged in, redirect him to the login/signup page
    }
    if (!selectedOption) {
      console.error("No Journalist selected");
      setIsSubmitting(false);
      return;
    }
    try {
      const submission = "submit data via POST request";
      //console.log({ submission });
      // const arr = {
      //   index: currentJournoIndex.index,
      //   id: "clfb3v8gr0001xueth4pss16l666",
      //   journalistId: selectedOption.journalistId,
      //   isNLTeam: true,
      //   userEmail: member,
      //   userId: 227569,
      //   cellWeightX: currentJournoIndex.cellWeightX,
      //   cellWeightY: currentJournoIndex.cellWeightY
      // };

      const arr = {
        journalistId: selectedOption.journalistId,
        userEmail: member.email,
        userId: member.id,
        cellWeightX: currentJournoIndex.cellWeightX,
        cellWeightY: currentJournoIndex.cellWeightY,
      };
      //console.log({ arr });
      Axios.post(
        "https://server.newslaundry.com/journalismSpectrum/submissions/",
        arr
      )
        .then((response) => {
          //console.log(response);
        })
        .catch((error) => {
          // console.log(error);
        });
      setIsSubmitting(false);
      // Navigate to confirm page
      props.onClick(2);
      return submission;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <section styleName="main-section">
        {/* {isModalOpen && <>
      <div styleName="view-all-modal-div">
        <div styleName="modal-contents">
          <div styleName="modal-content-title-row">
            <button styleName="close-btn" onClick={() => {
              //console.log("cl")
              setIsModalOpen(false)}}>
              <FaTimes />
            </button>
          </div>
          <div styleName="modal-contents-wrapper">
            <h4>From the dropdown, choose the journalist you want to vote for, drag its tile and place on the grid where you think they fall on the spectrum. </h4>
          </div>
        </div>
      </div>
      <div styleName="view-all-modal-outline"></div>
      </>} */}

        <h4 style={warn}>Please Choose One!</h4>
        <h1 styleName="title">Journalism Spectrum Edit</h1>
        <p styleName="description">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea eaque et
          nobis delectus, aut quos, dolorum adipisci maxime expedita laborum
          obcaecati sequi est alias vel sapiente modi quas nostrum. Ab.
        </p>
      </section>
      <section styleName="sub-section">
        <div styleName="choose-sub-section">
          <p>
            Choose a journalist and place on the grid where you think they fall
            on the spectrum:
          </p>
          <div styleName="select-menu">
            {selectedOption.image ? (
              <div
                styleName="select-menu-selection"
                onClick={() => setIsSelectOpen((state) => !state)}
              >
                <img
                  src={selectedOption.image}
                  alt={selectedOption.value}
                  styleName="select-menu-selection-image"
                  loading="eager"
                />
                <p>{selectedOption.value}</p>
                {isSelectOpen ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            ) : (
              <div
                styleName="select-menu-selection"
                onClick={() => setIsSelectOpen((state) => !state)}
              >
                <p styleName="placeholder">Choose a journalist...</p>
                {isSelectOpen ? <FaChevronUp /> : <FaChevronDown />}
              </div>
            )}
            {isSelectOpen && (
              <div styleName="select-menu-options">
                {options.map((option, i) => {
                  return (
                    <div
                      key={i}
                      styleName="select-menu-option"
                      onClick={() => {
                        setSelectedOption(option);
                        setIsSelectOpen(false);
                        setIsSelectClicked(true);
                      }}
                    >
                      <img
                        src={option.image}
                        alt={option.value}
                        styleName="select-menu-options-image"
                        loading="eager"
                      />
                      <p>{option.value}</p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </section>

      <section styleName="sub-section">
        {isSelectClicked && (
          <p
            styleName="description"
            style={{ color: "var(--nl-gray2)", fontWeight: "bold" }}
          >
            DRAG and DROP the following tile inside the grid below where you
            think they fall on the spectrum
          </p>
        )}
        <br />
        <Spectrum
          selectedJournalist={
            selectedOption.image ? selectedOption : initialOption
          }
          clearCurrentJournalistSelection={clearCurrentJournalistSelection}
          currentJournoIndex={currentJournoIndex}
          setCurrentJournoIndex={setCurrentJournoIndex}
          resetCurrentJournoIndex={resetCurrentJournoIndex}
        />
        <div styleName="btn-wrapper ">
          {isSelectClicked && selectedOption.image && (
            <button
              styleName={`try-btn ctav ${isSubmitting ? "btn-disabled" : ""}`}
              onClick={handleSubmit}
            >
              {isSubmitting ? "Please wait..." : "Submit"}
            </button>
          )}

          {/* <button onClick={(val) => props.onClick(2)} className="ctav">
            Proceed to vote <i className="fa fa-arrow-right"></i>
          </button> */}
        </div>
      </section>
      <section styleName="sub-section">
        <h2 styleName="sub-title">Journalism spectrum project - Legend</h2>
        <div styleName="legend-sub-section">
          <div styleName="legend-div">
            <h4>Social Spectrum Scale</h4>
            <p>
              <b>Totalitarian:</b> Proponents of giving unlimited power to the
              state to control virtually all aspects of public and private life
              such as the attitudes, morals, and beliefs of the people.
            </p>
            <p>
              <b>Authoritarian:</b> Authoritarians characterize an ideal society
              as one governed by a strong central government that allows people
              a limited degree of political freedom. However, the political
              process, as well as all individual freedom, is controlled by the
              government without any constitutional accountability.
            </p>
            <p>
              <b>Statist:</b> Statism is the doctrine that the political
              authority of the state is legitimate to a certain degree, and
              hence the government negotiably owns and controls most of a
              country’s affairs.
            </p>
            <p>
              <b>Liberalism:</b> Liberals view the society as one governed
              largely by individual choices with minimal government
              intervention.
            </p>
            <p>
              <b>Libertarianism:</b> Libertarians aspire for society to be one
              governed by individuals who are free to make their economic and
              social decisions, without any government intervention.
            </p>
            <p>
              <b>Anarchism:</b> Anarchists are skeptical of authority and see
              the society as one where the government is unnecessary and
              unrequired. Hence, they strive for the abolition of the government
              and control of the society by the society at large without any
              hierarchy.
            </p>
          </div>
          <div styleName="legend-div">
            <h4>Political Scale</h4>
            <p>
              <b>Communism:</b> Communism is a social and political ideology
              that strives to create a classless society in which all property
              and wealth are communally-owned by the society at large, instead
              of by individuals.
            </p>
            <p>
              <b>Socialism:</b> Socialists view an ideal society as one where
              even though the government owns the means of production, people
              are compensated based on their level of individual contribution to
              the economy.
            </p>
            <p>
              <b>Welfarism:</b> Welfarists view an ideal society as one where
              the government is just committed to ensuring that basic economic
              security is provided to its citizens.
            </p>
            <p>
              <b>Regulationist:</b> Regulationists view an ideal society as one
              where private individuals are allowed to own property and wealth
              but with restrictions and regulation.
            </p>
            <p>
              <b>Capitalist:</b> Capitalists view an ideal society as one where
              the means of production are owned by private individuals and they
              operate for profit with minimal government intervention.
            </p>
            <p>
              <b>Laissez Faire:</b> Proponents of Laissez-faire view the society
              as one where transactions between private groups of people are
              free from any form of economic interventionism by the government
              or authorities.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

JournalismSpectrumEdit.propTypes = {
  onClick: PropTypes.any,
};
