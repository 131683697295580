import React, { useEffect, useState } from "react";
import { object, shape } from "prop-types";
import { useSelector } from "react-redux";
import { getCollectionTemplate } from "../get-collection-template";
import { SubscriptionAppealBanner } from "../subscription-appeal-banner";
import { NewsableBottomSheet } from "../newsable-bottom-sheet";
import { useScrollPosition } from "../helper/hooks";
import {
  LazyCollection,
  WithPreview,
  replaceAllStoriesInCollection,
  LazyLoadImages
} from "@quintype/components";

export const HomePage = props => {
  const [toShowPopup, setToShowPopup] = useState("initial");
  const [toShowNewsableSheet, setToShowNewsableSheet] = useState(true);
  const scrollPosition = useScrollPosition();

  const fetchSubscription = useSelector(state => state.fetchSubscription);
  const member = useSelector(state => state.member);
  const isActive = fetchSubscription && fetchSubscription.isActive;

  // console.log({ isActive, member });

  useEffect(() => {
    if (scrollPosition > 1000 && toShowPopup === "initial") {
      setToShowPopup("shown");
    }
  }, [scrollPosition]);

  return (
    <div>
      <LazyLoadImages>
        <LazyCollection
          collection={props.data.collection}
          collectionTemplates={getCollectionTemplate}
          lazyAfter={2}
        />
        {toShowPopup === "shown" && !isActive && (
          <SubscriptionAppealBanner setToShowPopup={setToShowPopup} />
        )}
        {/* {toShowNewsableSheet ? } */}
        {/* {toShowPopup === "shown" && (
          <NewsableBottomSheet setToShowPopup={setToShowPopup} />
        )} */}

      </LazyLoadImages>
    </div>
  );
};

HomePage.propTypes = {
  data: shape({
    collection: object
  })
};

export const HomePagePreview = WithPreview(HomePage, (data, story) =>
  Object.assign({}, data, {
    collection: replaceAllStoriesInCollection(data.collection, story)
  })
);
