import React from "react";

import "./art-auction.m.css";

const ArtAuctionPage = () => {
  const handleBuyNow = () => {
    window.open(
      `https://razorpay.com/payment-button/pl_Irp87HQoevdRBv/view`,
      "_blank"
    );
  };

  return (
    <section styleName="section">
      <main styleName="hero-section">
        <div>
          <div styleName="hero-section-content">
            <h1 styleName="title">#NLat10 Art Auction</h1>
            <p styleName="border-bottom"></p>
            <p styleName="description">
              Newslaundry turns 10 years old on February 7, 2022! We’ve come a
              long way, delivering ad-free news despite being a lean team.
            </p>
            <p styleName="description">
              To celebrate this journey we have commissioned two limited-edition
              pieces of art by Manjul and Anish Daolagupu that showcase the team
              – both past and present – behind our work.
            </p>
          </div>
          <div styleName="hero-section-img">
            <img
              src="https://images.assettype.com/newslaundry/2022-02/86f3367a-61bc-4175-bbc4-668b789cccbc/NL_auction.jpg"
              alt="NL Art Auction"
            />
          </div>
        </div>
      </main>
      <div styleName="sub-section">
        <div styleName="sub-section-contents">
          <p>
            Since our subscribers have been our backbone since our inception, we
            would like to invite you to participate in this art auction. We will
            be auctioning the one copy each of the full-size artworks privately
            through Zoom. You can take a look at them below.
          </p>
          <div styleName="items-sub-section">
            <h3 styleName="items-heading">A sneak peek at the artworks</h3>
            <div styleName="item-option-grid">
              <div styleName="item-option">
                <img
                  loading="lazy"
                  src="https://images.assettype.com/newslaundry/2022-02/1ed5b19b-4891-4373-aaba-c82b7ba4ce20/collage_NFT_smaller_blurred.jpg"
                  alt=""
                  style={{ aspectRatio: "16/9" }}
                />
              </div>
              <div styleName="item-option">
                <img
                  loading="lazy"
                  src="https://images.assettype.com/newslaundry/2022-02/6dcf4b8b-a359-41aa-8618-d3b68bbb4a8b/NewslaundaryAtTenColourfinal.png"
                  alt=""
                  style={{ aspectRatio: "16/9" }}
                />
              </div>
            </div>
          </div>
          <h3 styleName="terms-heading">The Auction:</h3>
          <p>
            Only two copies each of both artworks will exist - one set with
            Newslaundry and the copies with the people winning the auction.
          </p>
          <p styleName="bold">
            To be eligible for the auction, you will have to pay Rs 1,500 by
            pressing the button below.
          </p>
          <button styleName="pay-btn" onClick={handleBuyNow}>
            Pay Now
          </button>
          <h3 styleName="terms-heading">Auction Rules:</h3>
          <ul>
            {/* <li styleName="bold">
              Registration to participate in the auction closes at midnight on
              February 11.
            </li> */}
            <li styleName="bold">
              The minimum bid for each of the full-sized artworks will be Rs
              20,000 each.
            </li>
            <li>
              All those who register for the auction will receive a Zoom link
              with the steps to participate in the auction on 12th February. In
              case of any queries please reach out to us at
              subscription@newslaundry.com with auction as the subject line.
            </li>
            <li>
              Once the auction is finalised, the winner must transfer the amount
              to us via a payment link.
            </li>
            <li>
              The winner will receive a printed copy of the artwork within 2
              weeks of the receipt of the payment. Deliveries will be in India
              only. So please provide an Indian address for the same.
            </li>
            <li>
              The next 9 people in the bid will have the option to get a smaller
              8X10 sized version of the artwork they bid for by paying INR 3000
            </li>
            <li>Only artwork without the frame will be delivered.</li>
            <li>
              If your bid is unsuccessful, we will extend your subscription for
              the said amount, or you can give a six-month Disruptor
              subscription to someone of your choice.
            </li>
          </ul>
          <p>Looking forward to seeing you on February 13 at 8.30 pm IST.</p>
        </div>
      </div>
    </section>
  );
};

export { ArtAuctionPage };
