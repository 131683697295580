/* eslint-disable max-len */
export const paymentFaqData = [
  {
    id: "001",
    q: "I was charged Rs.5 while trying to renew my subscription. Why?",
    a:
      "As per RBI guidelines, one has to set up a mandate to initiate a recurring payment via UPI. If you already have an active subscription, your subscription will kick in after your current subscription expires. The subscription payment will be processed as soon as your new subscription starts. In the meantime, the Rs. 5 charge is used to set up a mandate. Upon successful set up of mandate, Rs. 5 charge is refunded automatically within 12 hours."
  },
  {
    id: "002",
    q: "How do I contribute via PayPal for NL Sena/NL Legal Fund?",
    a:
      "After selecting the contribution amount, choose the second option, “Netbanking, UPI, Wallets or Paypal” on the payment checkout page."
  }
  // {
  //   id: "003",
  //   q: "How do I sign up for a recurring (auto-pay) subscription?",
  //   a:
  //     "Step 1: Go to the subscription page, select the plan of your choice, hit the ‘subscribe’ button. \nStep 2: Select the first payment method. \nPlease note that while all credit cards support recurring payments, only a few select Indian debit cards support recurring payments: ICICI Bank India, Citibank India, Canara Bank India and, Kotak Mahindra Bank. If you have a credit card, we suggest you use a credit card instead of a debit card. In case you choose the first payment option and use a debit card that doesn’t support a recurring payment, your subscription is treated as a one time subscription. \nWe accept all major international credit and debit cards. If you’re based overseas and want to have a recurring subscription in USD, you can use PayPal too."
  // }
];
