import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { AccessType } from "@quintype/components";
import TrialSubscriptionTemplate from "./subscription-template";
import { connect } from "react-redux";
import {
  IS_OPEN_LOGIN_FORM,
  SELECTED_PLAN,
  PAYMENT_CHECKOUT_PAGE
} from "../../../helper/actions";
import IntegrationData from "../../../../../../config/integrations.js";

import { SubscriptionTestimonial } from "../subscription-testimonial";
import { SubscriptionHelper } from "../subscription-helpers";
import { SubscriptionCollection } from "../subscription-collection";
import { SubscriptionBankDetails } from "../subscription-bank-details";
import { isDesktopSizeViewport } from "../../../../utils";
import "./trial-subscription.m.css";

function SubscriptionBase(props) {
  const integrationsConfig = IntegrationData();
  const email = get(props, ["member", "email"], "");
  const phone = get(props, ["member", "metadata", "phone"], "");
  const isStaging = get(
    props.config,
    ["publisher", "publisher", "accessTypeEnvironment"],
    true
  );
  const enableAccesstype = get(
    props.config,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  return (
    <div>
      <AccessType
        enableAccesstype={enableAccesstype}
        isStaging={false}
        accessTypeKey={accessTypeKey}
        email={email}
        phone={phone}
        accessTypeBkIntegrationId={accessTypeBkIntegrationId}
      >
        {({ initAccessType, getSubscriptionForUser, accessIsLoading }) => {
          return (
            <TrialSubscriptionTemplate
              initAccessType={initAccessType}
              accessIsLoading={accessIsLoading}
              getSubscriptionForUser={getSubscriptionForUser}
              {...props}
            />
          );
        }}
      </AccessType>
    </div>
  );
}

SubscriptionBase.propTypes = {
  config: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function(payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url),
  selectedPlan: function(plan, redirectUrl, plans) {
    dispatch({
      type: SELECTED_PLAN,
      plan: plan,
      planType: "standard",
      redirectUrl: redirectUrl,
      subscriptionPlans: plans
    });
  },
  paymentCheckout: function(payload) {
    dispatch({
      type: PAYMENT_CHECKOUT_PAGE,
      payload: payload
    });
  }
});

const mapStateToProps = state => {
  const trialgroup1 = get(state, [
    "qt",
    "config",
    "publisher",
    "publisher",
    "trialSubscription",
    "trialGroup1"
  ]);
  const trialgroup2 = get(state, [
    "qt",
    "config",
    "publisher",
    "publisher",
    "trialSubscription",
    "trialGroup2"
  ]);
  const allSubscriptions = get(state, ["subscriptions"], []);
  let subscriptions = allSubscriptions
    .filter(item =>
      [trialgroup1.groupId, trialgroup2.groupId].includes(item.id)
    )
    .reverse();

  subscriptions = subscriptions.map(item => ({
    ...item,
    subscription_plans: item.subscription_plans.filter(i =>
      [trialgroup1.planId, trialgroup2.planId].includes(i.id)
    )
  }));

  return {
    config: get(state, ["qt", "config"], {}),
    trialgroup1,
    trialgroup2,
    subscriptions,
    member: get(state, ["member"], null),
    checkoutActiveTab: get(state, ["paymentCheckout"], "subscription")
  };
};

const TrialSubscriptionBase = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionBase);

export const TrialSubscription = props => {
  const [deviceWidth, deviceWidthHandler] = useState("");
  useEffect(() => {
    deviceWidthHandler(!isDesktopSizeViewport() ? "full-width" : "");
  }, []);

  const image = get(props.config, ["publisher", "publisher", "image"], "");
  const text = get(props.config, ["publisher", "publisher", "text"], "");
  const secondText = get(
    props.config,
    ["publisher", "publisher", "secondText"],
    ""
  );
  const textInRed = get(
    props.config,
    ["publisher", "publisher", "textInRed"],
    ""
  );
  return (
    <div>
      <TrialSubscriptionBase />
      <SubscriptionBankDetails />
      <SubscriptionTestimonial {...props} />
      <div styleName="content" className="full-width-with-padding">
        <div styleName="video" className={`${deviceWidth}`}>
          <iframe
            width="670"
            height="376"
            src={image}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div styleName="video-text">
          <p styleName="text-one">{text}</p>
          {textInRed && <p styleName="text-one red">{textInRed}</p>}
          {secondText && <p styleName="text-two"> {secondText}</p>}
        </div>
      </div>
      <SubscriptionHelper {...props} />
      <SubscriptionCollection {...props} />
    </div>
  );
};

TrialSubscription.propTypes = {
  config: PropTypes.object
};
