import React from "react";
import PT from "prop-types";

import "./account-modal.m.css";

export class UserAccountModal extends React.Component {
  render() {
    return (
      <div className="modal-wrapper">
        <div className="modal-content">{this.props.children}</div>
      </div>
    );
  }
}

UserAccountModal.propTypes = {
  onBackdropClick: PT.func,
  children: PT.object,
  hideCloseIcon: PT.bool
};
