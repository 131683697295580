/* eslint-disable react/prop-types */
import { Link } from "@quintype/components";
import { differenceInDays } from "date-fns";
import get from "lodash/get";
import PT from "prop-types";
import { parseUrl } from "query-string";
import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import {
  IS_OPEN_LOGIN_FORM,
  PAYMENT_CHECKOUT_PAGE,
  SELECTED_PLAN
} from "../../helper/actions";
import { VideoModal } from "../video-modal";

import "./project-card.m.css";

class ProjectCardBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscriptions: {},
      showModal: false,
      isHindi: false
    };
    this.handleSubscriptionClick = this.handleSubscriptionClick.bind(this);
  }

  pushToDataLayer = (name, subscription) => {
    const obj = {
      event: "subscription_addToCart",
      subscription_category: "contribution_nl_sena",
      subscription_plan: name,
      price: +subscription.price_cents / 100,
      usertype: this.props.member ? "logged in" : "logged out",
      subscribertype: this.props.fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: this.props.member ? this.props.member.id : "null",
      subscriptionID: this.props.fetchSubscription.isActive
        ? this.props.fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  componentDidMount() {
    if (window) {
      this.setState({
        isHindi: !!(global.location && global.location.host.includes("hindi"))
      });
    }
  }

  handleSubscriptionClick(e, subscriptionGroup) {
    e.preventDefault();
    e.stopPropagation();

    const allPlans = get(subscriptionGroup, ["subscription_plans"], []);
    // const normalPlans = allPlans.filter(plan => plan.duration_unit !== "days");

    // const firstPlan = get(subscriptionGroup, ["subscription_plans", 0]);
    const firstPlan = allPlans[0];
    const selectedSubscriptionPlan = get(
      this.state,
      ["selectedSubscriptions", subscriptionGroup.id],
      firstPlan
    );

    this.pushToDataLayer(
      this.props.subscriptions.name,
      selectedSubscriptionPlan
    );

    if (!this.props.member) {
      // this.props.manageLoginForm(true);
      const params = parseUrl(this.props.currentPath);
      const callbackUrl = get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      );
      const redirectUrl = get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      );
      window.location = `${this.props.authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${this.props.authHost}/user-login`;
      return false;
    }

    this.props.selectedPlan(selectedSubscriptionPlan, this.props.redirectUrl);
    // this.props.navigateTo(`/payment-checkout?ref=sena-home`);
    this.props.navigateTo(
      `/campaign/checkout?plan=${selectedSubscriptionPlan.id}&group=${selectedSubscriptionPlan.subscription_group_id}&sena=true&ref=sena-home`
    );
    return true;
  }

  handlePlanChange(e, subscriptionGroupId) {
    const currentPlan = JSON.parse(e.value);
    const currentState = Object.assign({}, this.state.selectedSubscriptions, {
      [subscriptionGroupId]: currentPlan
    });
    this.setState({ selectedSubscriptions: currentState });
  }

  render() {
    const { showBottom = true, subscriptions } = this.props;

    // console.log({ subscriptions });

    if (!subscriptions) {
      return null;
    }

    const currency = get(subscriptions, ["currency"], "");
    const currencySymbol = currency === "INR" ? "₹" : "$";
    const targetAmount = get(subscriptions, ["target_amount"], 0) / 100;
    const completedAmount = get(subscriptions, ["collected_amount"], 0);
    const barWidth =
      completedAmount > targetAmount
        ? 100
        : Math.floor((completedAmount / targetAmount) * 100);
    const plans = get(subscriptions, ["subscription_plans"], []);

    // FIX
    const options = plans.map(plan => {
      const currency = plan.price_currency;
      const currencySymbol = currency === "INR" ? "₹" : "$";
      const label =
        plan.title + " - " + currencySymbol + plan.price_cents / 100;
      return { value: `${JSON.stringify(plan)}`, label: label };
    });

    // const colourStyles = {
    //   option: (styles, { isDisabled, isFocused, isSelected }) => {
    //     return {
    //       ...styles,
    //       backgroundColor: isDisabled
    //         ? null
    //         : isSelected
    //         ? "#fff"
    //         : isFocused
    //         ? "#fff"
    //         : null,
    //       color: isDisabled
    //         ? null
    //         : isSelected
    //         ? "#c00"
    //         : isFocused
    //         ? "#000"
    //         : null
    //     };
    //   }
    // };

    const {
      imageUrl,
      summary,
      storySlug,
      videoId,
      hindiSummary,
      hindiTitle,
      endDate
    } = subscriptions.description;

    let differenceDate = null;

    if (endDate) {
      differenceDate = differenceInDays(
        new Date(endDate).toDateString(),
        new Date().toDateString()
      );
    }

    const subscriptionGroupId = get(subscriptions, ["id"]);
    return (
      <div styleName="card-container">
        {this.state.showModal && (
          <VideoModal
            videoId={videoId}
            handleClose={() => this.setState({ showModal: false })}
          />
        )}
        <div className="figure">
          {imageUrl ? (
            <React.Fragment>
              {videoId && (
                <div
                  styleName="play"
                  onClick={() => this.setState({ showModal: true })}
                  aria-label="Click to play"
                >
                  <i className="material-icons-outlined" aria-hidden="true">play_circle</i>
                </div>
              )}
              <img src={imageUrl} alt={subscriptions.name} width="10" />
            </React.Fragment>
          ) : null}
        </div>
        {videoId === "zlO_WyiQhyk" ? (
          <div styleName="text-wrapper">
            {true ? (
              this.state.isHindi ? (
                <Link href="/electionfund">
                  <h2 styleName="title">{hindiTitle}</h2>
                </Link>
              ) : (
                <Link href="/electionfund">
                  <h2 styleName="title">{get(subscriptions, ["name"], "")}</h2>
                </Link>
              )
            ) : this.state.isHindi ? (
              <h2 styleName="title">{hindiTitle}</h2>
            ) : (
              <h2 styleName="title">{get(subscriptions, ["name"], "")}</h2>
            )}
            {this.state.isHindi ? (
              hindiSummary ? (
                <div
                  dangerouslySetInnerHTML={{ __html: hindiSummary }}
                  styleName="description show-scroll"
                  style={{ marginBottom: !showBottom ? "50px" : "" }}
                >
                  {/* {summary} */}
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: summary }}
                  styleName="description show-scroll"
                  style={{ marginBottom: !showBottom ? "50px" : "" }}
                >
                  {/* {summary} */}
                </div>
              )
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: summary }}
                styleName="description show-scroll"
                style={{ marginBottom: !showBottom ? "50px" : "" }}
              >
                {/* {summary} */}
              </div>
            )}
            {true && (
              <div styleName="read-more">
                <Link href="/electionfund">Learn more</Link>
              </div>
            )}
            <div styleName="spacer"></div>
            <div styleName="progress-wrapper">
              <div
                styleName="progress-bar"
                style={{
                  width: barWidth + "%",
                  backgroundColor: !showBottom ? "#1ad193" : ""
                }}
              ></div>
            </div>
            {showBottom && (
              <>
                <div styleName="amount-container">
                  <div styleName="amount-wrapper">
                    <div styleName="amount">
                      {currencySymbol + completedAmount}
                    </div>
                    <div styleName="goal">Raised so far</div>
                  </div>
                  <div styleName="amount-wrapper">
                    <div styleName="amount select">
                      {currencySymbol + targetAmount}
                    </div>
                    <div styleName="goal">Goal</div>
                  </div>
                </div>
                {differenceDate === 0 ? (
                  <div styleName="countdown-wrapper">
                    The campaign ends today
                  </div>
                ) : differenceDate > 0 ? (
                  <div styleName="countdown-wrapper">
                    {differenceDate} days left
                  </div>
                ) : null}
                {options && options.length > 0 && (
                  <div styleName="list-wrapper">
                    <label htmlFor="select" style={{"display":"none"}}>Select the appropriate level</label>
                    <Select
                      options={options}
                      onChange={e =>
                        this.handlePlanChange(e, subscriptionGroupId)
                      }
                      defaultValue={options[0]}
                      className="basic-single"
                      classNamePrefix="select"
                      id="select"
                      // styles={colourStyles}
                      isSearchable={false}
                      inputProps={{"aria-label":"select the appropriate level"}}
                    />
                  </div>
                )}

                <div styleName="btn-wrapper">
                  {subscriptions &&
                  subscriptions.description &&
                  subscriptions.description.type === "legal_fund" ? (
                    <button
                      styleName="btn"
                      onClick={e =>
                        this.handleSubscriptionClick(e, subscriptions)
                      }
                    >
                      Support
                    </button>
                  ) : (
                    <button
                      styleName="btn"
                      onClick={e =>
                        this.handleSubscriptionClick(e, subscriptions)
                      }
                    >
                      {/* Join&nbsp;NL&nbsp;Sena */} Contribute
                    </button>
                  )}
                </div>
              </>
            )}
            {!showBottom && (
              <div styleName="complete-wrapper">
                <div styleName="goal">{barWidth}% Funded</div>
                <div styleName="amount">{currencySymbol + targetAmount}</div>
              </div>
            )}
          </div>
        ) : (
          <div styleName="text-wrapper">
            {storySlug ? (
              this.state.isHindi ? (
                <a
                  href={
                    !storySlug.includes("nl-sena")
                      ? `https://www.newslaundry.com/nl-sena/${storySlug}`
                      : `https://www.newslaundry.com/${storySlug}` // since nl-sena wasnt added on the slug in acccesstype description ->need to handle that
                  }
                >
                  <h4 styleName="title">{hindiTitle}</h4>
                </a>
              ) : (
                <Link
                  href={
                    !storySlug.includes("nl-sena")
                      ? `/nl-sena/${storySlug}`
                      : `/${storySlug}` // since nl-sena wasnt added on the slug in acccesstype description ->need to handle that
                  }
                >
                  <h4 styleName="title">{get(subscriptions, ["name"], "")}</h4>
                </Link>
              )
            ) : this.state.isHindi ? (
              <h4 styleName="title">{hindiTitle}</h4>
            ) : (
              <h4 styleName="title">{get(subscriptions, ["name"], "")}</h4>
            )}
            {this.state.isHindi ? (
              hindiSummary ? (
                <div
                  dangerouslySetInnerHTML={{ __html: hindiSummary }}
                  styleName="description show-scroll"
                  style={{ marginBottom: !showBottom ? "50px" : "" }}
                >
                  {/* {summary} */}
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{ __html: summary }}
                  styleName="description show-scroll"
                  style={{ marginBottom: !showBottom ? "50px" : "" }}
                >
                  {/* {summary} */}
                </div>
              )
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: summary }}
                styleName="description show-scroll"
                style={{ marginBottom: !showBottom ? "50px" : "" }}
              >
                {/* {summary} */}
              </div>
            )}
            {storySlug && (
              <div styleName="read-more">
                <a
                  href={
                    !storySlug.includes("nl-sena")
                      ? `https://www.newslaundry.com/nl-sena/${storySlug}`
                      : `https://www.newslaundry.com/${storySlug}`
                  }
                >
                  Learn more
                </a>
              </div>
            )}
            <div styleName="spacer"></div>
            <div styleName="progress-wrapper">
              <div
                styleName="progress-bar"
                style={{
                  width: barWidth + "%",
                  backgroundColor: !showBottom ? "#1ad193" : ""
                }}
              ></div>
            </div>
            {showBottom && (
              <>
                <div styleName="amount-container">
                  <div styleName="amount-wrapper">
                    <div styleName="amount">
                      {currencySymbol + completedAmount}
                    </div>
                    <p styleName="goal">Raised so far</p>
                  </div>
                  <div styleName="amount-wrapper">
                    <div styleName="amount select">
                      {currencySymbol + targetAmount}
                    </div>
                    <p styleName="goal">Goal</p>
                  </div>
                </div>
                {differenceDate === 0 ? (
                  <div styleName="countdown-wrapper">
                    The campaign ends today
                  </div>
                ) : differenceDate > 0 ? (
                  <div styleName="countdown-wrapper">
                    {differenceDate} days left
                  </div>
                ) : null}
                {options && options.length > 0 && (
                  <div styleName="list-wrapper">
                    <label htmlFor="select" style={{"display":"none"}}>Select the appropriate level</label>
                    <Select
                      options={options}
                      onChange={e =>
                        this.handlePlanChange(e, subscriptionGroupId)
                      }
                      defaultValue={options[0]}
                      className="basic-single"
                      classNamePrefix="select"
                      id="select"
                      // styles={colourStyles}
                      isSearchable={false}
                      inputProps={{"aria-label":"select the appropriate level"}}
                    />
                  </div>
                )}

                <div styleName="btn-wrapper">
                  {subscriptions &&
                  subscriptions.description &&
                  subscriptions.description.type === "legal_fund" ? (
                    <button
                      styleName="btn"
                      onClick={e =>
                        this.handleSubscriptionClick(e, subscriptions)
                      }
                    >
                      Support
                    </button>
                  ) : (
                    <button
                      styleName="btn"
                      onClick={e =>
                        this.handleSubscriptionClick(e, subscriptions)
                      }
                    >
                      {/* Join&nbsp;NL&nbsp;Sena */} Contribute
                    </button>
                  )}
                </div>
              </>
            )}
            {!showBottom && (
              <div styleName="complete-wrapper">
                <div styleName="goal">{barWidth}% Funded</div>
                <div styleName="amount">{currencySymbol + targetAmount}</div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

ProjectCardBase.propTypes = {
  showBottom: PT.bool,
  subscriptions: PT.object,
  member: PT.object,
  manageLoginForm: PT.func,
  selectedPlan: PT.func,
  navigateTo: PT.func,
  showSingleCampaign: PT.bool
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function (payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url),
  selectedPlan: function (plan, redirectUrl) {
    dispatch({
      type: SELECTED_PLAN,
      plan: plan,
      planType: "campaign",
      redirectUrl: redirectUrl
    });
  },
  paymentCheckout: function (payload) {
    dispatch({
      type: PAYMENT_CHECKOUT_PAGE,
      payload: payload
    });
  }
});

const mapStateToProps = state => {
  return {
    config: get(state, ["qt", "config"], {}),
    member: get(state, ["member"], null),
    currentPath: get(state, ["qt", "currentPath"], ""),
    authHost: get(
      state,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    ),
    fetchSubscription: get(state, ["fetchSubscription"], {
      isLoading: false,
      isActive: false,
      isExpired: false,
      items: []
    })
  };
};

export const ProjectCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectCardBase);
