/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
import { Link } from "@quintype/components";
import React from "react";

import "./contact.m.css";

export function ContactUsPage() {
  return (
    <section>
      <main styleName="hero-section">
        <div>
          <div styleName="hero-section-content">
            <h1 styleName="title">Get in touch</h1>
            <p styleName="border-bottom"></p>
            <h3 styleName="description">
              Newslaundry is built on the faith of an engaged, active, alert and
              concerned reader base. We welcome your suggestions, criticism,
              ideas.
            </h3>
          </div>
          <div styleName="hero-section-img">
            <img
              src="https://images.assettype.com/newslaundry/2020-11/710e42aa-0704-4d0d-8059-bea12f58460d/22_Notification_page__1_.png"
              alt="contact us image"
            />
          </div>
        </div>
      </main>
      <div styleName="sub-section">
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">Contact email address:</p>
          <p styleName="enquiry-helper-text">subscription@newslaundry.com</p>
          <a
            target="_blank"
            styleName="enquiry-link"
            href="mailto:subscription@newslaundry.com"
            aria-label="send mail to subscription@newslaundry.com"
          >
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">Contact phone number:</p>
          <p styleName="enquiry-helper-text">+91-9811017925</p>
          <a target="_blank" styleName="enquiry-link" href="tel:+919811017925">
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">
            For submissions and editorial enquiries:
          </p>
          <a
            target="_blank"
            styleName="enquiry-link"
            href="mailto:submissions@newslaundry.com"
            aria-label="send mail to submissions@newslaundry.com"
          >
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">For internships and job enquiries:</p>
          <a
            target="_blank"
            styleName="enquiry-link"
            href="mailto:careers@newslaundry.com"
            aria-label="send mail to careers@newslaundry.com"
          >
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">For podcast letters and feedback:</p>
          <a
            target="_blank"
            styleName="enquiry-link"
            href="mailto:podcasts@newslaundry.com"
            aria-label="send mail to podcasts@newslaundry.com"
          >
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">For subscription queries and support:</p>
          <a
            target="_blank"
            styleName="enquiry-link"
            href="mailto:subscription@newslaundry.com" 
            aria-label="send mail to subscription@newslaundry.com"
          >
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">For event partnerships:</p>
          <a
            target="_blank"
            styleName="enquiry-link"
            href="mailto:chitranshutewari@newslaundry.com" 
            aria-label="send mail to chitranshutewari@newslaundry.com"
          >
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">For The Media Rumble:</p>
          <a
            target="_blank"
            styleName="enquiry-link"
            href="mailto:info@themediarumble.com" 
            aria-label="send mail to info@themediarumble.com"
          >
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">For merchandise related queries:</p>
          <a
            target="_blank"
            styleName="enquiry-link"
            href="mailto:store@newslaundry.com" 
            aria-label="send mail to store@newslaundry.com"
          >
            Get in touch
          </a>
        </div>
        <div styleName="enquiry-block">
          <p styleName="enquiry-text">Grievance Redressal form:</p>
          <Link styleName="enquiry-link" href="/grievance-redressal" 
          aria-label="go to grievance redressal page">
            File a complaint
          </Link>
        </div>
      </div>
    </section>
  );
}
