/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import { parseUrl } from "query-string";
import { WithMember } from "@quintype/components";
// import { Logo } from "../../molecules/publisher-logo";
import { StepProgress } from "../../molecules/step-progress";
import { LoginPage } from "../login";
import { Payment } from "./payment";
import { Confirmation } from "./confirmation";
import { SUBSCRIPTION_PAGE_URL } from "../../../constants";

import "./subscription-checkout.m.css";

export const SubscriptionCheckout = props => {
  const [activeStep, setActiveStep] = useState("payment");

  const dispatch = useDispatch();
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  // const member = useSelector(state => get(state, ["member"], null));

  const params = parseUrl(currentPath);
  const planId = params.query.plan;
  const status = params.query.status;

  const navigateToSubscriptionPage = () => {
    global.app.navigateToPage(dispatch, SUBSCRIPTION_PAGE_URL);
  };

  // useEffect(() => {
  //   const isHindiWeb = !!(
  //     global.location && global.location.host.includes("hindi")
  //   );

  //   if (!member) {
  //     window.location = `https://auth.newslaundry.com/user-login?callback-url=https://${
  //       isHindiWeb ? "hindi" : "www"
  //     }.newslaundry.com&redirect-url=https://${
  //       isHindiWeb ? "hindi" : "www"
  //     }.newslaundry.com/subscription`;
  //   }
  // }, []);

  useEffect(() => {
    if (!planId) {
      navigateToSubscriptionPage();
    }
    if (status) {
      setActiveStep("confirmation");
    }
  }, [props.currentPath]);

  return (
    <WithMember>
      {({ member, logout, checkForMemberUpdated, isLoading }) => {
        return (
          <div styleName="wrapper" className="full-width">
            <div styleName="steps-wrapper">
              <StepProgress
                {...props}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            </div>
            <br />

            {activeStep === "account" ? (
              <div className="container">
                <LoginPage />
              </div>
            ) : activeStep === "payment" ? (
              <Payment
                member={member}
                planId={planId}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
            ) : (
              <Confirmation {...props} />
            )}
          </div>
        );
      }}
    </WithMember>
  );
};
