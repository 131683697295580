import React, { useEffect, useState } from "react";
import Axios from "axios";
import { get } from "lodash";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { TelInput } from "../AccountDetails/telInput";
import {
  fetchDataFromLocalStorage,
  saveDataToLocalStorage
} from "../subscription-checkout/utils";
import {
  getDigitsExceptFirstN,
  getDigitsExceptLastN,
  getLastNDigits,
  isMobileValid
} from "../../../utils";

import countryCodeAndEmojisData from "../AccountDetails/countryCodes.json";

import "./hindi-whatsapp-optin.m.css";

const HindiWhatsappOptin = () => {
  const member = useSelector(state => state.member);
 // console.log({ member });

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState({
    countryName: "India",
    countryPhoneCode: "+91",
    flagEmoji: "🇮🇳",
    minSize: 10,
    maxSize: 10
  });
  const [error, setError] = useState(null);
  const [prompt, setPrompt] = useState(null);

  //const oldMobileNumber = get(member, ["phone-number"], null);
  const code = getDigitsExceptFirstN(selectedCountry.countryPhoneCode, 1);

  useEffect(() => {
    // if (member && member["phone-number"]) {
    //   const num = member["phone-number"];
    //   const setCountryName = fetchDataFromLocalStorage("phoneCountryName");

    //   if (num.length > 10) {
    //     const setCode = getDigitsExceptLastN(num, 10);
    //     const setNumber = getLastNDigits(num, 10);

    //     setPhoneNumber(setNumber);

    //     let countryData;

    //     if (setCountryName) {
    //       countryData = countryCodeAndEmojisData.find(
    //         c =>
    //           c.countryPhoneCode === `+${setCode}` &&
    //           c.countryName === setCountryName
    //       );
    //     } else {
    //       countryData = countryCodeAndEmojisData.find(
    //         c => c.countryPhoneCode === `+${setCode}`
    //       );
    //     }

    //     if (countryData) {
    //       setSelectedCountry({
    //         countryName: countryData.countryName,
    //         countryPhoneCode: countryData.countryPhoneCode,
    //         flagEmoji: countryData.flagEmoji,
    //         minSize: countryData.minSize,
    //         maxSize: countryData.maxSize
    //       });
    //     }
    //   }
    // }
  }, []);

  const handleSubmit = async () => {
    const isMobValid = isMobileValid(`${code}${phoneNumber}`);

    if (!isMobValid) {
      setError("कृपया एक मान्य मोबाइल नंबर दर्ज करें");
      return;
    }

    // const fullName =
    //   member["first-name"] || "" + member["last-name"] || "NL User";

    try {
      // const userObj = {
      //   first_name: member["first-name"] || "",
      //   last_name: member["last-name"] || "",
      //   name: fullName,
      //   email: member.email,
      //   phone_number: `${code}${phoneNumber}`,
      //   id: member.id
      // };

      // // console.log({ userObjWAFeature: userObj });

      // await Axios.post("/api/auth/v1/users/profile", userObj);

      // if (!oldMobileNumber) {
        await Axios.post(
          "https://server.newslaundry.com/whatsapp/addHindiUserPromotionalWhatsapp",
          {
            mobileNumber: phoneNumber,
            countryCode: code
          }
        );
      // } else {
      //   console.log({
      //     newMobileNumber: phoneNumber,
      //     oldMobileNumber: getLastNDigits(oldMobileNumber, 10),
      //     countryCode: code,
      //     emailAddress: member.email,
      //     name: member.name,
      //     phoneCountryName: selectedCountry.countryName
      //   });
      //   await Axios.put(
      //     "http://localhost:5000/whatsapp/updateHindiUserPromotionalWhatsapp",
      //     {
      //       newMobileNumber: phoneNumber,
      //       oldMobileNumber: getLastNDigits(oldMobileNumber, 10),
      //       countryCode: code,
      //       emailAddress: member.email,
      //       name: member.name,
      //       phoneCountryName: selectedCountry.countryName
      //     }
      //   );
      // }

      // Had to do this because AT's shitty profile update API does not support metadata updation
      // and neither has any country field in users table
      // saveDataToLocalStorage(selectedCountry.countryName, "phoneCountryName");

      setPrompt("आपका नंबर सफलतापूर्वक अपडेट कर दिया गया है।");
    } catch (error) {
      console.error(error);
      setError("सर्वर उपलब्ध नहीं है। कृपया बाद में पुन: प्रयास करें।");
    }
  };

  return (
    // <div styleName="feature-section">
    //   <h3 styleName="feature-headline">
    //     Get subscription updates on WhatsApp now!
    //   </h3>
    //   <p styleName="feature-text">
    //     Want to stay up to date with your Newslaundry subscription? We&apos;ve
    //     got your back! Just add your number and get all your subscription
    //     updates directly on WhatsApp
    //   </p>
    //   <div styleName="input-field-wrapper">
    //     <TelInput
    //       phoneNumber={phoneNumber}
    //       setPhoneNumber={setPhoneNumber}
    //       selectedCountry={selectedCountry}
    //       setSelectedCountry={setSelectedCountry}
    //     />
    //     <button styleName="submit-btn" onClick={handleSubmit}>
    //       Update Now
    //     </button>
    //   </div>
    //   {error && (
    //     <p styleName="error-text">
    //       <FaTimesCircle />
    //       <span>{error}</span>
    //     </p>
    //   )}
    //   {prompt && (
    //     <p styleName="success-text">
    //       <FaCheckCircle />
    //       <span>{prompt}</span>
    //     </p>
    //   )}
    // </div>
    <>
      <div>
        <main styleName="hero-section">
          <br />
          <br />
          <div styleName="hero-section-div">
            <div styleName="hero-section-img">
              <img
                src="https://images.assettype.com/newslaundry/2023-05/c94e5e9a-fc0f-4da7-85ce-ae5c9101b309/IMG_2575.jpg"
                alt="Whatsapp Hero Image"
              />
            </div>
            <div styleName="hero-section-content">
              {/* <h1 styleName="title">Get story updates on Whatsapp now! </h1> */}
              <h1 styleName="title">
                अब व्हाट्सएप पर भी सभी कहानियों के अपडेट प्राप्त करें!{" "}
              </h1>
              <p styleName="border-bottom"></p>
              <p styleName="description">
                {/* <p styleName="feature-text">
                  Want to stay up to date with Newslaundry content?
                  We&apos;ve got your back! Just add your number and get all
                  your updates of new stories, conetent from newslaundry directly on WhatsApp
                </p> */}
                <p styleName="feature-text">
                  न्यूज़लॉन्ड्री के कंटेंट के साथ अप-टू-डेट रहना चाहते हैं? बस
                  अपना नंबर जोड़ें और न्यूज़लॉन्ड्री की नई ख़बर के सभी अपडेट
                  सीधे व्हाट्सएप पर प्राप्त करें।
                </p>
                <br />
                <div styleName="input-field-wrapper">
                  <TelInput
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                  />
                  <button styleName="submit-btn" onClick={handleSubmit}>
                    {/* Update Now */}
                    नंबर जोड़ें!
                  </button>
                </div>
                {error && (
                  <p styleName="error-text" style={{ color: "red" }}>
                    <FaTimesCircle />
                    <span>{error}</span>
                  </p>
                )}
                {prompt && (
                  <p styleName="success-text" style={{ color: "green" }}>
                    <FaCheckCircle />
                    <span>{prompt}</span>
                  </p>
                )}
              </p>
            </div>
          </div>
          <br />
          <br />
        </main>
      </div>
    </>
  );
};

export { HindiWhatsappOptin };
