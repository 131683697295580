import React from "react";
import PropTypes from "prop-types";
import CommonStoryCard from "../common-story-card";
import "./verticle-story-card-list.m.css";

const VerticleStoryCardList = ({ stories = [], hideAuthor }) => {
  return (
    <div>
      {stories.map(story => (
        <div key={story.id} styleName="card-wrapper">
          <CommonStoryCard story={story} hideAuthor={hideAuthor} />
        </div>
      ))}
    </div>
  );
};

VerticleStoryCardList.propTypes = {
  stories: PropTypes.array,
  className: PropTypes.string,
  hideAuthor: PropTypes.bool
};
export default VerticleStoryCardList;
