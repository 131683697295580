/* eslint-disable react/prop-types */
import React from "react";
import { useSelector } from "react-redux";
import LineItem from "./LineItem";
import "./merch-page.m.css";

const Cart = props => {
  const { fetchSubscription, member } = useSelector(state => state);
  const pushToDataLayer = (productName, transactionAmount) => {
    const obj = {
      event: "product_checkout",
      product_name: productName,
      transaction_amount: transactionAmount,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  const openCheckout = () => {
    let productName = "";
    props.checkout.lineItems.forEach(item => {
      if (productName) {
        productName = productName + "," + item.title;
      } else {
        productName = productName + item.title;
      }
    });

    pushToDataLayer(productName, props.checkout.totalPrice.amount);

    if (/* props.subscription.userHasSubscription && */ lineItems.length) {
      window.open(props.checkout.webUrl);
    }
  };

  const lineItems = props.checkout.lineItems.map(lineItem => {
    return (
      <LineItem
        updateQuantityInCart={props.updateQuantityInCart}
        removeLineItemInCart={props.removeLineItemInCart}
        key={lineItem.id.toString()}
        line_item={lineItem}
      />
    );
  });

  return (
    <div styleName={`cart ${props.isCartOpen ? "cart--open" : ""}`}>
      <header styleName="cart__header">
        <h2 styleName="">Newslaundry Cart</h2>
        <button onClick={props.handleCartClose} styleName="cart__close" aria-label="Click to close cart">
          ×
        </button>
      </header>
      <ul styleName="cart__line-items" >{lineItems}</ul>
      <footer styleName="cart__footer">
        <div>
          <div styleName="cart-info__total cart-info__small">
            Total (incl. Taxes)
          </div>
          <div styleName="cart-info__pricing">
            {props.checkout.totalPrice && <span styleName="pricing">₹ {props.checkout.totalPrice.amount}</span> }
          </div>
        </div>
        <button
          styleName="cart__checkout"
          onClick={
            /* props.subscription.userHasSubscription ? openCheckout : props.dialog */ openCheckout
          }
        >
          Checkout
        </button>
      </footer>
    </div>
  );
};

export default Cart;
