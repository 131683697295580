import { AccessType, WithMember } from "@quintype/components";
import React, { useEffect, useState } from "react";
import {
  FaCcMastercard,
  FaCcVisa,
  FaCheck,
  FaPaypal,
  FaWallet,
} from "react-icons/fa";
import Select from "react-select";
import IntegrationData from "../../../../../config/integrations";
import { parseUrl } from "query-string";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";
// import { RiBankFill } from "react-icons/ri";
// import { SiGooglepay } from "react-icons/si";
import { get } from "lodash";
import { CgSpinner } from "react-icons/cg";
import { RiBankFill } from "react-icons/ri";
import { SiGooglepay } from "react-icons/si";
import { useSelector } from "react-redux";

import "./angel-subscriber.m.css";

const plansData = [
  {
    title: "Savior",
    prices: [
      {
        currency: "INR",
        price: 100000,
      },
    ],
    durationUnit: "years",
    durationLength: 2,
    benefits: [
      "Two year paywall access",
      "50 student subscriptions supported",
      "Fridge Magnets & Comics with a note by Abhinandan Sekhri",
      "Lifetime access to all NL events and meet ups",
      "Special WhatsApp group with CEO and Editors",
    ],
  },
  {
    title: "Star",
    prices: [
      {
        currency: "INR",
        price: 300000,
      },
    ],
    durationUnit: "years",
    durationLength: 3,
    benefits: [
      "Three year paywall access",
      "100 student subscriptions supported",
      "Fridge Magnets, Comics & Mug with note by Abhinandan Sekhri",
      "Lifetime access to all NL events and meet ups",
      "Special WhatsApp group with CEO and Editors",
      "Two tickets to a festival and stand-up performance in a year. Our partner festivals and performers include Jaipur Literary Festival, Kabira Festival, Varun Grover and Aisi Democracy.",
    ],
  },
  {
    title: "Champion",
    prices: [
      {
        currency: "INR",
        price: 500000,
      },
    ],
    durationUnit: "years",
    durationLength: 5,
    benefits: [
      "Five year paywall access",
      "500 student subscriptions supported",
      "Fridge Magnets, Comics, mug & stickers with note by Abhinandan Sekhri",
      "Lifetime access to all NL events and meet ups",
      "Special WhatsApp group with CEO and Editors",
      "Two tickets to a festival and stand-up performance in a year. Our partner festivals and performers include Jaipur Literary Festival, Kabira Festival, Varun Grover and Aisi Democracy.",
    ],
  },
  {
    title: "Legend",
    prices: [
      {
        currency: "INR",
        price: 1000000,
      },
    ],
    durationUnit: "years",
    durationLength: 5,
    benefits: [
      "Five year paywall access",
      "1000 student subscriptions supported",
      "Fridge Magnets, Comics, mug, stickers + Legend scroll with note by Abhinandan Sekhri",
      "Lifetime access to all NL events and meet ups",
      "Special WhatsApp group with CEO and Editors",
      "Two tickets to a festival and stand-up performance in a year. Our partner festivals and performers include Jaipur Literary Festival, Kabira Festival, Varun Grover and Aisi Democracy.",
    ],
  },
  {
    title: "Supreme",
    prices: [
      {
        currency: "INR",
        price: 1500000,
      },
    ],
    durationUnit: "years",
    durationLength: 5,
    benefits: [
      "Five year paywall access",
      "1500 student subscriptions supported",
      "Fridge Magnets, Comics, mug, stickers + Supreme scroll with note by Abhinandan Sekhri",
      "Lifetime access to all NL events and meet ups",
      "Special WhatsApp group with CEO and Editors",
      "Two tickets to a festival and stand-up performance in a year. Our partner festivals and performers include Jaipur Literary Festival, Kabira Festival, Varun Grover and Aisi Democracy.",
    ],
  },
];

const AngelSubscriber = () => {
  const state = useSelector((state) => state);
  console.log({state}, "ssss");
  //console.log(state.subscriptions);
  const [selectedPlan, setSelectedPlan] = useState(plansData[0].title);
  const [paymentOption, setPaymentOption] = useState("upi");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { qt } = useSelector((state) => state);
  const authHost = qt.config.publisher.publisher.auth_host;
  // const successUrl = "https://newslaundry.com";
  const [successUrl, setSuccessUrl] = useState("https://newslaundry.com");
  const [redirectUrl, setRedirectUrl] = useState("https://newslaundry.com/angel-subscriber");
  const [selectedPlanObj, setSelectedPlanObj] = useState({}); // =  useState(state.subscriptions.filter((obj) => obj.name === "Angel Subscriber Plan")[0].subscription_plans.filter((obj) => obj.title === "Savior")[0]);

  useEffect(() => {
    const params = parseUrl(qt.currentPath);
    setSuccessUrl(get(
      params,
      ["query", "callback-url"],
      global.location && global.location.origin
    ));
    setRedirectUrl( get(
      params,
      ["query", "redirect-url"],
      global && global.location && global.location.href
    ));
  //   console.log(get(
  //     params,
  //     ["query", "callback-url"],
  //     global.location && global.location.origin
  //   ));
  // console.log(get(
  //   params,
  //   ["query", "redirect-url"],
  //   global && global.location && global.location.href
  // ));
    
    if (state.subscriptions && state.subscriptions.length) {
      //console.log(state.subscriptions.filter((obj) => obj.name === "Angel Subscriber Plan")[0].subscription_plans.filter((obj) => obj.title === selectedPlan)[0]);
      
      let planObj= state.subscriptions
          .filter((obj) => obj.name === "Angel Subscriber Plan")[0];
      //console.log({planObj});
      if( planObj && planObj.subscription_plans)  {
        planObj = planObj.subscription_plans.filter((obj) => obj.title === selectedPlan)[0]
        setSelectedPlanObj(planObj);}
    }
  }, [selectedPlan, state]);

  const integrationsConfig = IntegrationData();

  const pub = useSelector((state) => get(state, ["qt", "config", "publisher"]));
  const member = useSelector((state) => get(state, ["member"], null));

  const isStaging = get(pub, ["publisher", "accessTypeEnvironment"], true);
  const enableAccesstype = get(
    pub,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const email = get(member, ["email"], "");
  const phone = get(member, ["metadata", "phone"], "");
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  const planData = plansData.find((p) => p.title === selectedPlan);

  const selectOptions = plansData.map((p) => ({
    value: p.title,
    label: p.title,
  }));

  const handleNotMember = async () => {

  const params = parseUrl(qt.currentPath);
    const callbackUrl = get(
      params,
      ["query", "callback-url"],
      global.location && global.location.origin
    );
    const redirectUrl = get(
      params,
      ["query", "redirect-url"],
      global && global.location && global.location.href
    );
    //console.log(`${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`, "url")
    window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  };

  const handlePayment = async (
    initAccessType,
    initRazorPayPayment,
    initStripePayment
  ) => {
    try {
      setLoading(true);
      await initAccessType();

      if (paymentOption === "upi") {
        const options = {
          argType: "options",
          selectedPlan: selectedPlanObj,
          planType: "standard",
          couponCode: "",
          successUrl: successUrl,
          recipientSubscriber: {
            recipient_identity_provider: "email",
          },
        };
        //console.log({options},"razorpay options")
        await initRazorPayPayment(options);

        setLoading(false);
        window.location.href = successUrl;

        return;
      }

      if (paymentOption === "card") {
        const options = {
          argType: "options",
          selectedPlan: selectedPlanObj,
          planType: "standard",
          couponCode: "",
          successUrl: successUrl,
          cancelUrl: redirectUrl,
          recipientSubscriber: {
            recipient_identity_provider: "email",
          },
        };
       // console.log({options},"Stripe options")
        await initStripePayment(options);

        setLoading(false);
        window.location.href = successUrl;

        return;
      }
    } catch (error) {
      console.log("Er:", error);
      console.error(
        `Payment Gateway error: ${JSON.stringify(error.message, null, 2)}`
      );
      setError(error.message);
      setLoading(false);
    }
  };

  return (<>
    {/* <WithMember> */}
          <div styleName="section-wrapper">
            <div styleName="hero-section">
              <div styleName="heading-wrapper">
                <img
                  src="https://images.assettype.com/newslaundry/2023-08/f2babf24-502c-4523-8c74-67adfe6780cb/angel_subscriber.png"
                  alt="Angel Subscriber"
                  styleName="hero-img"
                />
                <div styleName="title-wrapper">
                  <h1 styleName="title">Angel Subscriber</h1>
                  <p styleName="border-bottom"></p>
                </div>
                <p styleName="page-description">
                  Become an angel for independent media. We believe those who
                  pay for news determine its direction. And for news to remain
                  free and fair, independent media needs thousands of readers
                  like you to step up and show support.
                </p>
                <p styleName="page-description">
                  By becoming a Newslaundry Angel Subscriber, you will not only
                  support public-interest journalism but also champion a
                  movement that stands for truth, accountability, and
                  independent reporting. Your decision to become an Angel
                  Subscriber goes beyond financial contribution. It signifies
                  your commitment to driving change.
                </p>
                <p styleName="page-description">
                  Your angel subscription will provide you access to our paywall
                  stories, shows, ground reports, and subscriber-only events. As
                  gratitude, you will also get a limited edition Newslaundry
                  Angel subscription merch goodies.
                </p>
              </div>
            </div>
            <div styleName="pricing-wrapper">
              <div styleName="pricing-details-wrapper">
                <div styleName="pricing-plan-details-wrapper">
                  <p styleName="helper-heading">What you&apos;ll get</p>
                  {planData.benefits.map((item, i) => (
                    <div styleName="pricing-plan-detail" key={i}>
                      <span>
                        <FaCheck color="#22c55e" />
                      </span>
                      <p>{item}</p>
                    </div>
                  ))}
                </div>
                <div>
                  <p styleName="helper-heading">Choose a plan</p>
                  <Select
                    options={selectOptions}
                    styleName="select-wrapper"
                    defaultValue={selectOptions[0]}
                    onChange={(e) => {
                     // console.log(paymentOption)
                      setSelectedPlan(e.label);
                    }}
                    disabled={loading}
                    inputProps={{"aria-label":"select the appropriate level"}}
                  />
                </div>
                {/* Payment method is set to razorpay for now as there was some error with stripe, so below component to choose payment method is commented, and default Payment option is set to UPI to enal=nle razorpay only */}
                {/* <div>
                  <p styleName="helper-heading">Choose a payment method</p>

                  <div styleName="payment-methods-wrapper">
                    { selectedPlan != "Legend" && selectedPlan != "Supreme" &&
                    <div
                      styleName={`payment-method ${
                        paymentOption === "card" ? "selected" : ""
                      }`}
                      onClick={() => setPaymentOption("card")}
                    >
                      <div styleName="checkbox-svg">
                        {paymentOption === "card" ? (
                          <BiRadioCircleMarked styleName="checkbox-svg" />
                        ) : (
                          <BiRadioCircle styleName="checkbox-svg" />
                        )}
                      </div>
                      <div styleName="payment-div-block">
                        <span styleName="payment-title">Cards</span>
                        <FaCcVisa />
                        <FaCcMastercard />
                      </div>
                    </div>}
                    <div
                      styleName={`payment-method ${
                        paymentOption === "upi" ? "selected" : ""
                      }`}
                      onClick={() => setPaymentOption("upi")}
                    >
                      <div styleName="checkbox-svg">
                        {paymentOption === "card" ? (
                          <BiRadioCircle />
                        ) : (
                          <BiRadioCircleMarked />
                        )}
                      </div>
                      <div styleName="payment-div-block">
                        <span styleName="payment-title">UPI, Netbanking</span>
                        <SiGooglepay />
                        <RiBankFill />
                        <FaWallet />
                        <FaPaypal />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div styleName="pricing-plan-price">
                  <p styleName="helper-heading">Total</p>
                  <p>
                    &#8377;
                    {planData.prices.find((p) => p.currency === "INR")
                      ? planData.prices
                          .find((p) => p.currency === "INR")
                          .price.toLocaleString("en-IN")
                      : 0}
                  </p>
                </div>
                <div styleName="cta-wrapper">
                 { member? <AccessType
                    enableAccesstype={enableAccesstype}
                    isAccessTypeCampaignEnabled={true}
                    isStaging={false}
                    accessTypeKey={accessTypeKey}
                    accessTypeBkIntegrationId={accessTypeBkIntegrationId}
                    email={email}
                    phone={phone}
                  >
                    {({
                      initAccessType,
                      initRazorPayPayment,
                      initStripePayment,
                    }) => {
                      return (
                        <>
                          {loading ? (
                            <button
                              styleName="confirm-btn pay-btn-disabled"
                              disabled={true}
                            >
                              <CgSpinner styleName="loading" />
                            </button>
                          ) : (
                            <button
                              disabled={loading}
                              styleName={`confirm-btn ${
                                loading ? "pay-btn-disabled" : ""
                              }`}
                              onClick={() =>
                                handlePayment(
                                  initAccessType,
                                  initRazorPayPayment,
                                  initStripePayment
                                )
                              }
                            >
                              Subscribe
                            </button>
                          )}
                        </>
                      );
                    }}
                  </AccessType>:
                  <button
                              
                              styleName={`confirm-btn`}
                              onClick={()=> handleNotMember()}
                            >
                              Subscribe
                            </button>}
                </div>
                {/* {loading && <p styleName="loading">Checking eligibility...</p>} */}
                {error && <p styleName="error">{error}</p>}
              </div>
            </div>
          </div>
       
      
    {/* </WithMember> */}
    </>
  );
};

export { AngelSubscriber };
