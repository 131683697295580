import React, { useEffect, useState } from "react";
import { getIntegrationToken } from "../../../helper/api";
import IntegrationData from "../../../../../../config/integrations";
import axios from "axios";
import "./gift-subscriptions.m.css";
import Loader from "../../../atoms/loader";

export const GiftSubscriptions = () => {
  const [loading, setLoading] = useState(false);
  const [giftSubs, setGiftSubs] = useState([]);

  useEffect(() => {
    const loadGiftSubs = async () => {
      try {
        setLoading(true);
        const accessTypeKey = IntegrationData().isAccesstypeStaging
          ? IntegrationData().stagingAccessTypeKey
          : IntegrationData().accessTypeKey;

        const token = await getIntegrationToken();
        const response = await axios.get(
          `https://www.accesstype.com/api/access/v1/members/me/subscription-gifts?key=${accessTypeKey}&accesstype_jwt=${token}`
        );
        setGiftSubs(response.data.subscriptions);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    loadGiftSubs();
  }, []);

  return (
    <div>
      <h2>Gift Subscriptions</h2>
      {loading ? (
        <Loader color="#ec2227" />
      ) : giftSubs.length ? (
        <GiftList giftSubs={giftSubs} />
      ) : null}
    </div>
  );
};

const GiftList = props => {
  return props.giftSubs.map(item => {
    const description =
      (item.group_description && item.group_description.split("\n")) || [];
    const imgUrl = description.length > 1 ? description[0] : null;
    return (
      <div styleName="sub-wrapper" key={item.id}>
        <div styleName="img-wrapper">
          <img styleName="image" src={imgUrl} alt={item.name} />
        </div>

        <div styleName="info">
          <h4 styleName="group-name">{`${item.group_name}`}</h4>
          <h5 styleName="plan-name">{`${item.plan_name}`}</h5>
          {item.subscription_type !== "campaign" && (
            <span styleName="expiry-date">
              {" "}
              {item.in_trial_period
                ? "Billing starts on"
                : item.plan_occurrence === "Recurring"
                ? "Next due "
                : "Valid till "}
              {new Date(item.end_timestamp).toLocaleDateString("default", {
                day: "numeric",
                month: "short",
                year: "numeric"
              })}
            </span>
          )}
        </div>
      </div>
    );
  });
};
