import React from "react";
import assetify from "@quintype/framework/assetify";
import logo from "../../../../assets/images/newslaundry_logo.png";
import "./uday-foundation.m.css";

// eslint-disable-next-line max-len
const SUMMARY = `This winter, contribute to Uday Foundation’s blanket donation drive and get a free Newslaundry subscription for 6 months, and a deserved shoutout for your kindness from our social media handles.`;

export const UdayFoundationBanner = () => {
  return (
    <div styleName="row-container">
      <div styleName="container" className="full-width-with-padding">
        <div styleName="left-container">
          <div styleName="img-wrap">
            <img styleName="logo-wrapper" src={assetify(logo)} alt="newslaundry logo" />
            <img
              styleName="logo-wrapper"
              src={
                "https://images.assettype.com/newslaundry/2020-12/905d6108-5142-49dc-945e-4269a3acb1f2/udayfoundationl_logo.png"
              }
              alt="uday logo"
            />
          </div>

          <h1 styleName="text-container">
            <span styleName="text support">Season of</span>
            <span styleName="text-wrapper">
              <span styleName="text">Spreading&nbsp;</span>
              <span styleName="text">Warmth</span>
            </span>
          </h1>
        </div>
        <div styleName="right-container">
          <h2 styleName="title"> Newslaundry &amp; Uday Foundation</h2>
          <div styleName="summary">{SUMMARY}</div>
          <div styleName="btn-img-wrapper">
            <div styleName="subcribe-bottom">
              <a
                styleName="nl-btn"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                href={`https://bit.ly/Newslaundry_`}
              >
                Contribute here
              </a>
            </div>
            <img
              styleName="info-image"
              src={
                "https://images.assettype.com/newslaundry/2020-12/91b6eb1f-1b6e-4916-9e32-3c430d0094ee/blanket_drive.png"
              }
              alt="subscription image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
