import { Link } from "@quintype/components";
import React from "react";
import PropTypes from "prop-types";
// import { useScrollLock } from "../helper/hooks";
// import SubscriptionNudge from "../../../assets/images/nl-images/stopnudge1.png";
import logo from "../../../assets/images/newslaundry_logo.png";
import assetify from "@quintype/framework/assetify";
import { Button } from "../../ui/components";

import { FaTimes } from "react-icons/fa";
import "./newsable-bottom-sheet.m.css";

const NewsableBottomSheet = ({ setToShowPopup }) => {
  // const isHindi = !!(global.location && global.location.host.includes("hindi"));

  // useScrollLock();
  // const [toShowPopup, setToShowPopup] = useState(true);

  return (
    <div styleName="wrapper">
      <div styleName="container">
        <div styleName="left-container">
          <div styleName="row-wrapper">
            <div styleName="logo-wrapper">
              <img src={assetify(logo)} alt="newslaundry logo" />
            </div>
            <h2 styleName="text-container">
              <span>Introducing NewsAble</span>
            </h2>
          </div>
          <div styleName="appeal-wrapper">
            <p styleName="appeal-text">
              NewsAble is a set of structural changes and features that makes
              the Newslaundry app and website accessible to people with
              disabilities. Voice search, screen reader compatibility, dyslexia
              fonts, colour contrast themes and a lot more.
            </p>
            <Link
              styleName="redirect-link"
              href="/2024/05/01/presenting-newsable-the-newslaundry-website-and-app-are-now-accessible"
            >
              Learn more
            </Link>
          </div>
        </div>
        <div styleName="image-container">
          <img
            src="https://images.assettype.com/newslaundry/2024-04/131ca8a7-1015-486e-8566-f3ed6765025a/newsable_mobile_laptop.png"
            // src={assetify(SubscriptionNudge)}
            alt="subscription-appeal-image"
            styleName="subscription-appeal-image"
            // styleName="laptop-image"
          />
          {/* <img
            src={mobileScreen}
            // src={assetify(SubscriptionNudge)}
            alt="subscription-appeal-image"
            styleName="mobile-image"
          /> */}
        </div>
        {/* <div styleName="left-container">
        <img
        src={assetify(SubscriptionNudge)}
        alt="subscription-appeal-image"
        styleName="subscription-appeal-image"
        />
        </div>
        <div styleName="middle-container">
        <div styleName="row-wrapper">
        <div styleName="logo-wrapper">
        <img src={assetify(logo)} alt="newslaundry logo" />
        </div>
        <h2 styleName="text-container">
        <span>Unveiling Newsable</span>
        </h2>
        </div>
        <div styleName="appeal-wrapper">
        <p styleName="appeal-text">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias,
        voluptatibus illum tempora quae quasi sit? Aspernatur non sequi
        recusandae error veniam harum reiciendis incidunt, ullam repellat
        mollitia dolor quis architecto!
        </p>
        <Link
        styleName="redirect-link"
        href="/subscription?ref=homepage-newsable-banner"
        >
        <span>Read the full story</span>
        </Link>
        </div>
      </div> */}
        <div styleName="cancel-btn-div">
          <Button onClick={() => setToShowPopup("closed")}>
            <FaTimes />
          </Button>
          {/* <button styleName="cancel-btn" onClick={() => setToShowPopup("closed")}>
          <FaTimes />
        </button> */}
        </div>
      </div>
    </div>
  );
};

NewsableBottomSheet.propTypes = {
  setToShowPopup: PropTypes.func
};

export { NewsableBottomSheet };
