import React, { useEffect, useState } from "react";

import { Link } from "@quintype/components";
import get from "lodash/get";
import PT from "prop-types";
import getStoriesByCollection from "../../../utils";
import { CardImage } from "../../atoms/card-image";
import { CollectionName } from "../../atoms/collection-name";
import AuthorWithTime from "../../molecules/author-with-time";
import NlSenaWrapper from "../../pages/nl-sena-wrapper";
import { Headline } from "../../atoms/headline";

import "./four-stories-with-campaign.m.css";

// import { Headline } from "../../atoms/headline";
// const collection = {
//   "associated-metadata": "8rQsN1gRSKM?si=gTJoOncrceZZMw51"
// };

const sena2024ElectionFundCampaings = [1513, 1497, 1512, 1500, 1510, 1511];

export const FourStoriesWithCampaign = ({ collection }) => {
  const IS_HINDI = !!(
    global.location && global.location.host.includes("hindi")
  );

  const [stories, setStories] = useState([]);

  const collections = collection.items.filter(
    collection => collection.type === "collection"
  );

  useEffect(() => {
    if (IS_HINDI) {
      setStories(getStoriesByCollection(collection));
    } else {
      setStories(
        getStoriesByCollection(collection).filter(
          item => item.url.slice(8, 13) !== "hindi"
        )
      );
    }
  }, [IS_HINDI]);

  if (stories.length < 1) {
    return null;
  }

  const campaignId = get(
    collection,
    ["associated-metadata", "campaign_id"],
    ""
  );

  const getStoryCard = slice => {
    return (
      stories &&
      stories.slice(0, slice).map((story, index) => {
        const section = get(story, ["sections", "0"], {});
        const getLabel = get(
          story,
          ["metadata", "story-attributes", "storyattribute", 0],
          ""
        );
        return (
          <div key={index} styleName="story-wrapper">
            <CardImage story={story} />
            {getLabel && <div styleName="ribbon">{getLabel}</div>}
            <div styleName="text-wrapper">
              <Link href={section["section-url"]}>
                <p styleName="section-badge">
                  {section["display-name"] || section.name}
                </p>
              </Link>
              {/* <Link href={section["section-url"]}>
                <div styleName="section">
                  {section["display-Name"] || section.name}
                </div>
              </Link> */}
              {/* <h3 className="line-clamp-3 typography-heading-styles-h4">
                {getStoryHeading(story)}
              </h3> */}
              <Headline story={story} externalLink={true} />
              <div styleName="author-details">
                <AuthorWithTime story={story} />
              </div>
            </div>
          </div>
        );
      })
    );
  };

  return (
    <>
      {/* <LiveVideo collection={collection} /> */}

      <div className="full-width-with-padding" styleName="wrapper">
        <div styleName={campaignId ? "container" : ""} className="container">
          <div styleName="sub-section-grid">
            {<CollectionName collection={collection} />}
            {campaignId ? (
              <div styleName="story-container2">{getStoryCard(4)}</div>
            ) : (
              <div styleName="story-container">{getStoryCard(6)}</div>
            )}
          </div>

          <div styleName="sub-section-grid">
            {campaignId && collections.length > 0 && (
              <CollectionName
                collection={collections[0]}
                className="desktop-only"
                isJointSub={campaignId === 1385}
                is2024ElectionFund={sena2024ElectionFundCampaings.includes(
                  campaignId
                )}
              />
            )}
            {campaignId && (
              <div styleName="sena-wrapper">
                <NlSenaWrapper
                  showSingleCampaign={true}
                  campaignId={campaignId}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

FourStoriesWithCampaign.propTypes = {
  collection: PT.object
};
