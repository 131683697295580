import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { getSlug } from "../../../utils";
import { Link, ResponsiveImage } from "@quintype/components";
import "./base-card.m.css";

const BaseCard = ({ collection, config }) => {
  const slug = getSlug(collection, config);
  // console.log(getSlug(collection, config))
  const collectionName = get(collection, ["name"], "");
  const collectionSummary = get(collection, ["summary"], "");
  const defaultImgS3Key = get(
    config,
    ["publisher", "publisher", "defaulImgS3key"],
    ""
  );
  const imgS3Key = get(
    collection,
    ["metadata", "cover-image", "cover-image-s3-key"],
    defaultImgS3Key
  );
  const imgMetadata = get(
    collection,
    ["metadata", "cover-image", "cover-image-metadata"],
    {}
  );

  return (
    <div styleName="card-container">
      <Link href={slug} className="hero-image">
        <ResponsiveImage
          slug={imgS3Key}
          metadata={imgMetadata}
          aspectRatio={[16, 9]}
          defaultWidth={480}
          widths={[250, 480, 640]}
          sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
          imgParams={{ auto: ["format", "compress"] }}
          className="responsive-img"
          styleName="res-img"
          alt="Article image"
        />
        <div styleName="text-wrapper">
          <h3 styleName="headline" className="headline">
            <bdi dangerouslySetInnerHTML={{ __html: collectionName }} />
          </h3>
          <p
            styleName="subheadline"
            className="subheadline"
            // style={{ color: "#616160" }}
          >
            <bdi dangerouslySetInnerHTML={{ __html: collectionSummary }} />
          </p>
        </div>
      </Link>
    </div>
  );
};

BaseCard.propTypes = {
  collection: PropTypes.object,
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {})
});

export default connect(mapStateToProps)(BaseCard);
