import React, { useState, useRef } from 'react';
import './NamesInput.m.css';
function NamesInput({ names, onNamesChange, onNamesSubmit }) {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  const handleNameRemove = (index) => {
    const updatedNames = [...names];
    updatedNames.splice(index, 1);
    onNamesChange(updatedNames);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      const updatedNames = [...names, inputValue.trim()];
      onNamesChange(updatedNames);
      setInputValue('');
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <div styleName="input-container">
        {names.map((name, index) => (
          <div key={index} styleName="tag">
            {name}
            <button
              type="button"
              styleName="remove"
              onClick={() => handleNameRemove(index)}
            >
              &times;
            </button>
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter your suggestions & press enter"
          styleName="input"
          ref={inputRef}
        />
      </div>
    </div>
  );
}

export default NamesInput;
