import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { CollectionName } from "../../atoms/collection-name";
import VerticleStoryCard from "../../molecules/verticle-story-card";
import HorizontalStoryCard from "../../molecules/horizontal-story-card";
import StopPress from "../../molecules/stop-press";
import "./three-stories-with-subscribe-mail.m.css";

const ThreeStoriesWithSubscribeMail = ({
  currentPath,
  data = {},
  stories = [],
  limit
}) => {
  const collection = data.collection || {};
  const collections =
    collection.items.filter(collection => collection.type === "collection") ||
    [];
  const domainSlug = useSelector(state =>
    get(state, ["qt", "domainSlug"], null)
  );

  return (
    <div styleName="wrapper">
      <div styleName="section-heading">
        <CollectionName collection={collection} />
      </div>
      <div styleName="grid-container">
        <div styleName="verticla-card">
          <VerticleStoryCard story={stories[0]} />
        </div>
        <div styleName="horizontal-cards">
          {stories.slice(1, 3).map(story => (
            <HorizontalStoryCard
              story={story}
              key={story.id}
              hideAuthorWithTime
            />
          ))}
          <StopPress
            collection={collections[0]}
            planName={
              domainSlug
                ? "न्यूज़लॉन्ड्री डाक"
                : currentPath === "/events"
                ? "NL Events"
                : "NL Digest"
            }
          />
        </div>
      </div>
    </div>
  );
};

ThreeStoriesWithSubscribeMail.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object
  }),
  stories: PropTypes.array
};

export default ThreeStoriesWithSubscribeMail;
