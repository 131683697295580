/* eslint-disable no-undef */
import assetify from "@quintype/framework/assetify";
import Axios from "axios";
import get from "lodash/get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { newsletterData } from "../../../constants";

import { Checkbox } from "../../atoms/checkbox";
import { TextField } from "../../atoms/textfield";

import LoadingIcon from "../../../../assets/images/loader.svg";
import Loader from "../../../components/atoms/loader";
import "./newsletter.m.css";

const NewsletterPageBase = ({ config }) => {
  const [data, setData] = useState({ lists: [] });
  const [loading, setLoading] = useState(false);

  const member = useSelector(state => get(state, ["member"], {}));

  useEffect(() => {
    const fetchMailchimpListsData = async () => {
      setLoading(true);
      try {
        const listsResponse = await fetch("/newsletter-api");
        const lists = await listsResponse.json();

        const filteredList = lists.lists.filter(
          list => list.id !== "82e2052fcc" || list.id !== "3cf030021e"
        );

        setData({ lists: filteredList });
        setLoading(false);
      } catch (error) {
        console.warn(error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchMailchimpListsData();
  }, []);

  const domainSlug = config.domainSlug;

  const [checkedItems, setCheckedItems] = useState([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [prompt, setPrompt] = useState(null);
  const [showLoader, setShowLoader] = useState();

  const handleChange = e => {
    const isChecked = checkedItems.includes(e.target.value);

    if (!isChecked) {
      setCheckedItems([...checkedItems, e.target.value]);
    } else {
      setCheckedItems(checkedItems.filter(ci => ci !== e.target.value));
    }
  };

  useEffect(() => {
    if (member) {
      setEmail(member.email);
    } else {
      setEmail("");
    }
  }, [member]);

  const firstName = member
    ? member["first-name"]
      ? member["first-name"]
      : member.name
    : "NL User";

  const lastName = member ? member["last-name"] : "";

  const onSubmitClick = async e => {
    e.preventDefault();

    if (!checkedItems.length) {
      setError("Please select atleast one newsletter.");
      return;
    }

    try {
      setShowLoader(true);

      await Axios.post(`/newsletter-api/lists/members`, {
        email,
        firstName,
        lastName,
        lists: checkedItems
      });

      setShowLoader(false);
      setPrompt("Thanks for signing up. See you in your inbox soon.");

      setTimeout(() => {
        setEmail("");
        setCheckedItems([]);
        setPrompt(null);
      }, 3000);
    } catch (error) {
      setError(error.message);
      setShowLoader(false);
    }
  };

  if (loading) {
    return (
      <div className="full-width-with-padding" styleName="container">
        <div styleName="wrapper">
          <Loader color="#ec2227" />
        </div>
      </div>
    );
  }

  return (
    <div className="full-width-with-padding" styleName="container">
      <div styleName="wrapper">
        {!domainSlug ? (
          <h1 styleName="main-headline">Join Our Newsletter</h1>
        ) : (
          <h1 styleName="main-headline">हमारे न्यूज़लेटर से जुड़िए</h1>
        )}
        {!domainSlug ? (
          <p styleName="main-description">
            Newslaundry wants to make news a service to the public, not to the
            advertisers. We believe those who make any effort sustainable,
            determine its direction.
          </p>
        ) : (
          <p styleName="main-description">
            न्यूज़लॉन्ड्री का प्रयास खबरों को विज्ञापनादाताओं के चंगुल से मुक्त
            करवा कर जनहित से जोड़ना है. हमें भरोसा है कि इस दिशा में सार्थक
            प्रयास करने वाला ही मीडिया की दिशा तय करेगा.
          </p>
        )}
        <div styleName="plan-grid">
          {data.lists.map(list => {
            return (
              <div
                key={list.id}
                styleName={`plan-wrapper ${
                  checkedItems.includes(list.id) ? "plan-wrapper-active" : ""
                }`}
              >
                <label>
                  <div styleName="checkbox-wrapper">
                    <Checkbox
                      id={list.id}
                      value={list.id}
                      name={list.name}
                      checked={checkedItems.includes(list.id)}
                      onChange={handleChange}
                    />
                    <span styleName="checkmark"></span>
                  </div>
                  <div styleName="headine-wrapper">
                    <h4 styleName="headline">{list.name}</h4>
                  </div>
                  <div styleName="description">
                    {newsletterData.find(n => n.listId === list.id)
                      ? newsletterData.find(n => n.listId === list.id)
                          .description
                      : ""}
                  </div>
                </label>
              </div>
            );
          })}
        </div>
        {showLoader === true && (
          <div styleName="modal">
            <div styleName="modal-content">
              <img src={assetify(LoadingIcon)} alt="loading" />
            </div>
          </div>
        )}
        <form onSubmit={onSubmitClick} styleName="subscribe-view-content">
          <TextField
            type="email"
            required
            value={email}
            error={error}
            onChange={e => setEmail(e.target.value)}
            placeholder="Type your email"
          />
          <button styleName="submit-btn" type="submit">
            Sign Up
          </button>
        </form>
        {prompt && (
          <div styleName="modal">
            <div styleName="modal-content show-message">{prompt}</div>
          </div>
        )}
      </div>
    </div>
  );
};

NewsletterPageBase.propTypes = {
  config: PropTypes.object
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {}),
  currentPath: get(state, ["qt", "currentPath"], "")
});

export const NewsletterPage = connect(mapStateToProps)(NewsletterPageBase);
