import React from "react";
import PropTypes from "prop-types";
import getStoriesByCollection from "../../../utils";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import { CollectionName } from "../../atoms/collection-name";
import CommonStoryCard from "../../molecules/common-story-card";
import { CardImage } from "../../atoms/card-image";
import "./four-stories-component.m.css";

export const HeroStory = ({ story }) => {
  return (
    <article>
      <div styleName="hero-story-wrapper">
        <div styleName="hero-image">
          <CardImage story={story} />
        </div>
        <div styleName="content-wrapper">
          <div styleName="headline">
            <Headline story={story} externalLink={true} />
          </div>
          <div>
            <Subheadline story={story} className="typography-font-lg" />
          </div>
        </div>
      </div>
    </article>
  );
};

HeroStory.propTypes = {
  story: PropTypes.object
};

export const FourStoriesComponent = ({ collection }) => {
  const stories = getStoriesByCollection(collection);

  if (stories && stories.length < 1) {
    return null;
  }

  return (
    <div>
      <CollectionName collection={collection} />
      <div styleName="wrapper">
        <div>
          {stories.slice(0, 1).map((story, index) => (
            <HeroStory story={story} key={index} />
          ))}
        </div>
        <div styleName="second-row-cards">
          {stories.slice(1, 4).map((story, index) => (
            <CommonStoryCard
              story={story}
              key={index}
              showAuthorWithTime={false}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

FourStoriesComponent.storyLimit = 10;
FourStoriesComponent.propTypes = {
  collection: PropTypes.object
};
