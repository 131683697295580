import React from "react";
import "./Slider.m.css";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

const politicalValues = [
  {
    label: "Libertarian",
    value: 11,
    description:
      "Individual freedoms form the bedrock of society. The richest and poorest are treated the same. Free market should be unfettered, no matter how many people starve or lack basic needs. No government regulations, permits or patronage, but also no ration, no welfare, no unreserved coaches on trains. No bailouts to anybody – ever. No matter how big or small.",
  },
  {
    label: "In between Capitalist & Libertarian",
    value: 10,
    description: "",
  },
  {
    label: "Capitalist",
    value: 9,
    description:
      "Very limited state intervention in economic activity. Minimum regulation. Believes the ‘invisible hand’ will certainly take care of resource allocation and efficiency. State only provides the overarching structure and intervenes for stability. No handouts or bailouts – but is happy when big firms receive largesse.",
  },
  {
    label: "In between Capitalist & Free Market Optimist",
    value: 8,
    description: "",
  },
  {
    label: "Free Market Optimist",
    value: 7,
    description: "Private enterprise implies innovation and limited deregulation can help even the poorest. Genuinely believes in trickle-down economics and that the poor will believe from policies. Worries that bailouts to big firms will take away from society, but has faith that society works best when people and institutions are shaped to work according to market principles.",
  },
  {
    label: "In between Limited Free Market Welfarist & Free Market Optimist",
    value: 6,
    description: "",
  },
  {
    label: "Limited Free Market Welfarist",
    value: 5,
    description:
      "Believes in loose and limited regulation of markets and private enterprise. Trusts the market to arrive at optimum levels of resource allocation. The state spends significant amounts for public goods like health, education, safety and justice. Private enterprise, while given more freedom, is heavily taxed.",
  },
  {
    label: " In between Socialist & Limited Free Market Welfarist",
    value: 4,
    description: "",
  },
  {
    label: "Socialist",
    value: 3,
    description:
      "Limited private enterprise with licences and permits determining who gets to make what. Garibi hatao is the pet slogan. Believes the government is best equipped for this task. Tight regulation of markets and super tight regulation of financial markets. Government provides for all basic needs.",
  },
  {
    label: "In between Communist & Socialist",
    value: 2,
    description: "",
  },
  {
    label: "Communist",
    value: 1,
    description:
      " A classless society with no individual property rights. The state controls the means of production and distributes its fruits equitably. Everybody works for the collective good. To each according to his need and from each according to his ability. The Little Red Book is their constitution.",
  },
];

const socialValues = [
  {
    label: "Soldier of Hindutva",
    value: 11,
    description:
      "Hindu khatre mein hain. Muslims, Christians and Marxists go to Pakistan. Hindu rashtra banayenge – akhand Hindu rashtra. Never mind what’ll happen when Pakistan becomes part of India in the true Akhand Bharat way. Will find reason to defend everything from Babri demolition to beef ke naam lynching and even the garlanding of convicts. Hindu ke naam pe will support any cause – even Nathuram Godse. ",
  },
  {
    label: "In between Majority Indulger & Soldier of Hindutva",
    value: 10,
    description: "   ",
  },
  {
    label: "Majority Indulger",
    value: 9,
    description:
      "Hindus have been ignored at the cost of pandering to the minority. Does not support Hindutva but is soft on it. Hindu-patience-is-being-tested kind of zone. Sympathetic to the Hindu cause but not a minority hater. Will not condone a lynching but will not be alarmed at the the direction its taking the nation.",
  },
  {
    label: "In between Majority Sympathiser & Majority Indulger",
    value: 8,
    description: "   ",
  },
  {
    label: "Majority Sympathiser",
    value: 7,
    description: "Critical of minority appeasement by previous governments but is angered by minority harassment. Is willing to be critical of the majoritarian cause to achieve some level of social harmony. Worries about backwardness of minority traditions and crusades towards freeing them from their own selves.",
  },
  {
    label: "In between Context Free Secularist & Majority Sympathiser",
    value: 6,
    description: "   ",
  },
  {
    label: "Context Free Secularist",
    value: 5,
    description:
      "Doesn’t matter who is the majority and minority, no one must be indulged.  Everyone has to learn to take criticism in the jaw and religion in politics is a strict no-no. Keep politics and religion apart. Doesn’t really care for the religious nuts or religion across the board.  Will react to a transgression by any side in exactly the same way, irrespective of political and social power dynamics.",
  },
  {
    label: " In between Minority Indulger & Context Free Secularist",
    value: 4,
    description: "    ",
  },
  {
    label: "Minority Indulger",
    value: 3,
    description:
      "Since the minority is just that  – a minority – they should be given privileges. They should have rights in line with their customs even if it deviates somewhat from the general rule, because as the more marginalised (statistically), that latitude is fair.  A little uncomfortable with the current Hindu identity but generally cool with anything.",
  },
  {
    label: "In between Minority Fundamentalist & Minority Indulger",
    value: 2,
    description: "     ",
  },
  {
    label: "Minority Fundamentalist",
    value: 1,
    description:
      "Let all laws be as per minority community – religion or other grouping. Minority community, best community. Privileges to my religion and law as per my religion, and punish anyone who remotely criticises it. For the sake of the community will support any cause or violence, even Osama and Bhindrawale types.",
  },
];

export default function Slider({
  type = "political",
  hidetext = false,
  index = -1,
}) {
  const values = type === "political" ? politicalValues : socialValues;
  const [valued, setValue] = React.useState(1);
  const [windowDimensions, setWindowDimensions] = useState({
    width: 12,
    height: 28,
  });

  useEffect(() => {
    setWindowDimensions(() => {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height,
      };
    });
    function handleResize() {
      setWindowDimensions(() => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
          width,
          height,
        };
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div styleName={`sliderContainer ${type}`}>
      <div styleName="dots">
        <input
          styleName={`slider ${type} slider-color`}
          type="range"
          min="1"
          max="11"
          step="1"
          defaultValue={1}
          value={valued}
          onChange={(e) => {
            e.preventDefault();
            setValue(parseInt(e.target.value));
            index(parseInt(e.target.value));
          }}
        />
        <div styleName={`${type === "social" ? "dot-box-2" : "dot-box "}`}>
          {[...Array(11)].map((_, index2) => (
            <div
              key={index2}
              styleName={`${index2 % 2 === 0 ? "dot" : "dot-small"}`}
            />
          ))}
        </div>
      </div>
      <div>
        <output styleName={type}>
          <h3>
            {values.map((ini) => {
              return ini.value === valued ? ini.label : "";
            })}
          </h3>
        </output>
        {hidetext && (
          <div
            styleName={`${
              type === "social" ? "desc-social" : "desc-political "
            }`}
          >
            {values.map((ini) => {
              return ini.value === valued ? ini.description : "";
            })}
          </div>
        )}
      </div>
    </div>
  );
}
Slider.propTypes = {
  index: PropTypes.any,
  hidetext: PropTypes.any,
  type: PropTypes.any,
};
