/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import "./podcast-rss.m.css";
import { MdContentCopy } from "react-icons/md";
import { useUserSubscription, useCopyToClipboard } from "../../helper/hooks";
import { isEmpty } from "lodash";
import { getPodcastToken } from "../../helper/api";
import Loader from "../../atoms/loader";
const shows = require("./shows.json");

// Pass params in this order
// getPodcastToken(memberId, subscriptionName, end_timestamp ,showId);

export function PodcastRssPage() {
  const useSub = useUserSubscription();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (useSub.loading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [useSub.loading]);

  return (
    <div styleName="wrapper">
      <h2>Private RSS links for our Paywall Podcasts</h2>
      <p>
        Click here to learn how to add the private links and access the show in
        a podcast player of your choice.
      </p>
      <p>
        Supported Apps for iOS : Apple Podcast, Castro, Downcast, OverCast,
        Pocket Casts
      </p>
      <p>Supported Apps for Android : PocketCast, Podcast Addict, Player FM</p>
      <div styleName="card-container">
        {loading ? (
          <Loader color={"#ec2227"} width={"80px"} />
        ) : (
          shows.map(show => <Card key={show._id} show={show} />)
        )}
      </div>
    </div>
  );
}

function Card({ show }) {
  const useSub = useUserSubscription();
  const [isCopied, handleCopy] = useCopyToClipboard();
  const [tokenState, setTokenState] = useState("");
  const [linkButton, setLinkButton] = useState(true);

  const userSub = useSub.userSubscription.find(
    sub =>
      sub.active &&
      (sub.group_name.toLowerCase() === "game changer" ||
        sub.group_name.toLowerCase() === "3 year game changer")
  );

  const showClickHandler = showId => async e => {
    if (!isEmpty(useSub)) {
      if (userSub) {
        const { token } = await getPodcastToken(
          useSub.userSubscription.find(sub => sub.active).subscriber_id,
          useSub.userSubscription.find(sub => sub.active).group_name,
          useSub.userSubscription.find(sub => sub.active).end_timestamp,
          showId
        );
        setTokenState(`https://newslaundry.com/podcast-rss/getFeed/${token}`);
        setLinkButton(false);
      }
      if (!userSub) {
        window.alert("No subscription or Not Subscribed as Game Changer");
      }
    } else {
      window.alert("login!");
    }
  };

  useEffect(() => {
    if (isCopied) {
      window.alert(
        "The Rss Feed link has been Copied, paste it into your Podcast Client"
      );
    }
  }, [isCopied]);

  return (
    <div key={show._id} styleName="card">
      <img src={show.cover} height="300px" alt="show cover image" />
      {linkButton ? (
        <button onClick={showClickHandler(show._id)}>Get Feed Link</button>
      ) : (
        <div styleName="contentcopy">
          <input defaultValue={tokenState} type="text" readOnly />
          <button onClick={() => handleCopy(tokenState)}>
            <MdContentCopy color="white" size="22px" />
          </button>
        </div>
      )}
    </div>
  );
}
