import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link, ResponsiveImage } from "@quintype/components";
import { getHeroImage, getStoryMetadata } from "../../../utils";
import { SectionName } from "../../atoms/section-tag";

import "./vertical-mini-story-card.m.css";

const VerticalMiniStoryCard = ({ story }) => {
  const config = useSelector(({ qt }) => qt.config);

  const heroS3Key = getHeroImage(story, config);
  const slug =
    story["story-template"] === "news-elsewhere"
      ? story.metadata["reference-url"] || story.url
      : story.url;

  return (
    <div styleName="vertical-mini-story-card">
      <div styleName="hero-image">
        {story["story-template"] === "news-elsewhere" ? (
          <a
            href={slug}
            target={
              story["story-template"] === "news-elsewhere" ? "_blank" : ""
            }
          >
            <ResponsiveImage
              slug={heroS3Key}
              metadata={getStoryMetadata(story)}
              aspectRatio={[16, 9]}
              imgParams={{ auto: ["format", "compress"] }}
            />
          </a>
        ) : (
          <Link
            href={slug}
            target={
              story["story-template"] === "news-elsewhere" ? "_blank" : ""
            }
          >
            <ResponsiveImage
              slug={heroS3Key}
              metadata={getStoryMetadata(story)}
              aspectRatio={[16, 9]}
              imgParams={{ auto: ["format", "compress"] }}
            />
          </Link>
        )}
      </div>
      <div styleName="content-wrapper">
        <div styleName="section">
          <SectionName story={story} />
        </div>
        <div styleName="headline">
          {story["story-template"] ? (
            <a
              href={slug}
              target={
                story["story-template"] === "news-elsewhere" ? "_blank" : ""
              }
            >
              <h2>{story.headline}</h2>
            </a>
          ) : (
            <Link
              href={slug}
              target={
                story["story-template"] === "news-elsewhere" ? "_blank" : ""
              }
            >
              <h2>{story.headline}</h2>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

VerticalMiniStoryCard.propTypes = {
  story: PropTypes.object
};

export { VerticalMiniStoryCard };
