/* eslint-disable react/prop-types */
import React from "react";
import "./cases-component.m.css";

const LegalCaseComponent = ({ item }) => {
  return (
    <section styleName="legal-cases-wrapper">
      <h2 styleName="legal-cases-name">{item.headline}</h2>
      <div styleName="legal-cases-description">
        {item.description.map(text => text.text)}
      </div>
    </section>
  );
};

export default LegalCaseComponent;
