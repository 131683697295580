import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { AccessType } from "@quintype/components";
import IndependenceTemplate from "./independence-template";
import { connect } from "react-redux";
import {
  IS_OPEN_LOGIN_FORM,
  SELECTED_PLAN,
  PAYMENT_CHECKOUT_PAGE
} from "../../../helper/actions";
import IntegrationData from "../../../../../../config/integrations";

import { SubscriptionTestimonial } from "../subscription-testimonial";
// import { SubscriptionHelper } from "../subscription-helpers";
import { SubscriptionCollection } from "../subscription-collection";
import { SubscriptionBankDetails } from "../subscription-bank-details";
import { isDesktopSizeViewport } from "../../../../utils";
import "./independence.m.css";

function SubscriptionBase(props) {
  const integrationsConfig = IntegrationData();
  const email = get(props, ["member", "email"], "");
  const phone = get(props, ["member", "metadata", "phone"], "");
  const isStaging = get(
    props.config,
    ["publisher", "publisher", "accessTypeEnvironment"],
    true
  );
  const enableAccesstype = get(
    props.config,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  return (
    <div>
      <AccessType
        enableAccesstype={enableAccesstype}
        isStaging={false}
        accessTypeKey={accessTypeKey}
        email={email}
        phone={phone}
        accessTypeBkIntegrationId={accessTypeBkIntegrationId}
      >
        {({ initAccessType, getSubscriptionForUser, accessIsLoading }) => {
          return (
            <IndependenceTemplate
              initAccessType={initAccessType}
              accessIsLoading={accessIsLoading}
              getSubscriptionForUser={getSubscriptionForUser}
              {...props}
            />
          );
        }}
      </AccessType>
    </div>
  );
}

SubscriptionBase.propTypes = {
  config: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function(payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url),
  selectedPlan: function(plan, redirectUrl, plans) {
    dispatch({
      type: SELECTED_PLAN,
      plan: plan,
      planType: "standard",
      redirectUrl: redirectUrl,
      subscriptionPlans: plans
    });
  },
  paymentCheckout: function(payload) {
    dispatch({
      type: PAYMENT_CHECKOUT_PAGE,
      payload: payload
    });
  }
});

const mapStateToProps = state => {
  const trialgroup1 = get(state, [
    "qt",
    "config",
    "publisher",
    "publisher",
    "trialSubscription",
    "trialGroup1"
  ]);
  const trialgroup2 = get(state, [
    "qt",
    "config",
    "publisher",
    "publisher",
    "trialSubscription",
    "trialGroup2"
  ]);
  // const allSubscriptions = get(state, ["subscriptions"], []);

  return {
    config: get(state, ["qt", "config"], {}),
    trialgroup1,
    trialgroup2,
    member: get(state, ["member"], null),
    checkoutActiveTab: get(state, ["paymentCheckout"], "subscription")
  };
};

const IndependenceBase = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionBase);

export const IndependenceDayOffer = props => {
  const [deviceWidth, deviceWidthHandler] = useState("");
  useEffect(() => {
    deviceWidthHandler(!isDesktopSizeViewport() ? "full-width" : "");
  }, []);

  const image = get(props.config, ["publisher", "publisher", "image"], "");
  const text = get(props.config, ["publisher", "publisher", "text"], "");
  const secondText = get(
    props.config,
    ["publisher", "publisher", "secondText"],
    ""
  );
  const textInRed = get(
    props.config,
    ["publisher", "publisher", "textInRed"],
    ""
  );
  return (
    <div>
      <IndependenceBase />
      <SubscriptionBankDetails />
      <SubscriptionTestimonial {...props} />
      {/* <SubscriptionCollection {...props} /> */}
    </div>
  );
};

IndependenceDayOffer.propTypes = {
  config: PropTypes.object
};
