import { Link } from "@quintype/components";
import Axios from "axios";
import { get } from "lodash";
import { parseUrl } from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { SubscribePopup } from "../../../../molecules/popup/subscribe-popup";
import { saveDataToLocalStorage } from "../../../subscription-checkout/utils";
import Spectrum from "../../components/Grid/Spectrum";
import Slider from "../../components/Slider/Slider";
import "../../index.m.css";
import Select from "react-select";

const Explainer = () => {
  // Sepctrum code VARS
  const [averages, setAverages] = useState([]);
  const [windowDimensions, setWindowDimensions] = useState({
    width: 12,
    height: 28,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [noVotes, setNoVotes] = useState(false);
  const [voteClicked, setVoteClicked] = useState(false);
  const [swingLeftClicked, setSwingLeftClicked] = useState(false);
  const [swingRightClicked, setSwingRightClicked] = useState(false);
  const [isInside, setIsInside] = useState(true);
  const [activeBtn, setActivebtn] = useState("subs");
  const [originalAverages, setOriginalAverages] = useState([]);
  const [journalists, setJournalists] = useState([]);
  const [journalistData, setJournalistData] = useState({});
  const [totalVotes, setTotalVotes] = useState(null);
  const member = useSelector((state) => get(state, ["member"], null));
  const [UniqueOrgainistions, setUniqueOrgainistions] = useState([]);
  const fetchSubscription = useSelector((state) =>
    get(state, ["fetchSubscription"], null)
  );
  const { qt } = useSelector((state) => state);
  const customNoOptionsMessage = () => 'Oops! Journalist not found. Try others and suggest missing journalist on the voting page.';

  const authHost = qt.config.publisher.publisher.auth_host;
  const isActive = fetchSubscription && fetchSubscription.isActive;
  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : null,
        color: isDisabled
          ? null
          : isSelected
          ? "#c00"
          : isFocused
          ? "#000"
          : null,
      };
    },
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 })
  };

  const warn = {
    color: "#ed1b24",
    background: "#ffeeee",
    padding: "20px",
    borderRadius: "5px",
    textAlign: "center",
  };

  const warn2 = {
    color: "#ed1b24",
    textAlign: "center",
    padding: "10px",
  };
  const ref1 = useRef(null);
  useEffect(() => {
    function handleScroll() {
      let ins = window.pageYOffset < 2319;
      if (ins) {
        setIsInside(true);
      } else {
        setIsInside(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const fetchJournalists = async () => {
      try {
        //console.log("jas");
        const response = await Axios.get(
          "https://server.newslaundry.com/journalismSpectrum/journalists"
        );
        setJournalists(response.data.journalists);

        const UniqueOrgainistion = filterJournalistsWithSameOrganization(
          response.data.journalists
        );
        setUniqueOrgainistions(UniqueOrgainistion);

        const totalVotes = await Axios.get(
          "https://server.newslaundry.com/journalismSpectrum/submissions"
        );
        setTotalVotes(totalVotes.data.spectrumSubmissions.length);
        const x = {};
        response.data.journalists.forEach((j) => {
          x[j.id] = {
            name: j.name,
            organization: j.organization,
          };
        });
        setJournalistData(x);
      } catch (error) {
        console.error({ fetchJournalistsError: error });
      }
    };

    fetchJournalists();
  }, []);

  useEffect(() => {
    activeBtn === "team"
      ? Axios.get(
          "https://server.newslaundry.com/journalismSpectrum/averages/team"
        )
          .then((response) => {
            setAverages(response.data.nlTeamAverages);
            setOriginalAverages(response.data.nlTeamAverages);
            setNoVotes(false);
          })
          .catch((err) => console.log(err))
      : activeBtn === "subs"
      ? Axios.get(
          // "https://server.newslaundry.com/journalismSpectrum/averages/subs"
          "https://server.newslaundry.com/journalismSpectrum/averages"
        )
          .then((response) => {
            // setAverages(response.data.nlSubsAverages);
            setAverages(response.data.allAverages);
            setOriginalAverages(response.data.allAverages);
            setNoVotes(false);
            // console.log("subs api called  ", response.data.nlSubsAverages);
            // console.log("subs hit  "+ response.data.nlSubsAverages)
          })
          .catch((err) => console.log(err))
      : Axios.get(
          `https://server.newslaundry.com/journalismSpectrum/submissions/user/${member.email ||
            ""}`
        ).then((response) => {
          if (response.data.userSpectrumSubmissions.length) {
            setAverages(response.data.userSpectrumSubmissions);
            setOriginalAverages(response.data.userSpectrumSubmissions);
          } else setAverages([]);
          if (response.data.userSpectrumSubmissions.length == 0)
            setNoVotes(true);
          // console.log("my vote hit  " + response.data.userSpectrumSubmissions);
        });
  }, [activeBtn]);

  function filterJournalistsWithSameOrganization(journalistsArray) {
    // const organizationMap = new Map();

    // for (const journalist of journalistsArray) {
    //   if (journalist.organization) {
    //     if (!organizationMap.has(journalist.organization)) {
    //       organizationMap.set(journalist.organization, []);
    //     }
    //     organizationMap.get(journalist.organization).push(journalist);
    //   }
    // }

    // const filteredJournalists = [];
    // for (const [organization, journalists] of organizationMap) {
    //   if (journalists.length > 1) {
    //     filteredJournalists.push(...journalists);
    //   }
    // }

    const uniqueOrganizations = new Map();
    for (const journalist of journalistsArray) {
      if (!uniqueOrganizations.has(journalist.organization)) {
        uniqueOrganizations.set(journalist.organization, journalist);
      }
    }
    return Array.from(uniqueOrganizations.values());
  }
  const options = journalists
    .map((journalist) => ({
      value: journalist.id,
      label: journalist.name,
      type: "name",
    }))
    .concat(
      UniqueOrgainistions.map((journalist) => ({
        value: journalist.organization,
        label: journalist.organization,
        type: "organisation",
      }))
    );
  options.unshift({ value: "dlknmelkd", label: "All", type: "name" });
  // const optionsSet = new Set();

  // journalists.forEach(journalist => {
  //   optionsSet.add({
  //     value: journalist.id,
  //     label: journalist.name,
  //     type: 'name'
  //   });
  // });

  // journalists.forEach(journalist => {
  //   if (journalist.organization) {
  //     optionsSet.add({
  //       value: journalist.organization,
  //       label: journalist.organization,
  //       type: 'organization'
  //     });
  //   }
  // });

  //const options = Array.from(optionsSet);
  // function isObjectEqual(obj1, obj2) {
  //   return obj1.organization === obj2.organization && obj1.name === obj2.name;
  // }
  // const filteredOptions = options.filter(
  //   (currentObj, index, array) =>
  //     array.findIndex((obj) => isObjectEqual(obj, currentObj)) != index
  // );

  const handleBtnClick = async () => {
    const params = parseUrl(qt.currentPath);
    saveDataToLocalStorage("voting", "step");
    const callbackUrl = get(
      params,
      ["query", "callback-url"],
      global.location && global.location.origin
    );
    const redirectUrl = get(
      params,
      ["query", "redirect-url"],
      global && global.location && global.location.href
    );
    window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
  };
  // const journalistData = {};
  // journalists.forEach(journalist => {
  //   journalistData[journalist.id] = {
  //     name: journalist.name,
  //     organization: journalist.organization
  //   };
  // });
  // console.log({ journalists, journalistData });

  const filterAverages = (search) => {
    const filteredAverages = originalAverages.filter((average) => {
      const journalistInfo = journalistData[average.journalistId];

      if (journalistInfo) {
        return (
          journalistInfo.name.toLowerCase().includes(search.toLowerCase()) ||
          (journalistInfo.organization &&
            journalistInfo.organization
              .toLowerCase()
              .includes(search.toLowerCase()))
        );
      } else {
        return false;
      }

      // if (activeBtn === "subs") {
      //   return (
      //     journalistInfo &&
      //     (journalistInfo.name
      //       .toLowerCase()
      //       .includes(searchTerm.toLowerCase()) ||
      //       (journalistInfo.organization &&
      //         journalistInfo.organization
      //           .toLowerCase()
      //           .includes(searchTerm.toLowerCase())))
      //   );
      // } else if (activeBtn === "yourVotes") {
      //   return (
      //     journalistInfo &&
      //     (journalistInfo.name
      //       .toLowerCase()
      //       .includes(searchTerm.toLowerCase()) ||
      //       (journalistInfo.organization &&
      //         journalistInfo.organization
      //           .toLowerCase()
      //           .includes(searchTerm.toLowerCase())))
      //   );
      // }
      // return false;
    });

    if (search.length === 0) {
      setAverages(originalAverages);
    } else {
      setAverages(filteredAverages);
    }
  };

  const handleInputChange = (e) => {
    //console.log({ e: e.target.value });
    setSearchTerm(e.target.value);
    filterAverages(e.target.value);
  };

  const handleSelectChange = (selectedOption) => {
    setSearchTerm(selectedOption ? selectedOption.label : "");
    if (selectedOption.label === "All") {
      setAverages(originalAverages);
      filterAverages("");
    } else {
      filterAverages(selectedOption ? selectedOption.label : "");
    }
    const section = document.querySelector("#spectrum-grid");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <>
      {modalOpen && (
        <SubscribePopup showModal={modalOpen} setShowModal={setModalOpen} />
      )}

      <div styleName="section hideScbar">
        <h3>Where does your favorite journalist lie on the Media Biascope?</h3>
        {member ? (
          <>
            <p>
              Chart based on {totalVotes && <b>{`${totalVotes}`}</b>} votes from
              our subscribers - and you. Use the toggle button below to mark
              them on the grid.{" "}
            </p>
            <br />
            <p styleName="scroll-helper">
              {" "}
              Slide horizontally to view the full Biascope
            </p>
            <div styleName="tabberWrapper">
              <div styleName="chartTabber">
                <button
                  styleName={activeBtn === "subs" ? "active" : ""}
                  onClick={() => {
                    setActivebtn("subs");
                    filterAverages(searchTerm);
                  }}
                  id="subs"
                >
                  NL Subscribers
                </button>

                <button
                  styleName={activeBtn === "yourVotes" ? "active" : ""}
                  onClick={() => {
                    setActivebtn("yourVotes");
                    filterAverages(searchTerm);
                  }}
                  id="subs"
                >
                  My Votes
                </button>
              </div>
            </div>
          </>
        ) : (
          <>
            <p>
              Chart based on {totalVotes && <b>{`${totalVotes}`}</b>} votes from
              our subscribers.
            </p>
          </>
        )}
        {noVotes && (
          <>
            <br />{" "}
            <p
              style={{ ...warn, flexDirection: "row", gap: "10px" }}
              styleName="center-align"
            >
              Vote for Media Biascope to register your votes.
              <Link href={"/voting"} styleName="ctav" id="vote-button-2">
                Cast your vote now <span> ➜</span>
              </Link>
            </p>
          </>
        )}

        {/* <div styleName="search" style={{ marginTop: "40px" }}>
          <FaSearch styleName="search-icon" />
          <input
            type="text"
            placeholder="Search by journalist or organisation name"
            onChange={handleInputChange}
          />
          <i className="fa fa-search fs-2"></i>
        </div> */}
        <div styleName="list-wrapper">
          <Select
            styleName="custom-select"
            className="basic-single"
            classNamePrefix="select"
            options={options}
            styles={colourStyles}
            value={options.find((option) => option.label === searchTerm)}
            onChange={handleSelectChange}
            noOptionsMessage={customNoOptionsMessage}
            placeholder="Search by journalist name or organisation"
          />
        </div>

        <span id="spectrum-grid"></span>
        
        <div
          styleName="container"
          style={{ position: "relative", marginLeft: "-5vw" }}
        >
          {!averages.length&&<p styleName="error-msg">No votes yet for {`${searchTerm}`} 
        
        </p>}
          <Spectrum selectedJournalist={null} avgJournalists={averages} />
        </div>
      </div>

      <div styleName="center-align">
        <div
          ref={ref1}
          styleName="section-vote"
          id="vote-div"
          style={{ backgroundColor: "#ffeeee", padding: "10px" }}
        >
          <h2>
            What biases do these journalists have? Vote yourself and let the
            world know!
          </h2>
          <div styleName="">
            <Link href={"/voting"} styleName="ctav" id="vote-button-2">
              Cast your vote now <span> ➜</span>
            </Link>
          </div>
          <br />
        </div>
        {voteClicked && (
          <p style={warn2}>
            <b>
              {" "}
              Please subscribe to Newslaundry to vote for Media Biascope. Click{" "}
              <a href="https://www.newslaundry.com/subscription?ref=nav">
                <u>here</u>
              </a>{" "}
              to subscribe.
            </b>
          </p>
        )}
      </div>
      {isInside ? null : (
        <div styleName="center-align">
          <div
            ref={ref1}
            styleName={
              window.innerWidth > 1260 ? "vote-float" : "section-floating"
            }
            style={{ backgroundColor: "var(--nl-red)", color: "white" }}
          >
            {voteClicked ? (
              <p>
                <b>
                  {" "}
                  Please subscribe to Newslaundry to vote. Click{" "}
                  <a href="https://www.newslaundry.com/subscription?ref=nav">
                    <u>here</u>
                  </a>{" "}
                  to subscribe.
                </b>
              </p>
            ) : (
              <p>
                <b>
                  What biases do these journalists have?
                  {window.innerWidth > 1028 ? null : <br />} Vote yourself and
                  let the world know!
                </b>
              </p>
            )}
            <div styleName="">
              <Link
                href={"/voting"}
                styleName="ctav"
                style={{ backgroundColor: "white", color: "var(--nl-red)" }}
                id="vote-button-2"
              >
                Vote <span> ➜</span>
              </Link>
            </div>
            <br />
          </div>
        </div>
      )}
      <span id="methodology"></span>
      <div styleName="section methodBox">
        <h3 styleName="methodology">
          About the Biascope, <i>continued from the top</i>
        </h3>
        <p></p>
        <p>
          The process of votes and category description are explained below. You
          too will come at this task through your own biases, but working
          together, we can balance things out. It’s not perfect, it’s not as
          nuanced as we’d like, and it’s potentially reductionist – but it's
          better than nothing.
          <br />
          <br />
          The Media Biascope will allow you to plot news professionals on how
          you see their ideological inclinations and hopefully make them
          self-aware of how discerning news consumers view them. The overall
          result may help you identify your own biases as well. Each individual
          on this spectrum appears in the geometric mean positioning based on
          how Newslaundry subscribers have plotted each person. Surprise us.
          <br />
          <br />
        </p>
      </div>
      <div styleName="section methodBox">
        <h3 styleName="methodology">Methodology</h3>
        <p styleName="border-bottom-2"></p>
        <p>
          Journalists are placed on the Biascope grid based on votes. Their
          final position is a cumulative average of all the votes they get. Try
          it out for yourself and see where your favourite journalist winds up.
          <br />
          <br />
        </p>
      </div>
      <div styleName="slider-box">
        <div styleName="section slideToggler">
          <h3>Socio-Political Spectrum</h3>
          <p styleName="border-bottom-2"></p>
          <p>
            Use the sliders below to learn more about socio-political views in
            each segment of the spectrum.
          </p>
          <span>
            <Slider type="social" hidetext={true} index={-1} />
          </span>
        </div>
        {/* Dummy Sliders */}
        <div styleName="section slideToggler">
          <h3>Economic Spectrum</h3>
          <p styleName="border-bottom-2"></p>
          <p>
            Use the sliders below to learn more about economical views in each
            segment of the spectrum.
          </p>
          <span>
            <Slider type="political" hidetext={true} index={-1} />
          </span>
        </div>

        {/* Dummy Sliders End */}
      </div>
      <br />
      <br />
    </>
  );
};

export default Explainer;
