import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import get from "lodash/get";
// import CommonStoryCard from "../../../molecules/common-story-card";
// import getStoriesByCollection from "../../../../utils";
import BaseCard from "../../../molecules/base-card";
import "./subscription-collection.m.css";
import BaseCardStory from "../../../molecules/base-card-story";

export const SubscriptionCollection = ({ data }) => {
  const [isHindi, setIsHindi] = useState(false);

  useEffect(() => {
    setIsHindi(!!(global.location && global.location.host.includes("hindi")));
  }, []);

  const subscriptionCollection = data.collection.collection;
  const hindiSubscriptionCollection = data.hindiCollection.collection;

  const collections = subscriptionCollection.items.filter(
    collection => collection.type === "collection"
  );

  return (
    <div
      className="full-width-with-padding"
      styleName="subscription-collection-wrapper"
    >
      {isHindi ? (
        hindiSubscriptionCollection.summary ? (
          <h2 styleName="summary">{hindiSubscriptionCollection.summary}</h2>
        ) : (
          subscriptionCollection.summary && (
            <h2 styleName="summary">{subscriptionCollection.summary}</h2>
          )
        )
      ) : (
        subscriptionCollection.summary && (
          <h2 styleName="summary">{subscriptionCollection.summary}</h2>
        )
      )}
      {isHindi ? (
        <div styleName="subscription-collection">
          {hindiSubscriptionCollection.items.map(item => {
            return <BaseCardStory key={item.id} story={item} />;
          })}
        </div>
      ) : (
        <div styleName="subscription-collection">
          {collections.slice(0, 6).map(item => {
            return <BaseCard collection={item} key={item.id} />;
          })}
        </div>
      )}
    </div>
  );
};

SubscriptionCollection.propTypes = {
  data: PropTypes.object
};
SubscriptionCollection.storyLimit = 6;
