import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link } from "@quintype/components";
import Authors from "../../atoms/authors";
import TimeAgo from "react-timeago";
import { getStoryHeading, dateFormatterWithoutTime } from "../../../utils";
import "./headline-with-authors.m.css";

export const HeadlineWithAuthors = ({
  story = {},
  headlineColor = "#231f20",
  headlineSize = "20px",
  authorSize = "16px",
  authorColor = "#616160",
  hideAuthors = false,
  hideTime = false,
}) => {
  const headline = getStoryHeading(story);
  const slug =
    story["story-template"] === "news-elsewhere"
      ? get(story, ["metadata", "reference-url"], story.url)
      : story.url;
  return story["story-template"] === "news-elsewhere" ? (
    <a href={slug} target="_blank" rel="noopener noreferrer">
      <h2
        styleName="headline"
        style={{ color: headlineColor, fontSize: headlineSize }}
      >
        <bdi dangerouslySetInnerHTML={{ __html: headline }} />
      </h2>
      <div styleName="wrapper">
        {!hideAuthors && (
          <Authors
            story={story}
            authorSize={authorSize}
            authorColor={authorColor}
          />
        )}
        {!hideTime && (
          <TimeAgo
            formatter={dateFormatterWithoutTime}
            styleName="time-ago"
            date={story["last-published-at"]}
            live={false}
          />
        )}
      </div>
    </a>
  ) : (
    <Link href={slug}>
      <h2
        styleName="headline"
        style={{ color: headlineColor, fontSize: headlineSize }}
      >
        <bdi dangerouslySetInnerHTML={{ __html: headline }} />
      </h2>
      <div styleName="wrapper">
        {!hideAuthors && (
          <Authors
            story={story}
            authorSize={authorSize}
            authorColor={authorColor}
          />
        )}
        {!hideTime && (
          <TimeAgo
            formatter={dateFormatterWithoutTime}
            styleName="time-ago"
            date={story["last-published-at"]}
            live={false}
          />
        )}
      </div>
    </Link>
  );
};

HeadlineWithAuthors.propTypes = {
  story: PropTypes.object,
  headlineColor: PropTypes.string,
  headlineSize: PropTypes.string,
  authorSize: PropTypes.string,
  authorColor: PropTypes.string,
  hideAuthors: PropTypes.bool,
  hideTime: PropTypes.bool,
};
