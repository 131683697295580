import React, { useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";

import Close from "../../../../../assets/images/close-icon.svg";

import "./subscription-bank-details.m.css";

const SubscriptionBankDetailsBase = ({ bankDetails }) => {
  const [status, setStatus] = useState(false);

  const onClick = status => {
    setStatus(status);
  };

  const getDetailsBlock = bankDetails => {
    return (
      <React.Fragment>
        <h4 styleName="headline"> {bankDetails.title}</h4>
        {bankDetails.details.map(item => {
          return <p styleName="details">{item.item}</p>;
        })}
      </React.Fragment>
    );
  };

  return (
    <div>
      {/* <p styleName="text">
        Alternatively, you are welcome to send us a cheque for your subscription,{" "}
        <span styleName="text-red" onClick={() => onClick(true)}>
          Click here for details
        </span>
      </p> */}

      {status === true && (
        <div styleName="wrapper">
          <img src={`${assetify(Close)}`} onClick={() => onClick(false)} styleName="close-icon" alt="close" />
          {bankDetails[0] && <div styleName="address">{getDetailsBlock(bankDetails[0])}</div>}
          <div styleName="note">
            {bankDetails[1] && <div styleName="paragraph">{getDetailsBlock(bankDetails[1])}</div>}

            {bankDetails[2] && getDetailsBlock(bankDetails[2])}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    bankDetails: get(state, ["qt", "config", "bankDetails", "details"], [])
  };
};

const SubscriptionBankDetails = connect(mapStateToProps, null)(SubscriptionBankDetailsBase);

export { SubscriptionBankDetails };
