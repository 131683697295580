/* eslint-disable react/prop-types */
import React from "react";
import { CollectionName } from "../../atoms/collection-name";
import HindiColumnCard from "../../molecules/hindi-column-card";

import { Link, ResponsiveImage } from "@quintype/components";
import "./three-hindi-columns.m.css";

export function ThreeHindiColumns({ collection, config }) {
  const collections = collection.items.filter(
    collection => collection.type === "collection"
  );

  const toShowJointElectionSquareImage =
    collection.name.toLowerCase().includes("focus") &&
    collections.length > 3 &&
    collections.length < 6;

  return (
    <div className="full-width-with-padding" styleName="inner-wrapper">
      <div className="container">
        {/* <CollectionName collection={collection} color="#fff" /> */}

        <div styleName="wrapper">
        {/* <ul style={{ listStyle: "none"}}> */}
          {collections.map(item => {
            return (
              // <li>
              <HindiColumnCard
                collection={item}
                key={item.id}
                parentCollectionName={collection.name || ""}
              />
              // </li>
            );
          })}
          {toShowJointElectionSquareImage ? (
            <Link href="/nl-tnm-election-fund" >
              <ResponsiveImage
                slug={`newslaundry/2023-11/edea0f43-7f8d-4f28-8c40-9a50ea526704/nl_tnm_stories.jpg`}
                aspectRatio={[1, 1]}
                defaultWidth={480}
                widths={[250, 480, 640]}
                sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
                imgParams={{ auto: ["format", "compress"] }}
                alt="click to view nl-tnm election stories"
              />
            </Link>
            
          ) : null}
          {/* </ul> */}
        </div>
      </div>
    </div>
  );
}
