import React, { useState } from "react";
import Card from "./votejournCard";
import "../../index.m.css";
import PropTypes from "prop-types";
import { FaSearch } from "react-icons/fa";
import NamesInput from "./NamesInput";
import { useSelector } from "react-redux";
import { get } from "lodash";
import Axios from "axios";

export default function SearchArea(props) {
  const [search, setSearch] = useState("");
  const [clicked, setClicked] = useState(false);
  const [email, setEmail] = useState("");
  const [tags, setTags] = useState([]);
  const click = clicked ? "hidden" : "";
  const [names, setNames] = useState([]);
  const [isSentClicked, setIsSentClicked] = useState(false);
  const member = useSelector((state) => get(state, ["member"], null));
  const [err, setErr] = useState("");
  const [errOpen, setErrOpen] = useState(false);

  const handleNamesChange = (newNames) => {
    setNames(newNames);
    console.log("Names:", names);
  };
  const handleInputChange = (event) => {
    setErrOpen(false);
    setEmail(event.target.value);
  };

  const handleNamesSubmit = () => {
    if (member) {
      if (names.length) {
        Axios.post(
          "https://server.newslaundry.com/journalismSpectrum/suggestJournalists",
          { journalistNames: names, email: member.email }
        )
          .then((response) => {
            console.log(response);
            setNames([]);
            setErrOpen(false);
            setIsSentClicked(true);
          })
          .catch((error) => {
            console.log(error);
            setErr("Server Error");
            setErrOpen(true);
          });
      } else {
        setErr("Enter Suggestions");
        setErrOpen(true);
      }
    } else {
      {
        if (names.length && email.length && isValidEmail(email)) {
          Axios.post(
            "https://server.newslaundry.com/journalismSpectrum/suggestJournalists",
            { journalistNames: names, email: email }
          )
            .then((response) => {
              console.log(response);
              setNames([]);
              setErrOpen(false);
              setIsSentClicked(true);
            })
            .catch((error) => {
              console.log(error);
              setErr("Server Error");
              setErrOpen(true);
            });
        } else if (!isValidEmail(email)) {
          setErr("Enter a valid email");
          setErrOpen(true);
        } else {
          setErr("Enter Suggestions and email.");
          setErrOpen(true);
        }
      }
    }
  };

  function isValidEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  const mostVoted = props.allres
    .sort((a, b) => (a.numberOfVotes > b.numberOfVotes ? -1 : 1))
    .slice(0, 10);
  const allJour = props.allres.filter((el) => !mostVoted.includes(el));

  function fuzzySearch(target, search) {
    if (search.length === 0) {
      return true;
    }

    search = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    const regex = new RegExp(search.split("").join(".*"), "i");

    return regex.test(target);
  }
  return (
    <>
      <div styleName="container" style={{ marginTop: "0px" }}>
        <h1 style={{ textAlign: "center" }}>Which journalist do you follow?</h1>
        <div styleName="section">
          <div styleName="search">
            <FaSearch styleName="search-icon" />
            <input
              type="text"
              placeholder="Search journalist"
              onChange={(e) => setSearch(e.target.value.toLowerCase())}
            />
            <i className="fa fa-search fs-2"></i>
          </div>
          {!clicked && (
            <>
              <p
                style={{
                  color: "#bebebe",
                  textAlign: "left",
                  marginTop: "-30px",
                }}
              >
                <b>Tap on the journalist image to select.</b>
              </p>
              <br />
            </>
          )}
          <div
            style={{
              color: "#b1b1b1",
              display: "flex",
              justifyContent: "flex-start",
              width: "95%",
            }}
          >
            <p>
              <b>Most voted journalists</b>
            </p>
          </div>
          <br />
          <div styleName="cardLayout" style={{ width: "100%" }}>
            {mostVoted.map((pini) => {
              if (fuzzySearch(pini.name.toLowerCase(), search)) {
                return (
                  <Card
                    key={pini.id}
                    f
                    res={pini}
                    isVoted={props.selected.includes(pini.id)}
                    onClick={(val) => {
                      setClicked(true);
                      props.onClick(val);
                    }}
                    onDelete={(val) => props.onDelete(val)}
                  />
                );
              }
            })}
          </div>
          <br />
          <div
            style={{
              color: "#b1b1b1",
              display: "flex",
              justifyContent: "flex-start",
              width: "95%",
              fontSize: "30px",
            }}
          >
            <p>
              <b>All journalists</b>
            </p>
          </div>
          <br />
          <div styleName="cardLayout" style={{ width: "100%" }}>
            {allJour
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((pini) => {
                if (fuzzySearch(pini.name.toLowerCase(), search)) {
                  return (
                    <Card
                      key={pini.id}
                      f
                      res={pini}
                      isVoted={props.selected.includes(pini.id)}
                      onClick={(val) => {
                        setClicked(true);
                        props.onClick(val);
                      }}
                      onDelete={(val) => props.onDelete(val)}
                    />
                  );
                }
              })}
          </div>
        </div>
        <div>
          Didn't find your favourite journalist? Recommend us here:
          {member ? (
            <></>
          ) : (
            <>
              <br />
              <input
                type="text"
                value={email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                styleName="input"
              />
            </>
          )}
          <div styleName="j-suggest" style={{ width: "100%" }}>
            <NamesInput names={names} onNamesChange={handleNamesChange} />

            <button
              styleName="ctav"
              style={{ width: "150px", margin: "auto", marginTop: "10px" }}
              onClick={handleNamesSubmit}
            >
              Send
            </button>
          </div>
          <br />
          {isSentClicked && (
            <span style={{ color: "green" }}>Suggestions recorded!</span>
          )}
          {errOpen && <span style={{ color: "red" }}>{err}</span>}
        </div>
        <br />
      </div>
      <br />
    </>
  );
}
SearchArea.propTypes = {
  allres: PropTypes.any,
  onClick: PropTypes.any,
  onDelete: PropTypes.any,
  selected: PropTypes.any,
};
