/* eslint-disable react/prop-types */
import React from "react";
import assetify from "@quintype/framework/assetify";
import { CloseIcon } from "../../podcast-player/icons";
import subscriptionCTA from "../../../../assets/images/nl-images/subscription-CTA.png";
import { SUBSCRIPTION_PAGE_URL } from "../../../constants";
import { Button } from "../../atoms/button";
import { Modal } from "../../molecules/modal";
import { useDispatch } from "react-redux";

import "../diwali-merch-page/diwali-merch-page.m.css";

export function SubscriberOnlyNudge({ handleClose }) {
  const dispatch = useDispatch();

  const navigateToSubscriptionPage = () => {
    global.app.navigateToPage(dispatch, SUBSCRIPTION_PAGE_URL);
  };

  return (
    <Modal onBackdropClick={handleClose} hideCloseIcon>
      <div styleName="wrapper">
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="pressable" onClick={handleClose}>
            <CloseIcon fill="#656565" width={24}></CloseIcon>
          </button>
        </div>

        <img styleName="image" src={assetify(subscriptionCTA)} alt="newslaundry logo" />
        <h4 styleName="headline">Pay to keep news free</h4>
        <div styleName="content">
          Oops! This is available for subscribers only. Pay to keep news free and get access to our merchandise offer
          page.
        </div>
        <div styleName="button">
          <Button className="nl-btn-large" onClick={navigateToSubscriptionPage}>
            Subscribe
          </Button>
        </div>
      </div>
    </Modal>
  );
}
