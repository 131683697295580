import React from "react";
import { Link } from "@quintype/components";
import "./group-subscription.m.css";
// import { TextField } from "../../atoms/textfield";

const GroupSubscription = () => {
  return (
    <div styleName="section">
      <div>
        <main styleName="hero-section">
          <div>
            <div styleName="hero-section-content">
              <h1 styleName="title">Group Subscription</h1>
              <p styleName="border-bottom"></p>
              <p styleName="description">
                Stay informed together with Newslaundry&apos;s Group
                Subscription. Tailored for teams and institutions, it&apos;s
                your gateway to unbiased independent journalism and in-depth
                insights. Dive into a world of ad-free ground reports, podcasts,
                and videos, all while supporting independent media.
              </p>
              <p styleName="description">
                Join us in sustaining a media landscape that values truth and
                accountability. Subscribe to the Newslaundry Group Subscription
                today.
              </p>
              <Link
                styleName="redirect-link"
                href="https://forms.gle/fgH51s7RknTfc9d39"
              >
                Get Started
              </Link>
              {/* <form styleName="cta-btn-row">
                <TextField
                  type="email"
                  required
                  placeholder="Type your email"
                />
                <Link
                  styleName="started-btn"
                  href="https://forms.gle/fgH51s7RknTfc9d39"
                >
                  Get Started ➡
                </Link>
              </form> */}
              {/* <p styleName="description">
                Thanks for showing your interest in group subscription. We will
                be contacting you soon. Please lookout for our emails from
                subscription@newslaundry.com.
              </p> */}
            </div>
            <div styleName="hero-section-img">
              <img
                src="https://images.assettype.com/newslaundry/2022-03/7b8c9113-5364-48aa-a12b-7581be1310d7/student_subscription_main.png"
                alt="Student Subscription"
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export { GroupSubscription };
