import React from "react";
import PropTypes from "prop-types";

import { FaTimes } from "react-icons/fa";
import { FaGripVertical } from "react-icons/fa";
import { BsGripVertical } from "react-icons/bs";
import "./JournoChip.m.css";
// import "../../index.m.css";

export default function JournoChip({
  selectedJournalist,
  clearCurrentJournalistSelection,
  resetCurrentJournoIndex,
}) {
  return (
    <div
      styleName="chip-area"
      draggable="true"
      onDragStart={(e) => {
        e.target.style.opacity = "0.4";
      }}
      onDragEnd={(e) => {
        e.target.style.opacity = "1";
      }}
    >
      <div
        style={{
          height: "100%",
          backgroundColor: "#efefef",
          display: "flex",
          alignItems: "center",
        }}
      >
        <BsGripVertical
          style={{ height: "30px", width: "30px", color: "black" }}
        />
      </div>
      <img
        src={selectedJournalist.image}
        alt="Reporter name"
        styleName="chip-image"
        draggable="false"
      />
      {/* <p styleName="chip-title">{selectedJournalist.value}</p> */}
    </div>
  );
}

JournoChip.propTypes = {
  selectedJournalist: PropTypes.object,
  clearCurrentJournalistSelection: PropTypes.func,
  resetCurrentJournoIndex: PropTypes.func,
};

// export { JournoChip };
