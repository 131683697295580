import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { HeadlineWithAuthors } from "../headline-with-authors";
import { CollectionName } from "../../atoms/collection-name";
import { Link, ResponsiveImage } from "@quintype/components";
import "./three-row-story-card.m.css";
import { getHeroImage, getStoryMetadata } from "../../../utils";

export const ThreeRowStoryCard = ({ stories = [], collection = {}, config = {} }) => {
  if (isEmpty(collection)) {
    return null;
  }
  return (
    <div styleName="auto-fill">
      <CollectionName collection={collection} />
      {stories.slice(0, 1).map(story => {
        const heroS3Key = getHeroImage(story, config);
        return (
          <div key={story.id} styleName="auto-fill">
            <figure className="figure">
              <ResponsiveImage
                slug={heroS3Key}
                metadata={getStoryMetadata(story)}
                aspectRatio={[265, 354]}
                defaultWidth={173}
                widths={[173, 265]}
                sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
                imgParams={{ auto: ["format", "compress"] }}
                className="responsive-img"
              />
            </figure>
            <div styleName="wrapper card-grow">
              <Link href={get(story, ["sections", "0", "section-url"], "")}>
                <h2 styleName="title">{story.sections && get(story, ["sections", "0", "name"], "")}</h2>
              </Link>

              <HeadlineWithAuthors story={story} headlineColor="#ffffff" authorColor="#ffffff" />
            </div>
            {stories.slice(1, 3).map(story => (
              <div key={story.id} styleName="story-wrapper card-grow">
                <Link href={get(story, ["sections", "0", "section-url"], "")}>
                  <h2 styleName="title">{story.sections && get(story, ["sections", "0", "name"], "")}</h2>
                </Link>

                <HeadlineWithAuthors
                  story={story}
                  key={story.id}
                  headlineColor="#ffffff"
                  hideAuthors={true}
                  hideTime={true}
                />
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

ThreeRowStoryCard.propTypes = {
  stories: PropTypes.array,
  collection: PropTypes.object,
  config: PropTypes.object
};
