import React from "react";
import PropTypes from "prop-types";
import HorizontalStoryCard from "../horizontal-story-card";
import { CollectionName } from "../../atoms/collection-name";
import "./trending.m.css";

const Trending = ({ stories = [], collection, hideAuthorWithTime = false }) => {
  return stories.length > 0 ? (
    <div styleName="trending-container">
      <div styleName="headline">
        <CollectionName collection={collection} />
      </div>
      {stories.slice(0, 5).map(story => (
        <HorizontalStoryCard story={story} key={story.id} hideAuthorWithTime={hideAuthorWithTime} />
      ))}
    </div>
  ) : null;
};

Trending.propTypes = {
  stories: PropTypes.array,
  collection: PropTypes.object,
  hideAuthorWithTime: PropTypes.bool
};
export default Trending;
