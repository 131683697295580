import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { HeadlineWithAuthors } from "../../molecules/headline-with-authors";
import { CollectionName } from "../../atoms/collection-name";
import { ResponsiveImage } from "@quintype/components";
import { getHeroImage, getStoryMetadata } from "../../../utils";
import "./four-row-story-card.m.css";

const FourRowStoryCardBase = ({ stories = [], collection = {}, config = {} }) => {
  if (isEmpty(collection)) {
    return null;
  }
  return (
    <div styleName="auto-fill">
      <CollectionName collection={collection} />
      {stories.slice(0, 1).map(story => {
        const heroS3Key = getHeroImage(story, config);
        return (
          <div key={story.id} styleName="auto-fill">
            <figure className="figure">
              <ResponsiveImage
                slug={heroS3Key}
                metadata={getStoryMetadata(story)}
                aspectRatio={[265, 354]}
                defaultWidth={173}
                widths={[173, 265]}
                sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
                imgParams={{ auto: ["format", "compress"] }}
                className="responsive-img"
              />
            </figure>
            <div styleName="wrapper auto-fill">
              <div styleName="story-card">
                <HeadlineWithAuthors story={story} />
              </div>
              {stories.slice(1, 4).map(story => (
                <div styleName="story-card" key={story.id}>
                  <HeadlineWithAuthors story={story} key={story.id} hideTime={true} />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

FourRowStoryCardBase.propTypes = {
  stories: PropTypes.array,
  collection: PropTypes.object,
  config: PropTypes.object
};

function mapStateToProps(state) {
  return {
    config: get(state, ["qt", "config"], {})
  };
}

export const FourRowStoryCard = connect(mapStateToProps)(FourRowStoryCardBase);
