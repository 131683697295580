/* eslint-disable max-len */
import React from "react";
import "./diwali-merch-page.m.css";
// import { useUserSubscription } from "../../helper/hooks";
// import { SubscriberOnlyNudge } from "./subscriber-only-nudge";

export function DiwaliMerchPage() {
  const handleBuyNow = planId => () => {
    window.open(`https://razorpay.com/payment-button/${planId}/view`, "_blank");
  };

  return (
    <div styleName="wrapper">
      <h1 styleName="title">Diwali with Newslaundry & The News Minute</h1>
      <br />
      <div styleName="top-wrapper">
        <div styleName="img-wrap">
          <img
            alt="banner"
            src="https://images.assettype.com/newslaundry/2024-10-18/lb3nzs77/diwaliwithnlandtnm.jpg"
          />
        </div>
        <div styleName="text-wrap">
          <p styleName="description">
            {" "}
            We celebrate festivals for a number of reasons. This year,
            Newslaundry & The News Minute is adding one more to this list:
            <strong>
              <i>The spirit of independent journalism.</i>
            </strong>
            <br />
            <br />
            Explore our merchandise and proudly represent a community that
            powers free, and independent journalism.
            <br />
            <br />
            Or gift these items to friends and family to help spread the word
            about this important mission.
            <br />
          </p>
        </div>
      </div>
      <br />
      <div styleName="hamper__container">
        <div styleName="hamper__card">
          <h2 styleName="hamper__title">Hamper One</h2>
          <img
            alt=""
            // src="https://images.assettype.com/newslaundry/2021-10/c367c9d9-a6ca-42f9-92b3-72df7fb187e7/Diwali_Hamper_1_2__.jpg"
            src="https://images.assettype.com/newslaundry/2024-10-18/uy05c4ri/Hamper1.jpg"
            // style={{ margin: "-90px" }}
          />

          <ul style={{ height: "70%" }}>
            <li>Naxalbari comic worth ₹799/-</li>
            <li>Laptop sticker (nl) worth ₹249/-</li>
            <li>Fridge magnet (nl-tnm) worth ₹399/-</li>
            <li>NL-TNM mug worth ₹399/-</li>
            <li>1 x One Month Joint NL-TNM Subscription worth ₹500/-</li>
          </ul>
          <h2 styleName="hamper__price-1">
            <span styleName="rupee_sign">₹</span>1,999
          </h2>
          {/* {subscriptionLoading ? (
            <button disabled={true} aria-disabled="true">
              Loading...
            </button>
          ) : (
            <button onClick={handleBuyNow("pl_IDxBceYSd3ggkJ")}>Buy Now</button>
            )} */}
          <button onClick={handleBuyNow("pl_PAPYHwS8WMSSso")}>Buy Now</button>
        </div>
        <div styleName="hamper__card">
          <h2 styleName="hamper__title">Hamper Two</h2>
          <img
            alt=""
            // src="https://images.assettype.com/newslaundry/2021-10/ec1a2962-f4e7-479c-a983-9b16c2fbe4fc/Diwali_Hamper_2_2_.jpg"
            src="https://images.assettype.com/newslaundry/2024-10-18/ediluvhd/Hamper2.jpg"
            // style={{ margin: "-75px" }}
          />

          <ul style={{ height: "70%" }}>
            <li>Kashmir Ki Kahani Comic worth ₹1499/-</li>
            <li>Naxalbari comic worth ₹799/-</li>
            <li>Laptop sticker (nl) worth ₹249/-</li>
            <li>Fridge magnet (nl-tnm) worth ₹399/-</li>
            <li>NL-TNM mug worth ₹399/-</li>
            <li>Urban Naxal Mug worth ₹399/-</li>
            <li>2 x One Month Joint NL-TNM Subscription worth ₹1000/-</li>
          </ul>

          <h2 styleName="hamper__price-2">
            <span styleName="rupee_sign">₹</span>3,999
          </h2>
          {/* {subscriptionLoading ? (
            <button disabled={true} aria-disabled="true">
              Loading...
            </button>
          ) : (
            <button onClick={handleBuyNow("pl_IDxFgSepurUXrE")}>Buy Now</button>
            )} */}
          <button onClick={handleBuyNow("pl_PAPcIfNMaEgM6e")}>Buy Now</button>
        </div>
        {/* <div styleName="hamper__card">
          <h2 styleName="hamper__title">Hamper Three</h2>
          <img
            alt=""
            src="https://images.assettype.com/newslaundry/2021-10/acaa0e7a-7c68-4dbb-a6ce-9b20c46843d7/Diwali_Hamper_3_2_.jpg"
            style={{ marginTop: "-5px" }}
          />
          
          <ul>
            <li>Candle</li>
            <li>Fridge Magnets</li>
            <li>Karare Chane Mug</li>
            <li>Tote bag</li>
            <li>Flask</li>
            <li>Laptop Stickers</li>
            <li>Car Stickers</li>
            <li>Naxalbari Comic</li>
            <li>Gift 2x Six Months Disruptor Subscriptions</li>
          </ul>
          <h2 styleName="hamper__price-3">
            <span styleName="rupee_sign">₹</span>2599
          </h2>
          <button onClick={handleBuyNow("pl_IDxIssRYKq1uyV")}>Buy Now</button>
        </div> */}
      </div>
      <div>
        <strong>Terms &amp; Conditions</strong>
      </div>
      <ul styleName="notes_wrapper">
        <li styleName="notes_lines">
          • This is a limited period offer till November 11, 2024.
        </li>
        <li styleName="notes_lines">• Delivery in India only. </li>
        <li styleName="notes_lines">
          • If you already have a subscription, you can gift the subscription to
          your friend/family
        </li>
        <li styleName="notes_lines">
          • If you are based out of India, you can share an Indian address for
          the merch delivery.
        </li>
        <li styleName="notes_lines">
          • Please note, delivery of packages will take at least two to three
          weeks. Please be patient with us.
        </li>
        <li styleName="notes_lines">
          • We will manually map the subscription gifted. The subscription will
          be mapped within 48 hours.
        </li>
        <li styleName="notes_lines">
          • The product images shown may not be an exact representation of the
          product.
        </li>
        <li styleName="notes_lines">
          • If you have any queries, please drop an email on{" "}
          <a
            style={{ color: "var(--text-accent-muted)" }}
            href="mailto:store@newslaundry.com"
          >
            store@newslaundry.com
          </a>
        </li>
      </ul>
      <br />
    </div>
  );
}
