import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { get } from "lodash";
import assetify from "@quintype/framework/assetify";
import successLogo from "../../../../../assets/images/nl-images/nl-subscription-2.jpg";
import MerchAddressForm from "../payment/merch-form";
import {
  createMerchOrderAfterPayment,
  fetchDataFromLocalStorage,
  parseAddressData,
  saveDataToLocalStorage
} from "../utils";

import "./campaign-confirmation.m.css";
import { FaArrowLeft } from "react-icons/fa";
import { SocialShare } from "@quintype/components";
import ShareSuccessMessage from "../../../molecules/show-success-msg/share-success-message";

const CampaignConfirmation = () => {
  const member = useSelector(state => get(state, ["member"], null));
  // const { fetchSubscription } = useSelector(state => state);
  const [currentStep, setCurrentStep] = useState("address");
  const [userAddress, setUserAddress] = useState("");
  const [level, setLevel] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const handleHomeBtnClick = () => {
    window.location = window.location.origin;
  };

  useEffect(() => {
    // console.log(JSON.parse(fetchDataFromLocalStorage("selectedLevel")));
    setLevel(JSON.parse(fetchDataFromLocalStorage("selectedLevel")));
  }, []);

  const skipMerchForm = () => {
    setCurrentStep("confirm");
  };

  const handlePaymentSuccess = async value => {
    setIsLoading(true);
    // console.log("hiii thre")
    setUserAddress(value);
    saveDataToLocalStorage(value, "userAddress");

    try {
      const address = parseAddressData(value);
      // console.log( level.title,
      //   "campaign",
      //   member,
      //   address)
      await createMerchOrderAfterPayment(
        level.title,
        "campaign",
        member,
        address
      );

      setIsLoading(false);
      setCurrentStep("confirm");
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <div styleName="wrapper">
      {currentStep === "address" ? (
        <>
          <h3 styleName="title">
            You&apos;re a star! Thank you so much for paying to keep news free
            and supporting our journalism.
          </h3>
          <MerchAddressForm
            userAddress={userAddress}
            skipMerchForm={skipMerchForm}
            handlePaymentSuccess={handlePaymentSuccess}
            isLoading={isLoading}
          />
        </>
      ) : (
        <>
          <h3 styleName="title">
            Help us spread the word. Your post will encourage others to join the
            independent media bandwagon.
          </h3>
          <img src={assetify(successLogo)} alt="payment success image" />
          <div styleName="share-wrapper">
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              Spread the message:{" "}
              <div style={{ color: "var(--primary-color)" }}>
                #PayToKeepNewsFree
              </div>
            </div>
            <SocialShare
              fullUrl={"https://newslaundry.com/subscription"}
              title={"I #PayToKeepNewsFree to support independent media\n\n"}
              services={["facebook", "twitter", "whatsapp", "linkedin"]}
              story={null}
              template={ShareSuccessMessage}
            />
          </div>
          <button styleName="back-btn" onClick={handleHomeBtnClick}>
            <FaArrowLeft />
            <span>Go Home</span>
          </button>
        </>
      )}
    </div>
  );
};

CampaignConfirmation.propTypes = {
  userAddress: PropTypes.string,
  selectedLevel: PropTypes.object,
  toGiftSub: PropTypes.bool,
  gifteeEmail: PropTypes.string
};

export { CampaignConfirmation };
