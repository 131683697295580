import React from "react";
import { SubscriptionTestimonial } from "./subscription-testimonial";
import { SubscriptionCollection } from "./subscription-collection";
import { Features } from "./features";
import { SubscriptionBlocks } from "./subscription-blocks";
import { SubscriptionPlaylist } from "./subscription-playlist";
import { Faq } from "./faq";
import { SubscriptionExplained } from "./subscription-explained";
// import { SubscribersMeetSlider } from "./subscribers-meet-slider";
import { SubscriptionPhotoCollage } from "./subscription-photo-collage";

import "./subscription-page.m.css";

export const SubscriptionPage = props => {
  return (
    <div>
      <SubscriptionBlocks {...props} />
      <Features />
      <SubscriptionPhotoCollage />
      {/* <SubscribersMeetSlider /> */}
      <br />
      <br />
      <br />
      <SubscriptionTestimonial {...props} />
      <br />
      <SubscriptionCollection {...props} />
      <SubscriptionPlaylist />
      <SubscriptionExplained {...props} />

      <Faq />
    </div>
  );
};
