/* eslint-disable max-len */
/* eslint-disable react/jsx-no-target-blank */
import Axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";

import "./grievance-redressal.m.css";

const startYear = 2022;
const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

export function GrievanceRedressal() {
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const [wnToken, setWnToken] = useState(null);
  const [yearOptions, setYearOptions] = useState([]);

  const [selectedReport, setSelectedReport] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const monthOptions = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" }
  ];

  useEffect(() => {
    for (let i = startYear; i <= currentYear; i++) {
      setYearOptions(prevState => [...prevState, { value: i, label: `${i}` }]);
    }

    const fetchToken = async () => {
      try {
        const {
          data: { token }
        } = await Axios.post("/webnyay/fetch");

        if (token) {
          setWnToken(token);
        } else {
          setWnToken(null);
        }
      } catch (error) {
        console.log(error);
        // setError("Server error. Try after sometime.");
      }
    };

    fetchToken();
  }, []);

  const fetchReports = async () => {
    try {
      const { data } = await Axios.post(
        `/webnyay/generate`,
        {
          wnToken,
          selectedYear
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const foundMonth = monthOptions.find(
        month => selectedMonth === month.value
      ).label;

      if (foundMonth) {
        setSelectedReport(data.data[foundMonth]);
      } else {
        setSelectedReport([]);
      }
    } catch (error) {
      console.log(error);
      setError("Server error. Try again after sometime.");
    }
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    if (selectedYear >= currentYear && selectedMonth > currentMonth) {
      setError("Please select a valid date.");
    }

    setIsLoading(true);

    try {
      await fetchReports();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <section styleName="content">
      <main styleName="hero-section">
        <div>
          <div styleName="hero-section-content">
            <div styleName="title-wrapper">
              <h1 styleName="title">Grievance Redressal</h1>
              <p styleName="border-bottom"></p>
            </div>
            {/* <h3 styleName="description">
              As per the Information Technology (Intermediary Guidelines and
              Digital Media Ethics Code) Rules 2021, Newslaundry has appointed
              Jayashree Arunachalam its Grievance Officer.
            </h3> */}
          </div>
          <div styleName="hero-section-img">
            <img
              src="https://images.assettype.com/newslaundry/2022-04/1c3e965c-c6d6-40aa-9690-893804150e76/nudges_2.png"
              alt="Valentines Mystery box"
            />
          </div>
        </div>
      </main>
      <div styleName="grievance-section">
        <div styleName="grievance-sub-section">
          {/* <h4 styleName="sub-title">Want to file a complaint?</h4> */}
          <p>
            As per the Information Technology (Intermediary Guidelines and
            Digital Media Ethics Code) Rules 2021, Newslaundry has appointed
            Jayashree Arunachalam its Grievance Officer. Click{" "}
            <a
              styleName="link-text"
              href="https://digipub.webnyay.in/"
              target="_blank"
            >
              here
            </a>{" "}
            to file the complaint. Our Grievance Officer will respond to it as
            soon as possible.
          </p>
        </div>
        <div styleName="grievance-sub-section">
          <h4 styleName="sub-title">View Grievance Reports</h4>
          <div styleName="grievance-area">
            <form styleName="grievance-form" onSubmit={handleFormSubmit}>
              <div styleName="form-select-field">
                <label>Select year</label>
                <Select
                  options={yearOptions}
                  isDisabled={!yearOptions.length}
                  isSearchable={true}
                  onChange={e => setSelectedYear(e.value)}
                  classNamePrefix="grievance-select"
                  styleName="select-menus"
                />
              </div>
              <div styleName="form-select-field">
                <label>Select Month</label>
                <Select
                  options={monthOptions}
                  isDisabled={!selectedYear}
                  isSearchable={true}
                  onChange={e => setSelectedMonth(e.value)}
                  classNamePrefix="grievance-select"
                  styleName="select-menus"
                />
              </div>
              <button
                type="submit"
                styleName={`search-btn ${
                  !selectedMonth || !selectedYear || isLoading
                    ? "is-disabled"
                    : ""
                }`}
                disabled={!selectedYear || !selectedMonth || isLoading}
              >
                {isLoading ? "Please wait..." : "Search"}
              </button>
            </form>
            {selectedReport && selectedReport.length === 0 && (
              <div styleName="error-msg">No reports found for this date.</div>
            )}
            {selectedReport && selectedReport.length > 0 && (
              <div styleName="reports-section">
                <p>
                  Report generated successfully. Click{" "}
                  <a href={selectedReport} styleName="report-link">
                    here
                  </a>{" "}
                  to download.
                </p>
                {/* <ul>
                  {selectedReport.map((report, i) => (
                    <li key={i}>
                      <a styleName="report-link" href={report}>{`Report ${i +
                        1}`}</a>
                    </li>
                  ))}
                </ul> */}
              </div>
            )}
            {error && <p styleName="error-msg">{error}</p>}
          </div>
        </div>
      </div>
    </section>
  );
}
