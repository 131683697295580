import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { stateData } from "../campaign-checkout/state-data";
import { fetchDataFromLocalStorage, parseAddressData } from "../utils";

import "./merch-form.m.css";
import { CgSpinner } from "react-icons/cg";

const MerchAddressForm = ({
  userAddress,
  skipMerchForm,
  handlePaymentSuccess,
  isLoading
}) => {
  const [stateCode, setStateCode] = useState("");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [completeAddress, setCompleteAddress] = useState("");
  const [error, setError] = useState(null);
  const [prompt, setPrompt] = useState(null);

  const pinCodeRegEx = /[1-9][0-9]{5}/;
  const phoneNumberRegEx = /^[6-9]\d{9}$/;

  useEffect(() => {
    if (userAddress) {
      const parsedData = parseAddressData(userAddress);

      setStateCode(stateData.find(s => s.value === parsedData.state).code);
      setCity(parsedData.city);
      setPhoneNumber(parsedData.phone);
      setPincode(parsedData.pincode);
      setCompleteAddress(parsedData.address);
    } else if (fetchDataFromLocalStorage("userAddress")) {
      const addr = fetchDataFromLocalStorage("userAddress");

      const parsedData = parseAddressData(addr);

      setStateCode(stateData.find(s => s.value === parsedData.state).code);
      setCity(parsedData.city);
      setPhoneNumber(parsedData.phone);
      setPincode(parsedData.pincode);
      setCompleteAddress(parsedData.address);
    }
  }, []);

  const handleFormSave = e => {
    e.preventDefault();

    if (!pinCodeRegEx.test(pincode) || !phoneNumberRegEx.test(phoneNumber)) {
      setError("Invalid address crendentials.");

      setTimeout(() => {
        setError(null);
      }, 2000);
      return;
    }

    setPrompt("Your address has been saved.");

    setTimeout(() => {
      setPrompt(null);
    }, 2000);

    handlePaymentSuccess(
      `State:${
        stateData.find(s => s.code === stateCode).value
      };City:${city};Phone number:${phoneNumber};Pin Code:${pincode};Address:${completeAddress}`
    );
  };

  return (
    <div styleName="merch-form-area">
      <div styleName="title-div-wrapper">
        <h3 styleName="title">Address details for your free merchandise:</h3>
      </div>
      <form styleName="merch-form" onSubmit={handleFormSave}>
        <div styleName="row-wrapper">
          <div styleName="input-wrapper">
            <label htmlFor="country-state">Select your state</label>
            <select
              id="country-state"
              name="country-state"
              value={stateCode}
              required
              onChange={e => setStateCode(e.target.value)}
            >
              <option value="">Choose an option</option>
              {stateData.map(state => (
                <option key={state.code} value={state.code}>
                  {state.value}
                </option>
              ))}
            </select>
          </div>
          <div styleName="input-wrapper">
            <label htmlFor="cityField">Enter your city</label>
            <input
              type="text"
              name="cityField"
              id="cityField"
              placeholder="Enter the city you live in."
              required
              value={city}
              onChange={e => setCity(e.target.value)}
            />
          </div>
        </div>
        <div styleName="row-wrapper">
          <div styleName="input-wrapper">
            <label htmlFor="phoneField">Enter your phone number</label>
            <div styleName="input-field-wrapper">
              <div styleName="input-field-header">
                <p>+91</p>
              </div>
              <input
                type="number"
                name="phoneField"
                id="phoneField"
                placeholder="10-Digit Indian mobile number"
                required
                value={phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div styleName="input-wrapper">
            <label htmlFor="pincodeField">Enter your pincode</label>
            <input
              type="number"
              name="pincodeField"
              id="pincodeField"
              placeholder="Your 6 digit area pincode"
              required
              value={pincode}
              onChange={e => setPincode(e.target.value)}
            />
          </div>
        </div>
        <div styleName="input-wrapper">
          <label htmlFor="addressField">Your full address</label>
          <textarea
            name="addressField"
            id="addressField"
            cols="30"
            rows="10"
            placeholder="Deliveries in India only. If you're based outside India, please
            share an Indian address we can ship the merchandise to."
            required
            value={completeAddress}
            onChange={e => setCompleteAddress(e.target.value)}
          ></textarea>
        </div>
        {error ? (
          <p styleName="bottom-prompt error">{error}</p>
        ) : userAddress && !error ? (
          <p styleName="bottom-prompt success">{prompt}</p>
        ) : (
          <p styleName="bottom-prompt"></p>
        )}
        <div styleName="bottom-div">
          <div styleName="btn-cta-wrapper">
            <button
              type="button"
              styleName="submit-btn"
              onClick={skipMerchForm}
              disabled={isLoading}
            >
              Skip this step
            </button>
            {isLoading ? (
              <button styleName="next-btn next-btn-disabled" disabled={true}>
                <CgSpinner styleName="loading" />
              </button>
            ) : (
              <button type="submit" disabled={isLoading} styleName="next-btn">
                Save and Confirm
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

MerchAddressForm.propTypes = {
  userAddress: PropTypes.string,
  setUserAddress: PropTypes.func,
  increaseCurrentStep: PropTypes.func,
  handlePaymentSuccess: PropTypes.func,
  skipMerch: PropTypes.bool,
  isLoading: PropTypes.bool,
  skipMerchForm: PropTypes.func
};

export default MerchAddressForm;
