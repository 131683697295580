import { AccessType, Link } from "@quintype/components";
import get from "lodash/get";
import PT from "prop-types";
import { parseUrl } from "query-string";
import React, { useEffect, useState } from "react";
import { BiPhoneCall } from "react-icons/bi";
import { BsGiftFill, BsNewspaper } from "react-icons/bs";
import { connect, useSelector } from "react-redux";
import Select from "react-select";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import {
  IS_OPEN_LOGIN_FORM,
  PAYMENT_CHECKOUT_PAGE,
  SELECTED_PLAN
} from "../../helper/actions";
import "./combinedSena.m.css";

import IntegrationData from "../../../../../config/integrations";

const subscriptionsData = {
  id: 1385,
  account_id: 48,
  name: "NL-TNM Election Fund",
  description:
    '{\n    "imageUrl": "https://images.assettype.com/newslaundry/2023-10/6e8ae7c5-820c-4fe4-9a59-cc7cd8179546/nl_tnm_election_fund.jpg",\n    "summary": "Five states – Madhya Pradesh, Chhattisgarh, Rajasthan, Telangana and Mizoram – are all voting to elect new Assemblies in less than three months.<br/><br/>And this election season, The News Minute and Newslaundry will join hands to bring you everything you need to know.<br/><br/>Will Shivraj Singh Chouhan get a fifth term as CM in Madhya Pradesh? Will Bhupesh Baghel’s policies help the Congress retain power in Chhattisgarh? What will be the impact of the power tussle between Ashok Gehlot and Sachin Pilot in Rajasthan? How will KCR use the Telangana poll campaign for his party’s national expansion? Will the Manipur conflict play out in the Mizoram election? Will issues such as price rise, corruption, and unemployment matter at all?<br/><br/>Reporters and producers from Newslaundry and The News Minute will hit the ground to find the answers to these questions. Another Election Show will also return, featuring Dhanya, Manisha and Atul in the election states.<br/><br/>Together, we’ll bring you reports, analysis, interviews, videos and podcasts – all from the ground, not crafted in far-off studios. This is independent, ad-free reportage, and you can help us make it happen.<br/><br/>Contribute to the NL-TNM Election Fund today.",\n    "videoId": "zlO_WyiQhyk"\n}',
  subscription_type: "campaign",
  public: true,
  created_at: "2023-10-10T07:35:47.913Z",
  updated_at: "2023-10-11T05:38:59.255Z",
  deleted_at: null,
  preferred_identity_provider: "email",
  metadata_fields: [],
  currency: "INR",
  target_amount: 441700000,
  target_amount_validation_enabled: true,
  api_ordering: null,
  campaign_active: true,
  custom_attributes: [],
  assets: [],
  display_assets: [],
  target_reached: false,
  collected_amount_cents: 0,
  collected_amount: 0,
  collected_amount_percentage: 0,
  image_url: null,
  subscription_plans: [
    {
      id: 4119,
      subscription_group_id: 1385,
      duration_length: 1,
      price_cents: 50100,
      price_currency: "INR",
      created_at: "2023-10-10T13:32:27.625Z",
      updated_at: "2023-10-10T13:33:11.158Z",
      duration_unit: "days",
      description: "Credit as a patron in the story\n",
      title: "Soldier",
      max_trial_period_length: null,
      max_trial_period_unit: null,
      recurring: false,
      metadata: {},
      deleted_at: null,
      enabled: true,
      trial_period_enabled: false,
      supported_payment_providers: [
        "razorpay",
        "paypal",
        "stripe",
        "googlepay"
      ],
      user_limit: null,
      trial_period_type: null,
      custom_attributes: [],
      bundle_id: null,
      assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      display_assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      paid_trial_price_cents: null,
      image_url: null
    },
    {
      id: 4118,
      subscription_group_id: 1385,
      duration_length: 2,
      price_cents: 250000,
      price_currency: "INR",
      created_at: "2023-10-10T13:31:26.250Z",
      updated_at: "2023-10-10T13:31:26.250Z",
      duration_unit: "days",
      description:
        "Credit as a patron in the story\nUrban Naxal Mug +  Stronger together fridge magnet and mug ",
      title: "Bishop",
      max_trial_period_length: null,
      max_trial_period_unit: null,
      recurring: false,
      metadata: {},
      deleted_at: null,
      enabled: true,
      trial_period_enabled: false,
      supported_payment_providers: [
        "razorpay",
        "paypal",
        "stripe",
        "googlepay"
      ],
      user_limit: null,
      trial_period_type: null,
      custom_attributes: [],
      bundle_id: null,
      assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      display_assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      paid_trial_price_cents: null,
      image_url: null
    },
    {
      id: 4117,
      subscription_group_id: 1385,
      duration_length: 3,
      price_cents: 1100000,
      price_currency: "INR",
      created_at: "2023-10-10T13:30:10.022Z",
      updated_at: "2023-10-10T13:30:10.022Z",
      duration_unit: "days",
      description:
        "Credit as a patron in the story\nUrban Naxal Mug +  Stronger together fridge magnet and mug + I pay to keep news free fridge magnets and laptop stickers",
      title: "Knight",
      max_trial_period_length: null,
      max_trial_period_unit: null,
      recurring: false,
      metadata: {},
      deleted_at: null,
      enabled: true,
      trial_period_enabled: false,
      supported_payment_providers: [
        "razorpay",
        "paypal",
        "stripe",
        "googlepay"
      ],
      user_limit: null,
      trial_period_type: null,
      custom_attributes: [],
      bundle_id: null,
      assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      display_assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      paid_trial_price_cents: null,
      image_url: null
    },
    {
      id: 4116,
      subscription_group_id: 1385,
      duration_length: 4,
      price_cents: 2000000,
      price_currency: "INR",
      created_at: "2023-10-10T13:28:13.922Z",
      updated_at: "2023-10-10T13:29:01.470Z",
      duration_unit: "days",
      description:
        "Credit as a patron in the story\nUrban Naxal Mug +  Stronger together fridge magnet and mug + I pay to keep news free fridge magnets + laptop and car stickers",
      title: "Rook",
      max_trial_period_length: null,
      max_trial_period_unit: null,
      recurring: false,
      metadata: {},
      deleted_at: null,
      enabled: true,
      trial_period_enabled: false,
      supported_payment_providers: [
        "razorpay",
        "paypal",
        "stripe",
        "googlepay"
      ],
      user_limit: null,
      trial_period_type: null,
      custom_attributes: [],
      bundle_id: null,
      assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      display_assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      paid_trial_price_cents: null,
      image_url: null
    },
    {
      id: 4115,
      subscription_group_id: 1385,
      duration_length: 5,
      price_cents: 3000000,
      price_currency: "INR",
      created_at: "2023-10-10T13:27:04.061Z",
      updated_at: "2023-10-10T13:27:04.061Z",
      duration_unit: "days",
      description:
        "Credit as a patron in the story\nUrban Naxal Mug +  Stronger together fridge magnet and mug + I pay to keep news free fridge magnets + laptop and car stickers + Naxalbari comic",
      title: "King/Queen",
      max_trial_period_length: null,
      max_trial_period_unit: null,
      recurring: false,
      metadata: {},
      deleted_at: null,
      enabled: true,
      trial_period_enabled: false,
      supported_payment_providers: [
        "razorpay",
        "paypal",
        "stripe",
        "googlepay"
      ],
      user_limit: null,
      trial_period_type: null,
      custom_attributes: [],
      bundle_id: null,
      assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      display_assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      paid_trial_price_cents: null,
      image_url: null
    },
    {
      id: 4114,
      subscription_group_id: 1385,
      duration_length: 6,
      price_cents: 5000000,
      price_currency: "INR",
      created_at: "2023-10-10T13:22:40.844Z",
      updated_at: "2023-10-10T13:24:16.464Z",
      duration_unit: "days",
      description:
        "Credit as a patron in the story\nNL Hamper (Urban Naxal Mug +  Stronger together fridge magnet and mug + I pay to keep news free fridge magnets + laptop and car stickers + Naxalbari comic + Kashmir Ki Kahani comic)",
      title: "Grandmaster",
      max_trial_period_length: null,
      max_trial_period_unit: null,
      recurring: false,
      metadata: {},
      deleted_at: null,
      enabled: true,
      trial_period_enabled: false,
      supported_payment_providers: [
        "razorpay",
        "paypal",
        "stripe",
        "googlepay"
      ],
      user_limit: null,
      trial_period_type: null,
      custom_attributes: [],
      bundle_id: null,
      assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      display_assets: [
        {
          title: "full-site",
          metadata: {},
          type: "site",
          published_at: {}
        }
      ],
      paid_trial_price_cents: null,
      image_url: null
    }
  ]
};

function combinedSenaBase(props) {
  const [selectedSubscriptions, setSelectedSubscriptions] = useState({});
  const [isHindi, setIsHindi] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const state2 = useSelector(state => state);
  const shareUrl = "https://newslaundry.com/electionfund";
  const [subscriptions, setSubscriptions] = useState(subscriptionsData);
  const title =
    "Check out the @newslaundry and @thenewsminute election fund! Support independent media. Contribute now:";
  useEffect(() => {
    setIsHindi(global.location && global.location.host.includes("hindi"));
    if (state2.accessTypeCampaignSubscriptions.length)
      setSubscriptions(
        state2.accessTypeCampaignSubscriptions.find(plan => plan.id == 1385)
      );
    // console.log(subscriptions);
    // console.log({state},"state");
  }, [state2]);

  // const subscriptions = state2.accessTypeCampaignSubscriptions.find((plan)=> plan.id==1385);
  // console.log(subscriptions);

  //  const pushToDataLayer = (name, subscription) => {
  //     const obj = {
  //       event: "subscription_addToCart",
  //       subscription_category: "contribution_nl_sena",
  //       subscription_plan: name,
  //       price: +subscription.price_cents / 100,
  //       usertype: props.member ? "logged in" : "logged out",
  //       subscribertype: props.fetchSubscription.isActive
  //         ? "subscriber"
  //         : "nonsubscriber",
  //       userID: props.member ? props.member.id : "null",
  //       subscriptionID: props.fetchSubscription.isActive
  //         ? props.fetchSubscription.items.find(sub => sub.active === true).id
  //         : "null"
  //     };

  //     window.dataLayer.push(obj);
  //   };

  function handleSubscriptionClick(e, subscriptionGroup) {
    e.preventDefault();
    e.stopPropagation();
    const grpId = subscriptionGroup.id;
    //console.log(subscriptionGroup, "subsgrp");
    const allPlans = get(subscriptionGroup, ["subscription_plans"], []);
    //console.log({ allPlans });
    const firstPlan = allPlans[0];
    //console.log({ allPlans });
    //console.log({ selectedSubscriptions }, subscriptionGroup.id, "iddd");
    // const selectedSubscriptionPlan = get(
    //   state,
    //   ["selectedSubscriptions", subscriptionGroup.id],
    //   firstPlan
    // );
    let exactPlan = selectedSubscriptions[grpId];
    if (!exactPlan) exactPlan = firstPlan;
    //console.log({selectedSubscriptionPlan});

    //    pushToDataLayer(
    //       subscriptions.name,
    //       selectedSubscriptionPlan
    //     );
    localStorage.setItem("selectedPlan", JSON.stringify(subscriptions));
    localStorage.setItem("selectedLevel", JSON.stringify(exactPlan));
    if (!member) {
      // this.props.manageLoginForm(true);
      const params = parseUrl(state2.qt.currentPath);
      const callbackUrl = get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      );
      const redirectUrl = get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      );
      window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
      return false;
    }

    // console.log(selectedSubscriptions[grpId], "ssp");

    // props.selectedPlan(selectedSubscriptions, props.redirectUrl);
    // this.props.navigateTo(`/payment-checkout?ref=sena-home`);
    props.navigateTo(
      `/campaign/checkout?plan=${exactPlan.id}&group=${exactPlan.subscription_group_id}&sena=true&ref=sena-home`
    );
    return true;
  }

  function handlePlanChange(e, subscriptionGroupId) {
    //console.log({ state2 }, "stateee", e.value);
    const subscriptions2 = state2.accessTypeCampaignSubscriptions.find(
      plan => plan.id == 1385
    );
    //console.log({subscriptions2});
    const currentPlan = JSON.parse(e.value);
    const currentState = Object.assign({}, selectedSubscriptions, {
      [subscriptionGroupId]: currentPlan
    });
    // this.setState({ selectedSubscriptions: currentState });
    //console.log({ selectedSubscriptions });
    //console.log({ currentState });
    setSelectedSubscriptions(currentState);
  }

  if (!subscriptions) {
    return null;
  }

  const currency = get(subscriptions, ["currency"], "");
  const currencySymbol = currency === "INR" ? "₹" : "$";
  const targetAmount = get(subscriptions, ["target_amount"], 0) / 100;
  const completedAmount = get(subscriptions, ["collected_amount"], 0);
  const barWidth =
    completedAmount > targetAmount
      ? 100
      : Math.floor((completedAmount / targetAmount) * 100);
  const plans = get(subscriptions, ["subscription_plans"], []);
  const authHost = useSelector(state2 =>
    get(state2, ["qt", "config", "publisher", "publisher", "auth_host"], null)
  );
  // FIX
  const options = plans.map(plan => {
    const currency = plan.price_currency;
    const currencySymbol = currency === "INR" ? "₹" : "$";
    const label = plan.title + " - " + currencySymbol + plan.price_cents / 100;
    return { value: `${JSON.stringify(plan)}`, label: label };
  });

  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#fff"
          : isFocused
          ? "#fff"
          : null,
        color: isDisabled
          ? null
          : isSelected
          ? "#c00"
          : isFocused
          ? "#000"
          : null
      };
    }
  };

  const {
    imageUrl,
    summary,
    storySlug,
    videoId,
    hindiSummary,
    hindiTitle,
    endDate
  } = subscriptions.description;
  const subscriptionGroupId = get(subscriptions, ["id"]);
  const member = get(state2, ["member"], null);

  const integrationsConfig = IntegrationData();

  // const pub = useSelector((state) => get(state, ["qt", "config", "publisher"]));
  // const member = useSelector((state) => get(state, ["member"], null));

  const isStaging = get(
    props.config,
    ["publisher", "publisher", "accessTypeEnvironment"],
    true
  );
  const enableAccesstype = get(
    props.config,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const email = get(props.config, ["publisher", "publisher", "email"], "");
  const phone = get(props.config, ["publisher", "publisher", "phone"], "");

  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  return (
    <div>
      <main styleName="hero-section">
        <div styleName="hero-section-div">
          <div styleName="hero-section-img">
            <img
              src="https://images.assettype.com/newslaundry/2023-10/8bc203df-4daa-468b-9bb5-8cb5e0279550/Frame_22.png"
              alt="NL TNM Mic"
            />
          </div>

          <div styleName="hero-section-content">
            <h1 styleName="title">NL-TNM ELECTION FUND</h1>
            <br />
            <p styleName="hero-description">
              India's leading independent news organisations, <i>Newslaundry</i>{" "}
              and <i>The News Minute</i>, join hands to bring you ground reports
              and shows from the five states holding elections.
            </p>
          </div>
        </div>
      </main>

      <div className="full-width-with-padding" styleName="details-wrapper">
        <div styleName="sena-details">
          <div styleName="description">
            <h1>About the project</h1>
            <p styleName="border-bottom"></p>
            <p>
              {" "}
              {/* Election season is back! Madhya Pradesh, Chhattisgarh, Telangana,
              Rajasthan and Mizoram will all have new assemblies by the end of
              the year. As always, we’ll bring you the news from the ground –
              but there’s a twist.
              <br />
              <br />
              With one payment, you’ll tap into the resources of two leading
              news organisations in India and all the election coverage that
              comes with it. Newslaundry and The News Minute are joining hands
              to report on these five assembly elections and bring you
              everything you need to know.
              <br />
              <br /> Teams of reporters and producers will hit the ground, while
              Dhanya, Manisha and Atul host special election shows from the
              states going to vote. It’s an extravaganza of reportage – ground
              reports, analysis, videos and podcasts – so help us put this
              together. Contribute to the NL-TNM Election Fund today. */}
              Five states – Madhya Pradesh, Chhattisgarh, Rajasthan, Telangana
              and Mizoram – are all going to vote to elect new Assemblies.
              <br />
              <br />
              And this election season, <i>The News Minute</i> and{" "}
              <i>Newslaundry</i> will join hands to bring you everything you
              need to know.
              <br />
              <br />
              Will Shivraj Singh Chouhan get a fifth term as CM in Madhya
              Pradesh? Will Bhupesh Baghel’s policies help the Congress retain
              power in Chhattisgarh? What will be the impact of the power tussle
              between Ashok Gehlot and Sachin Pilot in Rajasthan? How will KCR
              use the Telangana poll campaign for his party’s national
              expansion? Will the Manipur conflict play out in the Mizoram
              election? Will issues such as price rise, corruption, and
              unemployment matter at all?
              <br />
              <br />
              Reporters and producers from <i>Newslaundry</i> and{" "}
              <i>The News Minute</i> will hit the ground to find the answers to
              these questions. Another Election Show will also return, featuring
              Dhanya, Sudipto, Manisha and Atul in the election states.
              <br />
              <br />
              Together, we’ll bring you reports, analysis, interviews, videos
              and podcasts – all from the ground, not crafted in far-off
              studios. This is independent, ad-free reportage, and you can help
              us make it happen.
            </p>
            <br />
            <br />
            {/* <img
              src="https://images.assettype.com/newslaundry/2023-10/6e8ae7c5-820c-4fe4-9a59-cc7cd8179546/nl_tnm_election_fund.jpg"
              alt="NL TNM Mic"
              styleName="sena-vid"
            /> */}
            {/* <iframe
              styleName="sena-vid"
              src="https://www.youtube.com/embed/8Zu-rIBA0bM?si=Ybj3rieR7L_S1dnm"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe> */}
            <iframe
              styleName="sena-vid"
              src="https://www.youtube.com/embed/zlO_WyiQhyk?si=4ta_7MnyuiDHJFlE"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div styleName="cta-wrapper">
            <h1>NL-TNM Election Fund</h1>
            {options && options.length > 0 && (
              <div styleName="list-wrapper">
                <Select
                  options={options}
                  onChange={e => handlePlanChange(e, subscriptionGroupId)}
                  defaultValue={options[0]}
                  className="basic-single"
                  classNamePrefix="select"
                  styles={colourStyles}
                  isSearchable={false}
                />
              </div>
            )}
            <br />

            <>
              <div styleName="bar-wrapper">
                <div styleName="progress-wrapper">
                  <div
                    styleName="progress-bar"
                    style={{
                      width: barWidth + "%",
                      // width: "3" + "%",
                      backgroundColor: "var(--nl-red)"
                    }}
                  ></div>
                </div>
              </div>
              <div styleName="amount-container">
                <div styleName="amount-wrapper">
                  <div styleName="amount">
                    {currencySymbol + completedAmount}
                    {/* {currencySymbol + "1,53,536"} */}
                  </div>
                  <div styleName="goal">Raised so far</div>
                </div>
                <div styleName="amount-wrapper">
                  <div styleName="amount select">
                    {currencySymbol + targetAmount}
                    {/* {currencySymbol + "44,17,000"} */}
                  </div>
                  <div styleName="goal">Goal</div>
                </div>
              </div>

              <div styleName="btn-wrapper">
                <button
                  styleName="btn"
                  onClick={e => handleSubscriptionClick(e, subscriptions)}
                >
                  Contribute
                </button>
              </div>

              <div styleName="btn-wrapper">
                <button
                  styleName="btn-transparent"
                  // onClick={() => {
                  //     const loc = global.location;
                  //     navigator.clipboard.writeText(
                  //         `${loc}`
                  //       );
                  //       setShowShare(true);
                  // }}
                >
                  {/* <BsFillShareFill style={{paddingTop:"3px"}}/>&nbsp;Share */}
                  <div style={{ paddingTop: "3px" }}>
                    <TwitterShareButton url={shareUrl} title={title}>
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>{" "}
                    <WhatsappShareButton url={shareUrl} title={title}>
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>{" "}
                    <FacebookShareButton url={shareUrl}>
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>{" "}
                    <LinkedinShareButton url={shareUrl} title={title}>
                      <LinkedinIcon size={32} round />
                    </LinkedinShareButton>{" "}
                  </div>
                </button>
                {showShare && (
                  <p style={{ color: "green", fontSize: "14px" }}>
                    Link copied!
                  </p>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
      <div styleName="story-links-wrapper">
        <h1>Stories</h1>
        <p styleName="border-bottom"></p>

        <div styleName="story-box-wrapper">
          <div styleName="story-box">
            <div styleName="box-logo">
              <img
                src="https://images.assettype.com/newslaundry/2020-10/fe2dc714-ad63-43b8-a43f-999fd1f9c7bc/qiMQgIos_400x400.png"
                alt="NL logo"
                styleName="logo"
              />
            </div>
            <div styleName="box-desc">
              <p>Read all stories by NL</p>
              <div styleName="btn-wrapper" style={{ marginTop: "0px" }}>
                <Link styleName="btn" href="/nl-tnm-election-fund">
                  Read ➡
                </Link>
              </div>
            </div>
          </div>

          <div styleName="story-box">
            <div styleName="box-logo">
              <img
                src="https://images.assettype.com/newslaundry/2023-11/8d2eff0c-7cb1-44ad-bb2f-0857c500d2f2/image_13.png"
                alt="TNM logo"
                styleName="logo-tnm"
              />
            </div>
            <div styleName="box-desc">
              <p>Read all stories by TNM</p>
              <div styleName="btn-wrapper" style={{ marginTop: "0px" }}>
                <Link
                  styleName="btn"
                  href="https://www.thenewsminute.com/topic/nl-tnm-election-coverage"
                >
                  Read ➡
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-width-with-padding" styleName="benefits-wrapper">
        <AccessType
          enableAccesstype={enableAccesstype}
          isAccessTypeCampaignEnabled={true}
          isStaging={false}
          accessTypeKey={accessTypeKey}
          accessTypeBkIntegrationId={accessTypeBkIntegrationId}
          email={email}
          phone={phone}
        >
          {({ initAccessType, initRazorPayPayment, initStripePayment }) => {
            return <></>;
          }}
        </AccessType>
        <h1>Reasons why you should contribute</h1>
        <div styleName="benefits-container">
          <div styleName="benefits-box">
            <div styleName="icon">
              <div style={{ marginTop: "20px" }}>
                <BsNewspaper />
              </div>
            </div>
            <div styleName="heading">
              <h2>Power ground reports</h2>
            </div>
            <div styleName="text">
              <p>
                Reportage is how news consumers stay informed. Your
                contributions support reporters and power access to the facts on
                the ground.
              </p>
            </div>
          </div>
          <div styleName="benefits-box">
            <div styleName="icon">
              <div style={{ marginTop: "20px" }}>
                <BiPhoneCall />
              </div>
            </div>
            <div styleName="heading">
              <h2>Interact with our editorial teams</h2>
            </div>
            <div styleName="text">
              <p>
                Join Zoom calls with our reporters, producers and editors –
                before and after election coverage – for a closer look at our
                work.
              </p>
            </div>
          </div>
          <div styleName="benefits-box">
            <div styleName="icon">
              <div style={{ marginTop: "20px" }}>
                <BsGiftFill />
              </div>
            </div>
            <div styleName="heading">
              <h2>Free merch</h2>
            </div>
            <div styleName="text">
              <p>
                Get exclusive merchandise as a token of our appreciation,
                including our all-new <i>Stronger Together: NL-TNM</i> merch.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//export {combinedSena};

combinedSenaBase.propTypes = {
  // showBottom: PT.bool,
  // subscriptions: PT.object,
  // member: PT.object,
  // manageLoginForm: PT.func,
  selectedPlan: PT.func,
  navigateTo: PT.func
  // showSingleCampaign: PT.bool
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function (payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url),
  selectedPlan: function (plan, redirectUrl) {
    dispatch({
      type: SELECTED_PLAN,
      plan: plan,
      planType: "campaign",
      redirectUrl: redirectUrl
    });
  },
  paymentCheckout: function (payload) {
    dispatch({
      type: PAYMENT_CHECKOUT_PAGE,
      payload: payload
    });
  }
});

const mapStateToProps = state2 => {
  return {
    config: get(state2, ["qt", "config"], {}),
    member: get(state2, ["member"], null),
    currentPath: get(state2, ["qt", "currentPath"], ""),
    authHost: get(
      state2,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    ),
    fetchSubscription: get(state2, ["fetchSubscription"], {
      isLoading: false,
      isActive: false,
      isExpired: false,
      items: []
    })
  };
};

const combinedSena = connect(
  mapStateToProps,
  mapDispatchToProps
)(combinedSenaBase);

export { combinedSena };
