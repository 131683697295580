import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link, ResponsiveImage } from "@quintype/components";
import { CollectionName } from "../../atoms/collection-name";
import getStoriesByCollection, { getHeroImage } from "../../../utils";
import { Headline } from "../../atoms/headline";
import Authors from "../../atoms/authors";

import "./comics-section-page.m.css";

const FirstStory = ({ story, config }) => {
  const getLabel = get(
    story,
    ["metadata", "story-attributes", "storyattribute", 0],
    ""
  );
  const heroS3Key = getHeroImage(story, config);
  return (
    <div styleName="first-story-wrapper">
      <div styleName="img-wrapper">
        <Link href={story.url}>
          <ResponsiveImage
            slug={heroS3Key}
            metadata={story["hero-image-metadata"]}
            aspectRatio={[16, 9]}
            defaultWidth={480}
            widths={[250, 480, 640]}
            sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
            imgParams={{ auto: ["format", "compress"] }}
            className="responsive-img"
            styleName="comic-card-image"
          />
        </Link>
        {/* <Link href={story.url} styleName="hero-image" className="hero-image">
          <figure className="figure-wrapper">
            <ResponsiveImage
              slug={heroS3Key}
              metadata={story["hero-image-metadata"]}
              aspectRatio={[195, 260]}
              defaultWidth={173}
              widths={[173, 265]}
              sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
              imgParams={{ auto: ["format", "compress"] }}
              className="responsive-img"
            />
          </figure>
        </Link> */}
        {getLabel && <div styleName="ribbon">{getLabel}</div>}
      </div>
      <div styleName="first-story-text">
        <Headline story={story} externalLink={true} headlineColor="#fff" />
        <Authors story={story} authorColor="#fff" />
        <Link href={story.url} styleName="comic-btn">
          View Comic
        </Link>
      </div>
    </div>
  );
};

FirstStory.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object
};

// const ComicCard = ({ story, config }) => {
//   const heroS3Key = getHeroImage(story, config);
//   return (
//     <div styleName="card-container">
//       <Link href={story.url}>
//         <ResponsiveImage
//           slug={heroS3Key}
//           metadata={story["hero-image-metadata"]}
//           aspectRatio={[16, 9]}
//           defaultWidth={480}
//           widths={[250, 480, 640]}
//           sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
//           imgParams={{ auto: ["format", "compress"] }}
//           className="responsive-img"
//           styleName="comic-card-image"
//         />
//       </Link>
//       {/* <Link href={story.url} styleName="hero-image" className="hero-image">
//         <figure className="figure-wrapper">
//         <ResponsiveImage
//             slug={heroS3Key}
//             metadata={story["hero-image-metadata"]}
//             aspectRatio={[16, 9]}
//             defaultWidth={480}
//             widths={[250, 480, 640]}
//             sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
//             imgParams={{ auto: ["format", "compress"] }}
//             className="responsive-img"
//           />
//         </figure>
//       </Link> */}
//       <div styleName="comic-text">
//         <Headline story={story} externalLink={true} />
//         <Authors story={story} />
//       </div>
//     </div>
//   );
// };

// ComicCard.propTypes = {
//   story: PropTypes.object,
//   config: PropTypes.object
// };

const ComicCollectionCard = ({ collection }) => {
  const heroS3Key = get(
    collection,
    ["metadata", "cover-image", "cover-image-s3-key"],
    ""
  );
  const metadata = get(collection, ["metadata", "cover-image", "metadata"], "");

  return (
    <div styleName="card-container">
      <Link href={`/collection/${collection.slug}`}>
        <ResponsiveImage
          slug={heroS3Key}
          metadata={metadata}
          aspectRatio={[16, 9]}
          defaultWidth={480}
          widths={[250, 480, 640]}
          sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
          imgParams={{ auto: ["format", "compress"] }}
          className="responsive-img"
          styleName="comic-card-image"
        />
      </Link>
      <div styleName="comic-text">
        <h3 styleName="comic-collection-heading">{collection.name}</h3>
        <p styleName="comic-collection-summary">{collection.summary}</p>
      </div>
    </div>
  );
};

ComicCollectionCard.propTypes = {
  collection: PropTypes.object,
  config: PropTypes.object
};

const ComicsSectioPage = props => {
  const collection = get(props, ["data", "collection"], {});
  const stories = getStoriesByCollection(collection) || [];

  const featuredCollections = get(collection, ["items"], []).filter(
    item => item.type === "collection"
  );

  return (
    <>
      <header styleName="header" className="full-width-with-padding">
        <div styleName="wrapper" className="full-width-with-padding">
          <div className="container" styleName="container">
            <div styleName="collection-text">
              <CollectionName
                collection={collection}
                color="#fff"
                borderBottomColor="#fff"
              />
              <div styleName="summary">{get(collection, ["summary"], "")}</div>
            </div>
          </div>
        </div>
      </header>
      {stories.length === 0 ? (
        <div styleName="error-msg">No Stories found</div>
      ) : (
        <div styleName="comic-card-wrapper" className="full-width-with-padding">
          <div className="container" styleName="comic-container">
            {featuredCollections.map(col => (
              <ComicCollectionCard
                key={col.id}
                collection={col}
                config={get(props, ["config"], {})}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ComicsSectioPage;
