import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import getStoriesByCollection from "../../../utils";
import { CollectionName } from "../../atoms/collection-name";
import CommonStoryCard from "../../molecules/common-story-card";
import "./one-row-three-col.m.css";

const OneRowThreeCol = ({ collection = {} }) => {
  const [location, setLocation] = useState("");
  useEffect(() => {
    setLocation(global.location.host);
  }, []);

  const stories =
    location === "www.newslaundry.com" ||
    location === "newslaundry-web.qtstage.io" ||
    location === "newslaundry-beta.quintype.io" ||
    location === "localhost:3000"
      ? getStoriesByCollection(collection).filter(
          item => item.url.slice(8, 13) !== "hindi"
        )
      : getStoriesByCollection(collection);
  if (stories.length < 1) {
    return null;
  }
  return (
    <div className="full-width" styleName="row-container">
      <div className="container">
        <CollectionName collection={collection} />
        <div styleName="wrapper">
          {stories.slice(0, 3).map(story => (
            <CommonStoryCard story={story} key={story.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

OneRowThreeCol.propTypes = {
  collection: PropTypes.object
};
export default OneRowThreeCol;
