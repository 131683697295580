/* eslint-disable react/prop-types */
import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "./merch-page.m.css";
import VariantSelector from "./VariantSelector";

class Product extends Component {
  constructor(props) {
    super(props);

    const defaultOptionValues = {};
    this.props.product.options.forEach(selector => {
      defaultOptionValues[selector.name] = selector.values[0].value;
    });
    this.state = { selectedOptions: defaultOptionValues };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
  }

  handleOptionChange(event) {
    const target = event.target;
    const selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.client.product.helpers.variantForOptions(
      this.props.product,
      selectedOptions
    );

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value
    });
  }

  render() {
    const productPictures = this.props.product.images.map(e => (
      <img alt="product" key={e.src} src={e.src} />
    ));

    const variantSelectors = this.props.product.options.map(option => {
      return (
        <VariantSelector
          handleOptionChange={this.handleOptionChange}
          key={option.id.toString()}
          option={option}
        />
      );
    });

    const variant =
      this.state.selectedVariant || this.props.product.variants[0];
    const variantQuantity = 1;

    return (
      <div styleName="product" aria-label="Product box">
        {/* {props.product.images.length ? <img src={props.product.images[0].src} key={props.product.images[0].src} /> : null} */}
        {this.props.product.images[0] ? (
          <AliceCarousel
            mouseTracking
            items={productPictures}
            disableButtonsControls
            controlsStrategy="responsive"
          />
        ) : null}
        <hr />
        <h2 styleName="product__title" aria-label="Product title:">{this.props.product.title}</h2>
        <p styleName="product__description" aria-label="Product description:">{this.props.product.description}</p>
        <h4 styleName="product__price" aria-label="Product price:">
          ₹{this.props.product.variants[0].price.amount}
        </h4>
        {this.props.product.variants[0].title !== "Default Title"
          ? variantSelectors
          : null}
        <button
          styleName="product__buy"
          disabled={!variant.available}
          onClick={() =>
            this.props.addVariantToCart(variant.id, variantQuantity)
          }
          aria-label={`${variant.available ? " Click to Add to Cart" : "Sorry product is Sold Out"}`}
        >
          {variant.available ? "Add to Cart" : "Sold Out"}
        </button>
      </div>
    );
  }
}

export default Product;
