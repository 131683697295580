import { Link, ResponsiveImage } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { getSlug } from "../../../utils";
import "./hindi-column-card.m.css";

const HindiColumnCard = ({ collection, config, parentCollectionName }) => {
  const slug = getSlug(collection, config);
  // console.log({ parentCollectionName });

  const collectionName = get(collection, ["name"], "");
  const collectionSummary = get(collection, ["summary"], "");
  const defaultImgS3Key = get(
    config,
    ["publisher", "publisher", "defaulImgS3key"],
    ""
  );
  const imgS3Key = get(
    collection,
    ["metadata", "cover-image", "cover-image-s3-key"],
    defaultImgS3Key
  );
  const imgMetadata = get(
    collection,
    ["metadata", "cover-image", "cover-image-metadata"],
    {}
  );

  return (
    <div styleName="card-container">
      <Link
        href={slug}
        className="hero-image"
        aria-label={`click to view ${collectionName} collection and stories inside it`}
      >
        <ResponsiveImage
          slug={imgS3Key}
          metadata={imgMetadata}
          aspectRatio={[16, 9]}
          defaultWidth={480}
          widths={[250, 480, 640]}
          sizes="( max-width: 500px ) 98%, ( max-width: 768px ) 48%, 23%"
          imgParams={{ auto: ["format", "compress"] }}
          className="responsive-img"
          alt={`${collectionName} cover image`}
        />
        <div styleName="text-wrapper">
          <h2 styleName="headline" className="headline">
            <bdi dangerouslySetInnerHTML={{ __html: collectionName }} />
          </h2>
          {parentCollectionName.toLowerCase().includes("focus") ? null : (
            <p
              styleName="subheadline"
              className="subheadline"
              // style={{ color: "#616160" }}
            >
              <bdi dangerouslySetInnerHTML={{ __html: collectionSummary }} />
            </p>
          )}
        </div>
      </Link>
    </div>
  );
};

HindiColumnCard.propTypes = {
  collection: PropTypes.object,
  config: PropTypes.object,
  parentCollectionName: PropTypes.string
};

const mapStateToProps = state => ({
  config: get(state, ["qt", "config"], {})
});

export default connect(mapStateToProps)(HindiColumnCard);
