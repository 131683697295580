import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link } from "@quintype/components";

// import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
// import { Button } from "../../atoms/button";
import { Button } from "../../../ui/components";
import subscriptionImage from "../../../../assets/images/nl-images/subsccription banner-on-homepage.png";
import logo from "../../../../assets/images/newslaundry_logo.png";
import { SUBSCRIPTION_PAGE_URL } from "../../../constants";
import "./subscribe-now.m.css";
// import { FaCheck } from "react-icons/fa";

const SUMMARY =
  "Newslaundry wants to make news a service to the public, not the advertisers. We believe those who make any effort sustainable, determine its direction. You decide.";

// const PERKS = [
//   "Paywall stories",
//   "Subscriber-only Discord Server",
//   "Meetups and virtual hangouts: NL Recess, NL Chatbox",
//   "Subscriber-only events, including The Media Rumble"
// ];

export const SubscribeNowTemplate = ({ collection = {} }) => {
  const { name, summary } = collection;
  const domainSlug = global.location && global.location.host.includes("hindi");

  const { member, fetchSubscription } = useSelector(state => state);

  const pushToDataLayer = (event, title) => {
    const obj = {
      event: event,
      subscription_category: title,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  return !domainSlug ? (
    <div styleName="row-container">
      <div styleName="container" className="full-width-with-padding">
        <div styleName="left-container">
          <img
            styleName="logo-wrapper"
            src={assetify(logo)}
            alt="newslaundry logo"
          />
          <h1 styleName="text-container">
            <span styleName="text support">Support</span>
            <span styleName="text-wrapper">
              <span styleName="text">Independent&nbsp;</span>
              <span styleName="text">Media</span>
            </span>
          </h1>
        </div>
        <div styleName="right-container">
          <h2 styleName="title">{name}</h2>
          <div styleName="summary">{summary || SUMMARY}</div>
          {/* <div styleName="perks">
            {PERKS.map((perk, i) => (
              <p key={i}>
                <FaCheck color="green" style={{ flexShrink: "0" }} />
                <span>{perk}</span>
              </p>
            ))}
          </div> */}
          <div styleName="btn-img-wrapper">
            <div styleName="subcribe-bottom">
              <Link href={SUBSCRIPTION_PAGE_URL + `?ref=homebanner`}>
                <Button
                  onClick={() =>
                    pushToDataLayer("nl_banner", "self_subscription")
                  }
                >
                  Subscribe Now
                </Button>
              </Link>
              {/* <Button>
                <span
                  onClick={() =>
                    pushToDataLayer("nl_banner", "self_subscription")
                  }
                >
                  Subscribe Now
                </span>
              </Button> */}
              {/* <p styleName="helper-btn-text">
                Buy our Disruptor Yearly plan and just around &#x20b9;8/Day
              </p> */}
            </div>
            <img
              styleName="info-image"
              src={assetify(subscriptionImage)}
              alt="subscription image"
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div styleName="row-container">
      <div styleName="container" className="full-width-with-padding">
        <div styleName="left-container">
          <img
            styleName="logo-wrapper"
            src={assetify(logo)}
            alt="newslaundry logo"
          />
          <h1 styleName="text-container">
            <span styleName="text">स्वतंत्र मीडिया का </span>
            <span styleName="text-wrapper">
              <span styleName="text support">समर्थन करें &nbsp;</span>
            </span>
          </h1>
        </div>
        <div styleName="right-container">
          <h2 styleName="title">मेरे खर्च पर आज़ाद हैं ख़बरें </h2>
          <div styleName="summary">
            न्यूज़लॉन्ड्री का प्रयास खबरों को जनहित से जोड़ना है न कि
            विज्ञापनदाताओं से. हमारा विश्वास है कि वही लोग यह काम कर सकते हैं
            जिनके पास इसे आत्मनिर्भर बनाने का जज्बा और सोच हो. फैसला आपके हाथ
            में हैं.
          </div>
          <div styleName="btn-img-wrapper">
            <div styleName="subcribe-bottom">
              <Link href={SUBSCRIPTION_PAGE_URL + `?ref=homebanner-hindi`}>
                <Button
                  onClick={() =>
                    pushToDataLayer("nl_banner", "self_subscription")
                  }
                >
                  Subscribe Now
                </Button>
              </Link>
              {/* <Button href={SUBSCRIPTION_PAGE_URL + `?ref=homebanner-hindi`}>
                <span
                  onClick={() =>
                    pushToDataLayer("nl_banner", "self_subscription")
                  }
                >
                  सब्सक्राइब
                </span>
              </Button> */}
            </div>
            <img
              styleName="info-image"
              src={assetify(subscriptionImage)}
              alt="subscription image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

SubscribeNowTemplate.propTypes = {
  collection: PropTypes.object
};
