import React from "react";
import { Link } from "@quintype/components";
import "./renewal-banner.m.css";

export const RenewalBanner = () => {
  return (
    <div styleName="wrapper" className="full-width">
      <div styleName="text-wrapper">
        <p>
        We miss you! Independent media won’t survive without reader support.  
        </p>
      </div>

      <div styleName="cta-wrapper">
        <Link styleName="redirect-link" href="/subscription?ref=renewal-banner">
        Renew Your Subscription
          {/* <button styleName="sub-btn">Subscribe now</button> */}
        </Link>
      </div>
    </div>
  );
};
