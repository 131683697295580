import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import getStoriesByCollection from "../../../utils";
import { HeroVerticalStoryCard } from "../../molecules/hero-vertical-story-card";
import { VerticalMiniStoryCard } from "../../molecules/vertical-mini-story-card";

import "./two-rows-five-stories-vertical.m.css";

const TwoRowsFiveStoriesVertical = ({ collection }) => {
  const stories = getStoriesByCollection(collection);

  const [location, setLocation] = useState("");

  if (stories.length < 1) {
    return null;
  }

  useEffect(() => {
    setLocation(global.location.host);
  }, []);

  return (
    <div styleName="wrapper">
      <div styleName="layout-grid">
        <div>
          {stories.slice(0, 1).map((story, index) => (
            <HeroVerticalStoryCard key={index} story={story} />
          ))}
        </div>
        {stories.length > 1 &&
          (location === "www.newslaundry.com" ||
            location === "newslaundry-web.qtstage.io" ||
            location === "newslaundry-beta.quintype.io" ||
            location === "localhost:3000") && (
            <div styleName="mini-story-card-grid">
              {stories.slice(1, 5).map(story => (
                <VerticalMiniStoryCard story={story} key={story.id} />
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

TwoRowsFiveStoriesVertical.propTypes = {
  collection: PropTypes.object
};

export { TwoRowsFiveStoriesVertical };
