import React, { useState } from "react";
import PropTypes from "prop-types";
import JournoChip from "./JournoChip";
import JournoTileGroup from "./JournoTileGroup";

import { COL_HEADERS, NO_OF_COLS, NO_OF_ROWS, ROW_HEADERS } from "./constants";
import "./Grid.m.css";

const Grid = ({
  selectedJournalist,
  clearCurrentJournalistSelection,
  avgJournalists,
  currentJournoIndex = {
    cellWeightX: 1,
    cellWeightY: 1,
    index: 15,
  },
  setCurrentJournoIndex,
  resetCurrentJournoIndex,
}) => {
  const [items, setItems] = useState(
    [...Array(NO_OF_COLS * NO_OF_ROWS)].map((elem, i) => {
      return {
        cellWeightX: COL_HEADERS[i % 6].weight,
        cellWeightY: ROW_HEADERS[Math.floor(i / 6)].weight,
        index: i,
      };
    })
  );

  const handleDrop = (e, itemIndex, itemCellWeightX, itemCellWeightY) => {
    e.preventDefault();
    e.stopPropagation();

    setCurrentJournoIndex({
      cellWeightX: itemCellWeightX,
      cellWeightY: itemCellWeightY,
      index: itemIndex,
    });
  };

  return (
    <div styleName="spectrum-grid">
      {items.map((item, j) => {
        let borderStyle = {};
        if (j == 3)
          borderStyle = {
            borderLeft: "6px solid #000000",
            background: "#fd7e23",
          };
        else if (j == 9)
          borderStyle = {
            borderLeft: "6px solid #000000",
            background: "#ffa044",
          };
        else if (j == 15)
          borderStyle = {
            borderLeft: "6px solid #000000",
            borderBottom: "6px solid #000000",
            background: "#ffba77",
          };
        else if (j == 21)
          borderStyle = {
            borderLeft: "6px solid #000000",
            background: "#b5ecff",
          };
        else if (j == 27)
          borderStyle = {
            borderLeft: "6px solid #000000",
            background: "#78ddff",
          };
        else if (j == 33)
          borderStyle = {
            borderLeft: "6px solid #000000",
            background: "#2dcaff",
          };
        else if (j == 12)
          borderStyle = {
            borderBottom: "6px solid #000000",
            background: "#ff0005",
          };
        else if (j == 13)
          borderStyle = {
            borderBottom: "6px solid #000000",
            background: "#ff6e47",
          };
        else if (j == 14)
          borderStyle = {
            borderBottom: "6px solid #000000",
            background: "#ffa477",
          };
        else if (j == 16)
          borderStyle = {
            borderBottom: "6px solid #000000",
            background: "#54d457",
          };
        else if (j == 17)
          borderStyle = {
            borderBottom: "6px solid #000000",
            background: "#0f7f12",
          };
        else if (j == 0) borderStyle = { background: "#fc381e" };
        else if (j == 1) borderStyle = { background: "#fc4d1e" };
        else if (j == 2) borderStyle = { background: "#fd7e23" };
        else if (j == 4) borderStyle = { background: "#fd7e23" };
        else if (j == 5) borderStyle = { background: "#6a6f0a" };
        else if (j == 6) borderStyle = { background: "#fc351e" };
        else if (j == 7) borderStyle = { background: "#fc421e" };
        else if (j == 8) borderStyle = { background: "#fd5b20" };
        else if (j == 10) borderStyle = { background: "#b18b30" };
        else if (j == 11) borderStyle = { background: "#567e14" };
        else if (j == 18) borderStyle = { background: "#ff0005" };
        else if (j == 19) borderStyle = { background: "#ff4d51" };
        else if (j == 20) borderStyle = { background: "#ffa7b0" };
        else if (j == 22) borderStyle = { background: "#249a6c" };
        else if (j == 23) borderStyle = { background: "#0d854f" };
        else if (j == 24) borderStyle = { background: "#d02f3f" };
        else if (j == 25) borderStyle = { background: "#956682" };
        else if (j == 26) borderStyle = { background: "#7e7d9e" };
        else if (j == 28) borderStyle = { background: "#29a489" };
        else if (j == 29) borderStyle = { background: "#22955d" };
        else if (j == 30) borderStyle = { background: "#845874" };
        else if (j == 31) borderStyle = { background: "#7e7d9e" };
        else if (j == 32) borderStyle = { background: "#2dcaff" };
        else if (j == 34) borderStyle = { background: "#2baa99" };
        else if (j == 35) borderStyle = { background: "#2dafab" };
        const toPaintBorderRight = false;
        const toPaintBorderLeft = false;
        const toPaintBorderUp = false;
        const toPaintBorderDown = false;

        return (
          <div
            styleName={`grid-item ${
              item.index === currentJournoIndex.index &&
              selectedJournalist !== null
                ? ""
                : "grid-cell"
            } ${toPaintBorderRight ? "border-r" : ""} ${
              toPaintBorderLeft ? "border-l" : ""
            } ${toPaintBorderUp ? "border-t" : ""} ${
              toPaintBorderDown ? "border-b" : ""
            }`}
            key={item.index}
            onDrop={(e) => {
              handleDrop(e, item.index, item.cellWeightX, item.cellWeightY);
            }}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
            style={borderStyle}
          >
            {avgJournalists &&
            avgJournalists.filter(
              (journo) =>
                (journo.avgCellWeightX === item.cellWeightX &&
                  journo.avgCellWeightY === item.cellWeightY) ||
                (journo.cellWeightX === item.cellWeightX &&
                  journo.cellWeightY === item.cellWeightY) ||
                (journo.avgCellWeightX === item.cellWeightX + 1 &&
                  journo.avgCellWeightY === item.cellWeightY + 1) ||
                (journo.cellWeightX === item.cellWeightX + 1 &&
                  journo.cellWeightY === item.cellWeightY + 1) ||
                (journo.avgCellWeightX === item.cellWeightX + 1 &&
                  journo.avgCellWeightY === item.cellWeightY) ||
                (journo.cellWeightX === item.cellWeightX + 1 &&
                  journo.cellWeightY === item.cellWeightY) ||
                (journo.avgCellWeightX === item.cellWeightX &&
                  journo.avgCellWeightY === item.cellWeightY + 1) ||
                (journo.cellWeightX === item.cellWeightX &&
                  journo.cellWeightY === item.cellWeightY + 1)
            ).length ? (
              <JournoTileGroup
                journalists={avgJournalists.filter(
                  (journo) =>
                    (journo.avgCellWeightX === item.cellWeightX &&
                      journo.avgCellWeightY === item.cellWeightY) ||
                    (journo.cellWeightX === item.cellWeightX &&
                      journo.cellWeightY === item.cellWeightY)
                )}
                borderXJournalists={avgJournalists.filter(
                  (journo) =>
                    (journo.avgCellWeightX === item.cellWeightX + 1 &&
                      journo.avgCellWeightY === item.cellWeightY) ||
                    (journo.cellWeightX === item.cellWeightX + 1 &&
                      journo.cellWeightY === item.cellWeightY)
                )}
                borderYJournalists={avgJournalists.filter(
                  (journo) =>
                    (journo.avgCellWeightX === item.cellWeightX &&
                      journo.avgCellWeightY === item.cellWeightY + 1) ||
                    (journo.cellWeightX === item.cellWeightX &&
                      journo.cellWeightY === item.cellWeightY + 1)
                )}
                borderPointJournalists={avgJournalists.filter(
                  (journo) =>
                    (journo.avgCellWeightX === item.cellWeightX + 1 &&
                      journo.avgCellWeightY === item.cellWeightY + 1) ||
                    (journo.cellWeightX === item.cellWeightX + 1 &&
                      journo.cellWeightY === item.cellWeightY + 1)
                )}
                cellWeightX={item.cellWeightX}
                cellWeightY={item.cellWeightY}
              />
            ) : item.index === currentJournoIndex.index &&
              selectedJournalist !== null ? (
              <JournoChip
                selectedJournalist={selectedJournalist}
                clearCurrentJournalistSelection={
                  clearCurrentJournalistSelection
                }
                resetCurrentJournoIndex={resetCurrentJournoIndex}
              />
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
};

Grid.propTypes = {
  selectedJournalist: PropTypes.object,
  clearCurrentJournalistSelection: PropTypes.func,
  avgJournalists: PropTypes.array,
  currentJournoIndex: PropTypes.object,
  setCurrentJournoIndex: PropTypes.func,
  resetCurrentJournoIndex: PropTypes.func,
};

export default Grid;
