/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Headline } from "../../atoms/headline";
import AuthorWithTime from "../author-with-time";
// import { SectionName } from "../../atoms/section-tag";
import { CardImage } from "../../atoms/card-image";
import { get } from "lodash";
import { Link } from "@quintype/components";

import "./horizontal-story-card.m.css";

const SectionBadge = ({ story }) => {
  const sections = get(story, ["sections", "0"], {});

  return (
    <Link href={sections["section-url"]}>
      <p styleName="section-badge">
        {sections["display-name"] || sections.name}
      </p>
    </Link>
  );
};

const HorizontalStoryCard = ({
  story = {},
  boxPadding = true,
  removeLastChildMargin = false,
  descriptionPadding = false,
  hideAuthor = false,
  hideTime = false,
  hideAuthorWithTime = false,
  showSection = false,
  increaseFontSize = false,
  descriptionTopMargin = false,
  color = ""
}) => {
  return (
    <article>
      <div
        styleName={`horizontal-card ${!boxPadding ? "remove-padding" : ""} ${
          removeLastChildMargin ? "remove-margin" : ""
        }`}
      >
        <CardImage story={story} />
        {/* <div styleName="card-image">
      </div> */}
        <div
          className="story-description"
          styleName={`story-description ${
            descriptionPadding ? "box-padding" : ""
          } ${descriptionTopMargin ? "description-margin" : ""} ${
            increaseFontSize ? "increaseHeadingFont" : ""
          } ${!showSection && increaseFontSize ? "padding-top" : ""}`}
        >
          {showSection && <SectionBadge story={story} />}
          <Headline story={story} externalLink={true} />
          {!hideAuthorWithTime && (
            <div styleName="author-details">
              <AuthorWithTime
                story={story}
                hideAuthor={hideAuthor}
                hideTime={hideTime}
                color={color}
              />
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

HorizontalStoryCard.propTypes = {
  story: PropTypes.object,
  boxPadding: PropTypes.bool,
  descriptionPadding: PropTypes.bool,
  showTime: PropTypes.bool,
  hideAuthor: PropTypes.bool,
  hideTime: PropTypes.bool,
  hideAuthorWithTime: PropTypes.bool,
  showSection: PropTypes.bool,
  increaseFontSize: PropTypes.bool,
  removeLastChildMargin: PropTypes.bool,
  descriptionTopMargin: PropTypes.bool,
  color: PropTypes.string
};

export default HorizontalStoryCard;
