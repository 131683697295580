/* eslint-disable react/prop-types */
import React from "react";
import "./merch-page.m.css";

const LineItem = props => {
  const decrementQuantity = lineItemId => {
    const updatedQuantity = props.line_item.quantity - 1;
    props.updateQuantityInCart(lineItemId, updatedQuantity);
  };

  const incrementQuantity = lineItemId => {
    const updatedQuantity = props.line_item.quantity + 1;
    props.updateQuantityInCart(lineItemId, updatedQuantity);
  };

  return (
    <li styleName="line-item" aria-label="Product box">
      <div styleName="line-item__img">
        {props.line_item.variant.image ? (
          <img src={props.line_item.variant.image.src} alt={`${props.line_item.title} product shot`} />
        ) : null}
      </div>
      <div styleName="line-item__content">
        <div styleName="line-item__content-row">
          <span styleName="line-item__title" aria-label="Product name:">
            {props.line_item.title + " "}
            {props.line_item.variant.title !== "Default Title" ? `(${props.line_item.variant.title})` : null}
          </span>
        </div>
        <div styleName="line-item__content-row">
          <div styleName="line-item__quantity-container">
            <button styleName="line-item__quantity-update" onClick={() => decrementQuantity(props.line_item.id)} aria-label="Click to decrease this product quantity by 1">
              -
            </button>
            <span styleName="line-item__quantity">{props.line_item.quantity}</span>
            <button styleName="line-item__quantity-update" onClick={() => incrementQuantity(props.line_item.id)} aria-label="Click to increase this product quantity by 1">
              +
            </button>
          </div>
          <span styleName="line-item__price" aria-label="Product total price">
            ₹ {(props.line_item.quantity * props.line_item.variant.price.amount).toFixed(2)}
          </span>
          <button styleName="line-item__remove" onClick={() => props.removeLineItemInCart(props.line_item.id)} aria-label="Click to remove this product from cart">
            ×
          </button>
        </div>
      </div>
    </li>
  );
};

export default LineItem;
