export const NO_OF_ROWS = 6;
export const NO_OF_COLS = 6;
export const ROW_HEADERS = [
  { value: "Soldier of Hindutva", weight: 11 },
  { value: "Majority Indulger", weight: 9 },
  { value: "Majority Sympathiser", weight: 7 },
  { value: "Context Free Secularist", weight: 5 },
  { value: "Minority Indulger", weight: 3 },
  { value: "Minority Fundamentalist", weight: 1 }
];

export const COL_HEADERS = [
  { value: "Communist", weight: 1 },
  { value: "Socialist", weight: 3 },
  { value: "Limited Free Market Welfarist", weight: 5 },
  { value: "Free Market Optimist", weight: 7 },
  { value: "Capitalist", weight: 9 },
  { value: "Libertarian", weight: 11 },
];

export const MAX_JOURNO_CIRCLE_IN_GRID = 3;
