/* eslint-disable react/prop-types */
import React from "react";
import "./merch-page.m.css";

const VariantSelector = props => {
  return (
    <>
    <label htmlFor={props.option.name} style={{visibility:"hidden"}}>{props.option.label}</label>
    <select
      styleName="variant-selector"
      name={props.option.name}
      key={props.option.name}
      onChange={props.handleOptionChange}
      aria-labelledby={`${props.option.name}-label`}
      aria-describedby={`${props.option.name}-description`}
    >
      {props.option.values.map(value => {
        return <option value={value} key={`${props.option.name}-${value}`}>{`${value}`}</option>;
      })}
    </select>
    </>
  );
};

export default VariantSelector;
