export const photoArray = [
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2024-05/765f0a60-5745-4af2-93cf-1998f1f76eca/qwerty",
    text: "Subscribers Meet-up, New York, 2024",
    linkUrl: "https://flic.kr/s/aHBqjBqK2p",
    year: 2024,
    city: "New York"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2024-06/d62be7e9-0ba2-4c51-a6c9-611846e9fe6d/53727303304_e78c4754c6_o.jpg",
    text: "'Writing With Fire' screening, Delhi, 2023",
    linkUrl: "https://flic.kr/s/aHBqjBqK3S",
    year: 2023,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://assets-global.website-files.com/6113c2e2f67a66583f146c13/65423da9e6f407f8dd753a2e_jpeg-optimizer_Panel%20(3)-p-800.jpg",
    text: "The Media Rumble, Delhi, 2023",
    linkUrl: "https://www.themediarumble.com/gallery",
    year: 2023,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2023-11/e853748a-d80f-4f90-bdd8-bea081ae0580/53212827390_d42150610a_c.jpg",
    text: "'While We Watched' screening, Delhi, 2023",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/6zy6F7199y",
    year: 2023,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2024-05/79fd9356-7680-41f5-bad9-3e9020e2ecae/banglore_meet.jpg",
    text: "Subscribers Meet-up, Bengaluru, 2023",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/3Yo2712qaB",
    year: 2023,
    city: "bengaluru"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2023-05/c794a363-dfd2-4d73-8b5a-439ca6d83e40/london_meetup_may2023.jpeg",
    text: "Subscribers Meet-up, London, 2023",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/2854oJ6axz",
    year: 2023,
    city: "london"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2023-05/d039cecc-57e2-4afc-b2be-97b22867a185/michigan.jpeg",
    text: "Subscribers Meet-up, Michigan, 2023",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/0w9JM3Jd7N",
    year: 2023,
    city: "michigan"
  },
  {
    ImgUrl:
      "https://uploads-ssl.webflow.com/63bbb55b280184b21a343624/642c28caffd9a018afe301f8_2-p-800.jpg",
    text: "FoeCon, Delhi, 2023",
    linkUrl: "https://www.foecon.in/gallery/2023",
    year: 2023,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://assets-global.website-files.com/6113c2e2f67a66583f146c13/634fdfe61508d669639f236e_RSP_1140-p-800.jpg",
    text: "The Media Rumble, Delhi, 2022",
    linkUrl: "https://www.themediarumble.com/gallery",
    year: 2022,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2024-05/c317e541-6960-40b3-9520-fe43cf575468/delhi2022.jpg",
    text: "Subscribers Meet-up, Delhi, 2022",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/h4xE25Z8E9",
    year: 2022,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2024-05/bbc74842-c8fe-46a6-9dad-5e69cc8f6fef/bombay.jpg",
    text: "Subscribers Meet-up, Mumbai, 2022",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/2s56499q9K",
    year: 2022,
    city: "mumbai"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2023-05/c27e3818-6a8f-48db-b1b1-633f4bc6e494/melbourne.jpeg",
    text: "Subscribers Meet-up, Melbourne, 2022",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/pfoa94Hzgy",
    year: 2022,
    city: "Melbourne"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2023-05/e17756fc-0f3b-4d9a-95eb-21a86420586f/sydney.jpeg",
    text: "Subscribers Meet-up, Sydney, 2022",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/pfoa94Hzgy",
    year: 2022,
    city: "Sydney"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2023-05/02808157-6d2b-4170-8037-a8afc4f527b3/london22.jpeg",
    text: "Subscribers Meet-up, London, 2022",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/a14D859TQF",
    year: 2022,
    city: "london"
  },
  {
    ImgUrl:
      "https://uploads-ssl.webflow.com/63bbb55b280184b21a343624/651550f0b54df11b9761f164_img5-p-800.jpg",
    text: "FoeCon, Delhi, 2022",
    linkUrl: "https://www.foecon.in/gallery/2022",
    year: 2022,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://assets-global.website-files.com/6113c2e2f67a66583f146c13/618ca97c4aa9dc2d436b40ad_TMR_Voting%20for%20Health-p-800.png",
    text: "The Media Rumble, Delhi, 2021",
    linkUrl: "https://www.themediarumble.com/gallery",
    year: 2021,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://assets-global.website-files.com/6113c2e2f67a66583f146c13/612e3f6d77fd6436c3e60fcc_119454236_706745573254082_2368067328057163266_n.jpg",
    text: "The Media Rumble, Delhi, 2020",
    linkUrl: "https://www.themediarumble.com/gallery",
    year: 2020,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://assets-global.website-files.com/6113c2e2f67a66583f146c13/61322a966287b729e95816fe_IMG_4786-p-1080.jpeg",
    text: "The Media Rumble, Delhi, 2019",
    linkUrl: "https://www.themediarumble.com/gallery",
    year: 2019,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://assets-global.website-files.com/6113c2e2f67a66583f146c13/612e3a43ff62386e5875c9e1_40159991_287540095174634_7718676978240323584_n-p-2000.jpeg",
    text: "The Media Rumble, Delhi, 2018",
    linkUrl: "https://www.themediarumble.com/gallery",
    year: 2018,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://images.assettype.com/newslaundry/2023-05/cdc9fb2e-afaa-4f3a-aa32-bef965e76638/boston.jpeg",
    text: "Subscribers Meet-up, Boston, 2018",
    linkUrl: "https://www.flickr.com/gp/199278736@N08/A98a5X8wr6",
    year: 2018,
    city: "boston"
  },
  {
    ImgUrl:
      "https://assets-global.website-files.com/6113c2e2f67a66583f146c13/6132060740767304c2b5dad8_6F5A0150-min-p-1080.jpeg",
    text: "The Media Rumble, Delhi, 2017",
    linkUrl: "https://www.themediarumble.com/gallery",
    year: 2017,
    city: "delhi"
  },
  {
    ImgUrl:
      "https://quintype-dropbox.s3-accelerate.amazonaws.com/newslaundry.quintype.com/2024-05-27/471/15235706_1802642673281778_4592881281985920901_o.jpg",
    text: "Speak Out, Delhi, 2016",
    linkUrl: "https://flic.kr/s/aHBqjBsaBs",
    year: 2016,
    city: "delhi"
  }
];
