export const stateData = [
  {
    code: "AN",
    value: "Andaman and Nicobar Islands"
  },
  {
    code: "AP",
    value: "Andhra Pradesh"
  },
  {
    code: "AR",
    value: "Arunachal Pradesh"
  },
  {
    code: "AS",
    value: "Assam"
  },
  {
    code: "BR",
    value: "Bihar"
  },
  {
    code: "CH",
    value: "Chandigarh"
  },
  {
    code: "CT",
    value: "Chhattisgarh"
  },
  {
    code: "DN",
    value: "Dadra and Nagar Haveli"
  },
  {
    code: "DD",
    value: "Daman and Diu"
  },
  {
    code: "DL",
    value: "Delhi"
  },
  {
    code: "GA",
    value: "Goa"
  },
  {
    code: "GJ",
    value: "Gujarat"
  },
  {
    code: "HR",
    value: "Haryana"
  },
  {
    code: "HP",
    value: "Himachal Pradesh"
  },
  {
    code: "JK",
    value: "Jammu and Kashmir"
  },
  {
    code: "JH",
    value: "Jharkhand"
  },
  {
    code: "KA",
    value: "Karnataka"
  },
  {
    code: "KL",
    value: "Kerala"
  },
  {
    code: "LA",
    value: "Ladakh"
  },
  {
    code: "LD",
    value: "Lakshadweep"
  },
  {
    code: "MP",
    value: "Madhya Pradesh"
  },
  {
    code: "MH",
    value: "Maharashtra"
  },
  {
    code: "MN",
    value: "Manipur"
  },
  {
    code: "ML",
    value: "Meghalaya"
  },
  {
    code: "MZ",
    value: "Mizoram"
  },
  {
    code: "NL",
    value: "Nagaland"
  },
  {
    code: "OR",
    value: "Odisha"
  },
  {
    code: "PY",
    value: "Puducherry"
  },
  {
    code: "PB",
    value: "Punjab"
  },
  {
    code: "RJ",
    value: "Rajasthan"
  },
  {
    code: "SK",
    value: "Sikkim"
  },
  {
    code: "TN",
    value: "Tamil Nadu"
  },
  {
    code: "TG",
    value: "Telangana"
  },
  {
    code: "TR",
    value: "Tripura"
  },
  {
    code: "UP",
    value: "Uttar Pradesh"
  },
  {
    code: "UT",
    value: "Uttarakhand"
  },
  {
    code: "WB",
    value: "West Bengal"
  }
];
