import React from "react";
import PropTypes from "prop-types";
import getStoriesByCollection from "../../../utils";
import { CollectionName } from "../../atoms/collection-name";
import OnecolFourStories from "../../molecules/one-col-four-stories";
import SubscribeOnlyColumn from "../../molecules/subscribe-only-column";

import { Link } from "@quintype/components";
import "./three-collections-with-poll.m.css";

const ThreeCollectionsWithPoll = ({ collection = {} }) => {
  // const [isHindi, setIsHindi] = useState(false);

  // useEffect(() => {
  // const isHindiCheck = !!(
  //   global.location && global.location.host.includes("hindi")
  // );
  // console.log("hindi story");
  // setIsHindi(isHindiCheck);
  // }, []);
  if (collection.items.length <= 0) {
    return null;
  }
  const collections = collection.items.filter(
    collection => collection.type === "collection"
  );
  const firstCollectionStories = getStoriesByCollection(collections[0]) || [];
  const secondCollectionStories = getStoriesByCollection(collections[1]) || [];
  const thirdCollectionStories = getStoriesByCollection(collections[2]) || [];
  return (
    <div className="full-width-with-padding" styleName="wrapper">
      <div styleName="grid-container" className="container">
        {firstCollectionStories.length > 0 && (
          <div styleName="col">
            <CollectionName collection={collections[0]} />
            <SubscribeOnlyColumn stories={firstCollectionStories} />
          </div>
        )}
        {secondCollectionStories.length > 0 && (
          <div styleName="col">
            <CollectionName collection={collections[1]} />
            <OnecolFourStories stories={secondCollectionStories} />
          </div>
        )}

        {thirdCollectionStories.length > 0 && (
          <div styleName="col">
            <CollectionName collection={collections[2]} />
            <OnecolFourStories stories={thirdCollectionStories} />
          </div>
        )}
        {/* {false ? (
          <div styleName="img-wrapper">
            <Link href={`/hindi-whatsapp-optin`}>
              <img
                styleName="banner"
                src="https://images.assettype.com/newslaundry/2023-06/b4adfa8d-db04-4bb2-a0bd-7884b8d88bea/Untitled_design__14_.png"
              />
            </Link>
          </div>
        ) : ( */}
        <div styleName="img-wrapper">
          <Link href={`/subscription?ref=homepage-vertical-banner`}>
            <img
              styleName="banner"
              src="https://images.assettype.com/newslaundry/2023-11/a5961565-740d-4007-b884-48c6c6600003/INTRODUCING_THE_NL_TNM_JOINT_SUBSCRIPTION__2_.png"
              alt="Click to subscribe to NL-TNM joint subscription"
            />
          </Link>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

ThreeCollectionsWithPoll.propTypes = {
  collection: PropTypes.object
};

export default ThreeCollectionsWithPoll;

ThreeCollectionsWithPoll.storyLimit = 3;
