import React from "react";
import { faqData } from "../../faq";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import { Link } from "@quintype/components";
import { VisuallyHidden } from "../../../../ui/components";

import "./faq.m.css";

export const Faq = () => {
  // const [isCollapsed, setIsCollapsed] = React.useState(true);
  const subFaqData = faqData.filter(
    data => data.sectionName === "Subscription basics"
  );

  return (
    <div styleName="section-wrapper" className="full-width">
      <h2>Frequently asked questions</h2>
      <div styleName="faq-wrapper">
        {subFaqData.map(sec => (
          <div key={sec.sectionName} id={`${sec.id}`}>
            <Accordion allowZeroExpanded>
              {sec.children.slice(0, 5).map(item => (
                <AccordionItem key={item.q}>
                  <AccordionItemHeading>
                    <AccordionItemButton>{item.q}</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>{item.a}</AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        ))}
      </div>
      <Link styleName="redirect-link" href="/faq">
        View more
        <VisuallyHidden>subscription related FAQ&apos;s</VisuallyHidden>
      </Link>
    </div>
  );
};
