/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { parseUrl } from "query-string";
import get from "lodash/get";
import assetify from "@quintype/framework/assetify";
import successLogo from "../../../../../assets/images/nl-images/nl-subscription-2.jpg";
import { subscriptionMerchandise } from "./MERCH";
import "./confirmation.m.css";
import Loader from "../../../atoms/loader";
import { createShopifyOrder } from "../../../../cartoon-mango";
import { fetchTagsForPlans } from "./data";

export const Confirmation = props => {
  // console.log({ confirmation_page_props: props });
  const [planName, setPlanName] = useState("");
  const [showMerchForm, setShowMerchForm] = useState(false);

  const member = useSelector(state => {
    return get(state, ["member"], null);
  });

  const { fetchSubscription } = useSelector(state => state);

  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  const params = parseUrl(currentPath);

  // const status = params.query.status;
  // const gameChanger = params.query.gamechanger;
  // const disruptor = params.query.disruptor;
  const planId = params.query.plan;

  const planLegend = {
    338: {
      name: "Disruptor - Monthly",
      price: "300"
    },
    340: {
      name: "Disruptor - Yearly",
      price: "3000"
    },
    343: {
      name: "Game Changer - Yearly",
      price: "10000"
    },
    2033: {
      name: "Disruptor - Student",
      price: "1,500"
    }
  };

  const pushToDataLayer = selectedPlan => {
    const obj = {
      event: "subscription_purchase_successful",
      subscription_plan: selectedPlan.name,
      price: selectedPlan.price,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    window.dataLayer.push(obj);
  };

  useEffect(() => {
    pushToDataLayer(
      planLegend[params.query.plan.toString()]
        ? planLegend[params.query.plan.toString()]
        : "null"
    );
    // console.log(planId, "plan id", typeof planId);
    // console.log(gameChanger, disruptor, "plan nameeee");
    // if ([4527, 4523, 4476, 4475, 751, 750, 327, 343].includes(+planId)) {
    if (
      [
        4523, 4527, 4522, 4526, 4476, 4475, 4479, 4477, 327, 750, 325, 746, 343,
        751, 341, 747
      ].includes(+planId)
    ) {
      console.log(planId, "plan id gamechanger");
      setShowMerchForm(true);
      setPlanName("gameChanger");
    } else if ([4524, 4520, 4472, 4471, 745, 742, 330, 340].includes(+planId)) {
      console.log(planId, "plan id disruptor");
      setShowMerchForm(true);
      setPlanName("disruptor");
    }
    // if (gameChanger && [
    //   4524, 4520, 4472, 4471, 745, 742, 330, 340, 4527, 4523, 4476, 4475,
    //   751, 750, 327, 343
    // ].includes(planId) ) {
    //   console.log(planId, "plan id gamechanger");
    //   setShowMerchForm(true);
    //   setPlanName("gameChanger");
    // } else if (disruptor && [
    //   4524, 4520, 4472, 4471, 745, 742, 330, 340, 4527, 4523, 4476, 4475,
    //   751, 750, 327, 343
    // ].includes(planId)) {
    //   console.log(planId, "plan id disruptor");
    //   setShowMerchForm(true);
    //   setPlanName("disruptor");
    // }
  }, []);

  return (
    <div styleName="wrapper" className="container">
      <img src={assetify(successLogo)} alt="success logo" />
      <h2>
        You’re a star! Thank you so much for paying to keep news free and
        supporting our journalism.
      </h2>
      <p />
      {showMerchForm && (
        <MerchForm
          planName={planName}
          member={member}
          showMerchForm={showMerchForm}
          setShowMerchForm={setShowMerchForm}
          planId={+planId}
        />
      )}
    </div>
  );
};

const MerchForm = ({
  planName,
  planId,
  member,
  showMerchForm,
  setShowMerchForm
}) => {
  const [data, setData] = useState({
    loading: false,
    success: false,
    error: "",
    address: "",
    phone: "",
    city: "",
    state: "",
    pincode: ""
  });

  const [error, setError] = useState(null);
  const { loading, address, phone, city, state, pincode } = data;

  const handleChange = e => {
    const { name, value } = e.target;
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      setData({ loading: true });

      const productsToOrder = subscriptionMerchandise[planName];
      console.log(productsToOrder);

      const lineItems =
        productsToOrder &&
        productsToOrder.length &&
        productsToOrder.map(product => ({
          variant_id: product.variantId,
          quantity: 1
        }));

      const userEmail = member.email;

      let userName = {};
      if (member["last-name"] && member["last-name"].length) {
        userName = {
          first_name: member["first-name"] || member.name,
          last_name: member["last-name"]
        };
      } else {
        userName = {
          first_name: "",
          last_name: member["first-name"] || member.name
        };
      }
      const userAddress = {
        address1: address,
        phone: phone,
        city,
        province: state,
        zip: pincode,
        country: "India"
      };

      const tags = fetchTagsForPlans(planId);

      console.log({ tags });

      const payload = {
        order: {
          email: userEmail,
          line_items: [...lineItems],
          customer: {
            first_name: member["first-name"] || member.name,
            last_name: member["last-name"],
            email: userEmail
          },
          billing_address: {
            ...userName,
            ...userAddress
          },
          shipping_address: {
            ...userName,
            ...userAddress
          },
          tags: tags.join(",")
        }
      };

      console.log({ shopify_payload: payload });

      await createShopifyOrder(payload);
      setShowMerchForm(false);
    } catch (e) {
      console.log(e);
      setError(
        "Something went wrong, please contact subscription@newslaundry.com"
      );
    } finally {
      setData({ loading: false });
    }
  };

  return (
    <div styleName="merch-form-wrapper">
      <h5>Shipping address</h5>
      <small>
        The address you would like the Newslaundry merch to be shipped to (if
        you&apos;re based overseas, please share an Indian address)
      </small>

      <form onSubmit={handleSubmit}>
        <div styleName="form-group">
          <label htmlFor="address" styleName="text">
            Address
          </label>
          <input
            styleName="input-text"
            required
            value={address}
            id="address"
            type="text"
            placeholder="Street address"
            name="address"
            onChange={handleChange}
          ></input>
        </div>

        <div styleName="flex-wrap">
          <div styleName="form-group">
            <label htmlFor="city" styleName="text">
              City
            </label>
            <input
              styleName="input-text"
              required
              value={city}
              id="city"
              type="text"
              placeholder="City"
              name="city"
              onChange={handleChange}
            ></input>
          </div>

          <div styleName="form-group">
            <label htmlFor="state" styleName="text">
              State
            </label>
            <input
              styleName="input-text"
              required
              value={state}
              id="state"
              type="text"
              placeholder="State"
              name="state"
              onChange={handleChange}
            ></input>
          </div>

          <div styleName="form-group">
            <label htmlFor="pincode" styleName="text">
              Pincode
            </label>
            <input
              styleName="input-text"
              required
              value={pincode}
              id="pincode"
              type="text"
              placeholder="Pincode"
              name="pincode"
              onChange={handleChange}
            ></input>
          </div>
        </div>

        <div styleName="form-group">
          <label htmlFor="phone" styleName="text">
            Phone Number
          </label>
          <input
            styleName="input-text"
            required
            value={phone}
            id="phone"
            type="text"
            placeholder="Phone"
            name="phone"
            onChange={handleChange}
          ></input>
        </div>
        <small styleName="error-message">{error}</small>
        <br />
        <button type="submit">{!loading ? "Submit" : <Loader />}</button>
      </form>
    </div>
  );
};
