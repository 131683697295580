import React from "react";
import App from "./App";

export function Spectrum() {
  return (
    <>
      <App />
    </>
  );
}
