import React from "react";
import "../../index.m.css";
import PropTypes from "prop-types";

export default function Card(props) {
  const isVoted = props.isVoted ? "card-select" : "";

  return (
    <>
      <div
        styleName={`card ${isVoted}`}
        onClick={e =>
          isVoted ? props.onDelete(props.res.id) : props.onClick(props.res.id)
        }
      >
        <div styleName="center-align">
          <div styleName="vote-img" style={{ position: "relative" }}>
            <img
              src={props.res.imageURL}
              styleName=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%"
              }}
              alt="..."
            />
            <span styleName="selected-check">✔</span>
          </div>
          <h3>{props.res.name}</h3>
          <p style={{marginTop:"-5px", marginBottom:"2vh", color:"#bebebe", fontSize:"calc(12px + 0.4vw)", width:"100%", textAlign:"center"}}>{props.res.numberOfVotes||"0"} votes</p>
        </div>
      </div>
    </>
  );
}
Card.propTypes = {
  onClick: PropTypes.any,
  onDelete: PropTypes.any,
  isVoted: PropTypes.any,
  res: PropTypes.any
};


