import React from "react";
import PropTypes from "prop-types";
import CommonListPage from "../../molecules/common-list-page";
import { TwitterIcon } from "../../atoms/icons/twitter-icon";
import "./author.m.css";

const AuthorPage = ({ data = {} }) => {
  const author = data.author || {};

  return (
    <div styleName="author-container">
      <div className="full-width-with-padding" styleName="author-box">
        <figure styleName="image-wrapper">
          {author["avatar-url"] && (
            <img src={author["avatar-url"]} styleName="image" alt="author avatar" />
          )}
        </figure>
        <div styleName="author-details">
          <h4 styleName="name">{author.name}</h4>
          <p styleName="bio">{author.bio}</p>
          {author["twitter-handle"] && (
            <a
              href={author["twitter-handle"]}
              styleName="twitter-handle"
              target="blank"
            >
              <TwitterIcon color="#1da1f2" />{" "}
              <span className="follow-text">Follow</span>
            </a>
          )}
        </div>
      </div>
      <CommonListPage data={data} />
    </div>
  );
};

AuthorPage.propTypes = {
  data: PropTypes.shape({
    stories: PropTypes.array
  })
};

export { AuthorPage };
