/* eslint-disable react/prop-types */
import React, { useRef, useState, useEffect } from "react";

import { BsCaretDownFill } from "react-icons/bs";
import countryCodeAndEmojisData from "./countryCodes.json";

import "./telInput.m.css";

const TelInput = ({
  phoneNumber,
  setPhoneNumber,
  selectedCountry,
  setSelectedCountry
}) => {
  const data = countryCodeAndEmojisData;

  const inputElem = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHindi, setIsHindi] = useState(false);

  useEffect(() => {
    const isHindiCheck = !!(
      global.location && global.location.host.includes("hindi")
    );

    setIsHindi(isHindiCheck);
  }, []);

  const handleOptionSelect = c => {
    setSelectedCountry({
      countryName: c.countryName,
      countryPhoneCode: c.countryPhoneCode,
      flagEmoji: c.flagEmoji,
      minSize: c.minSize,
      maxSize: c.maxSize
    });

    setIsMenuOpen(false);
    inputElem.current.focus();
  };

  return (
    <div styleName="telinput-wrapper">
      <div styleName="selected-country-wrapper">
        <div
          styleName="selected-country-emoji-wrapper"
          onClick={() => setIsMenuOpen(s => !s)}
        >
          <p>{selectedCountry.flagEmoji}</p>
          <BsCaretDownFill />
        </div>
        <div styleName="selected-country-code-wrapper">
          {selectedCountry.countryPhoneCode}
        </div>
        <input
          type="tel"
          name="phoneNumber"
          id="phoneNumber"
          required
          // pattern="[0-9]{10}"
          minLength={selectedCountry.minSize}
          maxLength={selectedCountry.maxSize}
          placeholder={
            isHindi
              ? "आपका 10 अंकों का मोबाइल नंबर"
              : "Your 10-digit mobile number"
          }
          styleName="selected-country-input"
          ref={inputElem}
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
      </div>
      {isMenuOpen && (
        <div styleName="select-menu-options">
          {data.map((c, i) => (
            <div
              key={i}
              styleName="select-menu-option"
              onClick={() => handleOptionSelect(c)}
            >
              <div styleName="flag-country-field">
                {c.flagEmoji} {c.countryName}
              </div>
              <div styleName="country-code-field">{c.countryPhoneCode}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export { TelInput };
