import React, { useState } from "react";

import "./valentine-page.m.css";

const ValentinePage = () => {
  const [hamperOption, setHamperOption] = useState(1);

  const handleBtnClick = () => {
    if (hamperOption === 1) {
      window.open(
        `https://razorpay.com/payment-button/pl_LDwNW6y6S9MKlO/view`,
        "_blank"
      );
    } else {
      window.open(
        `https://razorpay.com/payment-button/pl_LDwPm9tfKo9GD2/view`,
        "_blank"
      );
    }
  };

  return (
    <section styleName="section">
      <main styleName="hero-section">
        <div>
          {/* <div styleName="hero-title-wrapper">
            <h1 styleName="title">Newslaundry Mystery Hamper</h1>
            <p styleName="border-bottom"></p>
          </div> */}
          <div styleName="hero-section-content-wrapper">
            <div styleName="hero-section-content">
              <div styleName="hero-title-wrapper">
                <h1 styleName="title">Newslaundry Mystery Hamper</h1>
                <p styleName="border-bottom"></p>
              </div>
              <p styleName="description">
                If you’re looking for the perfect gift this Valentine’s Day, try
                out Newslaundry’s Mystery hampers.
              </p>
              <p styleName="description">
                Not only does this add an element of mystery to your gifting
                experience, you also keep reader-supported media alive while
                spreading the word about accurate and independent news.
              </p>
            </div>
            <div styleName="hero-section-img">
              <img
                src="https://images.assettype.com/newslaundry/2023-02/a6ac09cd-ce45-43aa-8601-453140b2929d/valentines_pink.jpg"
                alt="Valentines Mystery box"
              />
            </div>
          </div>
        </div>
      </main>
      <div styleName="sub-section">
        <div styleName="sub-section-contents">
          <p>
            Each hamper contains Newslaundry merch - such as Birubal and
            Birubala Wooden Dolls, NL Caps, Tote bags, exciting books and much
            more. The contents are a surprise, and they’ll be worth the cost of
            the hamper itself.
          </p>
          <p>Choose a hamper and surprise your loved ones!</p>
        </div>
        <div styleName="sub-section-form">
          <p styleName="option-heading">Choose a hamper:</p>
          <div styleName="option-row">
            <div
              styleName={`option left ${
                hamperOption === 1 ? "selected-option" : "unselected-option"
              }`}
              onClick={() => setHamperOption(1)}
            >
              Hamper #1
            </div>
            <div
              styleName={`option right ${
                hamperOption === 2 ? "selected-option" : "unselected-option"
              }`}
              onClick={() => setHamperOption(2)}
            >
              Hamper #2
            </div>
          </div>
          <p styleName="option-heading">Your hamper will include:</p>
          {hamperOption === 1 ? (
            <ul>
              <li>Five different products from the NL Store</li>
              <li>A book signed by the NL team</li>
            </ul>
          ) : (
            <ul>
              <li>Eight different products from the NL Store</li>
              <li>A book signed by the NL team</li>
            </ul>
          )}
          <div styleName="cta-row">
            <div styleName="cta-content">
              <div styleName="cta-content-to-pay">To pay</div>
              <div styleName="cta-content-price-wrapper">
                <div styleName="cta-content-price">
                  &#x20B9;{hamperOption === 1 ? "2,000" : "5,000"}
                </div>
                <div styleName="cta-content-price-small">
                  &#x20B9;{hamperOption === 1 ? "2,545" : "5,891"}
                </div>
              </div>
            </div>
            <button styleName="cta-pay-btn" onClick={handleBtnClick}>
              Buy Now
            </button>
          </div>
        </div>
      </div>
      <div styleName="items-sub-section">
        <h3 styleName="items-heading">A peek into the hamper</h3>
        <div styleName="item-option-grid">
          <div styleName="item-option last">
            <p styleName="item-option-heading">
              Books signed by Newslaundry Team
            </p>
            <img
              loading="lazy"
              src="https://images.assettype.com/newslaundry/2023-02/e344d51e-7306-4fed-8e1b-4535109b2c0a/Your__10_.png"
              alt="NL Mystery Hamper books"
              style={{ aspectRatio: "16/9" }}
            />
          </div>
          {/* <div styleName="item-option">
            <p styleName="item-option-heading">Desk Calendar by Manjul</p>
            <img
              loading="lazy"
              src="https://images.assettype.com/newslaundry/2022-02/aaf2f71f-4b53-4d62-bde0-a9fb654a2648/IMG_0089.jpg"
              alt=""
              style={{ aspectRatio: "16/9" }}
            />
          </div> */}
          <div styleName="item-option last">
            <p styleName="item-option-heading">Newslaundry Merchandise</p>
            <div styleName="products-grid">
              {/* <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/SoapBox-1_10cefa24-99b9-4d54-9352-5844946d8401.png?v=1605338175"
                alt="sabki dhulai soap set"
              /> */}
              <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/NaxalMug-Sides_79389f8f-60cb-4459-940a-8ce7b7474cf3.png?v=1606400739"
                alt="which naxal are you mug"
              />
              <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/asd3_578e67fa-162b-4f8d-8104-b021759e6a13_1024x1024@2x.png?v=1628580393"
                alt="car stickers"
              />
              {/* <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/shopify-black-mask2.jpg?v=1631534798"
                alt="mask"
              /> */}
              <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/asd3_1024x1024@2x.png?v=1627538272"
                alt="laptop stickers"
              />
              <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Fridgemagnets_1024x1024@2x.jpg?v=1636627660"
                alt="fridge magnets"
              />
              <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Bag-1_540x.jpg?v=1653990477"
                alt="nl backpack"
              />
              <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Shopify-CAP-1_1024x1024@2x.jpg?v=1675851243"
                alt="nl cap"
              />
              <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/Shopify-1_1024x1024@2x.jpg?v=1669289463"
                alt="kashmir ki kahani book"
              />
              <img
                loading="lazy"
                src="https://cdn.shopify.com/s/files/1/0480/0325/2390/products/bothsq_540x.jpg?v=1661949796"
                alt="birubal and birubala russian dolls"
              />
            </div>
          </div>
        </div>
      </div>
      <div styleName="terms-sub-section">
        <div styleName="terms-heading">Terms and Conditions:</div>
        <ul styleName="notes-wrapper">
          <li>
            This is a limited period offer from 8 February, 2023 to 22 February,
            2023.
          </li>
          <li>Delivery in India only.</li>
          <li>
            If you are based overseas, please share an Indian delivery address
            (of friends/family).
          </li>
          <li>
            Please note, delivery of packages will take at least two to three
            weeks. Thank you for your patience.
          </li>
          <li>
            If you have any queries, please drop an email on{" "}
            <a style={{ color: "#ec2227" }} href="mailto:store@newslaundry.com">
              store@newslaundry.com
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export { ValentinePage };
