/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Loader from "../../../../atoms/loader";
import "./independence-template.m.css";
import { parseUrl } from "query-string";
import { connect } from "react-redux";
import { SenaSuccessForm } from "../../../../molecules/show-success-msg/sena-success-form";
import assetify from "@quintype/framework/assetify";
import IDay from "../../../../../../assets/images/IDay.png";
import { sentences } from "./constant";
import { PAYMENT_CHECKOUT_PAGE } from "../../../../helper/actions";
import { GameChangerForm } from "../../../../molecules/show-success-msg/gc-form";
// import { SenaSuccessForm } from "../../../../molecules/show-success-msg/sena-success-form";
// import assetify from "@quintype/framework/assetify";
// import IDay from "../../../../../../assets/images/IDay.png";

class IndependenceTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSubscriptions: {},
      userSubscriptions: [],
      isAccesstypeLoaded: false,
      selectFirstPlan: {},
      selectedGroup: {},
      activeTab: "tab-2"
    };
    this.handleSubscriptionClick = this.handleSubscriptionClick.bind(this);
  }

  componentDidMount() {
    this.mountedRoute = window.location.href;
    const userSubscription = () => this.getSubscription();
    this.props.initAccessType(userSubscription);
    this.props.selectedPlan({});

    const groups = get(this.props, ["subscriptions"], []);
    this.setState({ selectedGroup: groups[1] });
  }

  async getSubscription() {
    const response = await this.props.getSubscriptionForUser();
    this.setState({
      userSubscriptions: response.subscriptions,
      isAccesstypeLoaded: true
    });
  }

  getDurationBlock() {
    const groups = get(this.props, ["subscriptions"], []);

    return (
      <div styleName="tab">
        <button
          styleName={`${
            this.state.activeTab === "tab-1" ? "tab-btn-active" : ""
          } tab-btn-1 `}
          onClick={() => this.manageTab("tab-1", groups[0])}
        >
          {groups[0].name}
        </button>
        <button
          styleName={`${
            this.state.activeTab === "tab-2" ? "tab-btn-active" : ""
          } tab-btn-2 `}
          onClick={() => this.manageTab("tab-2", groups[1])}
        >
          {groups[1].name}
        </button>
      </div>
    );
  }

  manageTab(tab, group) {
    this.setState({
      activeTab: tab,
      selectedGroup: group
    });
  }

  addDefaultSrc(ev, imgUrl) {
    ev.target.src = imgUrl;
  }

  handleSubscriptionClick(e, subscriptionGroup, hasSubscription, type = "") {
    e.preventDefault();
    e.stopPropagation();
    let selectedSubscriptionPlan;
    if (type === "gift-subscription") {
      this.props.paymentCheckout(type);
    } else {
      this.props.paymentCheckout(type);
    }
    if (!this.props.member) {
      const params = parseUrl(this.props.currentPath);
      const callbackUrl = get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      );
      const redirectUrl = get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      );
      window.location = `${this.props.authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${this.props.authHost}/user-login`;
      return false;
    }
    if (
      hasSubscription.filter(item => item.active && item.recurring).length &&
      type !== "gift-subscription"
    ) {
      window.alert(
        "Oops! You already have a recurring subscription. Click on gift subscription to avail the offer"
      );
      return true;
    }
    const plans = get(subscriptionGroup, ["subscription_plans"]);
    this.props.authHost.includes("qtstage")
      ? (selectedSubscriptionPlan =
          subscriptionGroup.name === "Disruptor" ? plans[14] : plans[10])
      : (selectedSubscriptionPlan =
          subscriptionGroup.name === "Disruptor" ? plans[12] : plans[12]);
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get("story-url") || "/independence-day-offer";
    this.props.selectedPlan(
      selectedSubscriptionPlan,
      redirectUrl,
      subscriptionGroup.subscription_plans
    );
    this.props.navigateTo(`/payment-checkout`);
    return true;
  }

  renderList(feature, index) {
    return (
      <li
        styleName={
          this.state.activeTab === "tab-1" && (index === 6 || index === 7)
            ? "feature-list-wrong"
            : "feature-list-right"
        }
        key={index + 1}
      >
        {feature}
      </li>
    );
  }

  getSubscriptionBlocks(subscriptionGroup) {
    // const plan = get(subscriptionGroup, ["subscription_plans", "0"], {});
    // let description;
    let imageUrl;
    let bottomDesc1;
    let bottomDesc2;
    const getDefaultImg = get(
      this.props.config,
      ["publisher", "publisher", "defaultSubImg"],
      ""
    );
    const hasSubscription =
      (this.state.userSubscriptions &&
        this.state.userSubscriptions.filter(
          userSub => userSub.subscription_group_id === subscriptionGroup.id
        )) ||
      [];
    switch (this.state.activeTab) {
      case "tab-1":
        description = get(subscriptionGroup, ["description"], "").split("\n");
        imageUrl =
          "https://thumbor-stg.assettype.com/newslaundry/2021-08/74644dcd-ce05-45d9-a29d-56dfcf76cb3d/Birbal_300X200.png";
        bottomDesc1 = "₹ 3600/year";
        bottomDesc2 = "₹ 3000/year";
        break;
      case "tab-2":
        description = get(subscriptionGroup, ["description"], "").split("\n");
        imageUrl =
          "https://thumbor-stg.assettype.com/newslaundry/2021-08/eaf163ff-a134-4b91-b99d-dac93959c94e/birubala_300x200.png";
        bottomDesc1 = "₹ 12000/year";
        bottomDesc2 = "₹ 10000/year";
        break;
    }
    return (
      <div>
        {this.getDurationBlock()}
        <div styleName="top-wrapper">
          <div styleName="left">{bottomDesc1}</div>
          <div styleName="right">{bottomDesc2}</div>
        </div>
        <div styleName="group">
          <img
            onError={ev => this.addDefaultSrc(ev, getDefaultImg)}
            styleName="image"
            src={imageUrl}
            alt={subscriptionGroup.name}
          />
          &nbsp;
          <div styleName="plan-content">
            <ul styleName="feature">
              {sentences.map((feature, index) => {
                return (
                  feature.length > 0 &&
                  (this.state.activeTab === "tab-1" && index !== 0
                    ? this.renderList(feature, index)
                    : this.state.activeTab === "tab-2" &&
                      index !== 1 &&
                      this.renderList(feature, index))
                );
              })}
            </ul>
          </div>
        </div>

        <div styleName="btn-wrapper">
          <button
            data-testid="plans-subscribe-btn"
            styleName="subs-btn"
            onClick={e =>
              this.handleSubscriptionClick(
                e,
                subscriptionGroup,
                hasSubscription
              )
            }
          >
            Subscribe
          </button>
          &nbsp;
          <button
            data-testid="plans-subscribe-btn"
            styleName="gift-btn"
            onClick={e =>
              this.handleSubscriptionClick(
                e,
                subscriptionGroup,
                hasSubscription,
                "gift-subscription"
              )
            }
          >
            Gift subscription
          </button>
        </div>
      </div>
    );
    // });
  }

  OnClick = () => {
    this.props.navigateTo(window.location.pathname);
  };

  renderForm = () => {
    return <GameChangerForm handleClose={this.OnClick} mode={3} />;
  };

  renderIndependenceTemplate = (
    planPageTitile,
    planPageDescription,
    selectedGroup
  ) => {
    const groups = get(this.props, ["subscriptions"], []);
    const locations = global.location.host;
    return groups && groups.length > 0 ? (
      <React.Fragment>
        <div styleName="outer" />
        <div className="full-width-with-padding" styleName="bg-wrapper">
          <div styleName="wrapper">
            {planPageTitile[0] &&
              (locations === "www.newslaundry.com" ||
                locations === "newslaundry-web.qtstage.io" ||
                locations === "newslaundry-beta.quintype.io") && (
                <h1 styleName="title">Newslaundry Subscription Offer</h1>
              )}
            {planPageTitile[0] &&
              (locations === "newslaundry-hindi-web.qtstage.io" ||
                locations === "hindi.newslaundry.com") && (
                <h1 styleName="title">न्यूज़लॉन्ड्री सब्सक्रिप्शन ऑफर</h1>
              )}
            {(locations === "www.newslaundry.com" ||
              locations === "newslaundry-web.qtstage.io" ||
              locations === "newslaundry-beta.quintype.io") && (
              <React.Fragment>
                <h4 styleName="description">
                  As we celebrate India’s 75th Independence Day, let’s remind
                  ourselves that free and independent media is the lifeblood of
                  a democratic society. News media can’t be free and independent
                  if it survives on government and corporate largesse.
                  Advertisements cannot sustain public interest journalism –
                  only you can.
                  <br /> This Independence Day, pay to keep news free. Buy a
                  subscription or gift one to access the full range of our work
                  and attend subscriber-only events such as NL Recess. As a
                  token of thanks, you’ll also get branded goodies such as the
                  Newslaundry tote bag and Sabki Dhulai herbal soap set{" "}
                </h4>
                <h2 styleName="description">
                  <b>Choose from the subscription plans below:</b>
                </h2>
              </React.Fragment>
            )}
            {(locations === "newslaundry-hindi-web.qtstage.io" ||
              locations === "hindi.newslaundry.com") && (
              <React.Fragment>
                <h4 styleName="description">
                  भारत अपना 75वां स्वतंत्रता दिवस मना रहा है, इस मौके पर आइए हम
                  खुद को याद दिलाएं कि स्वतंत्र और विज्ञापन मुक्त मीडिया एक
                  लोकतांत्रिक समाज के लिए ऑक्सीजन की तरह है. सरकार और कॉरपोरेट
                  के भरोसे चलने वाला मीडिया कभी भी स्वतंत्र नहीं हो सकता. जनहित
                  की पत्रकारिता कभी भी विज्ञापन के भरोसे नहीं हो सकती, यह सिर्फ
                  आप ही कर सकते है. इस स्वतंत्रता दिवस, समाचारों को विज्ञापन के
                  मुक्त रखे. न्यूज़लॉन्ड्री को सब्सक्राइब करें और हमारे सभी
                  पेवॉल कंटेंट, केवल सब्सक्राइबर्स के लिए आयोजित एनएल रीसेस जैसे
                  कार्यक्रमों का हिस्सा बने. आभार के रूप में आपको मिलेगा,
                  न्यूज़लॉन्ड्री टोट बैग और सबकी धुलाई हर्बल साबुन सेट.
                </h4>
                <h2 styleName="description">
                  <b> नीचे दिए गए सब्सक्रिप्शन प्लान चुनें:</b>
                </h2>
              </React.Fragment>
            )}

            <div styleName="layout">
              {this.getSubscriptionBlocks(selectedGroup)}

              <div>
                <br />
                <br />
                {(locations === "www.newslaundry.com" ||
                  locations === "newslaundry-web.qtstage.io" ||
                  locations === "newslaundry-beta.quintype.io") && (
                  <h1 styleName="title">Newslaundry Store Offer </h1>
                )}
                {(locations === "newslaundry-hindi-web.qtstage.io" ||
                  locations === "hindi.newslaundry.com") && (
                  <h1 styleName="title">न्यूज़लॉन्ड्री स्टोर ऑफर</h1>
                )}

                <br />

                <img
                  src="https://thumbor-stg.assettype.com/newslaundry/2021-08/feeb98b8-b5ec-45ee-914d-dd6208d97bbb/image_9.png"
                  alt="merch"
                  styleName="hamper"
                />
                <br />
                <div styleName="merch-btn">
                  <button
                    data-testid="plans-subscribe-btn"
                    styleName="gift-btn"
                    onClick={() => this.props.navigateTo("/store")}
                  >
                    Buy/Gift NL merchandise
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ol styleName="terms">
          <h2 styleName="termsHeading">Terms and Conditions</h2>
          <li>
            {" "}
            This is a limited period offer from August 10, 2021 to August 23,
            2021.
          </li>
          <li>
            {" "}
            Recurring subscribers will only be able to gift subscriptions.
          </li>
          <li>
            {" "}
            Delivery in India only. If you are based overseas, please share an
            Indian delivery address (of friends/family).
          </li>
          <li>
            {" "}
            Please be patient with us. Delivery of packages will take around two
            weeks.{" "}
          </li>
          <li> For questions, write to us at subscription@newslaundry.com</li>
        </ol>
      </React.Fragment>
    ) : null;
  };

  render() {
    const planPageTitile = get(
      this.props.config,
      ["publisher", "publisher", "planPageTitle"],
      ""
    ).split(",");
    const planPageDescription = get(
      this.props.config,
      ["publisher", "publisher", "planPageDesc"],
      ""
    );
    const groups = get(this.props, ["subscriptions"], []);

    if (this.state.isAccesstypeLoaded === false) {
      return (
        <div styleName="loader">
          <Loader color="#ec2227" />
        </div>
      );
    }

    const selectedGroup = isEmpty(this.state.selectedGroup)
      ? groups[0]
      : this.state.selectedGroup;
    return this.renderIndependenceTemplate(
      planPageTitile,
      planPageDescription,
      selectedGroup
    );
  }
}
const mapDispatchToProps = dispatch => ({
  paymentCheckout: function(payload) {
    dispatch({
      type: PAYMENT_CHECKOUT_PAGE,
      payload: payload
    });
  }
});
const mapStateToProps = state => {
  return {
    currentPath: get(state, ["qt", "currentPath"], ""),
    authHost: get(
      state,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    ),
    subscriptions: get(state, ["subscriptions"], null),
    redirectURL: get(state, ["selectedPlan", "redirectUrl"], ""),
    isSubscribedUser: get(state, ["isSubscribedUser"], []),
    isPaymentSuccess: get(state, ["isPaymentSuccess"], false)
  };
};

IndependenceTemplate.propTypes = {
  initAccessType: PropTypes.func,
  member: PropTypes.object,
  config: PropTypes.object,
  manageLoginForm: PropTypes.func,
  navigateTo: PropTypes.func,
  selectedPlan: PropTypes.func,
  paymentCheckout: PropTypes.func,
  getSubscriptionForUser: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndependenceTemplate);
