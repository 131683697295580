/* eslint-disable react/prop-types */
import React from "react";
import CommonStoryCard from "../../../molecules/common-story-card";
import getStoriesByCollection from "../../../../utils";
import "./subscription-explained.m.css";

export const SubscriptionExplained = ({ data }) => {
  const IS_HINDI = !!(
    global.location && global.location.host.includes("hindi")
  );

  let subscriptionCollection;
  let stories;

  if (IS_HINDI) {
    subscriptionCollection = data.hindiNLExplained.collection;
    stories = getStoriesByCollection(subscriptionCollection);
  } else {
    subscriptionCollection = data.collection.collection.items[6];
    stories = getStoriesByCollection(subscriptionCollection);
  }

  return (
    <div
      styleName="subscription-explained-wrapper"
      className="full-width-with-padding"
    >
      <div styleName="subscription-explained-inner-wrapper">
        <h2 styleName="summary">
          {IS_HINDI ? subscriptionCollection.name : "Newslaundry Explained"}
        </h2>
        <div styleName="subscription-collection">
          {stories.map(item => {
            return <CommonStoryCard story={item} key={item.id} />;
          })}
        </div>
      </div>
    </div>
  );
};
