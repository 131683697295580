import React from "react";
import PropTypes from "prop-types";
import { COL_HEADERS, ROW_HEADERS } from "./constants";
import Grid from "./Grid";

import "./Spectrum.m.css";

const Spectrum = ({
  selectedJournalist,
  clearCurrentJournalistSelection,
  avgJournalists,
  currentJournoIndex,
  setCurrentJournoIndex,
  resetCurrentJournoIndex,
}) => {
  return (
    <div styleName="spectrum-parent">
      <h4 styleName="leftHead" >Socio - Political Spectrum Scale</h4>

      <div styleName="spectrum-area">
        <div styleName="v-stack">
          {ROW_HEADERS.map((rowH, i) => (
            <div key={i}>{rowH.value}</div>
          ))}
        </div>
        <Grid
          selectedJournalist={selectedJournalist}
          clearCurrentJournalistSelection={clearCurrentJournalistSelection}
          avgJournalists={avgJournalists}
          currentJournoIndex={currentJournoIndex}
          setCurrentJournoIndex={setCurrentJournoIndex}
          resetCurrentJournoIndex={resetCurrentJournoIndex}
        />
        <div></div>
        <div styleName="h-stack">
          {COL_HEADERS.map((colH, i) => (
            <div key={i}>{colH.value}</div>
          ))}
        </div>
        <div></div>
        <h4 style={{ textAlign: "center", color: "#525252" }}>
          Economic Spectrum Scale
        </h4>
      </div>
    </div>
  );
};

Spectrum.propTypes = {
  selectedJournalist: PropTypes.object,
  clearCurrentJournalistSelection: PropTypes.func,
  avgJournalists: PropTypes.array,
  currentJournoIndex: PropTypes.object,
  setCurrentJournoIndex: PropTypes.func,
  resetCurrentJournoIndex: PropTypes.func,
};

export default Spectrum;
