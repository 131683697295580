import React from "react";
import { FaAward, FaXTwitter, FaLink } from "react-icons/fa6";

import "./download-app.m.css";
import { Link } from "@quintype/components";

const DownloadApp = () => {
  return (<>
    <div styleName="section">
      <div styleName="section-wrapper">
        <div styleName="left-content">
          <h1 styleName="primary-heading">
            Hooray! Our app is available to download now
          </h1>
          <p styleName="primary-description">
            Yup, you read it right. Our app is finally available to download. A
            big thank you to all our subscribers who contributed to make the app
            possible. Shoutout to all the readers who tested the app and helped
            us make it better.
          </p>
          <div styleName="download-cta-div">
            <a
              href="https://apps.apple.com/in/app/newslaundry/id1609227019?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://images.assettype.com/newslaundry/2022-05/13b3de80-f642-4a15-be70-874855d17ba4/512x512.png"
                alt="app store button"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.newslaundry.android.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://images.assettype.com/newslaundry/2022-05/c4d901fe-bf33-4d3a-b720-58c2182a8e9c/512x512.png"
                alt="google play store button"
              />
            </a>
          </div>
          <p styleName="helper-text">
            Native podcast player, in-app subscriptions, gift subscription,
            ad-free, personalised notifications, bookmark, dark mode...we got it
            all. What are you waiting for? Click on the links above to download
            the app. For feedback and bugs, write to{" "}
            <a
              style={{ color: "var(--text-accent-default)" }}
              href="mailto:subscription@newslaundry.com"
            >
              subscription@newslaundry.com
            </a>
          </p>
          <br />
        </div>
        <div styleName="right-content">
          <div styleName="image-wrapper">
            <img
              src="https://images.assettype.com/newslaundry/2022-05/b80947fb-36c4-434d-afac-a5d6bfc63b72/vertical_banner_new.jpg"
              alt="download app banner"
            />
          </div>
        </div>
      </div>
      <h1
            className="static-page-title"
            style={{
              textAlign: "center",
              width: "100%",
              fontSize: "var(--fontsize-jumbo)",
              textTransform: "none"
            }}
          >
           India's most-awarded digital news product
          </h1>
      <main styleName="hero-section">
        <div styleName="hero-section-div">
          <div styleName="hero-section-img">
            <Link href="https://www.newslaundry.com/2024/05/28/newslaundry-app-wins-best-innovative-digital-product-at-wan-ifra-digital-media-awards-worldwide">
            <img
              src="https://images.assettype.com/newslaundry/2024-06/9fcd34dd-83ca-4878-9142-b3221e72b9f0/unasddsqdq"
              alt="NL won Wan infra award poster"
              styleName="hero-desktop-image"
            />
            </Link>
            {/* <img
              src="https://images.assettype.com/newslaundry/2024-05/719b81ca-a5b0-4c4b-b6a3-282a1794b7b4/ele"
              alt="Combined live mobile"
              styleName="hero-mobile-image"
            /> */}
          </div>
        </div>
      </main>
      <div styleName="section-wrapper" style={{ height: "auto" }}>
        <div>
          <div className="awards-wrapper">
            <a
              href="https://www.newslaundry.com/2024/05/28/newslaundry-app-wins-best-innovative-digital-product-at-wan-ifra-digital-media-awards-worldwide"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                WAN-IFRA Digital Media Awards Worldwide (2024)
              </p>
              <p className="award-text">
                The Newslaundry app won the 'Best Innovative Digital Product'.
                The jury noted, ..."This is a great case that confirms how
                important it is to prioritise ’product’ strategies and not only
                ’pricing’ strategies when we move to subscription-based models.”
              </p>
            </a>
            <a
              // href="https://www.newslaundry.com/nl-sena/plunder-of-the-aravallis"
              className="award-item"
              aria-label="India Audio Summit and Awards (2024)"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                India Audio Summit and Awards (2024)
              </p>
              <p className="award-text">
                The Newslaundry iOS and Android app won the Best Innovation in
                Technology for its podcast player.
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2024/01/30/wan-ifra-awards-newslaundry-app-bags-gold-as-the-best-innovative-digital-product"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">
                WAN-IFRA Digital Media Awards South Asia (2024)
              </p>
              <p className="award-text">
                The Newslaundry won the ‘Best Innovative Digital Product’ at the
                Digital Media Awards South Asia. The jury noted, “The stand-out
                entry demonstrates best-in-class, audience-focused product
                development. What is particularly positive about the entry is
                not only the product-market fit but also the business-model
                product fit. It demonstrates the virtuous cycle possible with a
                reader-revenue-led newsroom, where the audience is central to
                business and product development.”
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2023/06/06/best-new-digital-product-newslaundry-app-receives-honourable-mention-at-inma-awards"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">INMA Global Media awards (2023)</p>
              <p className="award-text">
                Newslaundry app received an honorable mention in the 'best new
                digital product' category at the INMA Global Media awards,
                recognizing its excellence in innovation and its role in pushing
                boundaries and driving change within the news industry.
              </p>
            </a>
            <a
              href="https://www.newslaundry.com/2023/07/28/best-use-of-new-technology-newslaundry-app-wins-digipub-awards-2023"
              className="award-item"
              aria-label="Click to go to read more about award"
            >
              <FaAward
                style={{ width: "5rem", height: "5rem" }}
                aria-hidden="true"
              />
              {/* <img
                className="award-img"
                src="https://thumbor-stg.assettype.com/newslaundry/2020-08/1a003de6-a54f-475f-bc1d-4700bec4fd60/award_symbol.png"
              /> */}
              <p className="award-headline">Digipub Awards (2023)</p>
              <p className="award-text">
                Newslaundry's Android and iOS app was awarded the AFAQs Digipub
                Awards 2023 in the 'Best Use of Technology' category,
                recognizing its utilization of technology in online publishing
                to enhance user experience and efficiency.
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export { DownloadApp };
