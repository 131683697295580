import React, { useState, useEffect } from "react";
import { Slider } from "../Slider";
import "../../index.m.css";
import PropTypes from "prop-types";

const cardAlign = {
  width: "100%",
  display: "flex",
  gap: "10px",
  alignItems: "center",
};

export default function VoteCard(props) {
  const [xCoor, setXCoor] = useState(1);
  const [yCoor, setYCoor] = useState(1);

  const x = xCoor;
  const y = yCoor;
  const index = x + y * 6;
  props.calculate(index);

  useEffect(() => {
    const x = xCoor;
    const y = yCoor;
    const index = x + y * 6;
    props.cellX(xCoor);
    props.calculate(index);
  }, [xCoor]);

  useEffect(() => {
    const x = xCoor;
    const y = yCoor;
    const index = x + y * 6;
    props.cellY(yCoor);
    props.calculate(index);
  }, [yCoor]);

  return (
    <div styleName="container">
      <div styleName="card">
        <div style={cardAlign}>
          <img src={props.res.imageURL} styleName="vote-img-step2" alt="..." />
          <h3 style={{ fontWeight: "bolder" }}><b>{props.res.name}</b></h3>
        </div>
      </div>
      {/* <div styleName="slider-div">
        <div styleName="one-slider-div">
          <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>Economic Spectrum</p>
          <Slider
            type="political"
            hidetext={true}
            index={(val) => setXCoor(val)}
          />
          <br/>
        </div>
        <div styleName="one-slider-div">
          <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>Socio-Political Spectrum</p>
          <Slider
            type="social"
            hidetext={true}
            index={(val) => setYCoor(val)}
          />
        </div>
      </div> */}
      <div styleName="slider-box">
        <div styleName="section slideToggler">
          <h3>Socio-Political Spectrum</h3>
          <p styleName="border-bottom-2"></p>
          <span>
            <Slider type="social" hidetext={true} index={(val) => setYCoor(val)} />
          </span>
        </div>
        {/* Dummy Sliders */}
        <div styleName="section slideToggler">
          <h3>Economic Spectrum</h3>
          <p styleName="border-bottom-2"></p>
          <span>
            <Slider type="political" hidetext={true} index={(val) => setXCoor(val)} />
          </span>
        </div>

        {/* Dummy Sliders End */}
      </div>
    </div>
  );
}

VoteCard.propTypes = {
  calculate: PropTypes.any,
  res: PropTypes.any,
  cellX: PropTypes.any,
  cellY: PropTypes.any,
};

