import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import algoliasearch from "algoliasearch/lite";
import axios from "axios";
import { BsFillMicFill } from "react-icons/bs";
// import { ReactMic } from "react-mic";
import "./search.m.css";
import Loader from "../../atoms/loader";
import { Button } from "../../../ui/components/button";
import { Link } from "@quintype/components";
import SpeechRecognition, {
  useSpeechRecognition
} from "react-speech-recognition";

const algoliaClient = algoliasearch(
  "G7K3DOE8IY",
  "b7560a5d2d1aecd713b4ec35950844f3"
);
const storyIndex = algoliaClient.initIndex("nl_stories");

const sectionIndex = algoliaClient.initIndex("nl_sections");
const authorIndex = algoliaClient.initIndex("nl_authors");
const collectonIndex = algoliaClient.initIndex("nl_collections");
const tagIndex = algoliaClient.initIndex("nl_tags");

const SearchPage = () => {
  // if (typeof window === 'undefined') {
  //   // Do not render in non-browser environment
  //   return null;
  // }

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [query, setQuery] = useState("");
  const [notClicked, setNotClicked] = useState(true);
  const [filters, setFilters] = useState({
    author: "",
    section: "",
    dateRange: "all",
    language: ""
  });
  const [searchResults, setSearchResults] = useState([]);
  const [exactAuthorMatch, setExactAuthorMatch] = useState(null);
  const [exactSectionMatch, setExactSectionMatch] = useState(null);
  const [exactCollectionMatch, setExactCollectionMatch] = useState(null);
  const [exactTagMatch, setExactTagMatch] = useState(null);

  const perPage = 10;
  const [page, setPage] = useState(0);

  const [authorFacets, setAuthorFacets] = useState([]);
  const [sectionFacets, setSectionFacets] = useState([]);
  const [tagFacets, setTagFacets] = useState([]);
  const [typeFacets, setTypeFacets] = useState([]);
  const [loader, setLoader] = useState(false);
  const [rec, setRec] = useState(false);
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [showTagFilterDialog, setShowTagFilterDialog] = useState(false);
  const [showTypeFilterDialog, setShowTypeFilterDialog] = useState(false);
  const [showSecFilterDialog, setShowSecFilterDialog] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioData, setAudioData] = useState("");
  const [transcription, setTranscription] = useState("");
  const [sampleRate, setSampleRate] = useState(0);
  const [encoding, setEncoding] = useState("");
  const mediaRecorderRef = useRef(null);
  const [channelNo, setChannelNo] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [browserName, setBrowserName] = useState("Unknown Browser");
  const [micClicked, setMicCkicked] = useState(false);

  const toggleFilterSection = () => {
    setIsExpanded(!isExpanded);
  };

  let userAgent = null;
  // let browserName = "Unknown Browser";
  useEffect(() => {
    userAgent = typeof window !== "undefined" && window.navigator.userAgent;
    // console.log({ userAgent });
    // console.log(userAgent.indexOf("Firefox"), "ur-ag");
    // console.log({ browserName });
    // console.log({ notClicked });
    // console.log({ query });
    // console.log({ exactAuthorMatch });
    // console.log({ exactSectionMatch });
    // console.log({ exactCollectionMatch });
    // console.log({ exactTagMatch });
    // console.log(searchResults.length);
  });

  useEffect(() => {
    if (
      userAgent &&
      (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident/") !== -1)
    ) {
      setBrowserName("Internet Explorer");
    } else if (userAgent && userAgent.indexOf("Chrome") !== -1) {
      setBrowserName("Google Chrome");
    } else if (userAgent && userAgent.indexOf("Safari") !== -1) {
      setBrowserName("Apple Safari");
    } else if (userAgent && userAgent.indexOf("Edge") !== -1) {
      setBrowserName("Microsoft Edge");
    } else if (userAgent && userAgent.indexOf("Firefox") !== -1) {
      setBrowserName("Mozilla Firefox");
    } else {
      if (userAgent) console.log({ userAgent });
    }
  }, []);

  const startRecording2 = () => {
    // setAudioChunks([]);
    SpeechRecognition.startListening();
    setIsRecording(true);
  };

  // Refactor the stopRecording function
  const stopRecording2 = () => {
    SpeechRecognition.stopListening();
    setQuery(transcript);
    setIsRecording(false);
  };

  const mediaStreamRef = useRef(null);

  const convertAudioToBase64 = () => {
    const audioBlob = new Blob(audioChunks);

    // Detect the encoding based on MIME type
    let detectedEncoding = "";
    console.log(audioBlob.type, "MIME");
    if (audioBlob.type === "audio/webm; codecs=opus") {
      detectedEncoding = "OGG_OPUS";
    } else if (audioBlob.type === "audio/wav") {
      detectedEncoding = "LINEAR16";
    } else if (audioBlob.type === "audio/mp3") {
      detectedEncoding = "MP3";
    } else if (audioBlob.type === "audio/aac") {
      detectedEncoding = "AAC";
    } else {
      console.log("Unrecognized MIME type:", audioBlob.type);
    }
    setEncoding(detectedEncoding);

    const reader = new FileReader();
    reader.onload = () => {
      const base64Audio = reader.result.split(",")[1];
      setAudioData(base64Audio);
      console.log("audiodata set", base64Audio);
      sendAudioToBackend(base64Audio);
    };
    reader.readAsDataURL(audioBlob);
  };

  const sendAudioToBackend = async blob => {
    console.log("hii");
    try {
      console.log({ blob }, { audioData });
      if (blob) {
        console.log({ blob }, "blob");
        console.log("request:", {
          audio: blob,
          sampleRate: sampleRate,
          encoding: encoding,
          channelNo: channelNo,
          browser: browserName
        });
        const response = await axios.post(
          "https://server.newslaundry.com/convert/AudioDataToText",
          // "http://localhost:5001/convert/AudioDataToText",
          //"https://70c5-103-66-10-142.ngrok-free.app/convert/AudioDataToText",
          {
            audio: blob,
            sampleRate: sampleRate,
            encoding: encoding,
            channelNo: channelNo,
            browser: browserName
          }
        );
        console.log("sent");
        response.data.transcription
          ? setTranscription(response.data.transcription)
          : setTranscription("Browser not supported");
        response.data.transcription
          ? setQuery(response.data.transcription)
          : setQuery("Browser not supported");
      } else if (audioData) {
        console.log({ audioData });
        console.log("request:", {
          audio: audioData,
          sampleRate: sampleRate,
          encoding: encoding,
          channelNo: channelNo,
          browser: browserName
        });
        const response = await axios.post(
          "https://server.newslaundry.com/convert/AudioDataToText",
          // "http://localhost:5001/convert/AudioDataToText",
          {
            audio: audioData,
            sampleRate: sampleRate,
            encoding: encoding,
            channelNo: channelNo,
            browser: browserName
          }
        );
        console.log("sent");
        setTranscription(response.data.transcription);
        setQuery(response.data.transcription);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;

      const audioTrack = stream.getAudioTracks()[0];
      const hz = audioTrack.getSettings().sampleRate;
      console.log(hz, "hz");
      setSampleRate(hz);
      const channels = audioTrack.getSettings().channelCount;
      console.log("Number of audio channels:", channels);
      setChannelNo(channels);

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && mediaStreamRef.current) {
      mediaRecorderRef.current.removeEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.stop();
      mediaStreamRef.current.getTracks().forEach(track => {
        track.stop();
      });
      setIsRecording(false);
      convertAudioToBase64();
      //sendAudioToBackend();
    }
  };

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      setAudioChunks(prevChunks => [...prevChunks, event.data]);
    }
  };

  useEffect(() => {
    if (audioChunks.length > 0) {
      convertAudioToBase64();
    }
  }, [audioChunks]);

  async function fetchFacets() {
    try {
      const authorFacets = await getFacetValues("authors");
      setAuthorFacets(authorFacets);

      const sectionFacets = await getFacetValues("sections");
      setSectionFacets(sectionFacets);

      const tagFacets = await getFacetValues("tags");
      setTagFacets(tagFacets);

      const typeFacets = await getFacetValues("type");
      setTypeFacets(typeFacets);
    } catch (error) {
      console.error("Error retrieving facet values:", error);
    }
  }

  useEffect(() => {
    fetchFacets();
  }, []);

  async function getFacetValues(attributeName) {
    try {
      console.log(attributeName);
      const { facetHits } = await storyIndex.searchForFacetValues(
        attributeName
      );
      console.log({ facetHits }, attributeName);
      return facetHits;
    } catch (error) {
      console.error("Error retrieving the facet values:", error.message);
      return [];
    }
  }

  const getSearchResults = async () => {
    fetchFacets();
    const algoliaFilters = [];

    if (filters.author) {
      algoliaFilters.push(`authors:"${filters.author}"`);
    }

    if (filters.section) {
      algoliaFilters.push(`sections:"${filters.section}"`);
    }

    if (filters.tag) {
      algoliaFilters.push(`tags:"${filters.tag}"`);
    }
    if (filters.type) {
      algoliaFilters.push(`type:"${filters.type}"`);
    }

    if (filters.dateRange === "pastDay") {
      const yesterday = moment().subtract(1, "days");
      algoliaFilters.push(`publishedDate > ${yesterday.unix() * 1000}`);
    } else if (filters.dateRange === "pastWeek") {
      const lastWeek = moment().subtract(1, "weeks");
      algoliaFilters.push(`publishedDate > ${lastWeek.unix() * 1000}`);
    } else if (filters.dateRange === "pastYear") {
      const lastYear = moment().subtract(1, "years");
      algoliaFilters.push(`publishedDate > ${lastYear.unix() * 1000}`);
    } else if (filters.dateRange === "custom") {
      if (filters.startDate && filters.endDate) {
        const start = moment(filters.startDate).startOf("day");
        const end = moment(filters.endDate).endOf("day");
        algoliaFilters.push(
          `(publishedDate > ${start.unix() * 1000} AND publishedDate < ${
            end.unix() * 1000
          })`
        );
      }
    }

    if (filters.language) {
      algoliaFilters.push(`language:"${filters.language}"`);
    }

    const queryFilters = algoliaFilters.join(" AND ");
    const algoliaQuery = query;
    //console.log({ algoliaQuery }, { queryFilters });
    const { hits } = await storyIndex.search(algoliaQuery, {
      filters: queryFilters
    });
    setLoader(false);
    setSearchResults(hits);
    setNotClicked(false);

  };

  const handleSearch = async () => {
    // setSearchResults({});
    setLoader(true);
    setExactAuthorMatch(null);
    setExactCollectionMatch(null);
    setExactTagMatch(null);
    setExactSectionMatch(null);
    setPage(0);
    getSearchResults();
    if (filters.section) {
      const { hits } = await sectionIndex.search(filters.section);
      if (hits.length === 1) {
        setExactSectionMatch(hits[0]);
      }
    }

    if (filters.author) {
      const { hits } = await authorIndex.search(filters.author);
      if (hits.length === 1) {
        setExactAuthorMatch(hits[0]);
      }
    }

    // if (filters.tag) {
    //   const { hits } = await tagIndex.search(filters.tag);
    //   if (hits.length === 1) {
    //     setExactTagMatch(hits[0]);
    //   }
    // }

    if (query.length) {
      const hitsSection = await sectionIndex.search(query);
      if (hitsSection.hits && hitsSection.hits.length === 1) {
        setExactSectionMatch(hitsSection.hits[0]);
      }

      const hitsAuthor = await authorIndex.search(query);
      if (hitsAuthor.hits && hitsAuthor.hits.length) {
        setExactAuthorMatch(hitsAuthor.hits[0]);
      }

      const hitsCollection = await collectonIndex.search(query);
      if (hitsCollection.hits && hitsCollection.hits.length) {
        setExactCollectionMatch(hitsCollection.hits[0]);
      }

      const hitsTag = await tagIndex.search(query);
      if (hitsTag.hits && hitsTag.hits.length) {
        setExactTagMatch(hitsTag.hits[0]);
      }
    }
  };

  const handleSearchOnEnter = async event => {
    if (event.key === "Enter") {
      setLoader(true);
      setExactAuthorMatch(null);
      setExactCollectionMatch(null);
      setExactTagMatch(null);
      setExactSectionMatch(null);
      setPage(0);
      getSearchResults();

      if (filters.section) {
        const { hits } = await sectionIndex.search(filters.section);
        if (hits.length === 1) {
          setExactSectionMatch(hits[0]);
        }
      }

      if (filters.author) {
        const { hits } = await authorIndex.search(filters.author);
        if (hits.length === 1) {
          setExactAuthorMatch(hits[0]);
        }
      }

      // if (filters.tag) {
      //   const { hits } = await tagIndex.search(filters.tag);
      //   if (hits.length === 1) {
      //     setExactTagMatch(hits[0]);
      //   }
      // }

      if (query.length) {
        const hitsSection = await sectionIndex.search(query);
        if (hitsSection.hits && hitsSection.hits.length === 1) {
          setExactSectionMatch(hitsSection.hits[0]);
        }

        const hitsAuthor = await authorIndex.search(query);
        if (hitsAuthor.hits && hitsAuthor.hits.length) {
          setExactAuthorMatch(hitsAuthor.hits[0]);
        }

        const hitsCollection = await collectonIndex.search(query);
        if (hitsCollection.hits && hitsCollection.hits.length) {
          setExactCollectionMatch(hitsCollection.hits[0]);
        }

        const hitsTag = await tagIndex.search(query);
        if (hitsTag.hits && hitsTag.hits.length) {
          setExactTagMatch(hitsTag.hits[0]);
        }
      }
    }
  };

  const handleFilter = async () => {
    handleSearch();
    setExactAuthorMatch(null);
    setExactCollectionMatch(null);
    setExactTagMatch(null);
    setExactSectionMatch(null);
  };

  const handleClearFilters = () => {
    setFilters({
      author: "",
      section: "",
      dateRange: "all",
      startDate: null,
      endDate: null,
      language: ""
    });
    // setSearchResults([]);
    // setExactAuthorMatch(null);
    // setExactCollectionMatch(null);
    // setExactTagMatch(null);
    // setExactSectionMatch(null);
    // setPage(0);
  };

  async function handleAuthorChange(value) {
    try {
      const { facetHits } = await storyIndex.searchForFacetValues(
        "authors",
        value
      );
      setAuthorFacets(facetHits);
    } catch (error) {
      console.error("Error retrieving facet values:", error.message);
      return [];
    }
  }

  async function handleTagChange(value) {
    try {
      const { facetHits } = await storyIndex.searchForFacetValues(
        "tags",
        value
      );
      //console.log(facetHits);
      setTagFacets(facetHits);
    } catch (error) {
      console.error("Error retrieving facet values:", error.message);
      return [];
    }
  }
  async function handleTypeChange(value) {
    try {
      const { facetHits } = await storyIndex.searchForFacetValues(
        "type",
        value
      );
      //console.log(facetHits);
      setTypeFacets(facetHits);
    } catch (error) {
      console.error("Error retrieving facet values:", error.message);
      return [];
    }
  }
  async function handleSectionsChange(value) {
    try {
      const { facetHits } = await storyIndex.searchForFacetValues(
        "sections",
        value
      );
      //console.log({ facetHits });
      setSectionFacets(facetHits);
      return;
    } catch (error) {
      console.error("Error retrieving facet values:", error.message);
      return [];
    }
  }
  const authorFilterRef = useRef(null);
  const tagFilterRef = useRef(null);
  const typeFilterRef = useRef(null);
  const sectionFilterRef = useRef(null);

  useEffect(() => {
    const closeDialogs = event => {
      if (
        showFilterDialog &&
        authorFilterRef.current &&
        !authorFilterRef.current.contains(event.target)
      ) {
        setShowFilterDialog(false);
      }

      if (
        showTagFilterDialog &&
        tagFilterRef.current &&
        !tagFilterRef.current.contains(event.target)
      ) {
        setShowTagFilterDialog(false);
      }

      if (
        showSecFilterDialog &&
        sectionFilterRef.current &&
        !sectionFilterRef.current.contains(event.target)
      ) {
        setShowSecFilterDialog(false);
      }

      if (
        showTypeFilterDialog &&
        typeFilterRef.current &&
        !typeFilterRef.current.contains(event.target)
      ) {
        setShowTypeFilterDialog(false);
      }
    };

    document.addEventListener("click", closeDialogs);

    return () => {
      document.removeEventListener("click", closeDialogs);
    };
  }, [
    showFilterDialog,
    showSecFilterDialog,
    showTagFilterDialog,
    showTypeFilterDialog
  ]);

  const handleMicChange = () => {
    setMicCkicked((prev)=>!prev);
    if (!rec) {
      console.log("listening", rec);
      setQuery("");
      setRec(!rec);
      browserName == "Mozilla Firefox" ? startRecording() : startRecording2();
      // startRecording();
    } else {
      console.log("mic closed", rec);
      setRec(!rec);
      browserName == "Mozilla Firefox" ? stopRecording() : stopRecording2();
      // stopRecording()

      //sendAudioToBackend();
    }
  };

  return (
    <div styleName="page-wrapper">
      <div styleName="page-wrapper-2">
        <div styleName=" input-wrapper" className="full-width-with-padding">
          <div styleName="textbox-div">
            <input
              type="text"
              placeholder={
                rec
                  ? "Listening, press mic button to stop."
                  : "Search Newslaundry"
              }
              styleName="text-box"
              value={query}
              onChange={e => setQuery(e.target.value)}
              onKeyDown={handleSearchOnEnter}
            />
            <button
              onClick={handleMicChange}
              styleName={micClicked?"mic-btn-clicked":"mic-btn"}
              variant="accent"
              className="component-padding-x-lg"
              aria-label="Click to speak your query"
            >
              <BsFillMicFill />
            </button>
            {/* <div style= {{visibilty:"none"}}>
            {window && <ReactMic
                record={isRecording}
                onStop={(recordedBlob) => {
                  const audioData = recordedBlob.blob;
                  setAudioChunks([audioData]);
                }}
                strokeColor="red"
                
              />}
              </div> */}
            <Button
              onClick={handleSearch}
              styleName="search-btn"
              disabled={query.length === 0}
              aria-label="Click to search"
              variant="accent"
              className="component-padding-x-lg"
            >
              Search
            </Button>
          </div>
          <div styleName="side-margin-auto">
            <div styleName="filter-down">
              <h2 styleName="filter">
                {" "}
                {isExpanded ? "Hide Filters" : "Show Filters"}
              </h2>
              <div
                onClick={toggleFilterSection}
                styleName="arrow-btn"
                role="button"
                aria-label={`Click to ${
                  isExpanded ? "Hide" : "Show"
                } filter options`}
              >
                <span styleName={`arrow ${isExpanded ? "up" : "down"}`}></span>
              </div>
            </div>
            <div styleName="underline"></div>
          </div>
          {isExpanded && (
            <div styleName={`filter-section ${isExpanded ? "expanded" : ""}`}>
              <div styleName="filter-sub-div">
                <div styleName="margin-bottom" ref={authorFilterRef}>
                  <label htmlFor="author" styleName="author-label">
                    Author
                  </label>
                  <input
                    type="text"
                    id="author"
                    placeholder="Filter by Author"
                    value={filters.author}
                    // onFocus={() => {
                    //   setShowFilterDialog(true);
                    // }}
                    onChange={e => {
                      // console.log("val2", e.target.value);
                      setShowFilterDialog(true);
                      handleAuthorChange(e.target.value);
                      setFilters({ ...filters, author: e.target.value });
                      // setFilters((prev)=>({ ...prev, author: e.target?.value }));
                    }}
                    onClick={() => {
                      setShowFilterDialog(true);
                    }}
                    styleName="author-input"
                    aria-label="enter your author filter"
                  />
                  {showFilterDialog && (
                    <div styleName="author-options">
                      <ul styleName="auth">
                        {authorFacets.length ? (
                          authorFacets.map(facet => (
                            <li key={facet.value} styleName="single-item">
                              <div
                                onClick={() => {
                                  // console.log("val", facet.value);
                                  setFilters({
                                    ...filters,
                                    author: facet.value
                                  });
                                  // setFilters((prev)=>({ ...prev, author: facet.value }));
                                  setShowFilterDialog(false);
                                }}
                              >
                                {facet.value}
                              </div>
                            </li>
                          ))
                        ) : (
                          <li styleName="single-item-2">
                            <div>No such values found</div>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div styleName="margin-bottom" ref={tagFilterRef}>
                  <label styleName="author-label" htmlFor="tag">
                    Tag
                  </label>
                  <input
                    type="text"
                    placeholder="Filter by story tag"
                    value={filters.tag}
                    id="tag"
                    onChange={e => {
                      setShowTagFilterDialog(true);
                      handleTagChange(e.target.value);
                      setFilters({ ...filters, tag: e.target.value });
                    }}
                    aria-label="enter your tag filter"
                    onClick={() => {
                      setShowTagFilterDialog(true);
                    }}
                    styleName="author-input"
                  />
                  {showTagFilterDialog && (
                    <div styleName="author-options">
                      <ul styleName="auth">
                        {tagFacets.length ? (
                          tagFacets.map(facet => (
                            <li key={facet.value} styleName="single-item">
                              <div
                                onClick={() => {
                                  setFilters({ ...filters, tag: facet.value });
                                  setShowTagFilterDialog(false);
                                }}
                              >
                                {facet.value}
                              </div>
                            </li>
                          ))
                        ) : (
                          <li styleName="single-item-2">
                            <div>No such values found</div>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div styleName="margin-bottom" ref={typeFilterRef}>
                  <label styleName="section-label" htmlFor="type">
                    Type
                  </label>
                  <input
                    type="text"
                    placeholder="Filter by story type"
                    value={filters.type}
                    id="type"
                    aria-label="enter your type filter"
                    onChange={e => {
                      setShowTypeFilterDialog(true);
                      setFilters({ ...filters, type: e.target.value });
                      handleTypeChange(e.target.value);
                    }}
                    onClick={() => {
                      setShowTypeFilterDialog(true);
                    }}
                    styleName="section-box"
                  />
                  {showTypeFilterDialog && (
                    <div styleName="author-options">
                      <ul styleName="auth">
                        {typeFacets.length ? (
                          typeFacets.map(
                            facet =>
                              facet.value != "gumlet-video" &&
                              facet.value != "news-elsewhere" && (
                                <li key={facet.value} styleName="single-item">
                                  <div
                                    onClick={() => {
                                      setFilters({
                                        ...filters,
                                        type: facet.value
                                      });
                                      setShowTypeFilterDialog(false);
                                    }}
                                  >
                                    {facet.value}
                                  </div>
                                </li>
                              )
                          )
                        ) : (
                          <li styleName="single-item-2">
                            <div>No such values found</div>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div styleName="margin-bottom" ref={sectionFilterRef}>
                  <label styleName="section-label" htmlFor="section">
                    Section
                  </label>
                  <input
                    type="text"
                    placeholder="Filter by Section"
                    id="section"
                    aria-label="enter your section filter"
                    value={filters.section}
                    onChange={e => {
                      setShowSecFilterDialog(true);
                      setFilters({ ...filters, section: e.target.value });
                      handleSectionsChange(e.target.value);
                    }}
                    onClick={() => {
                      setShowSecFilterDialog(true);
                    }}
                    styleName="section-box"
                  />
                  {showSecFilterDialog && (
                    <div styleName="author-options">
                      <ul styleName="auth">
                        {sectionFacets.length ? (
                          sectionFacets.map(facet => (
                            <li key={facet.value} styleName="single-item">
                              <div
                                onClick={() => {
                                  setFilters({
                                    ...filters,
                                    section: facet.value
                                  });
                                  setShowSecFilterDialog(false);
                                }}
                              >
                                {facet.value}
                              </div>
                            </li>
                          ))
                        ) : (
                          <li styleName="single-item-2">
                            <div>No such values found</div>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </div>
                <div styleName="margin-bottom">
                  <label styleName="author-label" htmlFor="language-filter">
                    Language
                  </label>
                  <select
                    value={filters.language}
                    onChange={e =>
                      setFilters({ ...filters, language: e.target.value })
                    }
                    styleName="author-input"
                    id="language-filter"
                  >
                    <option value="">All Languages</option>
                    <option value="english">English</option>
                    <option value="hindi">Hindi</option>
                  </select>
                </div>
              </div>
              <div styleName="filter-sub-div-2">
                <div styleName="margin-bottom">
                  <label styleName="date-label" htmlFor="date-filter">
                    Date Range
                  </label>
                  <select
                    value={filters.dateRange}
                    onChange={e =>
                      setFilters({ ...filters, dateRange: e.target.value })
                    }
                    styleName="date-options"
                    id="date-filter"
                    aria-labelledby={`date-filter`}
                    aria-describedby={`date-filter`}
                  >
                    <option value="all">All</option>
                    <option value="pastDay">Past Day</option>
                    <option value="pastWeek">Past Week</option>
                    <option value="pastYear">Past Year</option>
                    <option value="custom">Custom Date Range</option>
                  </select>
                </div>
                {filters.dateRange === "custom" && (
                  <div styleName="margin-bottom">
                    <label styleName="date-label">Start Date</label>
                    <input
                      type="date"
                      value={filters.startDate || ""}
                      onChange={e =>
                        setFilters({ ...filters, startDate: e.target.value })
                      }
                      styleName="date-input"
                    />
                  </div>
                )}
                {filters.dateRange === "custom" && (
                  <div styleName="margin-bottom">
                    <label styleName="date-label">End Date</label>
                    <input
                      type="date"
                      value={filters.endDate || ""}
                      onChange={e =>
                        setFilters({ ...filters, endDate: e.target.value })
                      }
                      styleName="date-input"
                    />
                  </div>
                )}
                {/* {searchResults.length ?  */}
                <br />
                <Button
                  onClick={handleFilter}
                  styleName="apply-filter-btn"
                  // disabled={searchResults.length === 0}
                  variant="accent"
                  className="component-padding-x-lg"
                  aria-label="click to apply filter"
                >
                  Apply Filters
                </Button>
                {/* :<></>} */}
                <Button
                  onClick={handleClearFilters}
                  styleName="clear-filter-btn"
                  // disabled={query.length === 0}
                  variant="accent"
                  className="component-padding-x-lg"
                  aria-label="click to remove all filter"
                >
                  Clear Filters
                </Button>
              </div>
            </div>
          )}
        </div>
        <div styleName="filter-div">
          {notClicked &&
            !exactAuthorMatch &&
            !exactSectionMatch &&
            !exactCollectionMatch &&
            !exactTagMatch &&
            searchResults &&
            !searchResults.length && (
              <div styleName="util-class">
                <img
                  styleName="birubal-img"
                  src="https://images.assettype.com/newslaundry/2021-05/cd1b46a7-831e-47ff-8146-11a62d95ebaf/error_404.png"
                  aria-label="birubal anfd birbuala image"
                />
              </div>
            )}
          {loader && (
            <div style={{ padding: "50px 0", margin: "50px 0" }}>
              <Loader color="#ec2227" width="80" />
            </div>
          )}
          {searchResults.length ? (
            <div styleName="util-class">
              {/* <div styleName="first-line">
            Showing {searchResults.length} results for{" "}
            <span styleName="red-text">"{query}"</span>
          </div> */}
              {exactSectionMatch && (
                <a
                  href={`http://www.newslaundry.com/${exactSectionMatch.slug}`}
                  target="_blank"
                  key={exactSectionMatch.objectID}
                  rel="noopener noreferrer"
                  aria-label={`Go to section page: ${exactSectionMatch}`}
                >
                  <div styleName="exact-match">
                    <h3 styleName="exact-match-text">
                      NL Section: {exactSectionMatch.name}
                    </h3>
                  </div>
                </a>
              )}
              {exactAuthorMatch && (
                <a
                  href={`http://www.newslaundry.com/author/${exactAuthorMatch.slug}`}
                  target="_blank"
                  key={exactAuthorMatch.objectID}
                  rel="noopener noreferrer"
                  aria-label={`Go to author page: ${exactAuthorMatch}`}
                >
                  <div styleName="exact-match">
                    <h3 styleName="exact-match-text">
                      Read stories by {exactAuthorMatch.name}. →
                    </h3>
                    <p></p>
                  </div>
                </a>
              )}
              {/* {exactCollectionMatch && (
                <a
                  href={`http://www.newslaundry.com/${exactCollectionMatch.slug}`}
                  target="_blank"
                  key={exactCollectionMatch.objectID}
                  rel="noopener noreferrer"
                >
                  <div styleName="exact-match">
                    <h3 styleName="exact-match-text">
                      {exactCollectionMatch.name}→
                    </h3>
                    <p></p>
                  </div>
                </a>
              )} */}
              {exactTagMatch && (
                <a
                  href={`http://www.newslaundry.com/topic/${exactTagMatch.slug}`}
                  target="_blank"
                  key={exactTagMatch.objectID}
                  rel="noopener noreferrer"
                  aria-label={`Go to tag page: ${exactTagMatch}`}
                >
                  <div styleName="exact-match">
                    <h3 styleName="exact-match-text">
                      NL Topics : {exactTagMatch.name}
                    </h3>
                    <p></p>
                  </div>
                </a>
              )}
              {searchResults.slice(page * 10, (page + 1) * 10).map(result => (
                <article>
                  <Link
                    href={`http://www.newslaundry.com/${result.slug}`}
                    target="_blank"
                    key={result.objectID}
                    rel="noopener noreferrer"
                    aria-label={`Story Headline: ${
                      result.headline
                    } written by: ${result.authors.join(", ")}`}
                  >
                    <div styleName="result-card">
                      <div styleName="img-wrapper">
                        <img
                          src={result.imgUrl}
                          alt="article image"
                          styleName="result-img"
                        />
                      </div>
                      <div styleName="result-sub-wrapper">
                        <h3 styleName="headline">{result.headline}</h3>
                        <div styleName="result-bottom">
                          <p styleName="date">
                            {/* Published on:{" "} */}
                            <b>
                              {new Date(result.publishedDate)
                                .toDateString()
                                .split(" ")
                                .join(", ")}
                            </b>
                          </p>
                          <p styleName="result-author">
                            <b> {result.authors.join(", ")} </b>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </article>
              ))}
            </div>
          ) : (
            <>
              {!notClicked &&
                // query &&
                // !exactAuthorMatch &&
                // !exactSectionMatch &&
                // !exactCollectionMatch &&
                // !exactTagMatch &&
                !searchResults.length && (
                  <div styleName="util-class">
                    <img
                      styleName="birubal-img"
                      src="https://images.assettype.com/newslaundry/2024-01/9d10b399-ab50-4fd4-8cf9-2b656fb93a07/Birubal_can_t_find_what_you_are_looking_for__1_.png"
                      aria-label=" no results image"
                    />
                  </div>
                )}
            </>
          )}
        </div>
        {searchResults.length ? (
          <div styleName="pagination-div">
            <button
              onClick={() => setPage(page - 1)}
              disabled={page === 0}
              styleName="prev"
              aria-label="click to view previous page"
            >
              {"<"}
            </button>
            <button
              onClick={() => setPage(page + 1)}
              disabled={
                searchResults.slice(page * 10, (page + 1) * 10).length < perPage
              }
              aria-label="click to view next page"
              styleName="prev"
            >
              {">"}
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      <br />
    </div>
  );
};

// export default Search;
// SearchPage.propTypes = {
//   data: PropTypes.shape({
//     query: PropTypes.string,
//     total: PropTypes.number,
//     stories: PropTypes.array,
//     trendingCollection: PropTypes.object
//   })
// };

export { SearchPage };
