import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Link } from "@quintype/components";
import "./live-video.m.css";

const LiveVideo = ({ collection }) => {
  const [isHindi, setIsHindi] = useState(false);

  useEffect(() => {
    setIsHindi(global.location.host.includes("hindi"));
  }, []);

  return (
    <div styleName="wrapper" className="full-width-with-padding">
      <div styleName="video-wrapper">
        <div styleName="title-wrapper">
          <h3 styleName="subsection-title">
            #ResultsWithIndependentMedia
            {/* {isHindi ? "कर्नाटक का जनादेश" : "Decoding the Karnataka Mandate"} */}
          </h3>
          <p styleName="border-bottom"></p>
        </div>
        {isHindi ? (
          <iframe
            src="https://www.youtube.com/embed/jY4a8aLHBj0?si=-N0pth_7w7KqTdq6"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            styleName="iframe"
          ></iframe>
        ) : (
          <iframe
            src="https://www.youtube.com/embed/8rQsN1gRSKM?si=gTJoOncrceZZMw51"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            styleName="iframe"
          ></iframe>
        )}
        <div styleName="contribute-div-wrapper">
          <Link href="/electionfund" styleName="contribute-btn">
            Contribute now
          </Link>
        </div>
        {/* <iframe
          src={`https://www.youtube-nocookie.com/embed/${collection["associated-metadata"].video_id}`}
          title="YouTube video player"
          styleName="iframe"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullscreen
        ></iframe> */}
      </div>
    </div>
  );
};

LiveVideo.propTypes = {
  collection: PropTypes.object
};

export { LiveVideo };
