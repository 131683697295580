import React from "react";
import PropTypes from "prop-types";

import "./subscription-levels.m.css";

const data = [
  {
    name: "soldier",
    image:
      "https://images.assettype.com/newslaundry/2020-06/1b7ec40d-85fd-4785-beac-3450b7d8c8c4/soldier.png"
  },
  {
    name: "bishop",
    image:
      "https://images.assettype.com/newslaundry/2020-06/d60c87cd-b282-4526-b84d-812adcb2fe59/bishop.png"
  },
  {
    name: "knight",
    image:
      "https://images.assettype.com/newslaundry/2020-06/7698631b-e075-411a-bddc-56d09b493837/knight.png"
  },
  {
    name: "rook",
    image:
      "https://images.assettype.com/newslaundry/2020-06/449bd02b-397e-4541-be77-e23357e7c620/book.png"
  },
  {
    name: "king/queen",
    image:
      "https://images.assettype.com/newslaundry/2020-06/47b1254c-3601-4bfd-bb1f-ad415efeb312/king_queen.png"
  },
  {
    name: "grandmaster",
    image:
      "https://images.assettype.com/newslaundry/2020-06/933db8d0-a8cd-4480-96d1-374724fa0ed2/grand_master.png"
  },
  {
    name: "defender",
    image:
      "https://images.assettype.com/newslaundry/2022-05/4aeb4b38-71a3-44a5-bf6b-1087e8ce951e/defender.png"
  },
  {
    name: "truth-seeker",
    image:
      "https://images.assettype.com/newslaundry/2022-05/f3a04fc6-7bfb-40ff-bcc2-6d4bb71c71f7/truthseekar.png"
  },
  {
    name: "liberator",
    image:
      "https://images.assettype.com/newslaundry/2022-05/065697c9-bcb0-43ed-85e5-a91241d6a648/liberator.png"
  },
  {
    name: "patron",
    image:
      "https://images.assettype.com/newslaundry/2022-05/5585f034-d182-4089-9702-5747ab8a76da/patron.png"
  },
  {
    name: "independent news guardian",
    image:
      "https://images.assettype.com/newslaundry/2022-05/3fd9bcf6-76b5-4110-8fc3-4db9b7d37243/independent.png"
  },
  {
    name: "gift soldier",
    image:
      "https://images.assettype.com/newslaundry/2020-06/1b7ec40d-85fd-4785-beac-3450b7d8c8c4/soldier.png"
  },
  {
    name: "gift bishop",
    image:
      "https://images.assettype.com/newslaundry/2020-06/d60c87cd-b282-4526-b84d-812adcb2fe59/bishop.png"
  },
  {
    name: "gift knight",
    image:
      "https://images.assettype.com/newslaundry/2020-06/7698631b-e075-411a-bddc-56d09b493837/knight.png"
  },
  {
    name: "gift rook",
    image:
      "https://images.assettype.com/newslaundry/2020-06/449bd02b-397e-4541-be77-e23357e7c620/book.png"
  },
  {
    name: "gift king/queen",
    image:
      "https://images.assettype.com/newslaundry/2020-06/47b1254c-3601-4bfd-bb1f-ad415efeb312/king_queen.png"
  },
  {
    name: "gift grandmaster",
    image:
      "https://images.assettype.com/newslaundry/2020-06/933db8d0-a8cd-4480-96d1-374724fa0ed2/grand_master.png"
  },
  {
    name: "defender",
    image:
      "https://images.assettype.com/newslaundry/2022-05/4aeb4b38-71a3-44a5-bf6b-1087e8ce951e/defender.png"
  },
  {
    name: "gift truth-seeker",
    image:
      "https://images.assettype.com/newslaundry/2022-05/f3a04fc6-7bfb-40ff-bcc2-6d4bb71c71f7/truthseekar.png"
  },
  {
    name: "gift liberator",
    image:
      "https://images.assettype.com/newslaundry/2022-05/065697c9-bcb0-43ed-85e5-a91241d6a648/liberator.png"
  },
  {
    name: "gift patron",
    image:
      "https://images.assettype.com/newslaundry/2022-05/5585f034-d182-4089-9702-5747ab8a76da/patron.png"
  },
  {
    name: "gift independent news guardian",
    image:
      "https://images.assettype.com/newslaundry/2022-05/3fd9bcf6-76b5-4110-8fc3-4db9b7d37243/independent.png"
  }
];

const SubscriptionLevels = ({
  selectedPlan,
  selectedLevel,
  setSelectedLevel
}) => {
  return (
    <div styleName="section-wrapper">
      <h3 styleName="title" >Choose your contribution level:</h3>
      <ul styleName="levels-wrapper">

        {selectedPlan.subscription_plans.map(level => (
          <li tabIndex="0"
            key={level.id}
            styleName={`level ${
              level.id === selectedLevel.id ? "selected" : ""
            }`}
            onClick={() => {
              console.log(selectedPlan.subscription_plans);
              setSelectedLevel(level)}}
              aria-label={`Level: ${level.title}, price: Rupees: ${level.price_cents/100}`}
          >
            
            <img
              src={
                data.find(
                  item => level.title.trim().toLowerCase() === item.name
                ).image
              }
              alt={level.title}
              aria-hidden="true"
              styleName="level-img"
            />
            
            {/* <p styleName="level-title">{level.title}</p> */}
          </li>
        ))}
      </ul>
    </div>
  );
};

SubscriptionLevels.propTypes = {
  selectedPlan: PropTypes.object,
  selectedLevel: PropTypes.object,
  setSelectedLevel: PropTypes.func
};

export { SubscriptionLevels };
