import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import PT from "prop-types";
import { parseUrl } from "query-string";
import { Button } from "../../atoms/button";
import { IS_OPEN_LOGIN_FORM } from "../../helper/actions";
import "./login-button.m.css";

const LoginButtonBase = ({ text = "Login to view details", manageLoginForm, isLoginOpen, currentPath, authHost}) => {
  function handleLogin(e) {
    e.preventDefault();
    // !isLoginOpen && manageLoginForm(true);
    
    const params = parseUrl(currentPath);
      const callbackUrl = get(params, ["query", "callback-url"], global.location && global.location.origin);
      const redirectUrl = get(params, ["query", "redirect-url"], global && global.location && global.location.href);
      window.location = `${authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${authHost}/user-login`;
      return false;
  }
  return (
    <div styleName="wrapper">
      <h1>{text}...</h1>
      <div styleName="button" onClick={e => handleLogin(e)}>
        <Button>Login</Button>
      </div>
    </div>
  );
};

LoginButtonBase.propTypes = {
  text: PT.string,
  manageLoginForm: PT.func,
  isLoginOpen: PT.bool
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function(payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  }
});

const mapStateToProps = state => ({
  isLoginOpen: get(state, ["isLoginOpen"], false),
  currentPath: get(state, ["qt", "currentPath"], ""),
  authHost: get(state, ["qt", "config", "publisher", "publisher", "auth_host"], null)
});

export const LoginButton = connect(mapStateToProps, mapDispatchToProps)(LoginButtonBase);
