import React from "react";
import get from "lodash/get";
import PT from "prop-types";

import "./nl-legal-fund-levels.m.css";

export const NLLegalFundLevels = ({ levels }) => {
  return levels.map((level, index) => {
    const items = get(level, ["points"], []);
    return (
      <div key={index} styleName="container">
        <h3 styleName="title">{get(level, ["title"], "")}</h3>
        <ul styleName="list">
          {items.length > 0 &&
            items.map((item, index) => {
              return (
                <li styleName="list-item" key={index}>
                  {item.item}
                </li>
              );
            })}
        </ul>
      </div>
    );
  });
};

NLLegalFundLevels.propTypes = {
  firstCard: PT.bool,
  gift: PT.object
};
