/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import { AccessType } from "@quintype/components";
import Axios from "axios";
import { capitalize, get, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { BiCollapse, BiExpand } from "react-icons/bi";
import { FaGift } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { connect, useSelector } from "react-redux";
import IntegrationData from "../../../../../config/integrations.js";
import Loader from "../../atoms/loader";
import { IS_SUBSCRIPTION_CANCEL, SELECTED_PLAN } from "../../helper/actions";
import {
  getIntegrationToken,
  getPodcastToken,
  getShopifyOrders,
  storeCancelReason
} from "../../helper/api";
import { useCopyToClipboard, useUserSubscription } from "../../helper/hooks";
import { LoginButton } from "../../molecules/login-button";
import { Saved } from "../saved";
import { CancelPrompt } from "./cancelprompt";
import { EditProfile } from "./edit-profile";
import { GiftSubscriptions } from "./gift-subscriptions";
import { WhatsappFeatureSection } from "./whatsappFeatureSection";
import queryString from "query-string";

import "./account-details.m.css";

const shows = require("./shows.json");

const EndDateText = ({ subscription }) => {
  switch (subscription.status) {
    case "active":
      if (subscription.cancelled) {
        return (
          <p styleName="end-date-text">
            Subscription has been cancelled and will end on:
            <span styleName="highlighted-date red-highlighted-text">
              {new Date(subscription.end_timestamp).toLocaleDateString(
                "en-IN",
                {
                  day: "numeric",
                  month: "long",
                  year: "numeric"
                }
              )}
            </span>
          </p>
        );
      } else {
        return (
          <p styleName="end-date-text">
            {subscription.recurring ? "Renews on:" : "Expires on:"}{" "}
            <span styleName="highlighted-date">
              {new Date(subscription.end_timestamp).toLocaleDateString(
                "en-IN",
                {
                  day: "numeric",
                  month: "long",
                  year: "numeric"
                }
              )}
            </span>
          </p>
        );
      }
    case "pending":
      return (
        <p styleName="end-date-text">
          Subscription starts from:{" "}
          <span styleName="highlighted-date">
            {new Date(subscription.start_timestamp).toLocaleDateString(
              "en-IN",
              {
                day: "numeric",
                month: "long",
                year: "numeric"
              }
            )}
          </span>{" "}
          and will {subscription.recurring ? "renew on:" : "expire on:"}{" "}
          <span styleName="highlighted-date">
            {new Date(subscription.end_timestamp).toLocaleDateString("en-IN", {
              day: "numeric",
              month: "long",
              year: "numeric"
            })}
          </span>
        </p>
      );
    case "cancelled":
      return (
        <p styleName="end-date-text">
          {new Date(subscription.cancelled_at) > new Date()
            ? "Cancels"
            : "Cancelled"}{" "}
          on:{" "}
          <span styleName="highlighted-date">
            {new Date(subscription.cancelled_at).toLocaleDateString("en-IN", {
              day: "numeric",
              month: "long",
              year: "numeric"
            })}
          </span>
        </p>
      );
    case "expired":
      return (
        <p styleName="end-date-text">
          {new Date(subscription.end_timestamp) > new Date()
            ? "Expires"
            : "Expired"}{" "}
          on:{" "}
          <span styleName="highlighted-date">
            {new Date(subscription.end_timestamp).toLocaleDateString("en-IN", {
              day: "numeric",
              month: "long",
              year: "numeric"
            })}
          </span>
        </p>
      );
    default:
      return <p styleName="end-date-text"></p>;
  }
};

const SubscriptionCard = ({
  subscription,
  isGifted,
  member,
  cancelSubscription
}) => {
  const [cancelProm, setCancelProm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState(null);
  const [error, setError] = useState(null);

  const [selectedCancelReason, setSelectedCancelReason] = useState("");

  console.log({ selectedCancelReason });

  const description =
    (subscription.group_description &&
      subscription.group_description.split("\n")) ||
    [];
  let imgUrl = description.length > 1 ? description[0] : "";

  if (subscription.subscription_type === "campaign") {
    try {
      const res = JSON.parse(subscription.group_description);
      imgUrl = res.imageUrl;
    } catch (e) {
      console.log("campign description is not a valid JSON ", subscription);
      console.error(e);
    }
  }

  const CancelSub = subscription => {
    setCancelProm(false);
    setLoading(true);

    cancelSubscription(subscription.id)
      .then(async () => {
        setLoading(false);

        const qtId = subscription.subscriber.subscriber_identities.find(
          subId => subId.provider === "quintype"
        );

        await storeCancelReason({
          emailAddress: member.email,
          userId: Number(qtId.value),
          subscriberId: Number(subscription.subscriber_id),
          subscriptionId: Number(subscription.id),
          reason: selectedCancelReason,
          planId: Number(subscription.subscription_plan_id),
          groupName: subscription.group_name,
          planName: subscription.plan_name,
          planType: subscription.subscription_type,
          durationLength: Number(subscription.duration_length),
          durationUnit: subscription.duration_unit,
          isGifted: subscription.gifter !== null
        });

        setPrompt("Your subscription has been cancelled");
        setTimeout(() => {
          setPrompt(null);
        }, 3000);
      })
      .catch(error => {
        setLoading(false);

        setError(error.message);
        setTimeout(() => {
          setError(null);
        }, 3000);
      });
  };

  const toShowCancelBtn =
    subscription.status === "active" &&
    subscription.active === true &&
    subscription.recurring === true;

  return (
    <div styleName="sub-wrapper">
      {isGifted ? (
        <div styleName="status-flag-gift">
          <FaGift />
        </div>
      ) : (
        <div styleName="status-flag">{capitalize(subscription.status)}</div>
      )}
      <div styleName="content-wrapper">
        <div styleName="left-content-wrapper">
          <div styleName="img-wrapper">
            <img styleName="image" src={imgUrl} alt={subscription.name} />
          </div>
          <div styleName="info">
            <h4 styleName="group-name">{`${subscription.group_name}`}</h4>
            <h5 styleName="plan-name">{`${subscription.plan_name}`}</h5>
            {!isGifted && <EndDateText subscription={subscription} />}
            {isGifted ? (
              <p styleName="end-date-text">
                Subscription gifted to{" "}
                <span styleName="highlighted-date">
                  {
                    subscription.subscriber.subscriber_identities.find(
                      identity => identity.provider === "email"
                    ).value
                  }
                </span>
              </p>
            ) : null}
          </div>
        </div>
        <div styleName="right-content-wrapper">
          {toShowCancelBtn && (
            <button
              styleName="cancel-button"
              onClick={() => setCancelProm(true)}
              disabled={loading}
            >
              Cancel
            </button>
          )}
          {cancelProm && (
            <CancelPrompt
              member={member}
              subscription={subscription}
              CancelFunc={() => {
                CancelSub(subscription);
              }}
              handleClose={() => {
                setCancelProm(false);
              }}
              setSelectedCancelReason={setSelectedCancelReason}
            />
          )}
          {error && <p styleName="error-text">{error}</p>}
          {prompt && <p styleName="success-text">{prompt}</p>}
        </div>
      </div>
    </div>
  );
};

const SubscriptionsLayout = ({
  subscriptions,
  cumulativeStandardSubEndDate = null,
  cumulativeCampaignSubEndDate = null,
  cancelSubscription,
  giftSubs,
  member
}) => {
  const [expandedSection, setExpandedSection] = useState(null);

  const activeSubs = subscriptions
    .filter(sub => sub.status === "active")
    .sort((a, b) => new Date(a.end_timestamp) - new Date(b.end_timestamp));
  const pendingSubs = subscriptions
    .filter(sub => sub.status === "pending")
    .sort((a, b) => new Date(a.end_timestamp) - new Date(b.end_timestamp));
  const pastSubs = subscriptions
    .filter(sub => sub.status !== "active" && sub.status !== "pending")
    .sort((a, b) => new Date(a.end_timestamp) - new Date(b.end_timestamp));

  const ExpandButton = ({ section }) => {
    const handleExpand = () => {
      if (expandedSection === section) setExpandedSection(null);
      else setExpandedSection(section);
    };
    const isExpanded = expandedSection === section;

    return (
      <button styleName="subs-heading-button" onClick={handleExpand}>
        {isExpanded ? <BiCollapse /> : <BiExpand />}
        {isExpanded ? <span>Collapse</span> : <span>Expand</span>}
      </button>
    );
  };

  const isAnyActiveRecurring = activeSubs.find(
    sub =>
      sub.status === "active" && sub.active === true && sub.recurring === true
  );

  const toShowCumulativeDate =
    !!(cumulativeCampaignSubEndDate || cumulativeStandardSubEndDate) &&
    !isAnyActiveRecurring;

  return (
    <div styleName="subs-layout-wrapper">
      {toShowCumulativeDate && (
        <div>
          <div styleName={`subs-heading-wrapper`}>
            <div styleName="subs-heading-title-wrapper">
              <h3 styleName="subs-heading-title">
                All your subscriptions expire on{" "}
                {new Date(cumulativeStandardSubEndDate) >
                new Date(cumulativeCampaignSubEndDate)
                  ? new Date(cumulativeStandardSubEndDate).toLocaleDateString(
                      "en-IN",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric"
                      }
                    )
                  : new Date(cumulativeCampaignSubEndDate).toLocaleDateString(
                      "en-IN",
                      {
                        day: "numeric",
                        month: "long",
                        year: "numeric"
                      }
                    )}
              </h3>
            </div>
          </div>
        </div>
      )}
      <div>
        <div
          styleName={`subs-heading-wrapper ${
            activeSubs.length ? "subs-heading-wrapper-border-bottom" : ""
          }`}
        >
          <div styleName="subs-heading-title-wrapper">
            <h3 styleName="subs-heading-title">Active Subscription</h3>
            <p styleName="subs-heading-title-count">{activeSubs.length}</p>
          </div>
        </div>
        <div styleName="subs-list-wrapper">
          {activeSubs.length ? (
            activeSubs.map(activeSub => (
              <SubscriptionCard
                subscription={activeSub}
                key={activeSub.id}
                cancelSubscription={cancelSubscription}
                member={member}
              />
            ))
          ) : (
            <p styleName="no-plan">You do not have any active plans</p>
          )}
        </div>
      </div>
      <div>
        <div
          styleName={`subs-heading-wrapper ${
            pendingSubs.length ? "subs-heading-wrapper-border-bottom" : ""
          }`}
        >
          <div styleName="subs-heading-title-wrapper">
            <h3 styleName="subs-heading-title">Pending Subscriptions</h3>
            <p styleName="subs-heading-title-count">{pendingSubs.length}</p>
          </div>
          {pendingSubs.length ? <ExpandButton section="pending" /> : null}
        </div>
        <div
          styleName={`subs-list-wrapper ${
            expandedSection === "pending" ? "shown" : "hidden"
          }`}
        >
          {pendingSubs.map(pendingSub => (
            <SubscriptionCard
              subscription={pendingSub}
              key={pendingSub.id}
              member={member}
            />
          ))}
        </div>
      </div>
      <div>
        <div
          styleName={`subs-heading-wrapper ${
            pastSubs.length ? "subs-heading-wrapper-border-bottom" : ""
          }`}
        >
          <div styleName="subs-heading-title-wrapper">
            <h3 styleName="subs-heading-title">Past Subscriptions</h3>
            <p styleName="subs-heading-title-count">{pastSubs.length}</p>
          </div>
          {pastSubs.length ? <ExpandButton section="past" /> : null}
        </div>
        <div
          styleName={`subs-list-wrapper ${
            expandedSection === "past" ? "shown" : "hidden"
          }`}
        >
          {pastSubs.map(pastSub => (
            <SubscriptionCard
              subscription={pastSub}
              key={pastSub.id}
              member={member}
            />
          ))}
        </div>
      </div>
      <div>
        <div
          styleName={`subs-heading-wrapper ${
            giftSubs.length ? "subs-heading-wrapper-border-bottom" : ""
          }`}
        >
          <div styleName="subs-heading-title-wrapper">
            <h3 styleName="subs-heading-title">Gift Subscriptions</h3>
            <p styleName="subs-heading-title-count">{giftSubs.length}</p>
          </div>
          {giftSubs.length ? <ExpandButton section="gift" /> : null}
        </div>
        {giftSubs.length ? (
          <div
            styleName={`subs-list-wrapper ${
              expandedSection === "gift" ? "shown" : "hidden"
            }`}
          >
            {giftSubs.map(giftSub => (
              <SubscriptionCard
                subscription={giftSub}
                key={giftSub.id}
                isGifted={true}
                member={member}
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

const AccountDetailsLayout = ({ member = {}, cancelSubscription, hostUrl }) => {
  if (!member) {
    return <LoginButton />;
  }

  const useSub = useUserSubscription();

  const fetchSubscription = useSelector(state => state.fetchSubscription);
  // const isActive = useSelector(state => state.fetchSubscription.isActive);

  const [activeButton, setActiveButton] = useState("tab1");
  const [pastSubscription, setpastSubscription] = useState([]);
  const [giftSubs, setGiftSubs] = useState([]);
  // const [toShowPopup, setToShowPopup] = useState(false);

  // useEffect(() => {
  //   if (member) {
  //     if (!isActive) {
  //       console.log("SHOW POPUP", toShowPopup);
  //       setToShowPopup(true);
  //     } else {
  //       console.log("PUSH TO VOTING PAGE");
  //       setToShowPopup(false);
  //     }
  //   } else {
  //     console.log("NO MEMBER! DON'T LET THEM GO TO VOTE PAGE");
  //     setToShowPopup(false);
  //   }
  // }, [member, isActive]);

  const [cumulativeStandardSubEndDate, setCumulativeStandardSubEndDate] =
    useState(null);
  const [cumulativeCampaignSubEndDate, setCumulativeCampaignSubEndDate] =
    useState(null);

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if(params.activeTab){
      const tab = params.activeTab;
      setActiveButton(tab);
    }
    if (fetchSubscription.items && fetchSubscription.items.length) {
      const allSubscriptions = fetchSubscription.items;

      const cumulativeTimestamps =
        fetchSubscription.items[0].subscriber.cumulative_end_timestamps;

      setpastSubscription(allSubscriptions);

      setCumulativeStandardSubEndDate(
        cumulativeTimestamps.standard_subscriptions_cumulative_end_timestamp
      );
      setCumulativeCampaignSubEndDate(
        cumulativeTimestamps.campaign_subscriptions_cumulative_end_timestamp
      );
    }

    const loadGiftSubs = async () => {
      try {
        const accessTypeKey = IntegrationData().isAccesstypeStaging
          ? IntegrationData().stagingAccessTypeKey
          : IntegrationData().accessTypeKey;

        const token = await getIntegrationToken();

        const response = await Axios.get(
          `https://www.accesstype.com/api/access/v1/members/me/subscription-gifts?key=${accessTypeKey}&accesstype_jwt=${token}`
        );

        // Accesstype fucking sucks
        if (
          response.data.subscriptions.length === 1 &&
          response.data.subscriptions[0] === null
        ) {
          setGiftSubs([]);
        } else {
          setGiftSubs(response.data.subscriptions);
        }
      } catch (err) {
        console.log(err);
      }
    };
    loadGiftSubs();
  }, [fetchSubscription]);

  const manageTab = tab => {
    setActiveButton(tab);
  };

  return (
    <>
      <div styleName="account-wrapper">
        <div styleName="heading-wrapper">
          <h1 styleName="account-heading">Account Information</h1>
          <hr styleName="heading-border" />
        </div>
        <WhatsappFeatureSection />
        <div styleName="tabs">
          <button
            styleName={`${
              activeButton === "tab1" ? "active-button" : ""
            } button `}
            aria-pressed={activeButton === "tab1"}
            onClick={() => manageTab("tab1")}
            aria-label="Account Details"
          >
            Account Details
          </button>
          <button
            styleName={`${
              activeButton === "tab2" ? "active-button" : ""
            } button `}
            aria-pressed={activeButton === "tab2"}
            onClick={() => manageTab("tab2")}
            aria-label="Subscriptions"
          >
            Subscriptions
          </button>
          {/* <button
            styleName={`${
              activeButton === "tab3" ? "active-button" : ""
            } button `}
            onClick={() => manageTab("tab3")}
          >
            Saved Stories{" "}
          </button> */}
          <button
            aria-label="Podcast RSS"
            styleName={`${
              activeButton === "tab4" ? "active-button" : ""
            } button `}
            aria-pressed={activeButton === "tab4"}
            onClick={() => {
              if (
                !isEmpty(useSub) &&
                useSub.filter(
                  sub =>
                    sub.active &&
                    (sub.group_name.toLowerCase() === "game changer" ||
                      sub.group_name.toLowerCase() === "3 year game changer")
                )[0]
              ) {
                manageTab("tab4");
              } else {
                window.alert(
                  "Oops! This is a feature for Gamechanger subscribers only. Upgrade to Gamechanger subscription or access the podcast from our podcast player."
                );
              }
            }}
          >
            🔒 Podcast RSS 🔗{" "}
          </button>
          {/* <button
            styleName={`${
              activeButton === "tab5" ? "active-button" : ""
            } button `}
            onClick={() => {
              if (
                !isEmpty(useSub) &&
                useSub.filter(
                  sub =>
                    sub.active &&
                    (sub.group_name.toLowerCase() === "game changer" ||
                      sub.group_name.toLowerCase() === "3 year game changer" ||
                      sub.group_name.toLowerCase() === "disruptor")
                )[0]
              ) {
                manageTab("tab5");
              } else {
                window.alert(
                  "Oops! This is a feature for Gamechanger and Disruptor subscribers only."
                );
              }
            }}
          >
            NL Discord Server{" "}
          </button> */}
          {/* <button
            aria-label="Store Purchases"
            styleName={`${
              activeButton === "tab7" ? "active-button" : ""
            } button `}
            aria-pressed={activeButton === 'tab7'}
            onClick={() => manageTab("tab7")}
          >
            Store Purchases{" "}
          </button> */}
        </div>
        {activeButton === "tab1" && <EditProfile member={member} />}
        {activeButton === "tab2" && (
          <SubscriptionsLayout
            member={member}
            giftSubs={giftSubs}
            subscriptions={pastSubscription}
            cumulativeCampaignSubEndDate={cumulativeCampaignSubEndDate}
            cumulativeStandardSubEndDate={cumulativeStandardSubEndDate}
            cancelSubscription={cancelSubscription}
          />
        )}
        {activeButton === "tab3" && <Saved />}
        {activeButton === "tab4" && <PodcastRssComponent />}
        {/* {activeButton === "tab5" && <DiscordComponent />} */}
        {activeButton === "tab6" && <GiftSubscriptions />}
        {activeButton === "tab7" && <ShopifyPurchases />}
        {activeButton !== "tab4" && activeButton !== "tab5" && (
          <div styleName="button-wrapper">
            <div styleName="first-btn">
              <a
                styleName="btn-style"
                // className="nl-btn nl-btn-large"
                href={`${hostUrl}/faq`}
              >
                Frequently Asked Questions
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

AccountDetailsLayout.propTypes = {
  member: PropTypes.object,
  initAccessType: PropTypes.func,
  getSubscriptionForUser: PropTypes.func,
  config: PropTypes.object,
  navigateTo: PropTypes.func,
  cancelSubscription: PropTypes.func,
  subscriptionManage: PropTypes.func,
  isSubscriptionCanceled: PropTypes.bool,
  selectedPlan: PropTypes.func,
  groupSubscription: PropTypes.array,
  campaignSubscriptions: PropTypes.array
};

const AccountDetailsBase = ({
  member = {},
  config = {},
  navigateTo,
  subscriptionManage,
  isSubscriptionCanceled,
  selectedPlan,
  subscriptions,
  campaignSubscriptions,
  hostUrl
}) => {
  const integrationsConfig = IntegrationData();
  const email = get(member, ["email"], "");
  const phone = get(member, ["phone-number"], "");
  const isStaging = get(
    config,
    ["publisher", "publisher", "accessTypeEnvironment"],
    true
  );
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const enableAccesstype = get(
    config,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;
  return (
    <AccessType
      enableAccesstype={enableAccesstype}
      isStaging={false}
      accessTypeKey={accessTypeKey}
      email={email}
      phone={phone}
      isAccessTypeCampaignEnabled={true}
      accessTypeBkIntegrationId={accessTypeBkIntegrationId}
    >
      {({ initAccessType, getSubscriptionForUser, cancelSubscription }) => {
        return (
          <AccountDetailsLayout
            initAccessType={initAccessType}
            getSubscriptionForUser={getSubscriptionForUser}
            member={member}
            config={config}
            navigateTo={navigateTo}
            cancelSubscription={cancelSubscription}
            subscriptionManage={subscriptionManage}
            isSubscriptionCanceled={isSubscriptionCanceled}
            selectedPlan={selectedPlan}
            groupSubscription={subscriptions}
            campaignSubscriptions={campaignSubscriptions}
            hostUrl={hostUrl}
          />
        );
      }}
    </AccessType>
  );
};

AccountDetailsBase.propTypes = {
  member: PropTypes.object,
  config: PropTypes.object,
  navigateTo: PropTypes.func,
  subscriptionManage: PropTypes.func,
  isSubscriptionCanceled: PropTypes.bool,
  selectedPlan: PropTypes.func,
  subscriptions: PropTypes.array,
  campaignSubscriptions: PropTypes.array
};

const mapStateToProps = state => {
  return {
    config: get(state, ["qt", "config"], {}),
    member: get(state, ["member"], null),
    isSubscriptionCanceled: get(
      state,
      ["isSubscriptionCancel", "status"],
      false
    ),
    subscriptions: get(state, ["subscriptions"], []),
    campaignSubscriptions: get(state, ["accessTypeCampaignSubscriptions"], []),
    hostUrl: get(state, ["qt", "currentHostUrl"], "https://www.newslaundry.com")
  };
};

const mapDispatchToProps = dispatch => ({
  navigateTo: url => global.app.navigateToPage(dispatch, url),
  subscriptionManage: function (payload) {
    dispatch({
      type: IS_SUBSCRIPTION_CANCEL,
      payload: payload
    });
  },
  selectedPlan: function (plan, redirectUrl) {
    dispatch({
      type: SELECTED_PLAN,
      plan: plan,
      planType: "standard",
      redirectUrl: redirectUrl
    });
  }
});

function PodcastRssComponent() {
  return (
    <div styleName="wrapper">
      <h2>Private RSS link for our podcasts that are behind the paywall</h2>
      <br />
      <p>
        As much as we prefer to have subscribers use our podcast player on the
        website, we want to make the podcast experience as frictionless as
        possible. So while we work on our iOS and Android apps, we have made
        private RSS links via which Gamechanger subscribers can access our
        paywall podcasts on their favorite podcast apps.{" "}
      </p>
      <p>Apps that will work with our private RSS link:</p>
      <div style={{ margin: "0.5rem auto" }}>
        iOS : <br/><IosAppLogos />
      </div>
      <div style={{ margin: "0.5rem auto" }}>
        Android : <br/><AndroidAppLogos />
      </div>

      <div styleName="card-container">
        {shows.map(show => (
          <Card key={show._id} show={show} />
        ))}
      </div>
      <br/>
      {/* <br/> */}
      <div styleName="instructions">
        <h3>How do I add the RSS link to my podcast player?</h3>
        <h4>Apple Podcast</h4>
        <p>
          Click on Get Feed Link below the show icon &gt; Launch Apple Podcasts
          on your phone &gt; Tap on Library from the the menu items at the
          bottom &gt; Click on Edit at the top right &gt; Choose Add a Show by
          URL &gt; Paste the copied link and hit Subscribe
        </p>{" "}
        {/* <h4>Google Podcast</h4>
        <p>
          Click on Get Feed Link below the show icon &gt; Launch Google Podcasts
          on your phone &gt;Tap on Activity from the menu icons at the bottom
          &gt; Choose Subscriptions from the menu at the top &gt; Click on the
          kebab icon (⋮) at the top right &gt; Click on Add by RSS feed &gt;
          Paste the copied link and hit Subscribe
        </p>{" "} */}
        <h4>Pocket Casts</h4>
        <p>
          Click on Get Feed Link below the show icon &gt; Launch Pocket Casts on
          your phone &gt; Tap on Discover &gt; Paste the copied link and tap on
          the search icon &gt; Select the show and hit Subscribe
        </p>{" "}
        <h4>Podcast Addict</h4>{" "}
        <p>
          Click on Get Feed Link below the show icon &gt; Launch Podcast Addict
          app on your phone &gt; Tap the “+” icon in the top right &gt; Click on
          the RSS feed icon &gt; Paste the copied link and hit Add{" "}
        </p>
        <h4>Castro</h4>{" "}
        <p>
          Click on Get Feed Link below the show icon &gt; Launch Castro on your
          phone &gt; Tap on search icon / Discover on the menu icons at the
          bottom &gt; Paste the copied link &gt; Select the show and hit
          Subscribe
        </p>
      </div>
    </div>
  );
}

function IosAppLogos() {
  const links = [
    "https://images.assettype.com/newslaundry/2023-09/3f548487-2835-4f5c-ae3e-6c032909f57a/US_UK_Apple_Podcasts_Listen_Badge_RGB.svg",
    "https://www.pocketcasts.com/assets/images/badges/pocketcasts_large_light.svg",
    "https://images.assettype.com/newslaundry/2024-03/a8cb7b7b-8180-4519-8240-83374090b80c/castro.png"
  ];
  return (
    <div
      style={{
        display: "inline-flex",
        gap: "20px",
        margin: "auto 2rem",
        flexFlow: "row wrap"
      }}
    >
      {links.map(l => (
        <img alt={l} height="48px" key={l} src={l} />
      ))}
    </div>
  );
}

function AndroidAppLogos() {
  const links = [
    "https://www.pocketcasts.com/assets/images/badges/pocketcasts_large_light.svg",
    "https://play-lh.googleusercontent.com/m6FeLOkUfP8qTZNXKFSSI8_exI-SlGJRcIArl3gRm3-VninL7l1RdYlPkkf2CfbBnA=s180-rw",
    "https://play-lh.googleusercontent.com/VMgWF3Uv2rTu3Z7fHFs7qhsAsfru7_q7IRN7tpT-bRyqkosxlg5RiQm7hmcfT2iAo_Y=s180-rw"
  ];
  return (
    <div
      style={{
        display: "inline-flex",
        gap: "20px",
        margin: "auto 2rem",
        flexFlow: "row wrap"
      }}
    >
      {links.map(l => (
        <img alt={l} height="48px" key={l} src={l} />
      ))}
    </div>
  );
}

function Card({ show }) {
  const useSub = useUserSubscription();

  const [isCopied, handleCopy] = useCopyToClipboard();
  const [tokenState, setTokenState] = useState("");
  const [linkButton, setLinkButton] = useState(true);
  const [buttonText, setButtonText] = useState("Get Feed Link");

  const gameChangerSub = useSub
    .filter(
      sub =>
        sub.status.toLowerCase() === "active" ||
        sub.status.toLowerCase() === "pending"
    )
    .filter(
      sub =>
        sub.group_name.toLowerCase() === "game changer" ||
        sub.group_name.toLowerCase() === "3 year game changer"
    );

  const showClickHandler = showId => async e => {
    try {
      if (!isEmpty(useSub)) {
        if (useSub && gameChangerSub) {
          setButtonText(Loader);
          const { token } = await getPodcastToken(
            gameChangerSub[0].subscriber_id,
            gameChangerSub[0].group_name,
            gameChangerSub[0].end_timestamp,
            showId
          );
          setTokenState(`https://newslaundry.com/podcast-rss/getFeed/${token}`);
          setLinkButton(false);
        } else {
          setButtonText("Get Feed Link");
          window.alert("No subscription or Not Subscribed as Game Changer");
        }
      } else {
        setButtonText("Get Feed Link");
        window.alert("login!");
      }
    } catch (e) {
      setButtonText("Get Feed Link");
      console.log(e);
    }
  };

  useEffect(() => {
    if (isCopied) {
      window.alert(
        "The Rss Feed link has been Copied, paste it into your Podcast Client"
      );
    }
  }, [isCopied]);

  return (
    <div key={show._id} styleName="card">
      <img src={show.cover} height="300px" />
      {linkButton ? (
        <button onClick={showClickHandler(show._id)}>{buttonText}</button>
      ) : (
        <div styleName="contentcopy">
          <input defaultValue={tokenState} type="text" readOnly />
          <button onClick={() => handleCopy(tokenState)}>
            <MdContentCopy color="white" size="22px" />
          </button>
        </div>
      )}
    </div>
  );
}

// function DiscordComponent() {
//   const useSub = useUserSubscription();
//   const [successMsg, setSuccessMsg] = useState({ loading: false });
//   const [formData, setFormData] = useState({
//     email: "",
//     plan: "",
//     discordId: "",
//     prevId: ""
//   });

//   const submitHandler = async e => {
//     e.preventDefault();
//     try {
//       setSuccessMsg({ ...successMsg, loading: true });
//       setFormData({ ...formData, prevId: formData.discordId });
//       const res = await linkDiscordAccount(formData);
//       setSuccessMsg({ ...successMsg, ...res, loading: false });
//       console.log(res);
//     } catch (e) {
//       console.log(e);
//     }
//   };
//   useEffect(() => {
//     async function fetchStuff() {
//       try {
//         // This is to check for Standard subscriptions while user has another active campaign subscription
//         const activeSubs = await useSub.filter(sub =>
//           ["active", "pending"].includes(sub.status.toLowerCase())
//         );

//         let activeSub = null;

//         if (activeSubs.find(sub => sub.status.toLowerCase() === "active")) {
//           if (
//             activeSubs.filter(
//               sub =>
//                 sub.status.toLowerCase() === "active" &&
//                 sub.subscription_type.toLowerCase() === "standard"
//             )
//           ) {
//             activeSub = activeSubs.find(
//               sub =>
//                 sub.status.toLowerCase() === "active" &&
//                 sub.subscription_type.toLowerCase() === "standard"
//             );
//           } else {
//             activeSub = activeSubs.find(
//               sub => sub.status.toLowerCase() === "active"
//             );
//           }
//         } else {
//           activeSub = activeSubs.find(
//             sub => sub.status.toLowerCase() === "pending"
//           );
//         }

//         setFormData({
//           ...formData,
//           email: activeSub.preferred_identity.value,
//           plan: activeSub.group_name
//         });
//         const doc = await getDiscordIdByEmail(
//           activeSub.preferred_identity.value
//         );
//         if (doc.email) {
//           setFormData({
//             ...formData,
//             discordId: doc.discordId,
//             prevId: doc.discordId,
//             email: activeSub.preferred_identity.value,
//             plan: activeSub.group_name
//           });
//         }
//       } catch (e) {
//         console.log(e);
//       }
//     }
//     fetchStuff();
//   }, []);

//   return (
//     <div styleName="wrapper" style={{ marginBottom: "3rem" }}>
//       <div styleName="discord-tab-header">
//         <div>
//           <h2>
//             Join our Discord server and get access to its subscriber-only
//             channels
//           </h2>
//           <div styleName="discord-tab-steps">
//             <div>
//               {/* <h3>Step 1:</h3> */}
//               <p>
//                 Click on the following to join :{" "}
//                 <a
//                   href="https://discord.gg/EHRKe2CP6T"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   style={{ color: "#ec2227" }}
//                 >
//                   discord.gg/EHRKe2CP6T
//                 </a>
//               </p>
//             </div>
//             <div>
//               <h3>Note:</h3>
//               <p>
//                 If you are not able to access the subscriber only channels, or
//                 have any query, email it to us along with your Discord username
//                 at{" "}
//                 <a
//                   href="mailto:subscription@newslaundry.com"
//                   style={{ color: "#ec2227" }}
//                 >
//                   subscription@newslaundry.com
//                 </a>
//                 {". "}
//                 We will resolve it for you.
//                 {/* Submit your Discord id in the field below. Your username
//                 (Johndoe#4563) is not your Discord id. It&apos;s a unique
//                 18-digit number. */}
//               </p>
//             </div>
//           </div>
//         </div>
//         <img
//           style={{ alignSelf: "center" }}
//           src="https://images.assettype.com/newslaundry/2021-01/f79ec9db-fab6-4e2c-b305-291dd5ec4532/square_discord.jpg"
//           alt="disc_square"
//         />
//       </div>
//       {/* <div styleName="discord-tab-main">
//         <p style={{ margin: "15px auto" }}>
//           To find your Discord ID go to <strong>`# find-your-id`</strong>{" "}
//           channel on our server and type the command <strong>`myid`</strong>
//         </p>
//         {/* <img
//           src="https://images.assettype.com/newslaundry/2021-01/b4a15337-647c-4b44-8087-cd57b8e7748a/discord_id_bot2.png"
//           alt="disc_id"
//         /> */}
//       {/* <iframe
//           style={{
//             display: "block",
//             width: "100%",
//             maxWidth: "560px",
//             height: "315px"
//           }}
//           src="https://www.youtube-nocookie.com/embed/SKEVGRhevbs"
//           frameBorder={0}
//           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//           allowFullScreen
//         />
//         <form
//           style={{
//             display: "flex",
//             gap: "10px",
//             flexWrap: "wrap",
//             marginTop: "30px"
//           }}
//           onSubmit={submitHandler}
//         >
//           <TextField
//             type="text"
//             onChange={e =>
//               setFormData({ ...formData, discordId: e.target.value })
//             }
//             value={formData.discordId}
//             placeholder="Enter Discord ID"
//             required
//           />
//           <Button type="submit">
//             {successMsg.loading ? <Loader /> : "Submit"}
//           </Button>
//         </form>
//         <p
//           styleName="discord-tab-form-response"
//           style={
//             successMsg.type === "success"
//               ? { color: "green" }
//               : { color: "#ec2227" }
//           }
//         >
//           {successMsg.message}
//         </p> */}
//       {/* <div styleName="discord-tab-notes">
//           {/* <img src="https://s2.gifyu.com/images/Discord_-User-id-GIF.gif" alt="Discord_-User-id-GIF.gif" border="0" />
//           <div>
//             <h3>
//               <strong>NOTE:</strong>
//             </h3>
//             <ul>
//               <li>
//                 While anyone can join our Discord server, you need to link your
//                 Discord account here by submitting your id to get access to
//                 subscriber-only channels.{" "}
//               </li>
//               <li>
//                 Upon expiry of your subscription, you will lose access to the
//                 subscriber-only channels in this server. On renewing your
//                 subscription, you will automatically get access to these
//                 channels.
//               </li>
//               <li>
//                 We have a separate channel for overseas subscribers. If
//                 you&apos;re based outside India, email us your Discord username
//                 at{" "}
//                 <a
//                   href="mailto:subscription@newslaundry.com"
//                   style={{ color: "#ec2227" }}
//                 >
//                   subscription@newslaundry.com
//                 </a>{" "}
//                 after you join you submit your user id here. We will add you
//                 there.
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div> */}
//       <br />
//     </div>
//   );
// }

function ShopifyPurchases() {
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    try {
      const result = await getShopifyOrders();
      setOrders(result.orders);
      setOrders({});
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div styleName="wrapper" style={{ marginBottom: "3rem" }}>
      <div styleName="shopify">
        {orders !== null &&
          orders.length > 0 &&
          orders.map(i => (
            <div styleName="shopify-card" key={i.id}>
              <h3>Order {i.name}</h3>
              <ul>
                {i.line_items.map(item => (
                  <li key={item.id}>
                    {item.name} x{item.quantity} (₹{item.price})
                  </li>
                ))}
              </ul>
              <h3>Paid ₹{i.total_price}</h3>
            </div>
          ))}
        {orders !== null && orders.length < 1 && (
          <div styleName="empty-card" style={{ margin: "3rem 0" }}>
            <h2>You didn&apos;t make any purchases!</h2>
          </div>
        )}
        {orders === null && (
          <div styleName="empty-card" style={{ margin: "3rem 0" }}>
            <Loader color="#ec2227" />
          </div>
        )}
      </div>
    </div>
  );
}
export const AccountDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountDetailsBase);
