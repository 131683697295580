import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import PropTypes from "prop-types";
import getStoriesByCollection from "../../../utils";
import { TwoColStoryCard } from "../../molecules/two-col-story-card";
import HorizontalStoryCard from "../../molecules/horizontal-story-card";
import "./two-rows-three-stories.m.css";
import { RenewalBanner } from "../../layouts/renewal-banner";
// import ElectionFundNudge from "../../layouts/election-fund-top-nudge";

export const TwoRowsFourStories = ({ collection }) => {
  // const state = useSelector(state => state);
  // const member = useSelector(state => {
  //   return get(state, ["member"], null);
  // });
  // const isActive = useSelector(state =>
  //   get(state, ["fetchSubscription", "isActive"], null)
  // );
  const [location, setLocation] = useState("");
  const stories = getStoriesByCollection(collection);

  const isExpired = useSelector(state =>
    get(state, ["fetchSubscription", "isExpired"], null)
  );

  if (stories.length < 1) {
    return null;
  }

  useEffect(() => {
    setLocation(global.location.host);
  }, []);

  return (
    <React.Fragment>
      {isExpired ? (
        <div className="full-width">
          <RenewalBanner />
          <br />
        </div>
      ) : (
        <></>
      )}

      <div styleName="wrapper">
        {stories.slice(0, 1).map((story, index) => (
          <TwoColStoryCard story={story} key={index} />
        ))}
        <div styleName="second-card">
          {stories.length > 1 &&
            (location === "www.newslaundry.com" ||
              location === "newslaundry-web.qtstage.io" ||
              location === "newslaundry-beta.quintype.io" ||
              location === "localhost:3000") &&
            stories
              .slice(1, 4)
              .map(story => (
                <HorizontalStoryCard
                  story={story}
                  descriptionTopMargin={true}
                  key={story.id}
                  boxPadding={false}
                  descriptionPadding={false}
                  showTime={false}
                  hideAuthorWithTime
                />
              ))}
        </div>

        {stories.length > 1 &&
          (location === "hindi.newslaundry.com" ||
            location === "newslaundry-hindi-web.qtstage.io" ||
            location === "hindi.lvh.me:3000") &&
          null}
      </div>
    </React.Fragment>
  );
};

TwoRowsFourStories.storyLimit = 8;

TwoRowsFourStories.propTypes = {
  collection: PropTypes.object
};
