import React from "react";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import remove from "lodash/remove";
// import assetify from "@quintype/framework/assetify";

import Carousel from "../../../molecules/glide-slider/carousel";
// import quoteIcon from "../../../../../assets/images/quotes.png";
// import Arrow from "../../../../../assets/images/chevron-left-solid.svg";

import {
  FaCircleArrowLeft,
  FaCircleArrowRight,
  FaQuoteLeft
} from "react-icons/fa6";

import "./subscription-testimonial.m.css";

export const SubscriptionTestimonial = props => {
  const subscriptionTestimonial = get(props, ["data", "entities"], []);
  const clonedSubscriptionTestimonial = [...subscriptionTestimonial];

  if (subscriptionTestimonial.length < 1) {
    return null;
  }

  const priorityEntities = remove(clonedSubscriptionTestimonial, element => {
    return element.entity.number && element.entity.number !== "";
  });

  const sortedEntities = sortBy(priorityEntities, [
    function (element) {
      return parseInt(element.entity.number || 0);
    }
  ]);

  const groupedSortedEntities = sortedEntities.concat(
    clonedSubscriptionTestimonial
  );

  const children = groupedSortedEntities.map((item, index) => {
    // const photo = get(item, ["entity", "photo", "0", "url"], "");
    const description = get(item, ["entity", "description"], "");
    const entityName = get(item, ["entity", "name"], "");
    const entityGroup = get(item, ["entity", "group"], "");
    return (
      <div key={index}>
        <div styleName="photo-desc-wrapper">
          {/* <img styleName="photo" src={photo} /> */}

          <div styleName="content">
            <div styleName="description-wrapper">
              <i>
                <span styleName="description">
                  {description && description}
                </span>
              </i>
              <h3 styleName="name">{entityName && entityName}</h3>
              <h3 styleName="group-name">{entityGroup && entityGroup}</h3>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div styleName="testimonial-wrapper" className="full-width-with-padding">
      <div styleName="wrapper">
        <FaQuoteLeft aria-hidden="true" styleName="quote-icon" />
        {/* <img
          styleName="quote-icon"
          src="https://img.icons8.com/glyph-neue/64/fa314a/quote-left.png"
          alt="newslaundry logo"
        /> */}
        <h2 styleName="heading">What our subscribers say</h2>
        <Carousel
          carouselName={`carousel-testimonial`}
          options={{
            type: `${
              subscriptionTestimonial.length <= 2 ? "slider" : "carousel"
            }`,
            perView: 1,
            gap: 32,
            breakpoints: {
              769: { perView: 1, gap: 32, peek: { before: 0, after: 0 } }
            }
          }}
          totalItems={subscriptionTestimonial.length}
          showArrows={true}
          renderCenterLeftControls={({ previousSlide, currentSlide }) => {
            return (
              currentSlide !== 0 && (
                <button
                  onClick={previousSlide}
                  styleName="slider-btn"
                  aria-label="go to next testimonial slide"
                >
                  <FaCircleArrowLeft aria-hidden="true" />
                  {/* <img src={`${assetify(Arrow)}`} alt="left" /> */}
                </button>
              )
            );
          }}
          renderCenterRightControls={({ nextSlide }) =>
            subscriptionTestimonial.length > 2 && (
              <button
                onClick={nextSlide}
                styleName="slider-btn"
                aria-label="go to previous testimonial slide"
              >
                <FaCircleArrowRight aria-hidden="true" />
                {/* <img
                  src={`${assetify(Arrow)}`}
                  alt="right"
                  styleName="arrow-right"
                /> */}
              </button>
            )
          }
        >
          {children}
        </Carousel>
      </div>
    </div>
  );
};
