const tagsLegend = {
  4523: ["game_changer", "nl_tnm_joint_subscription", "one_year_one_time"],
  4527: ["game_changer", "nl_tnm_joint_subscription", "one_year_one_time_usd"],
  4522: ["game_changer", "nl_tnm_joint_subscription", "one_month_one_time"],
  4526: ["game_changer", "nl_tnm_joint_subscription", "one_month_one_time_usd"],
  4476: ["game_changer", "nl_tnm_joint_subscription", "yearly_recurring"],
  4475: ["game_changer", "nl_tnm_joint_subscription", "yearly_recurring_usd"],
  4479: ["game_changer", "nl_tnm_joint_subscription", "monthly_recurring"],
  4477: ["game_changer", "nl_tnm_joint_subscription", "monthly_recurring_usd"],
  327: ["game_changer", "nl_subscription", "one_year_one_time"],
  750: ["game_changer", "nl_subscription", "one_year_one_time_usd"],
  325: ["game_changer", "nl_subscription", "one_month_one_time"],
  746: ["game_changer", "nl_subscription", "one_month_one_time_usd"],
  343: ["game_changer", "nl_subscription", "yearly_recurring"],
  751: ["game_changer", "nl_subscription", "yearly_recurring_usd"],
  341: ["game_changer", "nl_subscription", "monthly_recurring"],
  747: ["game_changer", "nl_subscription", "monthly_recurring_usd"],
  4520: ["disruptor", "nl_tnm_joint_subscription", "one_year_one_time"],
  4524: ["disruptor", "nl_tnm_joint_subscription", "one_year_one_time_usd"],
  4471: ["disruptor", "nl_tnm_joint_subscription", "yearly_recurring"],
  4472: ["disruptor", "nl_tnm_joint_subscription", "yearly_recurring_usd"],
  340: ["disruptor", "nl_subscription", "yearly_recurring"],
  745: ["disruptor", "nl_subscription", "yearly_recurring_usd"],
  330: ["disruptor", "nl_subscription", "one_year_one_time"],
  742: ["disruptor", "nl_subscription", "one_year_one_time_usd"]
};

export const fetchTagsForPlans = planId => {
  let toSendTags = ["website_order"];

  const planTags = tagsLegend[planId];

  if (planTags) {
    toSendTags = [...toSendTags, ...planTags];
  }

  return toSendTags;
};
