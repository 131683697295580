/* eslint-disable prettier/prettier */
import PropTypes from "prop-types";
import React from "react";
import { SubscriptionTestimonial } from "./subscription-testimonial";
import { SubscriptionHelper } from "./subscription-helpers";
import { SubscriptionCollection } from "./subscription-collection";
import { Features } from "./features";
import { SubscriptionBlocks } from "./subscription-blocks";
import { SubscriptionPlaylist } from "./subscription-playlist";
import { SubscriptionExplained } from "./subscription-explained";
import { Faq } from "./faq";

export const GiftSubscription = props => {
  return (
    <div>
      <SubscriptionBlocks {...props} gift={true} />
      <br />
      <Features />
      <SubscriptionTestimonial {...props} />
      <br />
      <SubscriptionCollection {...props} />
      <SubscriptionPlaylist />
      <SubscriptionExplained {...props} />
      <Faq />
    </div>
  );
};

GiftSubscription.propTypes = {
  config: PropTypes.object
};
