/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import assetify from "@quintype/framework/assetify";
// import { useSelector } from "react-redux";
import gift from "../../../../../assets/images/nl-images/nl-sena-page.png";
import subscribe from "../../../../../assets/images/nl-images/nl-subscription-2.jpg";
// import nlLogo from "../../../../../assets/images/nl-images/NLSenaPage-image-on-topleft.png";
import nlLogo from "../../../../../assets/images/nl-images/grandmaster.png";
import "./subscription-helpers.m.css";
import { Link } from "@quintype/components";
import { TEXT } from "../constants";

export const SubscriptionHelper = props => {
  return (
    <div styleName="wrapper" className="full-width">
      <div style={{ maxWidth: "1200px", margin: "auto" }}>
        <div styleName="helper-boxes">
          {props.gift ? (
            <div styleName="box">
              <img styleName="image" src={assetify(subscribe)} alt="sub" />
              <div styleName="content">
                <h3 styleName="heading">{TEXT.subscriptionTitle}</h3>
                <p styleName="text">{TEXT.subscriptionDescription}</p>
              </div>
              <div styleName="button-wrapper">
                <Link href="/subscription" styleName="button">
                  Subscribe Now
                </Link>
              </div>
            </div>
          ) : (
            <div styleName="box">
              <img styleName="image" src={assetify(gift)} alt="gift sub" />
              <div styleName="content">
                <h3 styleName="heading">{TEXT.giftTitle}</h3>
                <p styleName="text">{TEXT.giftDescription}</p>
              </div>
              <div styleName="button-wrapper">
                <Link href="/gift" styleName="button">
                  Gift Subscription
                </Link>
              </div>
            </div>
          )}
          <div styleName="box">
            <img
              styleName="image"
              src="https://images.assettype.com/newslaundry/2022-03/bfbccad3-574d-4f8d-9c3e-0a2b3ac77c87/student_subscription.png"
              alt="newslaundry logo"
            />
            <div styleName="content">
              <h3 styleName="heading">{TEXT.studentTitle}</h3>
              <p styleName="text">{TEXT.studentDescription}</p>
            </div>
            <div styleName="button-wrapper">
              <Link href="/student-subscription" styleName="button">
                Learn More
              </Link>
            </div>
          </div>
          <div styleName="box">
            <img
              styleName="image"
              src={assetify(nlLogo)}
              alt="newslaundry logo"
            />
            <div styleName="content">
              <h3 styleName="heading">{TEXT.senaTitle}</h3>
              <p styleName="text">{TEXT.senaDescriptiion}</p>
            </div>
            <div styleName="button-wrapper">
              <Link href="/sena" styleName="button">
                Join NL Sena
              </Link>
            </div>
          </div>
          <div styleName="box">
            <img
              styleName="image"
              src="https://images.assettype.com/newslaundry/2022-03/7b8c9113-5364-48aa-a12b-7581be1310d7/student_subscription_main.png"
              alt="newslaundry group subscription"
            />
            <div styleName="content">
              <h3 styleName="heading">{TEXT.groupTitle}</h3>
              <p styleName="text">{TEXT.groupDescription}</p>
            </div>
            <div styleName="button-wrapper">
              <Link href="/group-subscription" styleName="button">
                Know More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SubscriptionHelper.propTypes = {
  config: PropTypes.object
};
