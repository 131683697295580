/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { AccessType } from "@quintype/components";
import { ProjectCard } from "../../molecules/project-card";
import { ProjectContainer } from "../../molecules/project-container";
import IntegrationData from "../../../../../config/integrations.js";
import Loader from "../../atoms/loader";
import NLSena from "../nl-sena";
import { campaignDescriptionParser } from "../../../utils";
// import { proppatch } from "superagent";

const NlSenaWrapper = props => {
  const integrationsConfig = IntegrationData();
  const email = get(props.config, ["publisher", "publisher", "email"], "");
  const phone = get(props.config, ["publisher", "publisher", "phone"], "");
  const isStaging = get(
    props.config,
    ["publisher", "publisher", "accessTypeEnvironment"],
    true
  );
  const enableAccesstype = get(
    props.config,
    ["publisher", "publisher", "enableAccesstype"],
    true
  );
  // const campSubscriptions = get(props, ["campaignSubscriptions"], []);
  const redirectUrl = props.redirectUrl || "/";
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;

  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  return (
    <AccessType
      enableAccesstype={enableAccesstype}
      isStaging={false}
      accessTypeKey={accessTypeKey}
      email={email}
      phone={phone}
      isAccessTypeCampaignEnabled={true}
      accessTypeBkIntegrationId={accessTypeBkIntegrationId}
    >
      {({
        initAccessType,
        initRazorPayPayment,
        initStripePayment,
        initPayPalPayment
      }) => {
        let campaignSubscriptions = get(props, ["campaignSubscriptions"], []);
        campaignSubscriptions = campaignSubscriptions.map(
          campaignDescriptionParser
        );

        const senaCampaigns = campaignSubscriptions.filter(
          camp => camp.description.type !== "legal_fund"
        );

        const campaign =
          campaignSubscriptions.filter(item => {
            return item.id === props.campaignId;
          }) || [];

        // console.log({ cID: props.campaignId, campaign, senaCampaigns });

        const showBottom = campaign[0] && campaign[0].campaign_active === true;

        // console.log(campaign)
        if (props.showSingleCampaign) {
          if (!props.detailPage === true) {
            return campaign.length > 0 ? (
              <ProjectCard
                showSingleCampaign={props.showSingleCampaign}
                subscriptions={campaign[0]}
                redirectUrl={redirectUrl}
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                <Loader color="#ec2227" />
              </div>
            );
          } else {
            // console.log(campaign[0])
            return campaign.length > 0 ? (
              <ProjectContainer
                // showSingleCampaign={props.showSingleCampaign}
                {...props}
                subscriptions={campaign[0]}
                redirectUrl={redirectUrl}
                showBottom={showBottom}
              />
            ) : (
              <div style={{ textAlign: "center" }}>
                <Loader color="#ec2227" />
              </div>
            );
          }
        } else {
          return (
            <NLSena
              initRazorPayPayment={initRazorPayPayment}
              initStripePayment={initStripePayment}
              initPayPalPayment={initPayPalPayment}
              initAccessType={initAccessType}
              {...props}
              campaignSubscriptions={senaCampaigns}
            />
          );
        }
      }}
    </AccessType>
  );
};

const mapStateToProps = state => {
  return {
    config: get(state, ["qt", "config"], {}),
    subscriptions: get(state, ["subscriptions"], null),
    campaignSubscriptions: get(state, ["accessTypeCampaignSubscriptions"], []),
    member: get(state, ["member"], null),
    currentPath: get(state, ["qt", "currentPath"], ""),
    navigateTo: url => global.app.navigateToPage(dispatch, url),
    selectedPlan: function(plan, redirectUrl) {
      dispatch({
        type: SELECTED_PLAN,
        plan: plan,
        planType: "campaign",
        redirectUrl: redirectUrl
      });
    },
    authHost: get(
      state,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    )
  };
};

NlSenaWrapper.propTypes = {
  config: PropTypes.object
};

export default connect(mapStateToProps, {})(NlSenaWrapper);
