import React, { useEffect, useState } from "react";
import Axios from "axios";
import { get } from "lodash";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { TelInput } from "./telInput";
import {
  fetchDataFromLocalStorage,
  saveDataToLocalStorage
} from "../subscription-checkout/utils";
import {
  getDigitsExceptFirstN,
  getDigitsExceptLastN,
  getLastNDigits,
  isMobileValid
} from "../../../utils";

import countryCodeAndEmojisData from "./countryCodes.json";

import "./whatsapp-feature-section.m.css";

export const WhatsappFeatureSection = () => {
  const member = useSelector(state => state.member);
  // console.log({ member });

  const [phoneNumber, setPhoneNumber] = useState(
    member["phone-number"] ? getLastNDigits(member["phone-number"], 10) : null
  );
  const [selectedCountry, setSelectedCountry] = useState({
    countryName: "India",
    countryPhoneCode: "+91",
    flagEmoji: "🇮🇳",
    minSize: 10,
    maxSize: 10
  });
  const [error, setError] = useState(null);
  const [prompt, setPrompt] = useState(null);

  const oldMobileNumber = get(member, ["phone-number"], null);
  const code = getDigitsExceptFirstN(selectedCountry.countryPhoneCode, 1);

  useEffect(() => {
    if (member && member["phone-number"]) {
      const num = member["phone-number"];
      const setCountryName = fetchDataFromLocalStorage("phoneCountryName");

      if (num.length > 10) {
        const setCode = getDigitsExceptLastN(num, 10);
        const setNumber = getLastNDigits(num, 10);

        setPhoneNumber(setNumber);

        let countryData;

        if (setCountryName) {
          countryData = countryCodeAndEmojisData.find(
            c =>
              c.countryPhoneCode === `+${setCode}` &&
              c.countryName === setCountryName
          );
        } else {
          countryData = countryCodeAndEmojisData.find(
            c => c.countryPhoneCode === `+${setCode}`
          );
        }

        if (countryData) {
          setSelectedCountry({
            countryName: countryData.countryName,
            countryPhoneCode: countryData.countryPhoneCode,
            flagEmoji: countryData.flagEmoji,
            minSize: countryData.minSize,
            maxSize: countryData.maxSize
          });
        }
      }
    }
  }, []);

  // console.log({ oldMobileNumber, phoneNumber, code });

  const handleSubmit = async () => {
    const isMobValid = isMobileValid(`${code}${phoneNumber}`);

    if (!isMobValid) {
      setError("Please enter a valid mobile number");
      return;
    }

    const fullName =
      member["first-name"] || "" + member["last-name"] || "NL User";

    try {
      const userObj = {
        first_name: member["first-name"] || "",
        last_name: member["last-name"] || "",
        name: fullName,
        email: member.email,
        phone_number: `${code}${phoneNumber}`,
        id: member.id
      };

      // console.log({ userObjWAFeature: userObj });

      await Axios.post("/api/auth/v1/users/profile", userObj);

      if (!oldMobileNumber) {
        await Axios.post("/whatsapp-api/addUserToWhatsapp", {
          mobileNumber: phoneNumber,
          countryCode: code,
          emailAddress: member.email,
          name: member.name,
          phoneCountryName: selectedCountry.countryName
        });
      } else {
        await Axios.put("/whatsapp-api/updateUserWhatsapp", {
          newMobileNumber: phoneNumber,
          oldMobileNumber: getLastNDigits(oldMobileNumber, 10),
          countryCode: code,
          emailAddress: member.email,
          name: member.name,
          phoneCountryName: selectedCountry.countryName
        });
      }

      // Had to do this because AT's shitty profile update API does not support metadata updation
      // and neither has any country field in users table
      saveDataToLocalStorage(selectedCountry.countryName, "phoneCountryName");

      setPrompt("Your number has been successfully updated.");
    } catch (error) {
      console.error(error);
      setError("Something went wrong. Please try again later");
    }
  };

  return (
    <div styleName="feature-section">
      <h3 styleName="feature-headline">
        Get subscription updates on WhatsApp now!
      </h3>
      <p styleName="feature-text">
        Want to stay up to date with your Newslaundry subscription? We&apos;ve
        got your back! Just add your number and get all your subscription
        updates directly on WhatsApp
      </p>
      <div styleName="input-field-wrapper">
        <TelInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
        <button styleName="submit-btn" onClick={handleSubmit}>
          Update Now
        </button>
      </div>
      {error && (
        <p styleName="error-text">
          <FaTimesCircle />
          <span>{error}</span>
        </p>
      )}
      {prompt && (
        <p styleName="success-text">
          <FaCheckCircle />
          <span>{prompt}</span>
        </p>
      )}
    </div>
  );
};
