/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AccessType } from "@quintype/components";
import get from "lodash/get";
import IntegrationData from "../../../../../config/integrations";
import "./subscription-coupon-form.m.css";
import Loader from "../../atoms/loader";
import { Button } from "../../../ui/components/button";

export const SubscriptionCouponForm = ({
  selectedPlan,
  isValidCoupon,
  setIsValidCoupon,
  couponCode,
  setCouponCode,
  discountedPrice,
  setDiscountedPrice
}) => {
  const [couponLoading, setCouponLoading] = useState(false);
  const [couponError, setCouponError] = useState(null);
  const [couponSuccess, setCouponSuccess] = useState(null);

  const pub = useSelector(state => get(state, ["qt", "config", "publisher"]));
  const isStaging = get(pub, ["publisher", "accessTypeEnvironment"], true);
  const integrationsConfig = IntegrationData();
  const accessTypeKey = isStaging
    ? integrationsConfig.stagingAccessTypeKey
    : integrationsConfig.accessTypeKey;
  const accessTypeBkIntegrationId = isStaging
    ? integrationsConfig.stagingAccessTypeBkIntegrationId
    : integrationsConfig.accessTypeBkIntegrationId;

  // Plan legend
  // STAGING
  // 1793 - 3 year GC Onetime
  // 1381 - GC Annual OneTime
  // 1859 - GC One year onetime USD
  // PROD
  // 1797 - 3 year GC onetime INR
  // 1796 - 3 year GC onetime USD
  // 750 - GC one year onetime USD
  // 327 - GC one year onetime INR
  // 330 - Disruptor one year onetime INR
  // 742 - Disruptor one year onetime USD
  // 4520 - Disruptor yearly Joint onetime INR
  // 4523 - Gamechanger yearly joint onetime INR
  // 4524 - Disruptor yearly Joint onetime USD
  // 4527 - Gamechanger yearly joint onetime USD
  // 4674 - 6 months Onetime Hindi subscription plan INR

  const toWhitelistPlans = [
    // 1793, 1381, 1859, 1797, 1796, 
    750, 327, 330, 742, 4520, 4523, 4524, 4527
  ];
  const toWhitelistHindiSubscriptionPlans = [4674];
  // const toWhitelistPlans = [ 4523, 4520, 4527, 4524  ];

  // const toWhitelistAnnualPlans = [
  //   750, 327, 330, 742, 4520, 4523, 4524, 4527
  // ]

  const handleSubmit = async (couponCode, validateCoupon) => {
    try {
      setCouponLoading(true);
      const oneTimePlanID =
        selectedPlan && Number(selectedPlan.description.split(",")[0]);

      const response = await validateCoupon(oneTimePlanID, couponCode);

      console.log({ couponValidateResponse: response });

      if (!response.valid) {
        setIsValidCoupon(false);
        setCouponLoading(false);
        setCouponSuccess(null);
        setDiscountedPrice(null);
        setCouponError(
          "Oops! The discount code is either incorrect or not active anymore."
        );
        return;
      }

      if (
        couponCode.toLowerCase() === "nlhindi" &&
        !toWhitelistHindiSubscriptionPlans.includes(oneTimePlanID)
      ) {
        setIsValidCoupon(false);
        setCouponLoading(false);
        setDiscountedPrice(null);
        setCouponSuccess(null);
        setCouponError(
          "Oops! This discount code is not valid for the subscription plan you have chosen. Try choosing the half yearly plan from NL Hindi website."
        );
        return;
      }

      if (
        couponCode.toLowerCase() !== "nlhindi" &&
        !toWhitelistPlans.includes(oneTimePlanID)
      ) {
        setIsValidCoupon(false);
        setCouponLoading(false);
        setDiscountedPrice(null);
        setCouponSuccess(null);
        setCouponError(
          // "Oops! This discount code is not valid for the subscription plan you have chosen. Try a different plan"
          "Oops! This discount code is not valid for the subscription plan you have chosen. Try annual plans."
        );
        return;
      }

      // couponCode = nlhindi && toWhitelistHindiSubscriptionPlans.includes(oneTimePlanID)
      // couponCode = any_other_valid_code && toWhitelistPlans.includes(oneTimePlanID)

      setIsValidCoupon(true);
      setCouponError("");

      const newPrice = get(
        response,
        ["discount_details", "discounted_price_cents"],
        selectedPlan.price_cents
      );

      setDiscountedPrice(newPrice);
      setCouponSuccess("Your Coupon has been applied.");
      setCouponLoading(false);
    } catch (error) {
      console.error("Coupon error: " + error);
      setCouponSuccess(null);
    }
  };

  //   const handleCouponRemoval = () => {
  //     setCouponLoading(true);
  //     setIsValidCoupon(false);
  //     setCouponError("");
  //     setCoupon("");
  //     setDiscountDetails({ value: 0, type: null });
  //     setDiscountedPrice(selectedPlan.price_cents);

  //     setUseSelectedPlan(selectedPlan);
  //     setCouponLoading(false);
  //   };

  return (
    <AccessType
      enableAccesstype={true}
      isAccessTypeCampaignEnabled={true}
      isStaging={false}
      accessTypeKey={accessTypeKey}
      accessTypeBkIntegrationId={accessTypeBkIntegrationId}
    >
      {({ validateCoupon }) => {
        return (
          <React.Fragment>
            <div styleName="coupon-code-wrapper">
              <input
                placeholder="Gift code or discount coupon"
                value={couponCode}
                onChange={e => setCouponCode(e.target.value.toUpperCase())}
              />
              <Button
                variant="accent"
                onClick={() => handleSubmit(couponCode, validateCoupon)}
              >
                {couponLoading ? <Loader /> : "Apply"}
              </Button>
              {/* <button onClick={() => handleSubmit(couponCode, validateCoupon)}>
              </button> */}
              <small>{couponError}</small>
              <span>{couponSuccess}</span>
            </div>
          </React.Fragment>
        );
      }}
    </AccessType>
  );
};
