import React, { useState } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import sortBy from "lodash/sortBy";
import remove from "lodash/remove";
import { Link } from "@quintype/components";
import { LoadMoreButton } from "../../atoms/load-more-button";

import "./company-page.m.css";

export const CompanyPageBase = props => {
  const [currentCompanies, loadMore] = useState(9);
  const companyEntities = get(props, ["data", "entities"], []);
  const cloneCompanyEntities = [...companyEntities];
  const priorityEntities = remove(cloneCompanyEntities, (company, index) => {
    return company.entity.number && company.entity.number !== "";
  });

  const sortedEntities = sortBy(priorityEntities, [
    function(element) {
      return parseInt(element.entity.number || 0);
    }
  ]);
  const groupedSortedEntities = sortedEntities.concat(cloneCompanyEntities);

  return (
    <div className="full-width-with-padding">
      <h1 styleName="page-title">Companies</h1>
      <div styleName="border-bottom"></div>
      <div styleName="wrapper" className="full-width-with-padding">
        <div styleName="companies">
          {groupedSortedEntities.length > 0 &&
            groupedSortedEntities.slice(0, currentCompanies).map((item, index) => {
              const imgSlug = get(item, ["entity", "photo", "0", "key"], null);
              return (
                <Link href={`/topic/${item.entity.slug}`} key={index} styleName="company-list">
                  <div>
                    <img src={`${props.publisherConfig["cdn-image"] + imgSlug}` } styleName="logo" alt="newslaundry-logo" />
                  </div>
                  <div>
                    <div styleName="border" />
                    <div styleName="name">{item.entity.name}</div>
                  </div>
                </Link>
              );
            })}
        </div>
        {currentCompanies < companyEntities.length && (
          <div styleName="load-more">
            <LoadMoreButton onClick={() => loadMore(currentCompanies + 3)} />
          </div>
        )}
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  publisherConfig: get(state, ["qt", "config", "publisher"], {})
});

export const CompanyPage = connect(mapStateToProps)(CompanyPageBase);