import React from "react";
import { useQuery, QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from "react-redux";
import get from "lodash/get";

import HorizontalStoryCard from "../../molecules/horizontal-story-card";
import CommonStoryCard from "../../molecules/common-story-card";
import Loader from "../../atoms/loader";
import { fetchBookmark } from "../../../cartoon-mango";
import { Link } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import { getAuthUrl } from "../../helper/hooks";
import LoginImg from "../../../../assets/images/bouncer.jpg";
import "./saved.m.css";

export const Saved = props => {
  const queryClient = new QueryClient();
  const member = useSelector(state => get(state, ["member"], null));
  return (
    <QueryClientProvider client={queryClient}>
      <div styleName="wrapper">
        <h2 styleName="headline">saved stories</h2>
        <div styleName="border" />
        <br />
        {member ? <Stories /> : <LoginPrompt />}
      </div>
    </QueryClientProvider>
  );
};

const Stories = () => {
  const { data, status } = useQuery("saved", fetchBookmark);

  return status === "loading" ? (
    <span>
      <br />
      <Loader color="#ec2227" />
    </span>
  ) : status === "error" ? (
    <p>Oops!Something went wrong</p>
  ) : data.data && data.data.count === 0 ? (
    <p>No stories found</p>
  ) : (
    <div>
      {data &&
        data.data &&
        data.data.items.map(item => {
          // console.log(item)
          return (
            <>
              <div className="desktop-only">
                <HorizontalStoryCard
                  story={item.story_detail}
                  key={item.id}
                  boxPadding={true}
                  increaseFontSize
                  hideAuthor={false}
                />
              </div>
              <div className="mobile-only">
                <CommonStoryCard story={item.story_detail} hideAuthor={false} />
              </div>
            </>
          );
        })}
    </div>
  );
};

const LoginPrompt = () => {
  return (
    <div styleName="login-prompt-wrapper">
      <img src={assetify(LoginImg)} alt="login image" />
      <p>Oops! You need to login to save stories for later</p>
      <Link styleName="redirect-link" href={getAuthUrl()}>
        Login
        {/* <button>Login</button> */}
      </Link>
    </div>
  );
};
