import React from "react";
import { WithMember } from "@quintype/components";
import assetify from "@quintype/framework/assetify";
import get from "lodash/get";
import { parseUrl } from "query-string";
import { useSelector } from "react-redux";

import logo from "../../../../assets/images/logo-nl.png";
import { AccountModal } from "../../rows/account-modal";

import "./login.m.css";

export const LoginPage = () => {
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  const domainSlug = useSelector(state =>
    get(state, ["qt", "config", "domainSlug"], "")
  );
  const subDomainUrl = useSelector(state =>
    get(state, ["qt", "config", "publisher", "subdomain_url"], "")
  );
  const frontEndUrl = useSelector(state =>
    get(state, ["qt", "config", "publisher", "frontend_url"], "")
  );
  const params = parseUrl(currentPath);
  const redirectUrl = get(
    params,
    ["query", "redirect-url"],
    domainSlug ? subDomainUrl : frontEndUrl
  );
  return (
    <WithMember>
      {({ member, logout, checkForMemberUpdated, isLoading }) => {
        return (
          <div styleName="wrapper">
            <div style={{ textAlign: "center" }}>
              <a href={redirectUrl} aria-label="click to go thr page you last visited">
                <img src={assetify(logo)} alt="nl-logo" />
              </a>
            </div>
            <AccountModal checkForMemberUpdated={checkForMemberUpdated} />
          </div>
        );
      }}
    </WithMember>
  );
};
