import React, { useState } from "react";
import { useSelector } from "react-redux";
import PT from "prop-types";
import get from "lodash/get";
// import assetify from "@quintype/framework/assetify";
import { parseUrl } from "query-string";
import {
  WithFacebookLogin,
  WithGoogleLogin /* WithTwitterLogin */
} from "@quintype/components";
import { Button } from "../../atoms/button";
import { FaFacebookF, FaApple, FaGoogle } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import IntegrationData from "../../../../../config/integrations.js";
import "./social-login.m.css";

export function SocialLogin({ checkForMemberUpdated }) {
  const [error, setError] = useState("");
  const { appKeys } = IntegrationData();
  const facebookKey = get(appKeys, ["facebookAppkey"], "");
  const googleClientId = get(appKeys, ["googleClientId"], "");
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  // const domainSlug = useSelector(state =>
  //   get(state, ["qt", "config", "domainSlug"], "")
  // );
  // const subDomainUrl = useSelector(state =>
  //   get(state, ["qt", "config", "publisher", "subdomain_url"], "")
  // );
  // const frontEndUrl = useSelector(state =>
  //   get(state, ["qt", "config", "publisher", "frontend_url"], "")
  // );
  const sketchesHost = useSelector(state =>
    get(state, ["qt", "currentHostUrl"], "https://newslaundry.com")
  );
  const params = parseUrl(currentPath);
  // const getRedirectUrl = get(
  //   params,
  //   ["query", "redirect-url"],
  //   domainSlug ? subDomainUrl : frontEndUrl
  // );
  // const redirectUrl = new URL(getRedirectUrl);
  // redirectUrl.searchParams.append("login_success", "true");

  const getRedirectUrl = get(params, ["query", "redirect-url"], sketchesHost);
  const isPlanAvailable = get(params, ["query", "plan"], null);
  const oldRedirectUrl = new URL(getRedirectUrl);
  const searchParams = new URLSearchParams(oldRedirectUrl.search);

  searchParams.append("login_success", "true");
  if (isPlanAvailable) {
    searchParams.append("plan", isPlanAvailable);
  }
  const redirectUrl = new URL(
    `${oldRedirectUrl.origin}${
      oldRedirectUrl.pathname
    }?${searchParams.toString()}`
  );

  const firstReplaced = redirectUrl.href.replace("&", "%26");
  const secondReplaced = firstReplaced.replace("&", "%26");
  const replacedString = secondReplaced.replace("&", "%26");

  // const twitterKey = get(appKeys, ["twitterAppkey"], "");
  function socialLogin(e, login) {
    e.preventDefault();
    login()
      .then(() => {
        checkForMemberUpdated();
        checkForMemberUpdated().then(res => {
          console.log("successfully login");
        });
        console.log("i'm here===", window.location);
      })
      .catch(error => {
        console.log("error", error);
        if (error === "NO_EMAIL") {
          setError(
            "The account you are using does not have an email id. Please try with another account."
          );
        } else if (error === "NOT_LOADED") {
          setError("");
        } else if (error === "NOT_GRANTED") {
          setError(
            "There seems to be an error with social logins. Please do a manual email/password login."
          );
        } else {
          setError("Oops! Something went wrong. Please try again later.");
        }
      }); // Can also make an API call to /api/v1/members/me
  }

  // const googleOnClick = (e, serverSideLoginPath) => {
  //   window.location.href = serverSideLoginPath;
  // };

  const pushToDataLayer = (event, platform) => {
    const obj = {
      event: event,
      intent: redirectUrl.href,
      login_platform: platform
    };

    window.dataLayer.push(obj);
  };

  const AppleLogin = () => {
    const bridgekeeperServerLoginPath = `/api/auth/v1/login?auth-provider=apple&redirect-url=${replacedString}`;
    return (
      <Button
        // color="black"
        flat
        className="apple-login-btn"
        href={bridgekeeperServerLoginPath}
        socialButton
        style={{ width: "100%", justifyContent: "center" }}
        onClick={() => pushToDataLayer("login_initiate", "apple")}
      >
        <span styleName="icon">
          <FaApple width="40px" height="40px" />
        </span>
        Sign in using Apple
      </Button>
    );
  };

  return (
    <div styleName="social-login">
      {/* <h3 styleName="title">Or login with</h3> */}
      <ul styleName="buttons">
        <li styleName="button">
          <AppleLogin />
        </li>
        <li styleName="button">
          <WithFacebookLogin
            sso={true}
            redirectUrl={replacedString}
            appId={facebookKey}
            scope="email"
            emailMandatory
          >
            {({ login, serverSideLoginPath }) => (
              <Button
                // color="#3b5998"
                className="facebook-login-btn"
                flat
                href={serverSideLoginPath}
                socialButton
                style={{ width: "100%", justifyContent: "center" }}
                onClick={() => pushToDataLayer("login_initiate", "facebook")}
              >
                <span styleName="icon">
                  {/* <FbIcon color="#3b5998" width="12px" height="12px" /> */}
                  <FaFacebookF width="12px" height="12px" />
                </span>
                Sign in using Facebook
              </Button>
            )}
          </WithFacebookLogin>
        </li>
        <li styleName="button">
          <WithGoogleLogin
            sso={true}
            redirectUrl={replacedString}
            clientId={googleClientId}
            scope="email"
            emailMandatory
          >
            {({ login, serverSideLoginPath }) => (
              <Button
                // color="#dd4b39"
                className="google-login-btn"
                flat
                href={serverSideLoginPath}
                socialButton
                style={{ width: "100%", justifyContent: "center" }}
                onClick={() => pushToDataLayer("login_initiate", "google")}
              >
                <span styleName="icon">
                  <FaGoogle width="12px" height="12px" />
                </span>{" "}
                Sign in using Google
              </Button>
            )}
          </WithGoogleLogin>
        </li>
        {/* <li styleName="button">
          <WithTwitterLogin apiKey={twitterKey} emailMandatory>
            {({ login, serverSideLoginPath }) => (
              <Button color="#1da1f2" flat href={serverSideLoginPath} onClick={e => socialLogin(e, login)} socialButton>
                <span styleName="icon">
                  <TwitterIcon color="#1da1f2" width={16} height={12} />
                </span>{" "}
                Twitter
              </Button>
            )}
          </WithTwitterLogin>
        </li> */}
      </ul>
      <p styleName="error">{error}</p>
    </div>
  );
}

SocialLogin.propTypes = {
  checkForMemberUpdated: PT.func
};
