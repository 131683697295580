import React, { useState } from "react";
import PT from "prop-types";
import { parseUrl } from "query-string";
import get from "lodash/get";
import { useSelector } from "react-redux";
import Axios from "axios";
import { Button } from "../../atoms/button";
import { InputField } from "../../atoms/input-field";
import { getUser, register } from "../../helper/api";
import {
  getDigitsExceptLastN,
  getLastNDigits,
  isMobileValid
} from "../../../utils";
import { Checkbox } from "../../atoms/checkbox";

import "./forms.m.css";

export function SignUp({ onSignup }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [isChecked, setIsChecked] = useState(true);
  const [isEmailConsentCheched, setIsEmailConsentCheched] = useState(true);

  const [errorMsg, setError] = useState("");
  const currentPath = useSelector(state =>
    get(state, ["qt", "currentPath"], "")
  );
  const sketchesHost = useSelector(state =>
    get(state, ["qt", "currentHostUrl"], "https://newslaundry.com")
  );
  const authHost = useSelector(state =>
    get(state, ["qt", "config", "publisher", "publisher", "auth_host"], null)
  );
  const params = parseUrl(currentPath);

  const getRedirectUrl = get(params, ["query", "redirect-url"], sketchesHost);
  const isPlanAvailable = get(params, ["query", "plan"], null);
  const oldRedirectUrl = new URL(getRedirectUrl);
  const searchParams = new URLSearchParams(oldRedirectUrl.search);

  searchParams.append("signup_success", "true");
  if (isPlanAvailable) {
    searchParams.append("plan", isPlanAvailable);
  }
  const redirectUrl = new URL(
    `${oldRedirectUrl.origin}${
      oldRedirectUrl.pathname
    }?${searchParams.toString()}`
  );

  // console.log({ redirectURLSignup: redirectUrl.href });

  const callbackUrl = get(params, ["query", "callback-url"], sketchesHost);

  const pushToDataLayer = event => {
    const obj = {
      event: event,
      intent: redirectUrl.href
    };

    window.dataLayer.push(obj);
  };

  const signUpHandler = async e => {
    e.preventDefault();
    e.stopPropagation();

    const userObj = {
      name,
      email,
      username: email,
      password,
      phone_number: `${mobile}`,
      "dont-login": false
    };

    if (isChecked) {
      const isMobValid = isMobileValid(mobile);

      if (!isMobValid) {
        setError("Please enter a valid mobile number");
        return;
      }
    }

    const user = () => {
      getUser()
        .then(response => {
          onSignup(response.member);
          // checkForMemberInFirebase(response.member);
        })
        .catch(ex => console.log(ex.message));
    };

    register(userObj, callbackUrl, redirectUrl.href, authHost)
      .then(async response => {
        pushToDataLayer("signup_initiate");

        if (isChecked) {
          await Axios.post("/whatsapp-api/addUserToWhatsapp", {
            mobileNumber: getLastNDigits(mobile, 10),
            countryCode: getDigitsExceptLastN(mobile, 10),
            emailAddress: email,
            name: name,
            phoneCountryName: null
          });
        }

        if (isEmailConsentCheched) {
          await Axios.post(`/newsletter-api/lists/c6b749d171/members`, {
            email,
            firstName: name.split(" ")[0] || "",
            lastName: name.split(" ").slice(1).join(" ") || "",
            tags: ["nl-website-signup"]
          });
        }

        user();
        // sendEmail(user);
      })
      .catch(err => {
        if (err.status === 409) {
          setError(`The email '${userObj.email}' already exists`);
        } else {
          setError("Oops! Something went wrong");
        }
      });
  };

  return (
    <form styleName="nl-form" onSubmit={signUpHandler}>
      <label htmlFor="" styleName="form-labels">
        <span>Please enter your full name</span>
        <InputField
          name="Name"
          id="name"
          required
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="Your full name"
        />
      </label>
      <label htmlFor="" styleName="form-labels">
        <span>Please enter your email address</span>
        <InputField
          name="Email"
          type="email"
          id="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
          placeholder="Your email address"
        />
      </label>
      <label htmlFor="digest-signup">
        <Checkbox
          name="digest-signup"
          id="digest-signup"
          checked={isEmailConsentCheched}
          value={isEmailConsentCheched}
          onChange={() => setIsEmailConsentCheched(!isEmailConsentCheched)}
        />
        <span styleName="consent-status-text">
          Sign up for the weekly newsletter
        </span>
      </label>
      <label htmlFor="" styleName="form-labels">
        <span>Please set your password</span>
        <InputField
          name="Password"
          type="password"
          id="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
          placeholder="Your password"
        />
      </label>
      <label htmlFor="" styleName="form-labels">
        <span>
          Please enter your whatsapp number preceded by the country code. Ex.
          919810741333
        </span>
        <InputField
          name="Mobile Number"
          type="tel"
          id="mobile"
          maxLength="13"
          pattern="^\d{13}$"
          value={mobile}
          onChange={e => setMobile(e.target.value)}
          placeholder="Your whatsapp number with country code"
        />
      </label>
      <label htmlFor="Consent status">
        <Checkbox
          name="Consent status"
          id="Consent status"
          checked={isChecked}
          value={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <span styleName="consent-status-text">
          I wish to receive subscription related notifications on WhatsApp
        </span>
      </label>
      {errorMsg && <p styleName="error">{errorMsg}</p>}
      <Button onClick={signUpHandler} className="nl-btn-large">
        Sign up
      </Button>
    </form>
  );
}

SignUp.propTypes = {
  onSignup: PT.func,
  setMember: PT.func
};
