import React from "react";
import { paymentFaqData } from "./payment-faq-data";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from "react-accessible-accordion";
import "./payment-faq.m.css";
import { Button } from "../../../../ui/components/button";

export const PaymentFaq = () => {
  return (
    <div styleName="section-wrapper" className="full-width">
      <h2>Frequently asked questions</h2>
      <div styleName="faq-wrapper">
        {paymentFaqData.map(sec => (
          <div key={sec.id} id={`${sec.id}`}>
            <Accordion allowZeroExpanded>
              <AccordionItem key={sec.q}>
                <AccordionItemHeading>
                  <AccordionItemButton>{sec.q}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>{sec.a}</AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
        <a
          href="https://www.newslaundry.com/faq"
          target="_blank"
          rel="noopener noreferrer"
          styleName="view-more-link"
        >
          <Button variant="accent">View more</Button>
        </a>
      </div>
    </div>
  );
};
