/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import {
  LoadMoreCollectionStories
  // LazyLoadImages,
  // LazyCollection
} from "@quintype/components";
import getStoriesByCollection from "../../../utils";
import { CollectionName } from "../../atoms/collection-name";
import { CardImage } from "../../atoms/card-image";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import HorizontalStoryCardList from "../../molecules/horizontal-story-card-list";
import VerticleStoryCardList from "../../molecules/verticle-story-card-list";
import OnecolFourStories from "../../molecules/one-col-four-stories";
import { LoadMoreButton } from "../../atoms/load-more-button";

import "./videos-sub-section-page.m.css";

const BigStory = ({ story }) => {
  const getLabel = get(
    story,
    ["metadata", "story-attributes", "storyattribute", 0],
    ""
  );
  return (
    <div styleName="big-story-wrapper">
      <div styleName="hero-image">
        <CardImage story={story} />
        {getLabel && <div styleName="ribbon">{getLabel}</div>}
      </div>
      <div styleName="big-story-text-wrapper">
        <Headline story={story} externalLink={true} />
        <Subheadline story={story} />
      </div>
    </div>
  );
};

BigStory.propTypes = {
  story: PropTypes.object
};

const VideosSubSectionPage = props => {
  const collection = get(props, ["data", "collection"], {});
  const totalCount = get(
    props,
    ["data", "collectionstories", "collection", "total-count"],
    ""
  );
  const stories = getStoriesByCollection(collection);
  // const horizontalStories = stories.slice(1);
  const trendingCollection = collection.items.filter(
    item => item.slug === "trending"
  );
  const trendingStories =
    trendingCollection && getStoriesByCollection(trendingCollection[0]);
  const displayText = "No Stories found";
  const isCollectionPresent = get(
    props,
    ["data", "collection", "items"],
    []
  ).filter(collection => collection.type === "collection");
  const limit = isCollectionPresent && isCollectionPresent[0] ? "1" : "1";

  if (stories.length < 1 && isCollectionPresent.length < 1) {
    return <div styleName="not-found">Stories not found...</div>;
  }

  const subVideosTemplate = ({ onLoadMore, stories }) => {
    return (
      <div styleName="story-wrapper">
        <div className="desktop-only">
          <HorizontalStoryCardList
            stories={stories.slice(limit, stories.length)}
            hideAuthorWithTime={false}
            hideAuthor={true}
          />
        </div>
        <div className="mobile-only">
          <VerticleStoryCardList
            stories={stories.slice(limit, stories.length)}
            hideAuthor={true}
          />
        </div>
        <div styleName="load-more-btn-wrapper">
          <div style={{ textAlign: "center" }}>
            {totalCount !== stories.length && (
              <LoadMoreButton onClick={onLoadMore} />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div styleName="container" className="full-width-with-padding">
        <div className="container">
          <CollectionName
            collection={collection}
            color="#ec2227"
            borderBottomColor="#000"
          />
          <div styleName="summary">{get(collection, ["summary"], "")}</div>
          {stories.length === 0 && trendingStories.length === 0 && (
            <div>{displayText}</div>
          )}
        </div>
      </div>
      {stories.slice(0, 1).map(story => (
        <BigStory story={story} key={story.id} />
      ))}
      <div styleName="bottom-fold">
        {/* {horizontalStories.length > 0 && (
          <div styleName="story-wrapper">
            <div className="desktop-only">
              <HorizontalStoryCardList stories={horizontalStories} hideAuthorWithTime={false} hideAuthor={true} />
            </div>
            <div className="mobile-only">
              <VerticleStoryCardList stories={horizontalStories} hideAuthor={true} />
            </div>
          </div>
        )} */}
        <LoadMoreCollectionStories
          template={subVideosTemplate}
          data={{ stories: stories }}
          collectionSlug={get(props, ["data", "collection", "slug"], {})}
          numStoriesToLoad={10}
          params={{ "item-type": "story" }}
        />
        {trendingStories.length > 0 && (
          <div styleName="trending-wrapper" className="desktop-only">
            <CollectionName collection={trendingCollection[0]} />
            <OnecolFourStories stories={trendingStories} />
          </div>
        )}
      </div>
    </>
  );
};

export default VideosSubSectionPage;
