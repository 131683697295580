/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { parseUrl } from "query-string";
import PT from "prop-types";

import { SocialLogin } from "../social-login";
import { Button } from "../../atoms/button";
import { InputField } from "../../atoms/input-field";
import { login, verifyEmail, getUser } from "../../helper/api";
import { IS_OPEN_LOGIN_FORM } from "../../helper/actions";
import "./forms.m.css";
import { assignUserIdToLogger } from "../../helper/logger";

function LoginBase({
  onLogin,
  checkForMemberUpdated,
  forgotPassword,
  manageLoginForm,
  currentPath,
  sketchesHost,
  authHost
}) {
  const [user, setUser] = useState({
    email: "",
    password: ""
  });
  const [error, setError] = useState({});

  useEffect(() => {
    getUser()
      .then(response => {
        setUser(response.member);
        assignUserIdToLogger(user.id);
        if (response.member["verification-status"]) {
          // User email is verified
          return checkForMemberUpdated().then(() => {
            manageLoginForm(false);
            console.log("logged in successfully");
          });
        } else {
          // User needs to validate the email account so send out an email to verify
          return verifyEmail(response.member.email)
            .then(res => onLogin(response.member, res))
            .catch(error => setError(error));
        }
      })
      .catch(ex => console.log(ex.message));
  }, []);

  const params = parseUrl(currentPath);

  const getRedirectUrl = get(params, ["query", "redirect-url"], sketchesHost);
  const isPlanAvailable = get(params, ["query", "plan"], null);
  const oldRedirectUrl = new URL(getRedirectUrl);
  const searchParams = new URLSearchParams(oldRedirectUrl.search);

  searchParams.append("login_success", "true");
  if (isPlanAvailable) {
    searchParams.append("plan", isPlanAvailable);
  }
  const redirectUrl = new URL(
    `${oldRedirectUrl.origin}${
      oldRedirectUrl.pathname
    }?${searchParams.toString()}`
  );

  const callbackUrl = get(params, ["query", "callback-url"], sketchesHost);

  const pushToDataLayer = event => {
    const obj = {
      event: event,
      intent: redirectUrl.href,
      login_platform: "newslaundry"
    };

    window.dataLayer.push(obj);
  };

  const setData = e => {
    const userObj = { ...user };
    const fieldName = e.target.id;
    userObj[fieldName] = e.target.value;
    setUser(userObj);
  };

  const loginHandler = async e => {
    e.preventDefault();
    e.stopPropagation();
    const userObj = {
      username: user.email,
      email: user.email,
      password: user.password
    };

    if (user.email.length < 1 || user.password.length < 1) {
      setError({ message: "Please provide username and password" });
      return null;
    }

    // login(userObj)
    //   .then(({ user }) => {
    //     assignUserIdToLogger(user.id);
    //     if (user["verification-status"]) {
    //       // User email is verified
    //       return checkForMemberUpdated().then(() => {
    //         manageLoginForm(false);
    //         console.log("loged in successfully");
    //       });
    //     } else {
    //       // User needs to validate the email account so send out an email to verify
    //       return verifyEmail(user.email)
    //         .then(res => onLogin(user, res))
    //         .catch(error => setError(error));
    //     }
    //   })
    //   .catch(error => setError(error));

    login(userObj, callbackUrl, redirectUrl.href, authHost)
      .then(response => {
        pushToDataLayer("login_initiate");

        window.location.href = response["redirect-url"];
      })
      .catch(err => {
        console.log("error----------", err);
        setError(err);
      });
  };

  return (
    <React.Fragment>
      <SocialLogin checkForMemberUpdated={checkForMemberUpdated} />
      <div styleName="line-separator-wrapper">
        <div styleName="line-separator"></div>
        <p styleName="line-separator-text">Or login with</p>
        <div styleName="line-separator"></div>
      </div>
      <form styleName="nl-form" onSubmit={loginHandler}>
        <label htmlFor="email" styleName="form-labels">
          <span>Please enter your email address</span>
          <InputField
            name="Email"
            id="email"
            type="email"
            value={user.email}
            placeholder="Your email address"
            required
            onChange={setData}
            autocomplete="off"
          />
        </label>
        <label htmlFor="password" styleName="form-labels">
          <span>Please enter your password</span>
          <InputField
            name="Password"
            id="password"
            type="password"
            value={user.password}
            placeholder="Your password"
            required
            onChange={setData}
            autocomplete="off"
          />
        </label>
        {error && <p styleName="error">{error.message}</p>}
        <div styleName="actions">
          <div styleName="nl-link nl-text" role="buttton" onClick={forgotPassword}>
            Forgot Password?
          </div>
          <Button onClick={loginHandler} className="nl-btn-large">
            Login
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
}

LoginBase.propTypes = {
  checkForMemberUpdated: PT.func,
  onLogin: PT.func,
  forgotPassword: PT.func,
  manageLoginForm: PT.func,
  isLoginOpen: PT.bool
};

function mapStateToProps(state) {
  return {
    isLoginOpen: get(state, ["isLoginOpen"], false),
    currentPath: get(state, ["qt", "currentPath"], ""),
    sketchesHost: get(
      state,
      ["qt", "currentHostUrl"],
      "https://newslaundry.com"
    ),
    authHost: get(
      state,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    )
  };
}

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function(payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  }
});

const Login = connect(mapStateToProps, mapDispatchToProps)(LoginBase);

export { Login };
