import React from "react";
import PropTypes from "prop-types";
import { CardImage } from "../../atoms/card-image";
// import { SectionName } from "../../atoms/section-tag";
import { Headline } from "../../atoms/headline";
import { Subheadline } from "../../atoms/subheadline";
import { TimeStamp } from "../../atoms/time-stamp";
import Authors from "../../atoms/authors";
import "./two-col-story-card.m.css";
import { get } from "lodash";
import { Link } from "@quintype/components";

export const TwoColStoryCard = ({ story }) => {
  const sections = get(story, ["sections", "0"], {});

  return (
    <>
      <article>
        <div styleName="first-story-wrapper">
          <div styleName="hero-image" role="img" aria-label="Article image">
            <CardImage story={story} />
          </div>
          <div styleName="content-wrapper">
            <div styleName="section">
              <Link href={sections["section-url"]}>
                <p styleName="section-badge">
                  {sections["display-name"] || sections.name}
                </p>
              </Link>
              {/* <SectionName story={story} /> */}
            </div>
            <div styleName="headline">
              <Headline story={story} externalLink={true} />
            </div>
            <div styleName="subheadline">
              <Subheadline story={story} />
            </div>
            <div styleName="time-social">
              <div styleName="author">
                <Authors story={story} className="typography-font-lg" />
                <TimeStamp story={story} />
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

TwoColStoryCard.propTypes = {
  story: PropTypes.object
};
