import React from "react";
import PropTypes from "prop-types";
import assetify from "@quintype/framework/assetify";
import groupImage from "../../../../assets/images/nl-images/newsletter-CTA-on-homepage.png";
// import { Button } from "../../atoms/button";
import "./subscribe-to-newsletters.m.css";

// const defaultSummary = "We deliver weekly newsletters. Customised and curated for you. Check it out.";
// const defaulttitle = "No spam! Newslaundry newsletters, tailored to your interests.";

export const StopPressNewsletter = ({ collection = {} }) => {
  return (
    <div styleName="row-container">
      <div styleName="subscribe-wrapper" className="full-width-with-padding">
        <div styleName="group-img">
          <img
            styleName="newsletter-image"
            src={assetify(groupImage)}
            alt="newlaundry logo"
          />
        </div>
        <div styleName="content-wrapper">
          {/* <div styleName="collection-title">{collection.name || defaulttitle}</div>
          <div styleName="collection-summary">{collection.summary || defaultSummary}</div> */}
          <iframe
            src="https://stoppress.substack.com/embed"
            width="480"
            height="320"
            //   style={{border:"1px solid #EEE", background:"white""}}
            style={{ border: "1px solid #EC2227" }}
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

StopPressNewsletter.propTypes = {
  collection: PropTypes.object
};
